import { colors, shadows } from "../../theme"

import { Fragment } from "react"
import { PropsWithTheme } from "../../providers/StoreThemeProvider"
import { RadioField } from "../RadioField"
import { RadioFieldProps } from "../RadioField/RadioField"
import styled from "@emotion/styled/macro"

type Props = {
  name?: RadioFieldProps["name"]
  inputRef?: RadioFieldProps["inputRef"]
  disabled?: RadioFieldProps["disabled"]
  checked?: RadioFieldProps["checked"]
  onChange?: RadioFieldProps["onChange"]
  label: RadioFieldProps["label"]
  value: RadioFieldProps["value"]
  hasError?: boolean
  children?: import("react").ReactNode
  tag?: import("react").ReactNode
}

export function RadioFieldCard({
  children,
  name,
  inputRef,
  label,
  value,
  checked,
  onChange,
  hasError,
  tag,
}: Props) {
  return (
    <Container
      onClick={() => {
        if (onChange != null) onChange(value)
      }}
      hasError={hasError}
    >
      <RadioField
        name={name}
        label={label}
        value={value}
        checked={checked}
        onChange={onChange}
        inputRef={inputRef}
        tag={tag}
      />
      {checked && children && (
        <Fragment>
          <Divider />
          <Body>{children}</Body>
        </Fragment>
      )}
    </Container>
  )
}

type ContainerProps = {
  hasError?: boolean
}

const Container = styled.div<PropsWithTheme & ContainerProps>(props => ({
  display: "grid",
  gridGap: "0.8rem",
  cursor: "pointer",
  padding: "1.6rem",
  borderRadius: "0.4rem",
  border: `1px solid ${props.hasError ? colors.redMain : colors.greyMainLight}`,
  backgroundColor: props.hasError ? colors.redLighter : colors.white,

  "&:hover": {
    boxShadow: shadows[3],
  },
}))

const Divider = styled.div<PropsWithTheme>(props => ({
  borderBottom: `2px solid ${props.theme.colors.primary}`,
  margin: "0.8rem -1.6rem",
}))

const Body = styled.span<PropsWithTheme>(props => ({
  fontSize: "1.4rem",
  color: colors.greyDark,
  cursor: "default",
  padding: "0 0.8rem",
}))
