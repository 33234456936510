import { useEffect, useMemo, useRef, useState } from "react"

import { api } from "../api"

type Status = "idle" | "fetching" | "success" | "error"

export function usePageComponents(pageId: string) {
  const isMounted = useRef<boolean | null>(null)
  const [
    pageComponents,
    setPageComponents,
  ] = useState<api.stores.pageConfig.Get.Result>([])

  const [status, setStatus] = useState<Status>("idle")

  const isLoading = useMemo(() => status === "fetching", [status])

  useEffect(() => {
    isMounted.current = true

    setStatus("fetching")

    api.stores
      .set()
      .pageConfig.set(pageId)
      .get()
      .then(components => {
        if (isMounted.current) {
          setStatus("success")

          setPageComponents(components)
        }
      })
      .catch(err => {
        console.error(err)

        if (isMounted.current) {
          setStatus("error")
        }
      })

    return () => {
      isMounted.current = false
    }
  }, [pageId])

  return useMemo(
    () => ({
      list: pageComponents,
      isLoading,
    }),
    [pageComponents, isLoading]
  )
}
