import { Value } from "../../types"
import { ValueButton } from "../ValueButton"

type ValuesListProps = {
  isDisabledProduct: boolean
  propertyId: string
  values: Value[]
  resetVariantHasError: () => void
}

export function ValuesList({
  isDisabledProduct,
  propertyId,
  values,
  resetVariantHasError,
}: ValuesListProps) {
  return (
    <>
      {values.map(({ name, id: valueId }) => {
        return (
          <ValueButton
            valueId={valueId}
            propertyId={propertyId}
            name={name}
            key={valueId}
            isDisabledProduct={isDisabledProduct}
            resetVariantHasError={resetVariantHasError}
          />
        )
      })}
    </>
  )
}
