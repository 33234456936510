import { App } from "./App"
import { Provider } from "./providers"
import React from "react"
import ReactDOM from "react-dom"

declare global {
  interface Window {
    STORE_ID: string
    SUBDOMAIN: string
    STORE_NAME: string
    ROUTES: Array<{
      id: string
      path: string
      kind: "P" | "C" | "H"
      name: string
      category_path?: string
    }>
  }
}

function prepare() {
  if (process.env.REACT_APP_MOCK) {
    const { worker } = require("./api/_mocks/browser")

    worker.start()
  }

  return Promise.resolve()
}

prepare().then(() => {
  ReactDOM.render(
    <Provider>
      <App />
    </Provider>,
    document.getElementById("root")
  )
})
