import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { colors } from "../../../../theme"
import { readableColor } from "polished"
import styled from "@emotion/styled"

export const Grid = styled.div({
  display: "grid",
  gridTemplateColumns: `auto 940px auto`,
  paddingTop: "2.4rem",
  minHeight: "100vh",
})

export const SpinnerContainer = styled.div({
  color: colors.greyMain,
  right: "50%",
  bottom: "50%",
  transform: "translate(50%, 50%)",
  position: "absolute",
  fontSize: "6.4rem",
})

export const Container = styled.div({
  gridColumnStart: 2,
  gridColumnEnd: 3,
  cursor: "default",
})

export const BreadcrumbContainer = styled.div({
  padding: "0 0 0.8rem",
})

export const Title = styled.h1<PropsWithTheme>(props => ({
  color: readableColor(props.theme.colors.background),
  fontFamily: props.theme.typographies.titles,
  fontSize: "2.4rem",
  margin: 0,
  textTransform: "capitalize",
  paddingBottom: "0.8rem",
}))

export const SortingPane = styled.div({
  alignSelf: "flex-end",
  paddingBottom: "1.6rem",
})

export const SidebarandProductsContainer = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr 3fr",
  gridGap: "1.8rem",
  paddingTop: "2.4rem",
})

export const ProductsList = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
})

export const ProductsContainer = styled.section`
  display: grid;
  grid-template-columns: 224px 224px 224px;
  gap: 1.4rem;
`

export const PaginatorContainer = styled.div({
  padding: "3.6rem",
})
