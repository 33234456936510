import { Response, Result } from "./types"

import paths from "../../../paths"
import { service } from "../../../Service"

export async function get() {
  try {
    const response = await service.get<Response>(paths.shippings())

    return transformResponse(response.body)
  } catch (error) {
    throw new Error(`API Shippings did not respond correctly`)
  }
}

function transformResponse(response: Response): Result {
  const branches = response.branches.map(branch => {
    return {
      city: branch.city,
      email: branch.email,
      hours: branch.hours,
      id: branch.id,
      name: branch.name,
      phone: branch.phone,
      state: branch.state,
      street: branch.street,
      streetExtra: branch.street_extra,
      streetNumber: branch.street_number,
      zipCode: branch.zip_code,
    }
  })
  const custom = response.custom.map(customMethod => {
    return {
      id: customMethod.id,
      name: customMethod.name,
      description: customMethod.description,
      priceType: customMethod.price_type,
      price: customMethod.price,
      currency: customMethod.currency,
      showDeliveryTime: customMethod.show_delivery_time,
      deliveryTime: customMethod.delivery_time,
    }
  })
  const freeShipping = {
    active: response.free_shipping.active,
    starterAmount: response.free_shipping.starter_amount,
  }

  return {
    branches,
    custom,
    freeShipping,
  }
}

export * as Get from "./types"
