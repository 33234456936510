import {
  BREAKPOINTS,
  HEADER_HEIGHT,
  colors,
  mediaQuery,
} from "../../../../theme"
import { Fragment, useEffect } from "react"
import {
  usePaymentMethods,
  useProductAPI,
  useScreenSize,
} from "../../../../hooks"

import { Breadcrumb } from "../../../../components/Breadcrumb"
import { ImageVideoGallery } from "../ImageVideoGallery"
import { NamePriceActions } from "../NamePriceActions"
import { PaymentMethods } from "../../../../components/PaymentMethods"
import { ProductAttributes } from "../ProductAttributes"
import { ProductDescription } from "../ProductDescription"
import { ProductNotAvailable } from "../ProductNotAvailable"
import { Spinner } from "../../../../icons"
import { WhatsAppButton } from "../../../../components/WhatsAppButton"
import { api } from "../../../../api"
import { getProductVIPUrl } from "../../../../utils"
import { plural } from "@lingui/macro"
import styled from "@emotion/styled/macro"
import { useAddToCart } from "../../hooks/useAddToCart"
import { useHistory } from "react-router-dom"
import { useProductInfo } from "../../hooks/useProductInfo"
import { useProductStore } from "../../provider/ProductStoreProvider"
import { useSelectedImage } from "../../hooks/useSelectedImage"
import { useStoreState } from "../../../../providers/StoreProvider"

type ProductViewProps = {
  productId: string
  urlTitle: string
}

export function ProductView({ productId, urlTitle }: ProductViewProps) {
  const { isDesktop, isMobile } = useScreenSize()

  const { isLoading, isProductNotFound } = useProductAPI(productId)
  const { product } = useProductStore()
  const paymentMethods = usePaymentMethods()

  const {
    images,
    description,
    breadcrumb,
    status,
    identifier: productID,
    attributes,
    title,
  } = product

  const { replace } = useHistory()

  useEffect(() => {
    const formattedTitle = getProductVIPUrl(title)

    if (!!formattedTitle && formattedTitle !== urlTitle) {
      replace(`/${formattedTitle}-rtid${productId}/`)
    }
  }, [productId, replace, title, urlTitle])

  const isNotAvailable = status === "P" || isProductNotFound
  const whatsAppButtonInfo = useStoreState()[0].config.whatsapp

  let productCategoryPath

  if (breadcrumb.length > 0) {
    productCategoryPath = window.ROUTES.find(route => {
      return (
        breadcrumb[breadcrumb.length - 1].id === route.id && route.kind === "C"
      )
    })?.path
  }

  const { selectedImage } = useSelectedImage()

  const {
    productHasStock,
    minPrice,
    productHasDifferentPrices,
  } = useProductInfo()

  const { cartIsLoading, handleAddToCart, cartError } = useAddToCart(
    productId,
    selectedImage
  )

  return (
    <ProductMain>
      <Grid productNotAvailable={isNotAvailable}>
        {isLoading && product.variants.length === 0 ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          <Container productNotAvailable={isNotAvailable}>
            {isNotAvailable ? (
              <ProductNotAvailable
                redirectUrl={productCategoryPath ? productCategoryPath : ""}
                category={
                  breadcrumb.length
                    ? breadcrumb[breadcrumb.length - 1].name
                    : ""
                }
              />
            ) : (
              <Fragment>
                <BreadcrumbContainer>
                  <Breadcrumb links={breadcrumb} />
                </BreadcrumbContainer>
                <ProductContainer>
                  {isDesktop ? (
                    <DesktopLayout
                      images={images}
                      image={selectedImage}
                      description={description}
                      namePriceActions={
                        <NamePriceActions
                          cartError={cartError}
                          cartIsLoading={cartIsLoading}
                          handleAddToCart={handleAddToCart}
                          minPrice={minPrice}
                          productID={productID}
                          productHasDifferentPrices={productHasDifferentPrices}
                          productHasStock={productHasStock}
                          canQuote={product.canQuote}
                        />
                      }
                      paymentMethods={paymentMethods.info}
                      isPaymentMethodsLoading={paymentMethods.isLoading}
                      attributes={attributes}
                    />
                  ) : (
                    <Fragment>
                      <ImageGalleryFigure>
                        <ImageGalleryContainer>
                          <ImageVideoGallery
                            images={images}
                            selectedImage={selectedImage}
                            stickyLabel={plural(images.length, {
                              one: "# foto",
                              other: "# fotos",
                            })}
                          />
                        </ImageGalleryContainer>
                      </ImageGalleryFigure>
                      <NamePriceActions
                        cartError={cartError}
                        cartIsLoading={cartIsLoading}
                        handleAddToCart={handleAddToCart}
                        minPrice={minPrice}
                        productID={productID}
                        productHasDifferentPrices={productHasDifferentPrices}
                        productHasStock={productHasStock}
                        canQuote={product.canQuote}
                      />

                      <DescriptionContainer>
                        {attributes.length > 0 && (
                          <ProductAttributes attributes={attributes} />
                        )}
                        <ProductDescription>{description}</ProductDescription>
                      </DescriptionContainer>
                      <PaymentMethodsContainer>
                        <PaymentMethods
                          paymentMethods={paymentMethods.info}
                          isPaymentMethodsLoading={paymentMethods.isLoading}
                        />
                      </PaymentMethodsContainer>
                    </Fragment>
                  )}
                </ProductContainer>
              </Fragment>
            )}
            {whatsAppButtonInfo?.showWhatsappButton && (
              <WhatsAppButton
                countryCode={whatsAppButtonInfo?.countryCode || ""}
                phoneNumber={whatsAppButtonInfo?.phoneNumber || ""}
                description={whatsAppButtonInfo.description || ""}
                isVipMobile={isMobile}
              />
            )}
          </Container>
        )}
      </Grid>
    </ProductMain>
  )
}

type DesktopLayoutProps = {
  images: api.products.variations.Get.Image[]
  image?: api.products.variations.Get.Image
  description: string
  namePriceActions: import("react").ReactNode
  paymentMethods: api.paymentMethods.Get.Result
  isPaymentMethodsLoading: boolean
  attributes: {
    id: string
    name: string
    value: string
    valueType: string
  }[]
}

function DesktopLayout({
  images,
  image,
  description,
  namePriceActions,
  paymentMethods,
  isPaymentMethodsLoading,
  attributes,
}: DesktopLayoutProps) {
  return (
    <Fragment>
      <DesktopLeftContainer>
        <ImageGalleryContainer>
          <ImageVideoGallery images={images} selectedImage={image} />
        </ImageGalleryContainer>
        <DescriptionContainer>
          {attributes.length > 0 && (
            <ProductAttributes attributes={attributes} />
          )}
          <ProductDescription>{description}</ProductDescription>
        </DescriptionContainer>
      </DesktopLeftContainer>
      <DesktopRightContainer>
        {namePriceActions}
        <PaymentMethodsContainer>
          <PaymentMethods
            paymentMethods={paymentMethods}
            isPaymentMethodsLoading={isPaymentMethodsLoading}
          />
        </PaymentMethodsContainer>
      </DesktopRightContainer>
    </Fragment>
  )
}

type GridProps = {
  productNotAvailable: boolean
}

const Grid = styled.div<GridProps>(({ productNotAvailable }) => ({
  minHeight: productNotAvailable
    ? 0
    : `calc(100vh - ${HEADER_HEIGHT.MOBILE}px)`,

  [mediaQuery.desktop]: {
    minHeight: 0,
    flexGrow: 1,
    display: "grid",
    gridTemplateColumns: `auto ${BREAKPOINTS.DESKTOP_MIN}px auto`,
  },
}))

const SpinnerContainer = styled.div({
  color: colors.greyMain,
  right: "50%",
  bottom: "50%",
  transform: "translate(50%, 50%)",
  position: "absolute",
  fontSize: "6.4rem",
})

type ContainerProps = {
  productNotAvailable: boolean
}

const Container = styled.div<ContainerProps>(({ productNotAvailable }) => ({
  minHeight: productNotAvailable ? 0 : "100vh",
  overflow: "auto",

  [mediaQuery.desktop]: {
    padding: "0 0 4.8rem",
    gridColumnEnd: 3,
    gridColumnStart: 2,
    overflow: "visible",
  },
}))

const DesktopLeftContainer = styled.div({
  flex: "16",
})

const DesktopRightContainer = styled.div({
  flex: "9",
  borderLeftWidth: 1,
  borderLeftStyle: "solid",
  borderLeftColor: colors.greyLight,
})

const BreadcrumbContainer = styled.div({
  padding: "2rem 0.8rem",

  [mediaQuery.desktop]: {
    padding: "3.6rem 0 2.4rem",
  },
})

const ProductContainer = styled.div({
  backgroundColor: colors.white,
  paddingBottom: "7.2rem",

  [mediaQuery.desktop]: {
    borderRadius: 4,
    display: "flex",
    paddingBottom: 0,
    boxShadow: "0 2px 4px 0 hsla(0, 0%, 0%, 0.28)",
  },
})

const ImageGalleryContainer = styled.div({
  [mediaQuery.desktop]: {
    padding: "2.4rem 0.4rem 2.4rem",
  },
})

const DescriptionContainer = styled.section({
  borderTopWidth: 1,
  borderTopStyle: "solid",
  borderTopColor: colors.greyLight,
  padding: "2.4rem 2.2rem 2.4rem",

  [mediaQuery.desktop]: {
    padding: "4.6rem",
  },
})

const PaymentMethodsContainer = styled.aside({
  borderTopWidth: 1,
  borderTopStyle: "solid",
  borderTopColor: colors.greyLight,
  padding: "2.4rem 2.2rem 4rem",

  [mediaQuery.desktop]: {
    padding: "2.8rem 2.4rem",
  },
})

const ProductMain = styled.main()

const ImageGalleryFigure = styled.figure()
