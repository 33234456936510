import { PropsWithTheme } from "../../../../../providers/StoreThemeProvider"
import { mediaQuery } from "../../../../../theme"
import styled from "@emotion/styled/macro"

type Props = {
  name: string
  price: string | import("react").ReactNode
}

export function ProductNamePrice({ name, price }: Props) {
  return (
    <div data-testid="product-name-price">
      <Name data-testid="product-name">{name}</Name>
      <Price data-testid="product-price">{price}</Price>
    </div>
  )
}

const Name = styled.h1<PropsWithTheme>(props => ({
  fontSize: "2.6rem",
  fontWeight: "normal",
  wordBreak: "break-word",
  margin: 0,
  color: props.theme.colors.texts,
  fontFamily: props.theme.typographies.texts,

  [mediaQuery.desktop]: {
    marginBottom: "1.2rem",
  },
}))

const Price = styled.span<PropsWithTheme>(props => ({
  fontSize: "3.2rem",
  fontWeight: "bold",
  display: "flex",
  alignItems: "baseline",
  color: props.theme.colors.texts,
  fontFamily: props.theme.typographies.texts,

  "& sup": {
    fontSize: "2rem",
  },
}))
