import { mediaQuery, shadows } from "../../../../theme"

import { WarholeImage } from "store-components"
import styled from "@emotion/styled/macro"
import { t } from "@lingui/macro"
import { useCheckoutScreen } from "../../hooks/useCheckoutScreen"
import { useHistory } from "react-router"
import { useStoreState } from "../../../../providers/StoreProvider"

interface Props {
  showAbort: () => void
}

export function HeaderCheckout({ showAbort }: Props) {
  const history = useHistory()
  const { currentStep } = useCheckoutScreen()
  const { img: logoImg, ...logoHeader } = useStoreState()[0].config.logoHeader
  const { backgroundColor } = useStoreState()[0].config.header
  const logoParsed = logoImg ? (JSON.parse(logoImg) as WarholeImage) : undefined

  const onClick = () => {
    if (currentStep === 3) {
      return history.replace("/")
    }
    return showAbort()
  }

  return (
    <HeaderContainer changeDefaultStyle={currentStep === 3}>
      <Grid backgroundColor={backgroundColor}>
        <ContentContainer>
          {logoHeader.type === "T" && (
            <Logo
              color={logoHeader.color}
              typography={logoHeader.typography}
              onClick={onClick}
              data-testid="headerCheckout-logo"
            >
              {logoHeader.text}
            </Logo>
          )}
          {logoHeader.type === "I" && (
            <LogoImage onClick={onClick}>
              <img src={logoParsed?.medium} alt={t`Logo de marca`} />
            </LogoImage>
          )}
        </ContentContainer>
      </Grid>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div<{ changeDefaultStyle: boolean }>(
  ({ changeDefaultStyle }) => ({
    ...(changeDefaultStyle && {
      boxShadow: shadows[2],
      position: "relative",
    }),
  })
)

const Grid = styled.div<{ backgroundColor: string }>(({ backgroundColor }) => ({
  height: "5.2rem",
  display: "grid",
  gridTemplateColumns: `1.6rem auto 1.6rem`,
  backgroundColor: backgroundColor,

  [mediaQuery.desktop]: {
    gridTemplateColumns: `auto 940px auto`,
    height: "7.6rem",
  },
}))

const ContentContainer = styled.div({
  gridColumnStart: 2,
  gridColumnEnd: 3,
  cursor: "default",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
})

type LogoProps = {
  color: string
  typography: string
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const Logo = styled.div<LogoProps>(({ color, typography, onClick }) => ({
  color,
  fontFamily: typography,
  fontSize: "2.4rem",
  textDecoration: "none",
  gridArea: "logo",
  cursor: onClick ? "pointer" : "default",

  [mediaQuery.desktop]: {
    fontSize: "3.6rem",
  },
}))

type LogoImageProps = {
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const LogoImage = styled.div<LogoImageProps>(({ onClick }) => ({
  "& img": {
    maxHeight: "3.2rem",

    [mediaQuery.desktop]: {
      maxHeight: "4.4rem",
    },
  },
  gridArea: "logo",
  cursor: onClick ? "pointer" : "default",
}))
