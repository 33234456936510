import { ReactNode, useEffect, useState } from "react"

import { AlertProps } from "../components/AlertBox"
import { t } from "@lingui/macro"

type ErrorAlert = {
  text: ReactNode
  variant: Required<AlertProps["variant"]>
}

type Props = {
  stock: number
  hasStockAvailable: boolean
  quantity: number
  handleStockError: (err: boolean) => void
}

export function useStockError({
  stock,
  hasStockAvailable,
  quantity,
  handleStockError,
}: Props) {
  const [errorAlert, setErrorAlert] = useState<ErrorAlert>()

  useEffect(() => {
    let errorAlert: ErrorAlert | undefined

    if (stock === 0) {
      errorAlert = {
        text: t`Ya no quedan unidades en stock. ¿Y si buscás otro producto?`,
        variant: "error",
      }
    } else if (!hasStockAvailable) {
      errorAlert = {
        text:
          stock === 1
            ? t`Solo queda 1 unidad disponible en stock.`
            : t`Solo quedan ${stock} unidades disponibles en stock.`,
        variant: "warning",
      }
    }

    setErrorAlert(errorAlert || undefined)
    handleStockError(!hasStockAvailable)
  }, [stock, quantity, hasStockAvailable, handleStockError])

  return { errorAlert }
}
