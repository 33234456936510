import { colors, mediaQuery } from "../../theme"
import { useEffect, useState } from "react"

import { MaintenanceMode } from "../../icons"
import { RealTrends } from "store-components/dist/icons"
import { Result } from "../../api/maintenance_mode/get/types"
import { Spinner } from "../../icons"
import { Trans } from "@lingui/macro"
import { WarholeImage } from "store-components"
import { api } from "../../api"
import styled from "@emotion/styled/macro"

export function Maintenance() {
  const [maintenanceData, setMaintenanceData] = useState<Result>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const logoParsed = maintenanceData?.logo.img
    ? (JSON.parse(maintenanceData?.logo.img) as WarholeImage)
    : undefined

  useEffect(() => {
    setIsLoading(true)
    api.maintenanceMode
      .get()
      .then(response => {
        setIsLoading(false)
        setMaintenanceData(response)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }
  const shopYear = new Date().getFullYear()

  return (
    <Container>
      {maintenanceData?.logo.type === "T" && (
        <LogoText
          color={maintenanceData != null ? maintenanceData?.logo.color : ""}
          typography={
            maintenanceData != null ? maintenanceData?.logo.typography : ""
          }
        >
          {maintenanceData?.logo.text}
        </LogoText>
      )}
      {maintenanceData?.logo.type === "I" && (
        <LogoImageContainer>
          <LogoImage src={logoParsed?.medium} alt="logo-shop" />
        </LogoImageContainer>
      )}

      <MessageContainer>
        <MaintenanceMode />
        <Message>
          {maintenanceData?.maintenanceMessage ? (
            <Trans>{maintenanceData.maintenanceMessage}</Trans>
          ) : (
            <>
              <Trans>
                Estamos mejorando nuestra tienda.
                <br /> Te esperamos muy pronto de nuevo.
              </Trans>
            </>
          )}
        </Message>
      </MessageContainer>
      <Footer>
        <MadeByContainer>
          <MadeBy>
            <Trans id={"Creado con "} />
          </MadeBy>
          <MadeByLogo>
            <RealTrends />
          </MadeByLogo>
        </MadeByContainer>
        <CopyrightContainer>
          <CopyrightText>
            © Copyright {maintenanceData?.shopName} - {shopYear}.
          </CopyrightText>
          <CopyrightText>
            <Trans>Todos los derechos reservados</Trans>
          </CopyrightText>
        </CopyrightContainer>
      </Footer>
    </Container>
  )
}

const Container = styled.div({
  height: "100vh",
  paddingTop: "3.6rem",
  backgroundColor: colors.white,
})

const LogoImageContainer = styled.div(() => ({
  width: "100%",
  display: "inline-block",
  textAlign: "center",
}))

const LogoImage = styled.img(() => ({
  height: "4.8rem",
  width: "auto",

  [mediaQuery.desktop]: {
    height: "8rem",
  },
}))

type LogoTextProps = {
  color: string
  typography: string
}

const LogoText = styled.div<LogoTextProps>(({ color, typography }) => ({
  color: color,
  fontFamily: typography,
  fontSize: "4.8rem",
  height: "4.8rem",
  textAlign: "center",
}))

const MessageContainer = styled.div({
  alignItems: "center",
  height: "70%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "1.6rem 3.2rem",

  "& svg": {
    fontSize: "7.2rem",
  },

  [mediaQuery.desktop]: {
    height: "80%",

    "& svg": {
      width: "20%",
      height: "20%",
    },
  },
})

const Message = styled.div({
  color: colors.greyMedium,
  fontFamily: "Open Sans",
  fontSize: "1.6rem",
  textAlign: "center",
  marginTop: "3.4rem",
  wordBreak: "break-word",

  [mediaQuery.desktop]: {
    maxWidth: "50%",
  },
})

const Footer = styled.div({
  alignItems: "center",
  backgroundColor: colors.greyMedium,
  bottom: 0,
  padding: "2.8rem 5rem",
  position: "absolute",
  width: "100%",
  height: "12rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",

  [mediaQuery.desktop]: {
    height: "5rem",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0px 5rem",
  },
})

const MadeByContainer = styled.div(() => ({
  display: "flex",
  gridGap: "0.8rem",
}))

const MadeBy = styled.p(() => ({
  color: colors.white,
  fontSize: "1rem",
  fontFamily: "Open Sans, sans-serif",
}))

const MadeByLogo = styled.span(() => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  fontSize: "5.6rem",

  "& svg": {
    height: "0.6em",
    color: colors.white,
  },
}))

const CopyrightContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "1.6rem",

  [mediaQuery.desktop]: {
    flexDirection: "row",
    marginTop: "unset",
  },
}))

const CopyrightText = styled.p(() => ({
  color: colors.white,
  fontSize: "1rem",
  fontFamily: "Open Sans, sans-serif",
  margin: "0px 0px 2px 0px",

  [mediaQuery.desktop]: {
    margin: "0px 0px 0px 2px",
  },
}))

const SpinnerContainer = styled.div({
  color: colors.greyMain,
  right: "50%",
  bottom: "50%",
  transform: "translate(50%, 50%)",
  position: "absolute",
  fontSize: "6.4rem",
})
