import { Container } from "./Container"

export function CheckoutChevron() {
  return (
    <Container viewBox="0 0 12 8">
      <path
        d="M6 5.76667L0.733333 0.5L0 1.16667L6 7.23333L12 1.16667L11.2667 0.5L6 5.76667Z"
        fill="currentColor"
      />
    </Container>
  )
}
