import { Action, Meta, Ordering, Products, State } from "./types"
import { createContext, useContext, useReducer } from "react"

import { productsReducer } from "./reducers/productsReducer"
import { useLocation } from "react-router-dom"

type ContextType = {
  products: Products
  meta: Meta
  ordering: Ordering
  dispatch: (action: Action) => void
}

const SearchResultContext = createContext<ContextType | null>(null)

type Props = {
  children: import("react").ReactNode
}

export const ProductStoreProvider = ({ children }: Props) => {
  const { search: locationSearch } = useLocation()
  const urlSearchParams = new URLSearchParams(locationSearch)
  const urlOrdering = (urlSearchParams.get("ordering") as Ordering) || "-id"
  const limit = 12

  const initialState: State = {
    meta: {
      totalCount: 0,
      breadcrumb: [],
      attributes: [],
      subcategories: [],
      limit: limit,
      elements: 0,
      offset: 0,
    },
    objects: [],
    ordering: urlOrdering,
  }
  const [{ meta, objects: products, ordering }, dispatch] = useReducer(
    productsReducer,
    initialState
  )

  const value = { meta, products, ordering, dispatch }

  return (
    <SearchResultContext.Provider value={value}>
      {children}
    </SearchResultContext.Provider>
  )
}

export const useProductsStore = () => {
  const searchResultStore = useContext(SearchResultContext)
  if (!searchResultStore) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error("You have forgot to use StoreProvider, shame on you.")
  }
  return searchResultStore
}
