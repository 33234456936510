import { Response, Result } from "./types"

import paths from "../../../paths"
import { service } from "../../../Service"

export function get(productId?: string) {
  return async function () {
    if (productId == null) {
      throw Error("You cannot get product's variation without product ID")
    }

    try {
      const response = await service.get<Response>(paths.variations(productId))

      if (response.status.code === 404) {
        return 404
      } else {
        return transformResponse(response.body)
      }
    } catch (error) {
      throw new Error(`API storesProduct did not respond correctly`)
    }
  }
}

function transformResponse(response: Response): Result {
  const variants = response.objects.map(variant => {
    return {
      id: variant.id,
      stock: variant.stock,
      price: variant.price,
      currency: variant.currency,
      imageId: `${variant.image_id}`,
      values: variant.values,
      freeShipping: !!variant.free_shipping,
    }
  })

  const images = response.meta.images.map(image => ({
    id: `${image.id}`,
    warholeID: image.warhole_id,
    thumbnails: {
      ...image.thumbnails,
    },
  }))

  const attributes = response.meta.attributes.map(attribute => {
    return {
      id: attribute.id,
      name: attribute.name,
      value: attribute.value,
      valueType: attribute.value_type,
    }
  })

  return {
    breadcrumb: response.meta.breadcrumb.map(breadcrumb => {
      return {
        id: `${breadcrumb.id}`,
        url: breadcrumb.url,
        name: breadcrumb.name,
      }
    }),
    description: response.meta.description,
    title: response.meta.title,
    identifier: response.meta.identifier,
    coverImageId: `${response.meta.cover_image_id}`,
    properties: response.meta.properties,
    images,
    status: response.meta.status,
    canQuote: response.meta.can_quote,
    variants,
    attributes,
  }
}
