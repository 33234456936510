import { Shipping } from "../../../../../../../../../api/stores/checkout"
import { t } from "@lingui/macro"

export function formatBillingToString(receiver?: Shipping["receiver"]) {
  if (!receiver) return ""

  const firstName = receiver.firstName ? `${receiver.firstName} ` : ""
  const lastName = receiver.lastName ? `${receiver.lastName} ` : ""
  const idenType = receiver.identityType
    ? `- ${receiver.identityType === "DU" ? t`DNI` : t`Otro`} `
    : ""
  const idenNumber = receiver.identityNumber ? `${receiver.identityNumber}` : ""

  return " " + firstName + lastName + idenType + idenNumber
}
