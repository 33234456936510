import { CustomPaymentMethods } from "./CustomPaymentMethods"
import { MercadoPagoPaymentMethods } from "./MercadoPagoPaymentMethods"
import { Spinner } from "../../../../icons"
import { colors } from "../../../../theme"
import styled from "@emotion/styled/macro"
import { usePaymentMethods } from "../../../../hooks"

export function PaymentMethodsCart() {
  const { info, isLoading } = usePaymentMethods()
  const { mercadoPago, custom } = info.objects

  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )

  const customPaymentMethodsWithDiscount = custom.filter(
    customMethod => customMethod.offersDiscount
  )
  const customPaymentMethodsWithoutDiscount = custom.filter(
    customMethod => !customMethod.offersDiscount
  )

  return (
    <Container data-testid="payment-methods-cart" id="payment-methods-cart">
      {customPaymentMethodsWithDiscount.length > 0 ? (
        <CustomPaymentMethods
          customMethods={customPaymentMethodsWithDiscount}
        />
      ) : null}
      {mercadoPago.length > 0 ? (
        <MercadoPagoPaymentMethods mercadoPagoMethods={mercadoPago} />
      ) : null}
      {customPaymentMethodsWithoutDiscount.length > 0 ? (
        <CustomPaymentMethods
          customMethods={customPaymentMethodsWithoutDiscount}
        />
      ) : null}
    </Container>
  )
}

const SpinnerContainer = styled.div({
  display: "flex",
  fontSize: "4rem",
  justifyContent: "center",
  color: colors.greyMain,
})

const Container = styled.div({
  display: "grid",
  gridGap: "2rem",
  wordBreak: "break-word",
})
