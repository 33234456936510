import React, {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"

const screenBlockersContext = createContext<React.Dispatch<
  React.SetStateAction<string[]>
> | null>(null)

type ScreenBlockersProps = {
  children: import("react").ReactNode
}

export function ScreenBlockersProvider({ children }: ScreenBlockersProps) {
  const [, setScreenBlockers] = useState<string[]>([])

  return (
    <screenBlockersContext.Provider value={setScreenBlockers}>
      {children}
    </screenBlockersContext.Provider>
  )
}

export function useScreenBlockers(id: string) {
  const setScreenBlockers = useContext(screenBlockersContext)

  if (setScreenBlockers == null) {
    throw new Error(
      "You have forgot to use ScreenBlockersProvider, shame on you."
    )
  }

  const bodyWidth = useRef(document.body.style.width)

  const register = useCallback(() => {
    setScreenBlockers(sb => {
      if (sb.includes(id)) return sb

      if (sb.length <= 0) {
        const scrollY = `-${window.scrollY}px`
        document.body.style.position = "fixed"
        document.body.style.top = scrollY
        document.body.style.width = "100vw"
      }

      return [...sb, id]
    })
  }, [id, setScreenBlockers])

  const unregister = useCallback(() => {
    setScreenBlockers(sb => {
      if (!sb.includes(id)) return sb

      if (sb.length === 1) {
        const scrollTo = document.body.style.top
        document.body.style.position = ""
        window.scrollTo(0, parseInt(scrollTo || "0") * -1)
        document.body.style.top = ""
        document.body.style.width = bodyWidth.current
      }

      return sb.filter(i => i !== id)
    })
  }, [id, setScreenBlockers])

  useLayoutEffect(() => {
    const _bodyWidth = bodyWidth.current

    return () => {
      setScreenBlockers(sb => {
        if (sb.length > 0) {
          document.body.style.position = ""
          document.body.style.top = ""
          document.body.style.width = _bodyWidth

          return []
        }

        return sb
      })
    }
  }, [setScreenBlockers])

  return useMemo(
    () => ({
      register,
      unregister,
    }),
    [register, unregister]
  )
}
