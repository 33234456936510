import {
  checkoutRoute,
  locationMatchCheckout,
} from "../../../../utils/locationMatchCheckout"
import { colors, mediaQuery } from "../../../../theme"
import { useCallback, useEffect, useState } from "react"
import { useCart, useCheckout, useModal } from "../../../../hooks"

import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { Trans } from "@lingui/macro"
import { readableColor } from "polished"
import styled from "@emotion/styled"
import { useHistory } from "react-router"

export const CartModalCounter = () => {
  const history = useHistory()
  const { data: cartData } = useCart()
  const { openModal, closeModal } = useModal().actions
  const { deleteCheckout } = useCheckout()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const timeLeftConst =
    cartData.meta.hasCheckout && cartData.meta.timeLeft
      ? cartData.meta.timeLeft
      : null

  const getModalContent = useCallback(
    (timeLeft: number) => {
      let title: JSX.Element, text: JSX.Element

      if (timeLeft > 600000) {
        title = <Trans>¡Aún estás a tiempo!</Trans>
        text = <Trans>Llevate eso que elegiste antes de que se agote.</Trans>
      } else if (timeLeft > 60000) {
        const minutes = Math.floor(timeLeft / 60000)
        title = <Trans>¡Todavía tenés tiempo!</Trans>
        text = (
          <Trans>
            Llevate eso que elegiste antes de que se agote. Te quedan {minutes}{" "}
            minutos para confirmar.
          </Trans>
        )
      } else {
        title = <Trans>¡Todavía tenés tiempo!</Trans>
        text = (
          <Trans>
            Llevate eso que elegiste antes de que se agote. Te queda menos de un
            minuto para confirmar.
          </Trans>
        )
      }
      return {
        title: <ModalTitle>{title}</ModalTitle>,
        body: (
          <>
            <ModalText>{text}</ModalText>
            <ModalButtons>
              <ModalButton
                actionType="cancel"
                onClick={() => {
                  deleteCheckout()
                  closeModal()
                }}
              >
                <Trans>Cancelar compra</Trans>
              </ModalButton>
              <ModalButton
                actionType="confirm"
                onClick={() => {
                  closeModal()
                  history.replace(checkoutRoute || "")
                }}
              >
                <Trans>Continuar compra</Trans>
              </ModalButton>
            </ModalButtons>
          </>
        ),
      }
    },
    [closeModal, history, deleteCheckout]
  )

  useEffect(() => {
    if (
      cartData.meta.hasCheckout &&
      cartData.objects.length > 0 &&
      !locationMatchCheckout() &&
      timeLeftConst != null &&
      !isModalOpen
    ) {
      openModal({
        ...getModalContent(timeLeftConst),
        disableCloseButton: true,
      })
      setIsModalOpen(true)
    }
  }, [
    isModalOpen,
    openModal,
    getModalContent,
    timeLeftConst,
    cartData.meta.hasCheckout,
    cartData.objects.length,
  ])

  return <></>
}

const ModalTitle = styled.h1<PropsWithTheme>(props => ({
  fontFamily: props.theme.typographies.titles,
  fontSize: "1.6rem",
  color: colors.greyDark,
  textAlign: "center",

  [mediaQuery.desktop]: {
    fontSize: "1.8rem",
  },
}))

const ModalText = styled.p<PropsWithTheme>({
  fontSize: "1.4rem",
  color: colors.greyDark,
  marginTop: "1.6rem",
  marginBottom: "4.2rem",

  [mediaQuery.desktop]: {
    fontSize: "1.6rem",
    marginBottom: "2.4rem",
  },
})

const ModalButtons = styled.div({
  display: "grid",
  gridAutoFlow: "column",
  gridGap: "1.6rem",
  justifyContent: "end",
})

type ModalButtonProps = {
  actionType: "cancel" | "confirm"
}

const ModalButton = styled.button<PropsWithTheme & ModalButtonProps>(props => {
  const primaryColor = props.theme.colors.primary

  const confirm = {
    backgroundColor: primaryColor,
    color: readableColor(primaryColor),
    border: `1px solid ${primaryColor}`,
    borderRadius: 4,
  }

  const cancel = {
    backgroundColor: colors.white,
    color: colors.black,
    border: "none",

    "&:hover": {
      textDecoration: "underline",
    },
  }

  return {
    fontSize: "1.4rem",
    fontFamily: props.theme.typographies.titles,
    padding: "0.8rem 1.6rem",
    cursor: "pointer",
    ...(props.actionType === "confirm" ? confirm : cancel),

    [mediaQuery.desktop]: {
      fontSize: "1.6rem",
    },
  }
})
