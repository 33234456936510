import { useEffect } from "react"
import { useLocalStorage } from "../../../hooks/useLocalStorage"

export function useScrollToProduct() {
  const [lastProductViewed, setLastProductViewed] = useLocalStorage(
    "lastProductViewed",
    ""
  )

  useEffect(() => {
    const element = document.getElementById(lastProductViewed)

    if (element) {
      element.scrollIntoView({ block: "center", behavior: "smooth" })
      setLastProductViewed("")
    }
  }, [lastProductViewed, setLastProductViewed])

  return { setLastProductViewed }
}
