import { ErrorResult, Result } from "../../../api/stores/checkout"

import { CheckoutScreenContext } from "../providers/CheckoutScreenProvider"
import { FormData } from "../types"
import { api } from "../../../api"
import { useCheckout } from "../../../hooks"
import { useContext } from "react"

export type TypeModal = "abort" | "reminder" | "expired"

export const useCheckoutScreen = () => {
  const contextValue = useContext(CheckoutScreenContext)

  if (contextValue === null) {
    throw new Error(
      "you must use useCheckoutScreen within a CheckoutScreenProvider"
    )
  }

  const { setStatus, setData } = useCheckout()
  const {
    currentStep: [currentStep, setCurrentStep],
    error: [error, setError],
  } = contextValue

  const submitForm = (data: FormData, callback?: (data: Result) => void) => {
    setStatus("fetching")

    api.stores
      .set()
      .checkout.set()
      .put({
        checkoutStep: currentStep === 1 ? "contact" : "billing",
        ...data,
      })
      .then(response => {
        setStatus("success")
        if (isCheckoutDataWithError(response)) {
          setError(response.error)
        } else {
          setData(response)
          if (callback) {
            callback(response)
          }
        }
      })
      .catch(err => {
        console.log(err)
        setStatus("error")
      })
  }
  return {
    currentStep,
    setCurrentStep,
    error: error,
    setError: setError,
    submitForm,
  }
}

function isCheckoutDataWithError(
  checkoutData: Result | ErrorResult
): checkoutData is ErrorResult {
  return (checkoutData as api.stores.checkout.ErrorResult).error != null
}
