import { useEffect, useState } from "react"

import { api } from "../../../api"
import { useProductStore } from "../provider/ProductStoreProvider"

export function useSelectedImage() {
  const { product, selectedVariant } = useProductStore()
  const { images, variants, coverImageId } = product

  const [
    selectedImage,
    setSelectedImage,
  ] = useState<api.products.variations.Get.Image>(images[0])

  useEffect(() => {
    let matchedImages = images[0]

    if (!selectedVariant) {
      for (let image of images) {
        if (image.id === coverImageId) {
          matchedImages = image
          break
        }
      }
    } else {
      for (let variant of variants) {
        if (variant.id === selectedVariant.id) {
          for (let image of images) {
            if (image.id === variant.imageId) {
              matchedImages = image
              break
            }
          }
          break
        }
      }
    }

    setSelectedImage(matchedImages)
  }, [coverImageId, images, variants, selectedVariant])

  return { selectedImage }
}
