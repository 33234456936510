import { Fragment, useState } from "react"
import { colors, mediaQuery, shadows } from "../../../../../../theme"
import { useCheckout, useScreenSize } from "../../../../../../hooks"

import { AppliedDiscountType } from "../../BodyCheckout"
import { Chevron } from "../../../../../../icons"
import { PriceView } from "../../../../../../components/PriceView"
import { PropsWithTheme } from "../../../../../../providers/StoreThemeProvider"
import { Trans } from "@lingui/macro"
import { calcDiscount } from "../../../../utils/calcDiscount"
import styled from "@emotion/styled/macro"
import { toLocalePrice } from "../../../../../../utils"

type PurchaseDetailCardProps = {
  appliedDiscount?: AppliedDiscountType
}

export function PurchaseDetailCard({
  appliedDiscount,
}: PurchaseDetailCardProps) {
  const { isDesktop } = useScreenSize()
  const [isExpanded, setIsExpanded] = useState(isDesktop)
  const {
    meta: { subtotalPrice, shipping, currency },
    objects: products,
  } = useCheckout().data
  const shippingPrice = shipping?.price || 0
  const discountAmount =
    appliedDiscount != null
      ? calcDiscount({ subtotalPrice, appliedDiscount })
      : 0
  const total =
    appliedDiscount != null
      ? subtotalPrice + shippingPrice - Math.min(discountAmount, subtotalPrice)
      : subtotalPrice + shippingPrice

  const isAppliedDiscount = appliedDiscount != null && discountAmount > 0

  const showSubtotal =
    subtotalPrice === total
      ? shippingPrice > 0 || isAppliedDiscount
        ? true
        : false
      : true
  return (
    <Container>
      <Header onClick={onClickHeader}>
        <Title>
          <Trans>Detalle de tu compra</Trans>
        </Title>
        {!isDesktop && !isExpanded && (
          <HeaderPrice>
            <PriceView price={toLocalePrice(total, currency)} />
          </HeaderPrice>
        )}
        {!isDesktop && (
          <ChevronIcon isExpanded={isExpanded}>
            <Chevron />
          </ChevronIcon>
        )}
      </Header>
      {isExpanded && (
        <Fragment>
          <Divider />
          {products.map(product => (
            <ProductItem key={`purchaseDetailCard-${product.productId}`}>
              <Name>{`${product.title} x ${product.quantity}`}</Name>
              <Price>
                <PriceView
                  price={toLocalePrice(
                    product.price * product.quantity,
                    product.currency
                  )}
                />
              </Price>
            </ProductItem>
          ))}
          {
            <PreTotal>
              {showSubtotal && (
                <PreTotalItem>
                  <Trans>Subtotal</Trans>:{" "}
                  <PriceView price={toLocalePrice(subtotalPrice, currency)} />
                </PreTotalItem>
              )}

              {isAppliedDiscount && (
                <DiscountItem>
                  <Trans>Descuento por medio de pago</Trans>: -
                  <PriceView price={toLocalePrice(discountAmount)} />
                </DiscountItem>
              )}
              {shippingPrice > 0 && (
                <PreTotalItem data-testid="shipping-option">
                  <Trans>Opción envío</Trans>:{" "}
                  <PriceView price={toLocalePrice(shippingPrice, currency)} />
                </PreTotalItem>
              )}
            </PreTotal>
          }
          <Total data-testid="total">
            <Trans>Total</Trans>:{" "}
            <PriceView price={toLocalePrice(total, currency)} />
          </Total>
          <Spacer />
        </Fragment>
      )}
    </Container>
  )

  function onClickHeader() {
    if (!isDesktop) {
      setIsExpanded(current => !current)
    }
  }
}

const Container = styled.div<PropsWithTheme>({
  backgroundColor: colors.white,
  boxShadow: shadows[3],
  borderRadius: 4,
  padding: "1.6rem",
  order: 1,

  [mediaQuery.desktop]: {
    gridRowStart: 3,
    height: "fit-content",
    margin: 0,
    padding: "2.4rem",
  },
})

const Header = styled.div<PropsWithTheme>({
  display: "grid",
  gridTemplateColumns: "auto auto 3.2rem",
  alignItems: "center",

  [mediaQuery.desktop]: {
    gridTemplateColumns: "1fr",
  },
})

const Title = styled.h1<PropsWithTheme>({
  fontSize: "1.6rem",
  color: colors.greyMedium,
  margin: 0,
  padding: 0,
  fontWeight: "normal",

  [mediaQuery.desktop]: {
    justifySelf: "flex-start",
  },
})

const HeaderPrice = styled.p<PropsWithTheme>({
  fontSize: "1.4rem",
  color: colors.greyMedium,
  margin: 0,
  padding: 0,
  fontWeight: "bold",
  justifySelf: "end",

  "& sup": {
    fontSize: "1rem",
  },
})

type IconProps = {
  isExpanded: boolean
}

const ChevronIcon = styled.span<IconProps & PropsWithTheme>(props => ({
  justifySelf: "end",
  color: props.theme.colors.primary,
  fontSize: "1rem",
  display: "flex",
  alignSelf: "center",
  transform: props.isExpanded ? "rotate(180deg)" : "rotate(0deg)",
  gridColumnStart: 3,
}))

const Divider = styled.div<PropsWithTheme>(props => ({
  borderBottom: `1px solid ${props.theme.colors.primary}`,
  margin: "0.8rem 0 2.4rem",

  [mediaQuery.desktop]: {
    margin: "1.6rem 0 2.4rem",
  },
}))

const ProductItem = styled.div<PropsWithTheme>({
  display: "flex",
  justifyContent: "space-between",
  gridGap: "2rem",
  margin: "2.4rem 0",
  padding: "0 0.8rem",
  color: colors.greyMedium,
  fontSize: "1.4rem",
})

const Name = styled.p<PropsWithTheme>({
  margin: 0,
})

const Price = styled.p<PropsWithTheme>({
  margin: 0,
})

const PreTotal = styled.h2<PropsWithTheme>({
  borderTop: `1px solid ${colors.greyMainLight}`,
  borderBottom: `1px solid ${colors.greyMainLight}`,
  textAlign: "end",
  margin: 0,
  padding: "1.6rem 0.8rem",
  color: colors.greyMedium,
  fontSize: "1.6rem",
  fontWeight: "normal",

  "& sup": {
    fontSize: "1rem",
  },
})

const PreTotalItem = styled.div({
  margin: "0.8rem 0",
  fontSize: "1.6rem",

  "& sup": {
    fontSize: "1rem",
  },
})

const DiscountItem = styled(PreTotalItem)({
  color: colors.greenDark,
})

const Total = styled.h2<PropsWithTheme>(props => ({
  borderColor: colors.greyMain,
  borderWidth: 2,
  borderStyle: "none none solid",
  textAlign: "end",
  margin: 0,
  padding: "1.6rem 0.8rem",
  color: colors.greyMedium,
  fontSize: "1.6rem",
  backgroundColor: colors.greyLight,

  "& sup": {
    fontSize: "1rem",
  },

  [mediaQuery.desktop]: {
    borderStyle: "solid none none",
  },
}))

const Spacer = styled.div<PropsWithTheme>({
  height: "1.6rem",

  [mediaQuery.desktop]: {
    height: "0.8rem",
  },
})
