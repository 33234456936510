import {
  RadioField,
  RadioFieldProps,
} from "../../../../../../../../../../components/RadioField"
import {
  colors,
  mediaQuery,
  shadows,
} from "../../../../../../../../../../theme"

import { PropsWithTheme } from "../../../../../../../../../../providers/StoreThemeProvider"
import styled from "@emotion/styled/macro"

type Props = {
  name?: RadioFieldProps["name"]
  inputRef?: RadioFieldProps["inputRef"]
  disabled?: RadioFieldProps["disabled"]
  checked?: RadioFieldProps["checked"]
  onChange?: RadioFieldProps["onChange"]
  label: RadioFieldProps["label"]
  value: RadioFieldProps["value"]
  description: import("react").ReactNode
  hasError?: boolean
  price: import("react").ReactNode
}

export function ShippingOptionCard({
  name,
  inputRef,
  label,
  value,
  checked,
  onChange,
  description,
  hasError,
  price,
}: Props) {
  return (
    <Container
      onClick={() => {
        if (onChange != null) onChange(value)
      }}
      hasError={hasError}
      checked={checked}
    >
      <Header>
        <RadioField
          name={name}
          label={label}
          value={value}
          checked={checked}
          onChange={onChange}
          inputRef={inputRef}
        />
        <Price>{price}</Price>
      </Header>
      <Description>{description}</Description>
    </Container>
  )
}

type ContainerProps = {
  hasError?: boolean
  checked?: boolean
}

const Container = styled.div<PropsWithTheme & ContainerProps>(props => ({
  display: "grid",
  cursor: "pointer",
  padding: "1.6rem 1.2rem",
  borderRadius: "0.4rem",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: props.hasError
    ? colors.redMain
    : props.checked
    ? props.theme.colors.primary
    : colors.greyMainLight,

  backgroundColor: props.hasError ? colors.redLighter : colors.white,

  [mediaQuery.desktop]: {
    padding: "1.6rem",
  },

  "&:hover": {
    boxShadow: shadows[3],
  },
}))

const Price = styled.p<PropsWithTheme>(props => ({
  fontSize: "1.4rem",
  color: colors.greyDark,
  margin: 0,
  padding: 0,
  fontWeight: "bold",
  justifySelf: "end",
}))
const Header = styled.div<PropsWithTheme>(props => ({
  display: "grid",
  gridTemplateColumns: "1fr auto",
  gridGap: 4,
}))

const Description = styled.span<PropsWithTheme>(props => ({
  fontSize: "1.4rem",
  color: colors.greyDark,
}))
