import { useModal, useScreenSize } from "../../../../../hooks"

import { CustomShippingsOptions } from "../InformationLinks/ShippingCostLink/CustomShippingsOptions"
import { Fragment } from "react"
import { FreeShipping } from "../../../../../icons"
import { LinkButton } from "../../../../../components/LinkButton"
import { PropsWithTheme } from "../../../../../providers/StoreThemeProvider"
import { Trans } from "@lingui/macro"
import { ZippinModalBody } from "../InformationLinks/ShippingCostLink/ZippinModalBody"
import { api } from "../../../../../api"
import { colors } from "../../../../../theme"
import styled from "@emotion/styled/macro"
import { toLocalePrice } from "../../../../../utils"

type ShippingCostModalTriggerProps = {
  linkId: string
  triggerTitle: string
  canQuote: boolean
  productID: string
  variantID: number
  quantity: number
  customMethods: api.stores.shippings.Get.Custom[]
  freeShippingAmount: api.stores.shippings.Get.FreeShipping
  productPrice: number
  currency: "ARS" | "BRL" | "MXN"
  freeShipping: boolean
}

export function ShippingCostModalTrigger({
  linkId,
  triggerTitle,
  productID,
  variantID,
  quantity,
  customMethods,
  canQuote,
  freeShipping,
  freeShippingAmount,
  productPrice,
  currency,
}: ShippingCostModalTriggerProps) {
  const {
    actions: { openModal, closeModal },
  } = useModal()

  const { isDesktop } = useScreenSize()

  const hasCustomMethods = customMethods.length > 0

  const handleClick = () => {
    openModal({
      title: (
        <ModalTitle>
          {hasCustomMethods ? (
            <Trans>¿Cómo querés recibir tu compra?</Trans>
          ) : (
            <Trans>Conocé nuestras opciones de envío</Trans>
          )}
        </ModalTitle>
      ),
      body: (
        <ModalBodyContainer data-testid="modal-shipping-cost-modal">
          {freeShipping && (
            <FreeShippingAlertContainer isDesktop={isDesktop}>
              <FreeShipping />
              ¡Envío gratis en compras mayores a{" "}
              {toLocalePrice(freeShippingAmount.starterAmount)}!
            </FreeShippingAlertContainer>
          )}
          {hasCustomMethods && (
            <Fragment>
              <CustomShippingsOptions
                customMethods={customMethods}
                hasCanQuote={canQuote}
                freeShipping={freeShipping}
                productPrice={productPrice}
                productSelectedQuantity={quantity}
                freeShippingAmount={freeShippingAmount}
                currency={currency}
              />
              {!canQuote && (
                <CloseButtonContainer>
                  <CloseButton onClick={closeModal}>
                    <Trans>Cerrar</Trans>
                  </CloseButton>
                </CloseButtonContainer>
              )}
            </Fragment>
          )}
          {canQuote && (
            <ZippinModalBody
              productID={productID}
              variantID={variantID}
              quantity={quantity}
              hasCustomMethods={hasCustomMethods}
            />
          )}
        </ModalBodyContainer>
      ),
      size: "large",
    })
  }

  return (
    <LinkButton onClick={handleClick} data-testid={linkId}>
      <Trans>{triggerTitle}</Trans>
    </LinkButton>
  )
}

const ModalTitle = styled.h1<PropsWithTheme>(props => ({
  fontFamily: props.theme.typographies.titles,
  fontSize: "1.8rem",
  color: props.theme.colors.titles,
  fontWeight: "bold",
  margin: 0,
}))

const CloseButtonContainer = styled.div({
  display: "flex",
  justifyContent: "flex-end",
})

const CloseButton = styled.button<PropsWithTheme>(props => ({
  backgroundColor: props.theme.colors.primary,
  border: "none",
  borderRadius: 4,
  color: colors.white,
  cursor: "pointer",
  fontFamily: props.theme.typographies.titles,
  fontSize: "1.6rem",
  height: "3.2rem",
  lineHeight: "1.6rem",
  marginTop: "2.4rem",
  padding: "0.8rem 1.6rem",
}))

type FreeShippingAlertContainerProps = {
  isDesktop: boolean
}

const FreeShippingAlertContainer = styled.div<FreeShippingAlertContainerProps>(
  ({ isDesktop }) => ({
    backgroundColor: colors.blueLighter,
    border: `1px solid ${colors.blueLight}`,
    padding: isDesktop ? "1.2rem 2rem" : "1.6rem 1.2rem",
    fontSize: isDesktop ? "1.4rem" : "1.2rem",
    fontWeight: isDesktop ? 600 : 400,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: isDesktop ? "1.2rem" : "0.8rem",
    borderRadius: "4px",
    "& svg": {
      ...(isDesktop ? { width: 32, height: 32 } : { width: 24, height: 24 }),
    },
  })
)

const ModalBodyContainer = styled.div({
  paddingTop: "1.6rem",
})
