import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { api } from "../api"
import { useProductStore } from "../screens/ProductVIP/provider/ProductStoreProvider"

type State = "idle" | "fetching" | "success" | "error"

export function useProductAPI(productId: string) {
  const isMounted = useRef<boolean | null>(null)
  const { dispatch } = useProductStore()
  const [isProductNotFound, setIsProductNotFound] = useState(false)
  const [state, setState] = useState<State>("fetching")

  const isLoading = useMemo(() => state === "fetching", [state])

  const fetchProduct = useCallback(async () => {
    const response = await api.products.set(productId).variations.get()

    return response
  }, [productId])

  useEffect(() => {
    isMounted.current = true

    setState("fetching")

    fetchProduct()
      .then(res => {
        if (isMounted.current) {
          if (res === 404) {
            setIsProductNotFound(true)

            setState("success")
          } else {
            dispatch({ type: "addProductInfo", payload: res })

            setState("success")
          }
        }
      })
      .catch(err => {
        console.error(err)

        if (isMounted.current) {
          setState("error")
        }
      })

    return () => {
      isMounted.current = false
    }
  }, [fetchProduct, dispatch])

  return useMemo(
    () => ({
      isLoading,
      isProductNotFound,
    }),
    [isLoading, isProductNotFound]
  )
}
