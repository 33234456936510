import { DropdownField } from "../../../../../../../components/DropdownField"
import { Value } from "../../../../../../../api/products/variations/get/types"
import styled from "@emotion/styled/macro"
import { t } from "@lingui/macro"
import { useAvailableOptions } from "../../hooks/useAvailableOptions"
import { useFindVariantByValue } from "../../../../../hooks/useFindVariantByValue"
import { useIsValueAvailable } from "../../hooks/useIsValueAvailable"
import { useProductStore } from "../../../../../provider/ProductStoreProvider"

type Props = {
  name: string
  propertyId: string
  values: Value[]
  variantHasError: boolean
  resetVariantHasError: () => void
}

export function PropertyValuesDropdown({
  name,
  propertyId,
  values,
  variantHasError,
  resetVariantHasError,
}: Props) {
  const { dispatch, selectedValues } = useProductStore()
  const { findVariantByValue } = useFindVariantByValue()
  const { getAvailableOptions } = useAvailableOptions()
  const { isVariantAvailable } = useIsValueAvailable()

  const items = values.map(value => {
    const propertyValue = { propertyId: propertyId, id: Number(value.id) }
    const isVariantPossible = isVariantAvailable({ propertyValue })
    const availableOptions = getAvailableOptions({ propertyValue })

    const name = getName({
      availableOptions,
      valueName: value.name,
      isVariantPossible,
    })

    return {
      id: String(value.id),
      name: name,
      value: String(value.id),
      disabled: availableOptions?.outStock,
    }
  })

  const [selectedVal] = selectedValues.filter(
    value => value.propertyId === propertyId
  )

  return (
    <Container data-testid={`${name}-dropdown`}>
      <DropdownField
        value={selectedVal ? String(selectedVal.id) : "Ver opciones"}
        name={name}
        label={""}
        onChange={clickHandler}
        options={[
          ...items,
          {
            id: "Ver opciones",
            value: "Ver opciones",
            name: "Ver opciones",
            isHidden: true,
          },
        ]}
        errorMsg={
          variantHasError && selectedVal === undefined
            ? "Seleccioná una opción para continuar"
            : ""
        }
        hideError
      />
    </Container>
  )

  function clickHandler(valueId: string) {
    const propertyValue = { propertyId: propertyId, id: Number(valueId) }
    const possibleVariant = findVariantByValue(propertyValue)
    dispatch({
      type: "setSelectedValues",
      payload: propertyValue,
    })
    dispatch({
      type: "setSelectedVariant",
      payload: possibleVariant,
    })
    if (possibleVariant === null || possibleVariant.stock === 0) {
      dispatch({
        type: "removeOppositeSelectedValue",
        payload: propertyValue,
      })

      dispatch({
        type: "setSelectedVariant",
        payload: null,
      })
    }
    resetVariantHasError()
  }

  type GetNameProps = {
    availableOptions?: {
      hasOtherOptionsAvailable: boolean
      outStock: boolean
    }
    valueName: string
    isVariantPossible: boolean
  }

  function getName({
    availableOptions,
    valueName,
    isVariantPossible,
  }: GetNameProps) {
    let name = ""
    if (isVariantPossible || availableOptions === undefined) {
      name = valueName
    } else if (availableOptions?.outStock) {
      name = t`${valueName} - Sin stock`
    } else if (availableOptions?.hasOtherOptionsAvailable) {
      name = t`${valueName} - Ver en otras opciones disponibles`
    } else {
      name = valueName
    }

    return name
  }
}

const Container = styled.div({
  width: "100%",
})
