import { Chevron, Order } from "../../../../icons"
import { Fragment, useState } from "react"
import { SortingOption, useSort } from "../../hooks/useSort"
import { colors, mediaQuery } from "../../../../theme"
import { useModal, useScreenSize } from "../../../../hooks"

import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { SortingList } from "./SortingList"
import { Trans } from "@lingui/macro"
import { readableColor } from "polished"
import styled from "@emotion/styled/macro"

export function SortingSelector() {
  const [isListOpen, setIsListOpen] = useState(false)
  const { actions } = useModal()
  const { isDesktop } = useScreenSize()
  const { selected, setSelected, sortingOptions } = useSort()

  const clickHandler = (selectedOption: SortingOption) => {
    setSelected(selectedOption)
    isDesktop ? setIsListOpen(false) : actions.closeModal()
  }

  return (
    <Fragment>
      <Container data-testid="sorting-selector" onClick={onClickSelector}>
        <Icon>{isDesktop ? <Chevron /> : <Order />}</Icon>
        <Label>{isDesktop ? selected.label : <Trans>Orden</Trans>}</Label>
      </Container>
      {isListOpen && (
        <Fragment>
          <Background onClick={() => setIsListOpen(false)} />
          <SortingList
            options={sortingOptions}
            selected={selected}
            clickHandler={clickHandler}
          />
        </Fragment>
      )}
    </Fragment>
  )

  function onClickSelector() {
    if (isDesktop) {
      setIsListOpen(o => !o)
    } else {
      actions.openModal({
        title: (
          <Header>
            <Trans>Ordenar por</Trans>
          </Header>
        ),
        body: (
          <SortingList
            options={sortingOptions}
            selected={selected}
            clickHandler={clickHandler}
          />
        ),
      })
    }
  }
}

const Container = styled.button<PropsWithTheme>(props => ({
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  display: "inline-flex",
  outline: "none",
  padding: 0,

  [mediaQuery.desktop]: {
    flexDirection: "row-reverse",
  },
}))

const Label = styled.p<PropsWithTheme>(props => ({
  fontSize: "1.4rem",
  color: readableColor(props.theme.colors.background),
  padding: "0 0.8rem",
  whiteSpace: "nowrap",
  margin: 0,
}))

const Icon = styled.span<PropsWithTheme>(props => ({
  color: readableColor(props.theme.colors.background),
  fontSize: "1.4rem",
  display: "flex",
  alignSelf: "center",

  [mediaQuery.desktop]: {
    fontSize: "1rem",
  },
}))

const Background = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100vh",
  width: "100vw",
})

const Header = styled.h1<PropsWithTheme>(props => ({
  fontFamily: props.theme.typographies.titles,
  fontSize: "2.4rem",
  color: colors.greyMedium,
  margin: "-2px 0 0.8rem",
}))
