import { Fragment } from "react"
import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { Trans } from "@lingui/macro"
import { mediaQuery } from "../../../../theme"
import styled from "@emotion/styled/macro"

type Props = {
  children: import("react").ReactNode
}

export function ProductDescription({ children }: Props) {
  return (
    <Fragment>
      <Title>
        <Trans>Descripción</Trans>
      </Title>
      <Description data-testid="product-description">{children}</Description>
    </Fragment>
  )
}

const Title = styled.h2<PropsWithTheme>(props => ({
  fontSize: "1.8rem",
  fontWeight: "bold",
  color: props.theme.colors.texts,
  fontFamily: props.theme.typographies.texts,
  marginBottom: "1.6rem",
  marginTop: 0,

  [mediaQuery.desktop]: {
    marginBottom: "2.4rem",
  },
}))

const Description = styled.p<PropsWithTheme>(props => ({
  fontSize: "1.6rem",
  color: props.theme.colors.texts,
  fontFamily: props.theme.typographies.texts,
  wordBreak: "break-word",
  whiteSpace: "pre-wrap",
}))
