import { Container } from "./Container"

export function WhatsAppIcon() {
  return (
    <Container viewBox="0 0 16 16">
      <g>
        <path
          d="M8.1468,14.3443A6.5784,6.5784,0,0,1,4.5138,13.26l-2.5371.8088.8239-2.4335A6.4867,6.4867,0,0,1,1.5428,7.7929a6.17,6.17,0,0,1,.033-.636,6.6065,6.6065,0,0,1,13.1526.1249c.0147.1691.022.3383.022.5111a6.5874,6.5874,0,0,1-6.6056,6.5523Zm7.8483-6.7495A7.8557,7.8557,0,0,0,.2985,7.4517c-.0037.1139-.0073.2281-.0073.342A7.7337,7.7337,0,0,0,1.4164,11.819L0,16l4.3511-1.3824A7.8642,7.8642,0,0,0,16,7.7941C15.9988,7.7274,15.9988,7.6611,15.9951,7.5948Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          d="M11.808,9.2134c-.19-.1006-1.1091-.5886-1.2822-.6541s-.2975-.1006-.4227.1006-.4872.6541-.5942.7856-.2182.1511-.4084.0505a5.0222,5.0222,0,0,1-1.5076-.9988A5.9558,5.9558,0,0,1,6.55,7.1076a.3085.3085,0,0,1,.0823-.41c.086-.0925.1866-.2359.2829-.3519.0253-.0351.0467-.066.0681-.0968a2.33,2.33,0,0,0,.1188-.24.3906.3906,0,0,0-.0142-.3523c-.0468-.1006-.4227-1.0916-.58-1.4941s-.3118-.333-.4227-.333S5.8524,3.81,5.7288,3.81a.6737.6737,0,0,0-.5015.2517,2.3438,2.3438,0,0,0-.6555,1.68,2.7588,2.7588,0,0,0,.0966.685,4.7094,4.7094,0,0,0,.67,1.3934,7.98,7.98,0,0,0,3.2018,3.04c1.9093.8009,1.9093.5338,2.2531.4991a1.8809,1.8809,0,0,0,1.2644-.9559,1.7852,1.7852,0,0,0,.111-.9564C12.1193,9.3641,11.9934,9.314,11.808,9.2134Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
    </Container>
  )
}
