import { Tooltip } from "../Tooltip"
import { WhatsAppIcon } from "../../icons"
import { colors } from "../../theme"
import styled from "@emotion/styled/macro"
import { useScreenSize } from "../../hooks/useScreenSize"

type Props = {
  countryCode: string
  phoneNumber: string
  isVipMobile?: boolean
  description: string
}

export function WhatsAppButton({
  countryCode,
  phoneNumber,
  isVipMobile,
  description,
}: Props) {
  const { isMobile } = useScreenSize()

  const hasTooltip = !isMobile && description !== ""

  return (
    <>
      <LinkWhatsApp
        href={`https://wa.me/${countryCode}${phoneNumber}`}
        target="_blank"
        rel="noreferrer"
      >
        <Tooltip
          content={description}
          placement="top"
          maxWidth={180}
          disabled={!hasTooltip}
        >
          <ButtonContainer
            data-testid="whatsapp-button"
            isVipMobile={isVipMobile}
          >
            <WhatsAppIcon />
          </ButtonContainer>
        </Tooltip>
      </LinkWhatsApp>
    </>
  )
}

type ButtonContainerProps = {
  isVipMobile?: boolean
}

const ButtonContainer = styled.button<ButtonContainerProps>(
  ({ isVipMobile }) => ({
    alignItems: "center",
    backgroundColor: "#25d366",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
    border: "none",
    borderRadius: "50%",
    bottom: isVipMobile ? 48 : 0,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    height: "6rem",
    justifyContent: "center",
    margin: "0 1.6rem 1.6rem 0",
    padding: "0.8rem",
    position: "fixed",
    right: 0,
    width: "6rem",
    zIndex: 3,

    "&:hover": {
      backgroundColor: "#1a9548",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.3)",
    },

    "& svg": {
      color: colors.white,
      fontSize: "3.2rem",
    },
  })
)

const LinkWhatsApp = styled.a()
