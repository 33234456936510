import { Response } from "./types"
import paths from "../../paths"
import { service } from "../../Service"

export async function get() {
  const response = await service.get<Response>(paths.language())

  if (response.status.code >= 400 && response.status.code < 600) {
    throw new Error(response.status.message)
  }

  try {
    return transformResponse(response.body)
  } catch (error) {
    console.error(error)
    throw new Error("API Settings did not respond correctly")
  }
}

export function transformResponse(response: Response) {
  return response
}
