import { useEffect, useState } from "react"

import { api } from "../api"
import { useStoreState } from "../providers/StoreProvider"

export function useConfig() {
  const [, setState] = useStoreState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")

  useEffect(() => {
    setLoading(true)

    api.stores
      .set()
      .config.get()
      .then(result => {
        setState({ config: result })
      })
      .catch(error => {
        setError(error.message)
      })
      .finally(() => setLoading(false))
  }, [setState])

  return {
    loading,
    error,
  }
}
