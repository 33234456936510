import { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"
import { useProductsStore } from "../provider/ProductStoreProvider"
// import { useLocation } from "react-router-dom"
import { useScreenSize } from "../../../hooks"

export function usePagination() {
  const { isDesktop } = useScreenSize()
  const { meta } = useProductsStore()
  const { totalCount, limit } = meta
  const [shownPages, setShownPages] = useState<number[]>([])

  const maxPages = isDesktop ? 11 : 5
  const totalPages = Math.ceil(totalCount / limit)

  const history = useHistory()

  const urlSearchParams = new URLSearchParams(history.location.search)

  const currentPage = Number(urlSearchParams.get("page")) || 1

  if (urlSearchParams.get("page") === null) {
    urlSearchParams.set("page", String(currentPage))
    urlSearchParams.set("ordering", "-id")
    history.replace({ search: urlSearchParams.toString() })
  }

  const handleChangePage = (page: number) => {
    urlSearchParams.set("page", String(page))
    history.push({
      pathname: history.location.pathname,
      search: `?${urlSearchParams}`,
    })
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handleChangePage(currentPage + 1)
    }
  }

  const handlePreviousPage = () => {
    handleChangePage(currentPage - 1)
  }

  useEffect(() => {
    let startPage = 1
    let endPage = totalPages

    if (totalPages > maxPages) {
      const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2)
      const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1

      if (currentPage <= maxPagesBeforeCurrentPage) {
        endPage = maxPages
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        startPage = totalPages - maxPages + 1
        endPage = totalPages
      } else {
        startPage = currentPage - maxPagesBeforeCurrentPage
        endPage = currentPage + maxPagesAfterCurrentPage
      }
    }

    const _shownPages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      i => startPage + i
    )

    setShownPages(_shownPages)
  }, [currentPage, maxPages, totalPages])

  return {
    totalPages,
    currentPage,
    handleNextPage,
    handlePreviousPage,
    handleChangePage,
    shownPages,
  }
}
