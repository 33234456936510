import * as z from "zod"

import { PropertySchema } from "../types"

export const NewVariantRequestRawSchema = z.object({
  itemId: z.string(),
  price: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  quantity: z.number(),
  title: z.string(),
  properties: z.array(PropertySchema),
  thumbnail: z.string(),
})

export type NewVariantRequestRaw = z.infer<typeof NewVariantRequestRawSchema>

export const RequestSchema = z.object({
  item_id: z.string(),
  price: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  quantity: z.number(),
  title: z.string(),
  properties: z.array(PropertySchema),
  thumbnail: z.string(),
})

export type Request = z.infer<typeof RequestSchema>
