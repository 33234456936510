import {
  ShippingOptionsP as ShippingOptionsPType,
  ShippingOptionsS as ShippingOptionsSType,
  ShippingOptionsZ as ShippingOptionsZType,
} from "../../../../../../../../api/stores/checkout"
import { useCallback, useEffect } from "react"

import { ShippingData } from "../../types"
import { ShippingOptionsP } from "./components/ShippingOptionsP"
import { ShippingOptionsS } from "./components/ShippingOptionsS"
import { ShippingOptionsZ } from "./components/ShippingOptionsZ"
import { useCheckout } from "../../../../../../../../hooks"
import { useFormContext } from "react-hook-form"

type ShippingOptionItem =
  | ShippingOptionsZType["options"][0]
  | ShippingOptionsPType["options"][0]
  | ShippingOptionsSType["options"][0]

export const CardShippingOptions = () => {
  const {
    shipping,
    requirements: { shippingOptions },
  } = useCheckout().data.meta
  const methods = useFormContext<ShippingData>()

  const { setValue, getValues } = methods

  const getShippingOption = useCallback(() => {
    const values = getValues()
    let shippingOption

    const isOptionIdExist =
      shippingOptions?.__kind &&
      shippingOptions.options.some(
        (option: ShippingOptionItem) => option.id === values.shippingOption
      )

    if (shipping?.id) {
      shippingOption = shipping?.id
    } else if (
      values.shippingOption &&
      shippingOptions?.__kind &&
      values.shippingOption !== shippingOptions.options[0].id &&
      isOptionIdExist
    ) {
      shippingOption = values.shippingOption
    } else if (shippingOptions?.__kind) {
      shippingOption = shippingOptions.options[0].id
    } else {
      shippingOption = ""
    }

    return shippingOption
  }, [getValues, shipping?.id, shippingOptions])

  useEffect(() => {
    setValue("shippingOption", getShippingOption())
  }, [shipping, shippingOptions, setValue, getValues, getShippingOption])

  return (
    <>
      {/* The shipping method "Z" can return shipping
          options of the following types: "Z", "C" and "M".
      */}
      {(shipping?.shippingType === "Z" ||
        shipping?.shippingType === "C" ||
        shipping?.shippingType === "M") && <ShippingOptionsZ />}
      {shipping?.shippingType === "S" && <ShippingOptionsS />}
      {shipping?.shippingType === "P" && <ShippingOptionsP />}
    </>
  )
}
