export function parseField(value: string) {
  if (!isNaN(parseInt(value))) {
    return value.replace(/[^\d]/gi, "")
  } else if (value === "") {
    return value
  } else {
    return ""
  }
}

export function parseFieldWithPattern(
  value: string,
  pattern: RegExp,
  valueToReplace: string
) {
  return value?.replace(pattern, valueToReplace) ?? ""
}

export function parsePhoneNumberField(value: string) {
  return parseFieldWithPattern(value, /[^0-9^+^-\d-]/g, "")
}
