import { colors, mediaQuery } from "../../../theme"

import { PropsWithTheme } from "../../../providers/StoreThemeProvider"
import { Trans } from "@lingui/macro"
import { readableColor } from "polished"
import styled from "@emotion/styled"

interface Config {
  default: {
    closeModal: () => void
  }
  reminder: Config["default"] & {
    cancelPurchase: () => void
    timeLeft: number
  }
  abort: Config["default"] & {
    cancelPurchase: () => void
  }
  expired: Config["default"]
}

interface ModalContent {
  title: JSX.Element
  body: JSX.Element
  disableCloseButton: true
  disableMobile: true
}

const testIdCancelButton = "checkoutModalCounter-cancel"
const testIdConfirmButton = "checkoutModalCounter-confirm"

export function getModalContent(
  type: "reminder",
  config: Config["reminder"]
): ModalContent
export function getModalContent(
  type: "abort",
  config: Config["abort"]
): ModalContent
export function getModalContent(
  type: "expired",
  config: Config["expired"]
): ModalContent
export function getModalContent(
  type: string,
  config: Config["default"]
): ModalContent {
  let title, text, cancelButton, confirmButton

  if (type === "reminder") {
    const {
      cancelPurchase,
      closeModal,
      timeLeft,
    } = config as Config["reminder"]

    title = <Trans>¡Todavía tenés tiempo!</Trans>
    text =
      timeLeft < 60000 ? (
        <Trans>
          Llevate eso que elegiste antes de que se agote. Tenés menos de un
          minuto para terminar tu compra.
        </Trans>
      ) : formatTimeLeft(timeLeft) === "1" ? (
        <Trans>
          Llevate eso que elegiste antes de que se agote. Te queda 1 minuto para
          confirmar.
        </Trans>
      ) : (
        <Trans>
          Llevate eso que elegiste antes de que se agote. Te quedan{" "}
          {formatTimeLeft(timeLeft)} minutos para confirmar.
        </Trans>
      )
    cancelButton = (
      <ModalButton
        data-testid={testIdCancelButton}
        actionType="cancel"
        onClick={cancelPurchase}
      >
        <Trans>Cancelar compra</Trans>
      </ModalButton>
    )
    confirmButton = (
      <ModalButton
        data-testid={testIdConfirmButton}
        actionType="confirm"
        onClick={closeModal}
      >
        <Trans>Continuar compra</Trans>
      </ModalButton>
    )
  } else if (type === "abort") {
    const { cancelPurchase, closeModal } = config as Config["abort"]

    title = <Trans>¿De verdad querés irte?</Trans>
    text = <Trans>Pensalo mejor y retomá tu compra cuando quieras.</Trans>
    cancelButton = (
      <ModalButton
        data-testid={testIdCancelButton}
        actionType="cancel"
        onClick={closeModal}
      >
        <Trans>Comprar ahora</Trans>
      </ModalButton>
    )
    confirmButton = (
      <ModalButton
        data-testid={testIdConfirmButton}
        actionType="confirm"
        onClick={cancelPurchase}
      >
        <Trans>Salir</Trans>
      </ModalButton>
    )
  } else if (type === "expired") {
    const { closeModal } = config as Config["expired"]

    title = <Trans>Tiempo cumplido</Trans>
    text = (
      <Trans>
        Pasaron 20 minutos sin que confirmes tu compra. Te la guardamos por si
        querés volver.
      </Trans>
    )
    confirmButton = (
      <ModalButton
        data-testid={testIdConfirmButton}
        actionType="confirm"
        onClick={closeModal}
      >
        <Trans>Ir a comprar</Trans>
      </ModalButton>
    )
  }

  return {
    title: (
      <ModalTitle data-testid="checkoutModalCounter-title">{title}</ModalTitle>
    ),
    body: (
      <>
        <ModalText data-testid="checkoutModalCounter-text">{text}</ModalText>
        <ModalButtons>
          {cancelButton}
          {confirmButton}
        </ModalButtons>
      </>
    ),
    disableCloseButton: true,
    disableMobile: true,
  }
}

function formatTimeLeft(timeLeft: number) {
  return (timeLeft / 60000).toFixed()
}

const ModalTitle = styled.h1<PropsWithTheme>(props => ({
  fontFamily: props.theme.typographies.titles,
  fontSize: "1.6rem",
  color: colors.greyDark,
  textAlign: "center",

  [mediaQuery.desktop]: {
    fontSize: "1.8rem",
  },
}))

const ModalText = styled.p<PropsWithTheme>(props => ({
  fontSize: "1.4rem",
  color: colors.greyDark,
  marginTop: "1.6rem",
  marginBottom: "4.2rem",

  [mediaQuery.desktop]: {
    fontSize: "1.6rem",
    marginBottom: "2.4rem",
  },
}))

const ModalButtons = styled.div({
  display: "grid",
  gridAutoFlow: "column",
  gridGap: "1.6rem",
  justifyContent: "end",
})

type ModalButtonProps = {
  actionType: "cancel" | "confirm"
}

const ModalButton = styled.button<PropsWithTheme & ModalButtonProps>(props => {
  const primaryColor = props.theme.colors.primary

  const confirm = {
    backgroundColor: primaryColor,
    color: readableColor(primaryColor),
    border: `1px solid ${primaryColor}`,
    borderRadius: 4,
  }

  const cancel = {
    backgroundColor: colors.white,
    color: readableColor(colors.white),
    border: "none",

    "&:hover": {
      textDecoration: "underline",
    },
  }

  return {
    fontSize: "1.4rem",
    fontFamily: props.theme.typographies.titles,
    padding: "0.8rem 1.6rem",
    cursor: "pointer",
    ...(props.actionType === "confirm" ? confirm : cancel),

    [mediaQuery.desktop]: {
      fontSize: "1.6rem",
    },
  }
})
