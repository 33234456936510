import * as z from "zod"

export const DiscountTypeSchema = z.union([
  z.literal("P"), // Percentage
  z.literal("A"), // Amount
])

export const CustomPaymentMethodRawSchema = z.object({
  name: z.string(),
  id: z.string(),
  description: z.string(),
  offers_discount: z.boolean(),
  discount_type: DiscountTypeSchema.nullable(),
  discount_value: z.number().nullable(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
})

export const CustomPaymentMethodsRaw = z.array(CustomPaymentMethodRawSchema)

const PaymentTypeIDSchema = z.union([
  z.literal("ticket"),
  z.literal("atm"),
  z.literal("credit_card"),
  z.literal("debit_card"),
  z.literal("prepaid_card"),
])

const MPPaymentMethodRawSchema = z.object({
  name: z.string(),
  payment_type_id: PaymentTypeIDSchema,
  secure_thumbnail: z.string(),
})

export const MPPaymentMethodsRawSchema = z.array(MPPaymentMethodRawSchema)

export const ResponseSchema = z.object({
  objects: z.object({
    custom: CustomPaymentMethodsRaw,
    mercadopago: MPPaymentMethodsRawSchema,
  }),
})

export type Response = z.infer<typeof ResponseSchema>

export const CustomPaymentMethodSchema = z.object({
  name: z.string(),
  id: z.string(),
  description: z.string(),
  offersDiscount: z.boolean(),
  discountType: DiscountTypeSchema.nullable(),
  discountValue: z.number().nullable(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
})

export type CustomPaymentMethod = z.infer<typeof CustomPaymentMethodSchema>

export const MPPaymentMethodSchema = z.object({
  name: z.string(),
  paymentTypeId: PaymentTypeIDSchema,
  secureThumbnail: z.string(),
})

export type MPPaymentMethod = z.infer<typeof MPPaymentMethodSchema>

export const ResultSchema = z.object({
  objects: z.object({
    custom: z.array(CustomPaymentMethodSchema),
    mercadoPago: z.array(MPPaymentMethodSchema),
  }),
})

export type Result = z.infer<typeof ResultSchema>
