import { Trans, t } from "@lingui/macro"
import { colors, mediaQuery, shadows } from "../../../../theme"

import { Link } from "react-router-dom"
import { OutStockAlert } from "../../../../components/OutStockAlert"
import { PriceView } from "../../../../components/PriceView"
import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { TagFreeShipping } from "../../../../components/TagFreeShipping"
import { getProductVIPUrl } from "../../../../utils"
import styled from "@emotion/styled/macro"
import { useScrollToProduct } from "../../hooks/useScrollToProduct"

type Props = {
  productId: string
  thumbnail: string
  title: string
  price: string
  showLabelFrom?: boolean
  stock: number
  freeShipping?: boolean
}

export function ProductBox({
  productId,
  thumbnail,
  title,
  price,
  showLabelFrom,
  stock,
  freeShipping,
}: Props) {
  const { setLastProductViewed } = useScrollToProduct()

  const hasFreeShipping = stock > 0 && freeShipping

  return (
    <Container
      onClick={clickHandler}
      data-testid={`product-box-${productId}`}
      id={productId}
      to={`/${getProductVIPUrl(title)}-rtid${productId}/`}
    >
      <ProductArticle>
        <ThumbnailContainer>
          <Thumbnail src={thumbnail} alt={t`Imagen miniatura de: ${title}`} />
          {stock === 0 && <OutStockAlert productsListStyle />}
        </ThumbnailContainer>
        <Detail hasFreeShipping={hasFreeShipping}>
          <PriceContainer>
            {showLabelFrom && (
              <FromText>
                <Trans>Desde</Trans>
              </FromText>
            )}
            <Price>
              <PriceView price={price} />
            </Price>
          </PriceContainer>
          {hasFreeShipping && (
            <TagContainer>
              <TagFreeShipping />
            </TagContainer>
          )}
          <TitleContainer>
            <Title>{title}</Title>
          </TitleContainer>
        </Detail>
      </ProductArticle>
    </Container>
  )

  function clickHandler() {
    setLastProductViewed(productId)
  }
}

const Container = styled(Link)<PropsWithTheme>(props => ({
  borderRadius: 4,
  backgroundColor: colors.white,
  fontFamily: props.theme.typographies.texts,
  cursor: "pointer",
  boxShadow: shadows[3],
  textDecoration: "none",
  width: "100%",
  height: "27.7rem",
  maxHeight: "100%",
  position: "relative",

  [mediaQuery.desktop]: {
    display: "grid",
    gridTemplateRows: "65% 35%",
    width: "22.4rem",
    height: "36rem",
    "&:hover": {
      boxShadow: "0px 6px 12px 0px rgba(0,0,0,0.2)",
    },
  },
}))

const ThumbnailContainer = styled.div({
  width: "100%",
  height: "16rem",
  borderRadius: "0.4rem 0.4rem 0 0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",

  [mediaQuery.desktop]: {
    height: "100%",
  },
})

const Thumbnail = styled.img({
  width: "100%",
  height: "100%",
  objectFit: "contain",
  objectPosition: "center",
  alignSelf: "center",
  borderRadius: "0.4rem 0.4rem 0 0",
  maxHeight: "100%",
  maxWidth: "100%",
})

type DetailProps = {
  hasFreeShipping: boolean | undefined
}

const Detail = styled.div<DetailProps>(({ hasFreeShipping }) => ({
  "--titleFontSize": "1.2rem",
  "--titleLineHeight": 1.4,
  "--titleLineClamp": hasFreeShipping ? 2 : 3,
  "--titleMaxHeight": `calc(
      (var(--titleFontSize) * var(--titleLineHeight)) * var(--titleLineClamp)
    )`,
  display: "grid",
  gridTemplateRows: "1fr auto 2fr",
  gridTemplateAreas: `"price" 
                      "tag" 
                      "title"`,
  width: "100%",
  padding: "0.8rem",
  maxHeight: "100%",

  [mediaQuery.desktop]: {
    "--titleFontSize": "1.4rem",
    gridTemplateRows: "auto auto 2fr",
    padding: "1.6rem",
  },
}))

const TitleContainer = styled.div({
  gridArea: "title",
  overflow: "hidden",
  paddingTop: "0.8rem",
})

const TagContainer = styled.div({
  gridArea: "tag",
  paddingTop: "0.8rem",
})

const Title = styled.h2<PropsWithTheme>(
  props => `
  margin: 0;
  font-size: var(--titleFontSize);
  font-weight: normal;
  font-family: ${props.theme.typographies.titles};
  line-height: var(--titleLineHeight);
  color: ${colors.greyMedium};
  display: -webkit-box;
  -webkit-line-clamp: var(--titleLineClamp);
  -webkit-box-orient: vertical;
  height: 100%;
  max-height: var(--titleMaxHeight);
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`
)

const PriceContainer = styled.div({
  display: "flex",
  flexFlow: "row nowrap",
  alignItems: "center",
  gap: "0.8rem",
  whiteSpace: "nowrap",
  wordBreak: "break-word",
  overflow: "hidden",
  gridArea: "price",
  height: "fit-content",
})

const Price = styled.span<PropsWithTheme>(props => ({
  fontSize: "1.6rem",
  color: colors.greyMedium,
  fontFamily: props.theme.typographies.titles,
  gridRow: 3,
  fontWeight: 600,

  "& sup": {
    fontSize: "1.2rem",
  },

  [mediaQuery.desktop]: {
    fontSize: "1.8rem",
    fontWeight: 600,
  },
}))

const FromText = styled.p<PropsWithTheme>(props => ({
  margin: 0,
  fontSize: "1.2rem",
  color: colors.greyMedium,
  fontFamily: props.theme.typographies.titles,

  [mediaQuery.desktop]: {
    fontSize: "1.4rem",
  },
}))

const ProductArticle = styled.article({})
