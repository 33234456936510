import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { BREAKPOINTS } from "../theme"
import { useScreenSize } from "./useScreenSize"

type LinkList = {
  id: string
  name: string
  url: string
}

type Props = {
  links: Array<LinkList>
}

export function useVisibleLinks({ links }: Props) {
  const { innerWidth } = useScreenSize()
  const [linkList, setLinkList] = useState<Array<LinkList>>(links)
  const [lastBreadcrumbStep, setLastBreadcrumbStep] = useState<number>(0)
  const listRef = useRef<HTMLOListElement>(null)
  const [elementsWidth, setElementsWidth] = useState<number[]>([])
  const [containerListWidth, setContainerListWidth] = useState<number>(0)

  const renderDots = useMemo(
    () => innerWidth <= BREAKPOINTS.DESKTOP_MIN && lastBreadcrumbStep > -1,
    [innerWidth, lastBreadcrumbStep]
  )

  // Get the width of each link.
  useEffect(() => {
    const container = listRef.current

    if (container) {
      for (let index = 0; index < container.children.length; index++) {
        const child = container.children[index] as HTMLElement
        setElementsWidth(prev => [...prev, child.offsetWidth])
      }
    }
  }, [])

  useEffect(() => {
    const updateContainerWidth = () => {
      const container = listRef.current

      if (container) {
        setContainerListWidth(container.offsetWidth)
      }
    }

    updateContainerWidth()

    window.addEventListener("resize", updateContainerWidth)

    return () => window.removeEventListener("resize", updateContainerWidth)
  }, [])

  const updateBreadcrumbAndLinks = useCallback(
    (step: number, arrayLinks: Array<LinkList>) => {
      setLastBreadcrumbStep(step)
      setLinkList(arrayLinks)
    },
    []
  )

  // Calculate the width of the links including the space remaining per line.
  // But it is not including the space remaining of the last line.
  const calculateLinksWidth = useCallback(
    (widths: number[]) => {
      let totalWidth = 0
      let currentLineWidth = 0

      for (let index = 0; index < widths.length; index++) {
        currentLineWidth += widths[index]

        if (currentLineWidth > containerListWidth) {
          const lastLineWidth = currentLineWidth - widths[index]

          totalWidth += lastLineWidth + (containerListWidth - lastLineWidth)

          currentLineWidth = widths[index]
        }
      }

      return totalWidth + currentLineWidth
    },
    [containerListWidth]
  )

  // Calculate the links that fit in two lines (recursively).
  const calculateLinksToRender = useCallback(
    (indexFrom: number = 0, withDots: boolean = false) => {
      const nextItemIndex = indexFrom + 1

      const totalWidth = calculateLinksWidth(
        elementsWidth.slice(withDots ? nextItemIndex : indexFrom)
      )

      const totalLines = totalWidth / containerListWidth

      if (totalLines > 2 && nextItemIndex < links.length - 1) {
        calculateLinksToRender(nextItemIndex, true)
      } else {
        updateBreadcrumbAndLinks(
          withDots ? indexFrom : -1,
          links.slice(withDots ? nextItemIndex : indexFrom)
        )
      }
    },
    [
      links,
      containerListWidth,
      elementsWidth,
      calculateLinksWidth,
      updateBreadcrumbAndLinks,
    ]
  )

  useEffect(() => {
    const container = listRef.current

    if (
      !container ||
      innerWidth >= BREAKPOINTS.DESKTOP_MIN ||
      containerListWidth === 0
    ) {
      updateBreadcrumbAndLinks(-1, links)
      return
    }

    calculateLinksToRender()
  }, [
    links,
    innerWidth,
    containerListWidth,
    elementsWidth,
    calculateLinksToRender,
    calculateLinksWidth,
    updateBreadcrumbAndLinks,
  ])

  return {
    linkList,
    lastBreadcrumbStep,
    renderDots,
    listRef,
  }
}
