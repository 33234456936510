import { Fragment, useState } from "react"
import { colors, mediaQuery, opacity } from "../../theme"

import { PropsWithTheme } from "../../providers/StoreThemeProvider"
import { Trans } from "@lingui/macro"
import { lighten } from "polished"
import styled from "@emotion/styled/macro"

type QuantitySelectorProps = {
  quantityInformation?: number
  quantitySelected: number
  productHasStock?: boolean
  error?: boolean
  disabled?: boolean
  onChange: (event: import("react").ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: import("react").ChangeEvent<HTMLInputElement>) => void
}

export function QuantitySelector({
  quantityInformation,
  quantitySelected,
  productHasStock,
  error = false,
  disabled = false,
  onChange,
  onBlur,
}: QuantitySelectorProps) {
  const [focused, setFocused] = useState(false)

  const stockMessage =
    quantityInformation === 1 ? (
      <Trans>(Último disponible)</Trans>
    ) : (
      <Trans>({quantityInformation} disponibles)</Trans>
    )

  const handleOnBlur = (e: import("react").ChangeEvent<HTMLInputElement>) => {
    setFocused(false)
    if (onBlur) {
      onBlur(e)
    }
  }

  return (
    <Fragment>
      <label>
        <InputLabel
          focused={focused}
          error={error && !!productHasStock}
          notAllowed={!productHasStock || disabled}
        >
          <Trans>Cantidad</Trans>
        </InputLabel>
        <InputContainer
          data-testid="quantity-selector"
          name="quantity"
          type="number"
          min={1}
          value={!isNaN(quantitySelected) ? quantitySelected : ""}
          onClick={e => e.currentTarget.focus()}
          onChange={onChange}
          onBlur={handleOnBlur}
          onFocus={() => setFocused(true)}
          focused={focused}
          error={error && !!productHasStock}
          disabled={!productHasStock || disabled}
          notAllowed={!productHasStock || disabled}
        />
      </label>
      {quantityInformation != null && (
        <Warning data-testid="quantity-selector-warning">
          {stockMessage}
        </Warning>
      )}
    </Fragment>
  )
}

type InputProps = {
  focused: boolean
  error: boolean
  notAllowed: boolean
}

const InputLabel = styled.p<PropsWithTheme & InputProps>(props => ({
  fontSize: "1.4rem",
  opacity: props.notAllowed ? opacity[2] : 1,
  color: props.error
    ? colors.redMain
    : props.focused
    ? props.theme.colors.primary
    : props.theme.colors.texts,
  fontFamily: "Open Sans, sans-serif",
  margin: 0,

  [mediaQuery.desktop]: {
    fontSize: "1.2rem",
  },
}))

const Warning = styled.p<PropsWithTheme>(props => ({
  fontSize: "1.4rem",
  color: lighten(0.4, props.theme.colors.texts),
  fontFamily: "Open Sans, sans-serif",
  marginLeft: "0.8rem",
  display: "inline",
}))

const InputContainer = styled.input<PropsWithTheme & InputProps>(props => ({
  width: "6rem",
  height: "3.2rem",
  fontSize: "1.6rem",
  marginBottom: "0.4rem",
  padding: 2,
  border: 0,
  cursor: props.notAllowed ? "not-allowed" : "auto",
  outline: "none",
  opacity: props.notAllowed ? opacity[2] : 1,
  background: "transparent",
  borderBottom: `1px solid ${
    props.error
      ? colors.redMain
      : props.focused
      ? props.theme.colors.primary
      : colors.greyMedium
  }`,
}))
