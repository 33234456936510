import { Link, RouteComponentProps } from "react-router-dom"
import { RTCHeaderDesktop, RTCHeaderMobile } from "store-components"
import { useCart, usePaymentMethods, useScreenSize } from "../../../hooks"

import { RTCHeaderProps } from "store-components/dist/Header"
import { Trans } from "@lingui/macro"
import { parseHomeUrl } from "../helpers/parseHomeUrl"
import { t } from "@lingui/macro"
import { useStoreState } from "../../../providers/StoreProvider"

export function Header({ location, history }: RouteComponentProps) {
  const [state] = useStoreState()
  const { isDesktop, innerWidth, innerHeight } = useScreenSize()
  const { data: cartData, actions: cartActions } = useCart()
  const allProductsPath = window.ROUTES.find(
    route => route.kind === "C" && route.category_path === ""
  )?.path

  const paymentMethods = usePaymentMethods()

  const noPaymentMethods = Object.values(paymentMethods.info.objects).every(
    method => method.length === 0
  )

  const headerProps: RTCHeaderProps = {
    backgroundColor: state.config.header.backgroundColor,
    bannerActive: state.config.header.banner.enabled,
    bannerBackgroundColor: state.config.header.banner.backgroundColor,
    bannerContent: state.config.header.banner.content,
    bannerTypography: state.config.header.banner.typography,
    bannerVisibility: state.config.header.banner.visibility,
    containerSize: {
      height: innerHeight,
      width: innerWidth,
    },
    disabledCart: noPaymentMethods,
    homeUrl: parseHomeUrl(),
    itemsCount: cartData.objects.length,
    isInHome: location.pathname === "/",
    logoColor: state.config.logoHeader.color,
    logoContent: state.config.logoHeader.text,
    logoImage: state.config.logoHeader.img,
    logoType: state.config.logoHeader.type,
    logoTypography: state.config.logoHeader.typography,
    menu: state.config.menu.map(({ id, name, url, type }) => ({
      id: id,
      title: name,
      href: type !== "L" || /^https?:\/\//.test(url) ? url : "http://" + url,
      type: type,
    })),
    menuMoreButtonText: <Trans>Más</Trans>,
    menuTypography: state.config.typographies.menu,
    onCartIconClick: cartActions.openCart,
    primaryColor: state.config.colors.primary,
    renderLink: ({ title, href }: { title: string; href: string }) => (
      <Link title={title} to={href}>
        {title}
      </Link>
    ),
    onSearch: (text: string) => {
      if (text.length > 0) history.push(`${allProductsPath}?search=${text}`)
    },
    searchPlaceholder: t`Buscar`, // TODO: Use <Trans> after fix in Header
    searchTerm: new URLSearchParams(location.search).get("search") ?? "",
    secondaryColor: state.config.colors.secondary,
    skin: state.config.header.design,
    sticky: state.config.header.sticky,
    textColor: state.config.colors.texts,
  }

  return isDesktop ? (
    <RTCHeaderDesktop {...headerProps} />
  ) : (
    <RTCHeaderMobile {...headerProps} />
  )
}
