import { PaymentMethod } from "./PaymentMethod"
import { PropsWithTheme } from "../../providers/StoreThemeProvider"
import { Spinner } from "../../icons"
import { Trans } from "@lingui/macro"
import { api } from "../../api"
import { colors } from "../../theme"
import styled from "@emotion/styled/macro"
import { toLocalePrice } from "../../utils"

type Props = {
  paymentMethods: api.paymentMethods.Get.Result
  hideTitle?: boolean
  isPaymentMethodsLoading: boolean
}

export function PaymentMethods({
  paymentMethods,
  hideTitle,
  isPaymentMethodsLoading,
}: Props) {
  const { mercadoPago, custom } = paymentMethods.objects

  const PaymentMethodsText: {
    [key: string]: {
      subtitle: import("react").ReactNode
      description: import("react").ReactNode
    }
  } = {
    credit_card: {
      subtitle: <Trans>Tarjetas de crédito</Trans>,
      description: (
        <Trans>Tenemos cuotas sin interés con bancos seleccionados</Trans>
      ),
    },
    debit_card: {
      subtitle: <Trans>Tarjetas de débito</Trans>,
      description: "",
    },
    other: {
      subtitle: <Trans>Otros</Trans>,
      description: "",
    },
  }

  return (
    <div data-testid="payment-methods" id="payment-methods">
      {!hideTitle && (
        <Title>
          <Trans>Medios de pago</Trans>
        </Title>
      )}
      {isPaymentMethodsLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <Container>
          {mercadoPago.length > 0 &&
            Object.keys(PaymentMethodsText).map(type => (
              <PaymentMethod
                key={type}
                type={type}
                subtitle={PaymentMethodsText[type].subtitle}
                description={PaymentMethodsText[type].description}
                methods={mercadoPago}
              />
            ))}
          {custom.map(paymentMethod => {
            const {
              name,
              id,
              offersDiscount,
              discountType,
              discountValue,
              currency,
            } = paymentMethod
            return (
              <CustomPaymentMethod key={id}>
                <SubTitle>{name}</SubTitle>
                {offersDiscount && (
                  <DiscountText>
                    <b>
                      {discountType === "P"
                        ? ` ${discountValue}% `
                        : discountValue != null &&
                          ` ${toLocalePrice(discountValue * 100, currency)}`}
                    </b>{" "}
                    <Trans>
                      <b>de descuento</b> con este medio de pago
                    </Trans>
                  </DiscountText>
                )}
              </CustomPaymentMethod>
            )
          })}
        </Container>
      )}
    </div>
  )
}

const Container = styled.div({
  display: "grid",
  gridGap: "2.4rem",
  wordBreak: "break-word",
})

const SpinnerContainer = styled.div({
  display: "flex",
  fontSize: "4rem",
  justifyContent: "center",
  color: colors.greyMain,
})

const CustomPaymentMethod = styled.div({
  display: "grid",
})

const Title = styled.h2<PropsWithTheme>(props => ({
  fontSize: "2rem",
  fontWeight: "bold",
  color: props.theme.colors.texts,
  fontFamily: props.theme.typographies.texts,
  marginBottom: "1.6rem",
  marginTop: 0,
}))

const SubTitle = styled.h3<PropsWithTheme>(props => ({
  fontSize: "1.6rem",
  fontWeight: "normal",
  marginBottom: "0.4rem",
  marginTop: 0,
  color: props.theme.colors.texts,
  fontFamily: props.theme.typographies.texts,
}))

const DiscountText = styled.p<PropsWithTheme>(props => ({
  fontSize: "1.4rem",
  fontWeight: "normal",
  marginBottom: "0.4rem",
  marginTop: 0,
  color: props.theme.colors.texts,
  fontFamily: props.theme.typographies.texts,
}))
