import { useFindVariantByValue } from "../../../../hooks/useFindVariantByValue"
import { useProductStore } from "../../../../provider/ProductStoreProvider"
import { useValuesAvailableOptions } from "../../../../hooks/useValuesAvailableOptions"

type Props = {
  propertyValue: { propertyId: string; id: number }
}

export function useAvailableOptions() {
  const { selectedValues } = useProductStore()
  const valuesAvailableOptions = useValuesAvailableOptions()
  const { findVariantByValue } = useFindVariantByValue()

  function getAvailableOptions({ propertyValue }: Props) {
    const isSelected = selectedValues.some(
      value => value.id === propertyValue.id
    )
    const hasOtherOptionsAvailable =
      Object.keys(valuesAvailableOptions).length > 0
        ? valuesAvailableOptions[propertyValue.id].length > 0
        : false

    const possibleVariant = findVariantByValue(propertyValue)

    if (!isSelected) {
      if (hasOtherOptionsAvailable) {
        return { hasOtherOptionsAvailable, outStock: false }
      } else if (
        (possibleVariant && possibleVariant?.stock === 0) ||
        possibleVariant === undefined
      ) {
        return { hasOtherOptionsAvailable: false, outStock: true }
      }
    } else {
      return undefined
    }
  }

  return { getAvailableOptions }
}
