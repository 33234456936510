import { PropsWithTheme } from "../../providers/StoreThemeProvider"
import { colors } from "../../theme"
import styled from "@emotion/styled/macro"

export type RadioFieldProps = {
  name?: import("react").InputHTMLAttributes<HTMLInputElement>["name"]
  inputRef?: import("react").ClassAttributes<HTMLInputElement>["ref"]
  disabled?: import("react").InputHTMLAttributes<HTMLInputElement>["disabled"]
  checked?: import("react").InputHTMLAttributes<HTMLInputElement>["checked"]
  onChange?: (value: string) => void
  label: import("react").ReactNode
  value: string
  tag?: import("react").ReactNode
}

export function RadioField({
  name,
  inputRef,
  label,
  value,
  disabled,
  checked,
  onChange,
  tag,
}: RadioFieldProps) {
  return (
    <Container>
      <RadioButton
        id={value}
        name={name}
        ref={inputRef}
        type="radio"
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={() => {
          if (onChange != null) onChange(value)
        }}
      />
      <RadiomarkHolder disabled={disabled} />
      <Radiomark disabled={disabled} />
      <ContentContainer tagExist={tag != null}>
        <Label htmlFor={value} disabled={disabled}>
          {label}
        </Label>
        {tag != null && tag}
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div({
  display: "grid",
  position: "relative",
  gridTemplateColumns: `1.4rem auto`,
  fontSize: "1.4rem",
})

type DisableProp = {
  disabled?: boolean
}

const RadiomarkHolder = styled.div<DisableProp>(props => ({
  gridColumnStart: 1,
  gridColumnEnd: 2,
  position: "absolute",
  borderRadius: "100%",
  border: `1px solid ${props.disabled ? colors.greyMain : colors.greyDark}`,
  width: "1em",
  height: "1em",
  boxSizing: "border-box",
  pointerEvents: "none",
  alignSelf: "center",
  justifySelf: "center",
  cursor: props.disabled ? "default" : "pointer",
}))

const Radiomark = styled.div<PropsWithTheme & DisableProp>(props => ({
  gridColumnStart: 1,
  gridColumnEnd: 2,
  position: "absolute",
  height: 0,
  width: 0,
  backgroundColor: props.disabled
    ? colors.greyMain
    : props.theme.colors.primary,
  borderRadius: "100%",
  alignSelf: "center",
  justifySelf: "center",
  cursor: props.disabled ? "default" : "pointer",
}))

const Label = styled.label<DisableProp>(props => ({
  fontSize: "1em",
  color: props.disabled ? colors.greyMain : colors.greyDark,
  cursor: props.disabled ? "default" : "pointer",
}))

type ContentContainerProps = {
  tagExist: boolean
}

const ContentContainer = styled.div<ContentContainerProps>(({ tagExist }) => ({
  gridColumnStart: 2,
  gridColumnEnd: 3,
  display: "flex",
  justifyContent: tagExist ? "space-between" : "flex-start",
  alignItems: "center",
  width: "100%",
  paddingLeft: "0.8rem",
  paddingRight: tagExist ? "0.4rem" : "0",
  "& label": {
    maxWidth: tagExist ? "75%" : "none",
  },
}))

const RadioButton = styled.input<PropsWithTheme & DisableProp>`
  opacity: 0;
  position: absolute;
  grid-column-start: 1;
  grid-column-end: 2;
  align-self: center;
  justify-self: center;
  margin: 0;
  cursor: ${props => (props.disabled ? "default" : "pointer")};

  &:focus {
    & ~ ${RadiomarkHolder} {
      border: 1px solid ${props => props.theme.colors.primary};
    }
  }

  &:checked {
    & ~ ${Radiomark} {
      width: 0.8rem;
      height: 0.8rem;
      transition: width 0.1s ease-out, height 0.1s ease-out;
    }
    & ~ ${RadiomarkHolder} {
      border: 1px solid
        ${props =>
          props.disabled ? colors.greyMain : props.theme.colors.primary};
    }
  }
`
