import * as z from "zod"

const LinkRaw = z.object({
  id: z.number(),
  url: z.string(),
  name: z.string(),
})

const ValueRaw = z.object({
  name: z.string(),
  id: z.number(),
})

const PropertyRaw = z.object({
  name: z.string(),
  id: z.string(),
  values: z.array(ValueRaw),
})

const ImageRaw = z.object({
  id: z.number(),
  warhole_id: z.number(),
  thumbnails: z.object({
    thumbnail: z.string(),
    small: z.string(),
    medium: z.string(),
    large: z.string(),
    full: z.string(),
  }),
})

const AttributeRaw = z.object({
  name: z.string(),
  id: z.string(),
  value: z.string(),
  value_type: z.string(),
})

const VariantRaw = z.object({
  id: z.number(),
  stock: z.number(),
  price: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  image_id: z.number(),
  values: z.array(z.number()),
  free_shipping: z.boolean().optional().nullable(),
})

export const ShopStatusSchema = z.union([z.literal("A"), z.literal("P")])

export const ResponseSchema = z.object({
  meta: z.object({
    breadcrumb: z.array(LinkRaw),
    description: z.string(),
    title: z.string(),
    identifier: z.string(),
    cover_image_id: z.number(),
    properties: z.array(PropertyRaw),
    images: z.array(ImageRaw),
    status: ShopStatusSchema,
    can_quote: z.boolean(),
    attributes: z.array(AttributeRaw),
  }),
  objects: z.array(VariantRaw),
})

export type Response = z.infer<typeof ResponseSchema>

export const LinkSchema = z.object({
  id: z.string(),
  url: z.string(),
  name: z.string(),
})

export type Link = z.infer<typeof LinkSchema>

export const ValueSchema = z.object({
  name: z.string(),
  id: z.number(),
})

export type Value = z.infer<typeof ValueSchema>

export const PropertySchema = z.object({
  name: z.string(),
  id: z.string(),
  values: z.array(ValueSchema),
})

export type Property = z.infer<typeof PropertySchema>

export const ImageSchema = z.object({
  id: z.string(),
  warholeID: z.number(),
  thumbnails: z.object({
    thumbnail: z.string(),
    small: z.string(),
    medium: z.string(),
    large: z.string(),
    full: z.string(),
  }),
})

export type Image = z.infer<typeof ImageSchema>

export const VariantSchema = z.object({
  id: z.number(),
  stock: z.number(),
  price: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  imageId: z.string(),
  values: z.array(z.number()),
  freeShipping: z.boolean(),
})

export type Variant = z.infer<typeof VariantSchema>

const AttributeSchema = z.object({
  name: z.string(),
  id: z.string(),
  value: z.string(),
  valueType: z.string(),
})

export type Attribute = z.infer<typeof AttributeSchema>

export const ResultSchema = z.object({
  breadcrumb: z.array(LinkSchema),
  description: z.string(),
  title: z.string(),
  identifier: z.string(),
  coverImageId: z.string(),
  properties: z.array(PropertySchema),
  images: z.array(ImageSchema),
  status: ShopStatusSchema,
  canQuote: z.boolean(),
  variants: z.array(VariantSchema),
  attributes: z.array(AttributeSchema),
})

export type Result = z.infer<typeof ResultSchema>
