import { FilterChip } from "../../FilterChip"
import { Link } from "react-router-dom"
import { PropsWithTheme } from "../../../../../providers/StoreThemeProvider"
import { RangeSelector } from "../../../../../components/RangeSelector"
import { Title } from "../styles"
import { Trans } from "@lingui/macro"
import { readableColor } from "polished"
import styled from "@emotion/styled"
import { useFilters } from "../../../hooks/useFilters"
import { useMeta } from "../../../hooks/useMeta"
import { useProductsStore } from "../../../provider/ProductStoreProvider"

export function Sidebar() {
  const { meta } = useProductsStore()

  const { breadcrumbLinks, title, subCategories } = useMeta()
  const {
    appliedFiltersValues,
    filters,
    addFilter,
    removeFilter,
    priceFilter,
    removePriceFilter,
    applyPriceFilter,
  } = useFilters()

  const RangeSelectorHandler = (min?: number, max?: number) => {
    applyPriceFilter(min, max)
  }

  return (
    <Container>
      <Title>{title}</Title>
      {breadcrumbLinks.length === 0 && (
        <TotalCount>
          {meta.totalCount}{" "}
          {meta.totalCount === 1 ? (
            <Trans>resultado</Trans>
          ) : (
            <Trans>resultados</Trans>
          )}
        </TotalCount>
      )}
      {(appliedFiltersValues.length > 0 || priceFilter) && (
        <FiltersActivated>
          {appliedFiltersValues.map(filter => (
            <FilterChip
              key={filter.id}
              label={filter.name}
              onClick={() => removeFilter(filter.id)}
            />
          ))}
          {priceFilter && (
            <FilterChip label={priceFilter} onClick={removePriceFilter} />
          )}
        </FiltersActivated>
      )}
      <SelectionPane>
        {subCategories.length > 0 && (
          <SectionGroup>
            <SectionName>
              <Trans>Categorías</Trans>
            </SectionName>
            {subCategories.map(subcategory => (
              <SubcategoryLink key={subcategory.id} to={subcategory.url}>
                {`${subcategory.name} (${subcategory.count})`}
              </SubcategoryLink>
            ))}
          </SectionGroup>
        )}
        {filters.map(filter => (
          <SectionGroup key={filter.id}>
            <SectionName>{filter.name}</SectionName>
            {filter.values.map(value => (
              <Filter key={value.id} onClick={() => addFilter(value.id)}>
                {`${value.name} (${value.count})`}
              </Filter>
            ))}
          </SectionGroup>
        ))}
        {!priceFilter && (
          <SectionGroup>
            <SectionName>
              <Trans>Precio</Trans>
            </SectionName>
            <RangeSelector clickHandler={RangeSelectorHandler} />
          </SectionGroup>
        )}
      </SelectionPane>
    </Container>
  )
}

const Container = styled.aside({})

const TotalCount = styled.p<PropsWithTheme>(props => ({
  fontSize: "1.4rem",
  color: readableColor(props.theme.colors.background),
  margin: 0,
  paddingBottom: "0.8rem",
}))

const FiltersActivated = styled.div({
  margin: "0.8rem 0 1.6rem",
})

const SelectionPane = styled.div({
  margin: "1.6rem 0 4.8rem",
  display: "grid",
  gridGap: "1.2rem",
})

const SectionGroup = styled.div({})

const SectionName = styled.h3<PropsWithTheme>(props => ({
  color: readableColor(props.theme.colors.background),
  fontWeight: "bold",
  fontFamily: props.theme.typographies.texts,
  fontSize: "1.8rem",
  margin: "0 0 1.2rem",
}))

const SubcategoryLink = styled(Link)<PropsWithTheme>(props => ({
  display: "block",
  color: readableColor(props.theme.colors.background),
  fontFamily: props.theme.typographies.texts,
  fontSize: "1.6rem",
  margin: "0.8rem 0",
  textDecoration: "none",

  "&:hover": {
    textDecoration: "underline",
    opacity: 0.5,
  },
}))

const Filter = styled.p<PropsWithTheme>(props => ({
  color: readableColor(props.theme.colors.background),
  fontFamily: props.theme.typographies.texts,
  fontSize: "1.6rem",
  margin: "0.8rem 0",
  cursor: "pointer",

  "&:hover": {
    textDecoration: "underline",
    opacity: 0.5,
  },
}))
