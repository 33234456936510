import * as z from "zod"

export const CheckoutLineRawSchema = z.object({
  product_id: z.number(),
  product_identifier: z.string(),
  variation_id: z.number(),
  price: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  quantity: z.number(),
  title: z.string(),
  thumbnail: z.string(),
  values: z.array(z.string()),
})

export const IdentityTypeSchema = z.union([
  z.literal("DU"),
  z.literal("CUIT"),
  z.literal("OTHER"),
])

export const ContactRawSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  identity_type: IdentityTypeSchema,
  identity_number: z.string(),
  email: z.string(),
  phone: z.string(),
})

export const ReceiverRawSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  identity_type: IdentityTypeSchema,
  identity_number: z.string(),
  phone: z.string(),
})

export const DestinationRawSchema = z.object({
  address_street: z.string(),
  address_number: z.string(),
  address_other: z.string(),
  city: z.string(),
  postal_code: z.string(),
  state: z.string(),
})

export const ShippingBaseRawSchema = z.object({
  id: z.string().nullable(),
  shipping_status: z.string(),
  receiver: ReceiverRawSchema.nullable(),
  destination: DestinationRawSchema.nullable(),
  price: z.number().nullable(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  tracking_external: z.string(),
})

export const BillingRawSchema = z.object({
  full_name: z.string(),
  identity_number: z.string(),
  identity_type: IdentityTypeSchema,
  address_street: z.string(),
  address_number: z.string(),
  address_other: z.string(),
  city: z.string(),
  postal_code: z.string(),
  state: z.string(),
})

export const PaymentTypeSchema = z.union([z.literal("M"), z.literal("C")])

export const PaymentRawSchema = z.object({
  id: z.string(),
  init_point: z.string().nullable(),
  type: PaymentTypeSchema,
  name: z.string(),
  status: z.union([
    z.literal("success"),
    z.literal("pending"),
    z.literal("failure"),
    z.null(),
  ]),
})

export const ShippingTypeOfShippingSchema = z.union([
  z.literal("S"),
  z.literal("Z"),
  z.literal("M"),
  z.literal("C"),
  z.literal("P"),
])

export const ShippingTypeOfShippingMethodSchema = z.union([
  z.literal("S"), // point of sale, or delivery point, punto de entrega/venta
  z.literal("Z"), // home delivery, envío a domicilio
  z.literal("P"), // mail point, near post office sucursal de correo
])

export const PaymentOptionRawSchema = z.object({
  name: z.string(),
  payment_type: PaymentTypeSchema,
  id: z.string(),
  description: z.string().nullable(),
  discount_value: z.number().nullable(),
  discount_type: z.union([z.literal("A"), z.literal("P")]).nullable(),
  offers_discount: z.boolean().nullable(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
})

export const ShippingMethodRawSchema = z.object({
  name: z.string(),
  id: z.string(),
  shipping_type: ShippingTypeOfShippingMethodSchema,
})

export const RequirementsBaseRawSchema = z.object({
  shipping_methods: z.array(ShippingMethodRawSchema),
  payment_options: z.array(PaymentOptionRawSchema),
})

export const MetaDataBaseRawSchema = z.object({
  total_count: z.number(),
  subtotal_price: z.number(),
  total_price: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  unique_id: z.string(),
  store_id: z.string(),
  identifier: z.string(),
  contact: z.union([ContactRawSchema, z.null()]),
  billing: z.union([BillingRawSchema, z.null()]),
  payment: z.union([PaymentRawSchema, z.null()]),
  user_comment: z.union([z.string(), z.null()]),
  extra_data: z.union([z.string(), z.null()]),
  preference_id: z.union([z.string(), z.null()]),
  time_left: z.number(),
  seller_email: z.string().nullable(),
  checkout_step: z.union([
    z.literal("initial"),
    z.literal("contact"),
    z.literal("billing"),
    z.literal("data_complete"),
    z.literal("order_complete"),
  ]),
  free_shipping: z.boolean().optional(),
})

export const ShippingTypeRawPayloadSchema = z.object({
  carrier_name: z.string(),
  arrival_date: z.string(),
  price: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  id: z.string(),
  service_type: z.string(),
  logistic_type: z.string(),
  shipping_type: ShippingTypeOfShippingSchema,
})

export const PriceTypeSchema = z.union([z.literal("F"), z.literal("T")])

// shippingOption === Z
const RawOptionZSchema = z.object({
  id: z.string(),
  carrier_id: z.string(),
  carrier_name: z.string(),
  arrival_date: z.string(),
  price: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  service_type: z.string(),
  logistic_type: z.string(),
  shipping_type: z.literal("Z"),
})

// shippingOption === C
const RawOptionCSchema = z.object({
  id: z.string(),
  carrier_name: z.string(),
  price: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  shipping_type: z.literal("C"),
  description: z.string(),
  price_type: PriceTypeSchema,
  delivery_time: z.string(),
})

//step shipping
export const MetaDataRawZSchema = MetaDataBaseRawSchema.extend({
  // shippingMethod === Z may contain shippingOption Z | C
  shipping: ShippingBaseRawSchema.extend({
    shipping_type: z.literal("Z"),
  }),
  requirements: RequirementsBaseRawSchema.extend({
    shipping_options: z.array(
      z.union([
        // shippingOption === Z
        RawOptionZSchema,
        RawOptionCSchema,
      ])
    ),
  }),
})

export const MetaDataRawCSchema = MetaDataBaseRawSchema.extend({
  shipping: ShippingBaseRawSchema.extend({
    shipping_type: z.literal("C"),
  }),
  requirements: RequirementsBaseRawSchema.extend({
    shipping_options: z.array(
      z.union([
        // shippingOption === Z
        RawOptionZSchema,
        RawOptionCSchema,
      ])
    ),
  }),
})

export const MetaDataRawSSchema = MetaDataBaseRawSchema.extend({
  shipping: ShippingBaseRawSchema.extend({
    shipping_type: z.literal("S"),
  }),
  requirements: RequirementsBaseRawSchema.extend({
    shipping_options: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
        zip_code: z.string(),
        street: z.string(),
        street_number: z.string(),
        street_extra: z.string(),
        city: z.string(),
        state: z.string(),
        hours: z.string(),
        phone: z.string(),
        email: z.string(),
        shipping_type: z.literal("S"),
      })
    ),
  }),
})

export const MetaDataRawPSchema = MetaDataBaseRawSchema.extend({
  shipping: ShippingBaseRawSchema.extend({
    shipping_type: z.literal("P"),
  }),
  requirements: RequirementsBaseRawSchema.extend({
    shipping_options: z.array(
      z.object({
        carrier_name: z.string(),
        distance: z.number(),
        arrival_date: z.string(),
        location: z.object({
          street: z.string(),
          street_number: z.string(),
          street_extras: z.string(),
          city: z.string(),
          state: z.string(),
        }),
        open_hours: z.union([z.string(), z.null()]),
        phone: z.string(),
        description: z.string(),
        price: z.number(),
        currency: z.enum(["ARS", "BRL", "MXN"]),
        carrier_id: z.string(),
        id: z.string(),
        service_type: z.string(),
        logistic_type: z.string(),
        shipping_type: z.literal("P"),
      })
    ),
  }),
})

export const MetaDataRawOtherSchema = MetaDataBaseRawSchema.extend({
  shipping: ShippingBaseRawSchema.extend({
    shipping_type: z.literal("C"),
  }).nullable(),
  requirements: RequirementsBaseRawSchema.extend({
    shipping_options: z.array(z.object({})),
  }),
})

export const MetaDataRawSchema = z.union([
  MetaDataRawZSchema,
  MetaDataRawCSchema,
  MetaDataRawSSchema,
  MetaDataRawPSchema,
])

export const ResponseSchema = z.object({
  meta: MetaDataRawSchema,
  objects: z.array(CheckoutLineRawSchema),
})

export const ContactSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  identityType: IdentityTypeSchema,
  identityNumber: z.string(),
  email: z.string(),
  phone: z.string(),
})

export const ShippingSchema = z.object({
  shippingType: ShippingTypeOfShippingSchema,
  id: z.string().nullable(),
  shippingStatus: z.string(),
  receiver: z
    .object({
      firstName: z.string(),
      lastName: z.string(),
      identityType: IdentityTypeSchema,
      identityNumber: z.string(),
      phone: z.string(),
    })
    .nullable(),
  destination: z
    .object({
      addressStreet: z.string(),
      addressNumber: z.string(),
      addressOther: z.string(),
      city: z.string(),
      postalCode: z.string(),
      state: z.string(),
    })
    .nullable(),
  price: z.number().nullable(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  trackingExternal: z.string(),
})

export const BillingSchema = z.object({
  fullName: z.string(),
  identityNumber: z.string(),
  identityType: IdentityTypeSchema,
  addressStreet: z.string(),
  addressNumber: z.string(),
  addressOther: z.string(),
  city: z.string(),
  postalCode: z.string(),
  state: z.string(),
})

export const PaymentSchema = z.object({
  id: z.string(),
  initPoint: z.string().nullable(),
  type: PaymentTypeSchema,
  status: z
    .union([z.literal("success"), z.literal("pending"), z.literal("failure")])
    .nullable(),
})

const OptionZSchema = z.object({
  id: z.string(),
  price: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  logisticType: z.string(),
  serviceType: z.string(),
  carrierId: z.string(),
  carrierName: z.string(),
  arrivalDate: z.string(),
  shippingType: z.literal("Z"),
})

export const OptionCSchema = z.object({
  id: z.string(),
  carrierName: z.string(),
  shippingType: z.literal("C"),
  description: z.string(),
  priceType: PriceTypeSchema,
  price: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  arrivalDate: z.string(),
})

export const ShippingOptionsZSchema = z.object({
  __kind: z.literal("Z"),
  options: z.array(z.union([OptionZSchema, OptionCSchema])),
})

export const ShippingOptionsCSchema = z.object({
  __kind: z.literal("C"),
  options: z.array(z.union([OptionZSchema, OptionCSchema])),
})

export const ShippingOptionsSSchema = z.object({
  __kind: z.literal("S"),
  options: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      zipCode: z.string(),
      street: z.string(),
      streetNumber: z.string(),
      streetExtra: z.string(),
      city: z.string(),
      state: z.string(),
      hours: z.string(),
      shippingType: z.literal("S"),
      phone: z.string(),
      email: z.string(),
    })
  ),
})

export const ShippingOptionsPSchema = z.object({
  __kind: z.literal("P"),
  options: z.array(
    z.object({
      id: z.string(),
      logisticType: z.string(),
      serviceType: z.string(),
      shippingType: z.literal("P"),
      price: z.number(),
      currency: z.enum(["ARS", "BRL", "MXN"]),
      carrierName: z.string(),
      distance: z.number(),
      arrivalDate: z.string(),
      location: z.object({
        street: z.string(),
        streetNumber: z.string(),
        streetExtras: z.string(),
        city: z.string(),
        state: z.string(),
      }),
      openHours: z.union([z.string(), z.null()]),
      phone: z.string(),
      description: z.string(),
      carrierId: z.string(),
    })
  ),
})

export const RequirementsSchema = z.object({
  shippingMethods: z.array(
    z.object({
      name: z.string(),
      id: z.string(),
      shippingType: ShippingTypeOfShippingMethodSchema,
    })
  ),
  shippingOptions: z.union([
    ShippingOptionsZSchema,
    ShippingOptionsCSchema,
    ShippingOptionsSSchema,
    ShippingOptionsPSchema,
    z.object({
      __kind: z.null(),
    }),
  ]),
  paymentOptions: z.array(
    z.object({
      name: z.string(),
      paymentType: PaymentTypeSchema,
      id: z.string(),
      description: z.string().nullable(),
      discountValue: z.number().nullable(),
      discountType: z.union([z.literal("A"), z.literal("P")]).nullable(),
      offersDiscount: z.boolean().nullable(),
      currency: z.enum(["ARS", "BRL", "MXN"]),
    })
  ),
})

export const MetadataSchema = z.object({
  totalCount: z.number(),
  subtotalPrice: z.number(),
  totalPrice: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  uniqueId: z.string(),
  storeId: z.string(),
  identifier: z.string(),
  contact: ContactSchema.nullable(),
  shipping: ShippingSchema.nullable(),
  billing: BillingSchema.nullable(),
  payment: PaymentSchema.nullable(),
  userComment: z.string().nullable(),
  extraData: z.string().nullable(),
  preferenceId: z.string().nullable(),
  timeLeft: z.number(),
  sellerEmail: z.string().nullable(),
  checkoutStep: z.union([
    z.literal("initial"),
    z.literal("contact"),
    z.literal("billing"),
    z.literal("data_complete"),
    z.literal("order_complete"),
  ]),
  requirements: RequirementsSchema,
  freeShipping: z.boolean(),
})

export const ProductSchema = z.object({
  productId: z.number(),
  productIdentifier: z.string(),
  variationId: z.number(),
  price: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  quantity: z.number(),
  title: z.string(),
  thumbnail: z.string(),
  values: z.array(z.string()),
})

export const ResultSchema = z.object({
  meta: MetadataSchema,
  objects: z.array(ProductSchema),
})

// Section: Error

export const ErrorFieldSchema = z.enum([
  "general",
  "zip_code",
  "product",
  "variation",
])

export const ErrorCodeSchema = z.enum([
  "postal_code",
  "authentication_key",
  "no_measurements",
  "overpriced_value",
  "quote_error",
  "general",
])

export const ErrorRawSchema = z.object({
  field: ErrorFieldSchema,
  code_error: ErrorCodeSchema,
  message: z.string(),
})

export const ErrorResponseSchema = z.object({
  error: ErrorRawSchema,
})

export const ErrorSchema = z.object({
  field: ErrorFieldSchema,
  code: ErrorCodeSchema,
  message: z.string(),
})

export const ErrorResultSchema = z.object({
  error: ErrorSchema,
})

export type ShippingTypeOfShippingMethod = z.infer<
  typeof ShippingTypeOfShippingMethodSchema
>

export type ShippingTypeOfShipping = z.infer<
  typeof ShippingTypeOfShippingSchema
>

export type PaymentOptionRaw = z.infer<typeof PaymentOptionRawSchema>

export type ShippingMethodRaw = z.infer<typeof ShippingMethodRawSchema>

export type RequirementsBasicRaw = z.infer<typeof RequirementsBaseRawSchema>

export type MetaDataBasicRaw = z.infer<typeof MetaDataBaseRawSchema>

export type ShippingTypeRaw = z.infer<typeof ShippingTypeRawPayloadSchema>

export type MetaDataRawZ = z.infer<typeof MetaDataRawZSchema>

export type MetaDataRawS = z.infer<typeof MetaDataRawSSchema>

export type MetaDataRawP = z.infer<typeof MetaDataRawPSchema>

export type MetaDataRawC = z.infer<typeof MetaDataRawCSchema>

export type MetaDataRawOther = z.infer<typeof MetaDataRawOtherSchema>

export type MetaDataRaw = z.infer<typeof MetaDataRawSchema>

export type Contact = z.infer<typeof ContactSchema>

export type Shipping = z.infer<typeof ShippingSchema>

export type PaymentRaw = z.infer<typeof PaymentRawSchema>

export type PaymentType = z.infer<typeof PaymentTypeSchema>

export type BillingRaw = z.infer<typeof BillingRawSchema>

export type ShippingBasicRaw = z.infer<typeof ShippingBaseRawSchema>

export type DestinationRaw = z.infer<typeof DestinationRawSchema>

export type ReceiverRaw = z.infer<typeof ReceiverRawSchema>

export type ContactRaw = z.infer<typeof ContactRawSchema>

export type IdentityType = z.infer<typeof IdentityTypeSchema>

export type CheckoutLineRaw = z.infer<typeof CheckoutLineRawSchema>

export type Billing = z.infer<typeof BillingSchema>

export type Payment = z.infer<typeof PaymentSchema>

export type ShippingOptionsZ = z.infer<typeof ShippingOptionsZSchema>

export type ShippingOptionsC = z.infer<typeof ShippingOptionsCSchema>

export type ShippingOptionZ = z.infer<typeof RawOptionZSchema>

export type ShippingOptionC = z.infer<typeof RawOptionCSchema>

export type ShippingOptionsS = z.infer<typeof ShippingOptionsSSchema>

export type ShippingOptionsP = z.infer<typeof ShippingOptionsPSchema>

export type Requirements = z.infer<typeof RequirementsSchema>

export type Metadata = z.infer<typeof MetadataSchema>

export type Product = z.infer<typeof ProductSchema>

export type Response = z.infer<typeof ResponseSchema>

export type Result = z.infer<typeof ResultSchema>

export type ErrorField = z.infer<typeof ErrorFieldSchema>

export type ErrorCode = z.infer<typeof ErrorCodeSchema>

export type Error = z.infer<typeof ErrorSchema>

export type ErrorResponse = z.infer<typeof ErrorResponseSchema>

export type ErrorResult = z.infer<typeof ErrorResultSchema>
