import {
  Checkout,
  ControllablePage,
  Maintenance,
  ProductVIP,
  ProductsList,
} from "../../screens"
import { Fragment, useEffect } from "react"
import { Route, useHistory } from "react-router-dom"

import { WithHeaderAndFooter } from "../WithHeaderAndFooter"
import { checkoutRoute as checkout } from "../../utils/locationMatchCheckout"
import { locationMaintenance } from "../../utils/locationMatchMaintenance"
import { useCart } from "../../hooks"
import { useScrollToProduct } from "../../screens/ProductsList/hooks/useScrollToProduct"

const PageComponents: {
  P: typeof ControllablePage
  C: typeof ProductsList
} = {
  P: ControllablePage,
  C: ProductsList,
}

const checkoutPath = checkout.substring(0, checkout?.lastIndexOf("/"))

export function Router() {
  const { actions: cartActions } = useCart()
  const history = useHistory()
  const { setLastProductViewed } = useScrollToProduct()
  const productsPaths = window.ROUTES.filter(route => {
    return route.kind === "C"
  })

  useEffect(() => {
    const producVIPRegex = /^\/rtid\w+/
    const pathname = history.location.pathname
    const isNotProductsPaths = !productsPaths.some(
      route => route.path === pathname
    )

    if (isNotProductsPaths && !producVIPRegex.test(pathname)) {
      setLastProductViewed("")
    }
  }, [history.location.pathname, setLastProductViewed, productsPaths])

  useEffect(() => {
    cartActions.getData()
  }, [cartActions])

  return (
    <Fragment>
      <Route
        exact
        path="*rtid([A-Za-z0-9]+)"
        render={props => {
          const productId = props.match.params[1]
          // This removes first (/) and last (-) char from the urlTitle
          const urlTitle = props.match.params[0].slice(1, -1)

          return (
            <WithHeaderAndFooter {...props}>
              <ProductVIP productId={productId} urlTitle={urlTitle} />{" "}
            </WithHeaderAndFooter>
          )
        }}
      />
      {checkoutPath && (
        <>
          <Route
            exact
            path={[checkoutPath, checkoutPath + "/:id"]}
            component={Checkout}
          />
        </>
      )}

      {locationMaintenance() && (
        <>
          <Route exact path="/paused/" component={Maintenance} />
        </>
      )}
      {window.ROUTES.filter(route => route.kind !== "H").map(route => {
        const PageToRender = PageComponents[route.kind as "P" | "C"]

        return (
          <Route
            exact
            key={route.kind + route.id}
            path={route.path}
            render={routeProps => (
              <WithHeaderAndFooter {...routeProps}>
                <PageToRender
                  {...routeProps}
                  categoryPath={route.category_path ?? ""}
                  pageId={route.id}
                />
              </WithHeaderAndFooter>
            )}
          />
        )
      })}
    </Fragment>
  )
}
