import { delay } from "../../../utils"
import { rest } from "msw"

export const variations = [
  rest.get("*/v1/products/:productId/variations/", function (_req, res, ctx) {
    const { productId } = _req.params
    // we only test the product with the id 2, if you need to test another product, add a new fixture.
    if (productId === "2") {
      return res(delay(ctx), ctx.json(require(`./fixture2.json`)))
    } else {
      return res(delay(ctx), ctx.json(require(`./fixture.json`)))
    }
  }),
]
