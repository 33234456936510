import { useEffect, useLayoutEffect, useState } from "react"

import { BodyCheckout } from "./components/BodyCheckout"
import { HeaderCheckout } from "./components/HeaderCheckout"
import { LoaderCheckout } from "./components/LoaderCheckout"
import { useCheckout } from "../../hooks"
import { useCheckoutModal } from "./hooks/useCheckoutModal"
import { useParams } from "react-router-dom"

export interface CheckoutParams {
  id: string
}

export function Checkout() {
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true)
  const { id } = useParams<CheckoutParams>()
  const {
    status,
    data: {
      meta: { payment },
    },
    getData,
  } = useCheckout(id || "")
  const { showAbort } = useCheckoutModal()

  useEffect(() => {
    setIsLoadingInitialData(true)
    getData().then(() => {
      setIsLoadingInitialData(false)
    })
  }, [getData])

  useLayoutEffect(() => {
    if (status === "fetching") {
      window.scrollTo(0, 0)
    }
  }, [status])

  return (
    <>
      {status === "fetching" && <LoaderCheckout />}
      {!isLoadingInitialData && (
        <>
          <HeaderCheckout showAbort={showAbort} />
          <BodyCheckout />
          <form method="POST" style={{ display: "none" }}>
            <script
              src="https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js"
              data-preference-id={payment?.id}
            ></script>
          </form>
        </>
      )}
    </>
  )
}
