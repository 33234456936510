import { ContinueButton, Form, FormBody } from "../../style"
import { FormProvider, useForm } from "react-hook-form"

import { CardContact } from "./components/CardContact"
import { CardShippingMethod } from "./components/CardShippingMethod"
import { StepContactData } from "./types"
import { Trans } from "@lingui/macro"
import { useCheckoutScreen } from "../../../../hooks/useCheckoutScreen"

export function StepContact({ isCurrent }: { isCurrent: boolean }) {
  const { submitForm } = useCheckoutScreen()
  const methods = useForm<StepContactData>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  })

  return (
    <FormProvider {...methods}>
      <Form
        data-testid="step-contact"
        onSubmit={methods.handleSubmit(data => submitForm(data))}
        autoComplete="on"
        active={isCurrent}
      >
        <FormBody>
          <CardContact />
          <CardShippingMethod />
        </FormBody>

        <ContinueButton type="submit">
          <Trans>Continuar</Trans>
        </ContinueButton>
      </Form>
    </FormProvider>
  )
}
