import { Action, State } from "../types"

export function productsReducer(state: State, action: Action): State {
  switch (action.type) {
    case "addResults": {
      return {
        ...state,
        meta: action.payload.meta,
        objects: action.payload.objects,
      }
    }
    case "changeLimit": {
      return {
        ...state,
        meta: { ...state.meta, limit: action.payload.limit },
      }
    }
    case "changeOrdering": {
      return {
        ...state,
        meta: { ...state.meta, offset: action.payload.offset },
        ordering: action.payload.ordering,
      }
    }
  }
}
