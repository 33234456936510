import React, { useCallback } from "react"

export type State = {
  open: boolean
  title: React.ReactNode
  body: React.ReactNode
  onClose?: () => void
  disableCloseButton?: boolean
  disableMobile?: boolean
  size?: "large" | "medium"
}

export type ModalOptions = Omit<State, "open">

export type Actions =
  | {
      type: "open modal"
      payload: ModalOptions
    }
  | {
      type: "close modal"
    }

const initialState: State = Object.freeze({
  open: false,
  title: "",
  body: "",
  onClose: () => {},
  disableCloseButton: false,
  disableMobile: false,
  size: "large",
})

export const reducer: React.Reducer<State, Actions> = (state, action) => {
  switch (action.type) {
    case "open modal": {
      return {
        open: true,
        title: action.payload.title ?? state.title,
        body: action.payload.body ?? state.body,
        onClose: action.payload.onClose ?? state.onClose,
        disableCloseButton:
          action.payload.disableCloseButton ?? state.disableCloseButton,
        disableMobile: action.payload.disableMobile ?? state.disableMobile,
        size: action.payload.size ?? state.size,
      }
    }
    case "close modal": {
      return {
        ...initialState,
        open: false,
      }
    }
  }
}

export const ModalStateContext = React.createContext<State>(initialState)

export const ModalDispatchContext = React.createContext<
  React.Dispatch<Actions>
>(() => {})

export function ModalProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return (
    <ModalDispatchContext.Provider value={dispatch}>
      <ModalStateContext.Provider value={state}>
        {children}
      </ModalStateContext.Provider>
    </ModalDispatchContext.Provider>
  )
}

export function useModal() {
  const state = React.useContext(ModalStateContext)
  const dispatch = React.useContext(
    ModalDispatchContext
  ) as React.Dispatch<Actions>

  const openModal = useCallback(
    (options: ModalOptions) => {
      dispatch({ type: "open modal", payload: options })
    },
    [dispatch]
  )

  const closeModal = useCallback(() => {
    dispatch({ type: "close modal" })
  }, [dispatch])

  return {
    state,
    actions: {
      openModal,
      closeModal,
    },
  }
}
