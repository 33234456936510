import { Action, State } from "../types"

import { Property } from "../../components/NamePriceActions/ProductVariants/types"
import { Variant } from "../../types"

export function productReducer(state: State, action: Action): State {
  switch (action.type) {
    case "addProductInfo": {
      const { variant, defaultValues } = findDefaultVariant(
        action.payload.variants,
        action.payload.properties
      )
      return {
        ...state,
        product: { ...action.payload },
        selectedVariant: variant,
        selectedValues: defaultValues,
      }
    }
    case "setSelectedVariant": {
      return {
        ...state,
        selectedVariant: action.payload,
      }
    }
    case "setSelectedValues": {
      return {
        ...state,
        selectedValues: [
          ...state.selectedValues.filter(
            value => value.propertyId !== action.payload.propertyId
          ),
          action.payload,
        ],
      }
    }
    case "setDefaultValues": {
      return {
        ...state,
        selectedValues: action.payload,
      }
    }
    case "changeQuantity": {
      return {
        ...state,
        quantity: action.payload,
      }
    }
    case "removeOppositeSelectedValue": {
      return {
        ...state,
        selectedValues: state.selectedValues.filter(
          selectedValue =>
            selectedValue.propertyId === action.payload.propertyId
        ),
      }
    }
  }
}

function findDefaultVariant(variants: Variant[], properties: Property[]) {
  const variant = variants.find(variant => variant.stock > 0)
  if (variant) {
    if (variant.values.length > 0) {
      const defaultValues = variant.values.map(value => {
        const property = properties.filter(property =>
          property.values.some(val => val.id === value)
        )
        return {
          propertyId: property[0].id,
          id: value,
        }
      })
      return { variant, defaultValues }
    }
    return { variant, defaultValues: [] }
  }
  return { variant: null, defaultValues: [] }
}
