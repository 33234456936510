import { Container } from "./Container"

export function Alert({ ...props }) {
  return (
    <Container>
      <path
        d="M9.7155,1.5383A.85.85,0,0,0,9,1.13a.8246.8246,0,0,0-.7155.4088L.1073,15.6439a.8326.8326,0,0,0,0,.8178.85.85,0,0,0,.7155.4088H17.1772a.85.85,0,0,0,.7155-.4088.8326.8326,0,0,0,0-.8178ZM2.2334,15.2351,9,3.5826l6.7462,11.6525Z"
        fill="currentColor"
      />
      <path
        d="M8.1823,7.4667V11.351a.8177.8177,0,0,0,1.6354,0V7.4667a.8177.8177,0,0,0-1.6354,0Z"
        fill="currentColor"
      />
      <path
        d="M9,12.7819a.8178.8178,0,1,0,.8177.8178A.8177.8177,0,0,0,9,12.7819Z"
        fill="currentColor"
      />
    </Container>
  )
}
