import { Response } from "../types"
import paths from "../../../paths"
import { service } from "../../../Service"
import { transformResponse } from ".."

export function get() {
  return async function () {
    try {
      const response = await service.get<Response>(paths.carts())

      return transformResponse(response.body)
    } catch (error) {
      throw new Error(`API Carts did not respond correctly`)
    }
  }
}

export * as Get from "./types"
