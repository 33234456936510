import { useCheckout, usePaymentOption } from "../../../../../../../../hooks"

import { InformationColumn } from "../InformationColumn"
import { PriceView } from "../../../../../../../../components/PriceView"
import { toLocalePrice } from "../../../../../../../../utils"

export const PaymentColumn = () => {
  const { paymentOption } = usePaymentOption()
  const { payment, totalPrice, currency } = useCheckout().data.meta

  return (
    <>
      {payment?.type === "C" && (
        <InformationColumn
          type="payment"
          description={paymentOption?.name || ""}
          offersDiscount={paymentOption?.offersDiscount}
          discountType={paymentOption?.discountType}
          discountValue={paymentOption?.discountValue}
          currency={currency}
        />
      )}
      {payment?.type === "M" && (
        <InformationColumn
          type="payment"
          description={
            <>
              Mercado Pago <br />
              <PriceView price={toLocalePrice(totalPrice, currency)} />
            </>
          }
          offersDiscount={null}
          discountType={null}
          discountValue={null}
          currency={currency}
        />
      )}
    </>
  )
}
