import { Response } from "../types"
import paths from "../../../paths"
import { service } from "../../../Service"
import { transformResponse } from ".."

export function get(checkoutId?: string) {
  return async function () {
    if (checkoutId == null) {
      throw Error("Don't call Checkout GET without checkout ID")
    }

    const response = await service.get<Response>(paths.checkout(checkoutId))

    return transformResponse(response.body)
  }
}
