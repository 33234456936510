import { Trans, t } from "@lingui/macro"
import { colors, mediaQuery, shadows } from "../../../../theme"

import { Chevron } from "../../../../icons"
import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { Status } from "../../hooks/useProducts"
import { Title } from "../Desktop/styles"
import styled from "@emotion/styled"
import { useFilters } from "../../hooks/useFilters"
import { useHistory } from "react-router-dom"
import { useMeta } from "../../hooks/useMeta"
import { useProductsStore } from "../../provider/ProductStoreProvider"

type NoProductType =
  | "searchByFilter"
  | "searchByText"
  | "hasNoProducts"
  | "hasError"
  | ""

export function NoProductsCard({ status }: { status: Status }) {
  const history = useHistory()
  const { appliedFiltersValues, priceFilter } = useFilters()
  const { meta } = useProductsStore()
  const { title } = useMeta()

  const noProductsType: NoProductType = getProductType({
    status,
    hasAppliedFilters: appliedFiltersValues.length > 0,
    priceFilter,
    title,
    totalAcount: meta.totalCount,
  })

  const noProductWording = {
    searchByFilter: {
      title: "",
      content: t`No encontramos productos para tu búsqueda. Podés aplicar otro
      filtro o escribir una palabra.`,
    },
    searchByText: {
      title: t`No encontramos "${title}"`,
      content: t`Revisá si hay algún error en lo que escribiste, navegá por las categorías o intentá con otras palabras clave.`,
    },
    hasNoProducts: {
      title: t`Estamos armando nuestra Tienda`,
      content: t`Todavía no cargamos nuestros productos, pero podés seguir navegando nuestra tienda para conocernos mejor.`,
    },
    hasError: {
      title: t`¿Nos das otra oportunidad?`,
      content: t`Ocurrió un error y no pudimos hacer la búsqueda.`,
    },
  }

  if (noProductsType === "") {
    return <></>
  }

  return (
    <Container>
      <Description>
        {noProductsType !== "searchByFilter" && (
          <NoProductCardTitle>
            {noProductWording[noProductsType].title}
          </NoProductCardTitle>
        )}
        <Text>{noProductWording[noProductsType].content}</Text>
      </Description>
      {noProductsType !== "hasNoProducts" && (
        <GoBackButton onClick={history.goBack}>
          <Chevron />
          <Trans>Buscar de nuevo</Trans>
        </GoBackButton>
      )}
    </Container>
  )

  type getProductTypeProps = {
    status: string
    hasAppliedFilters: boolean
    priceFilter: string
    title: String | undefined
    totalAcount: number
  }

  function getProductType({
    status,
    hasAppliedFilters,
    priceFilter,
    title,
    totalAcount,
  }: getProductTypeProps) {
    if (status === "error") {
      return "hasError"
    } else if (hasAppliedFilters || priceFilter) {
      return "searchByFilter"
    } else if (title !== undefined) {
      return "searchByText"
    } else if (totalAcount === 0 && title === undefined) {
      return "hasNoProducts"
    }
    return ""
  }
}

const Container = styled.div<PropsWithTheme>(props => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: 4,
  backgroundColor: colors.white,
  fontFamily: props.theme.typographies.texts,
  boxShadow: shadows[3],
  margin: "2.8rem 1.6rem 6.8rem",
  padding: "2.4rem 1.6rem 2.4rem 1.6rem",

  [mediaQuery.desktop]: {
    margin: "4.8rem 0 6.8rem",
    padding: "6.8rem 20rem 6.8rem 20rem",
  },
}))

const Description = styled.div<PropsWithTheme>(props => ({
  fontFamily: props.theme.typographies.titles,
  textAlign: "center",
  fontSize: "1.6rem",

  [mediaQuery.desktop]: {
    fontSize: "1.8rem",
    marginBottom: "3.8rem",
  },
}))

const Text = styled.p({
  fontSize: "1.8rem",
  color: colors.greyMedium,
})

const NoProductCardTitle = styled(Title)({
  textTransform: "none",
  fontSize: "2rem",

  [mediaQuery.desktop]: {
    fontSize: "2.4rem",
  },
})

const GoBackButton = styled.button({
  display: "flex",
  alignSelf: "center",
  justifyContent: "center",
  cursor: "pointer",
  fontSize: "1.4rem",
  background: "transparent",
  border: "none",
  color: colors.greyMedium,

  [mediaQuery.desktop]: {
    fontSize: "1.6rem",
  },

  "& svg": {
    color: colors.greyMedium,
    transform: "rotate(90deg)",
    fontSize: "1rem",
    display: "flex",
    alignSelf: "center",
    marginRight: "0.8rem",
  },

  "&:hover": {
    textDecoration: "underline",
    color: colors.black,
    "& svg": {
      color: colors.black,
    },
  },
})
