import { Controller, useFormContext } from "react-hook-form"
import {
  DecoratedText,
  DestinationContainer,
  SingleColumnRow,
  Subtitle,
} from "../../../../../../style"
import { Trans, t } from "@lingui/macro"

import { ContainerCard } from "../../../../../../../../../../components/ContainerCard"
import { ShippingOptionsP as OptionsP } from "../../../../../../../../../../api/stores/checkout"
import { PriceView } from "../../../../../../../../../../components/PriceView"
import { ShippingData } from "../../../../types"
import { ShippingOptionCard } from "../ShippingOptionCard"
import { toLocalePrice } from "../../../../../../../../../../utils"
import { useCheckout } from "../../../../../../../../../../hooks"
import { useCheckoutScreen } from "../../../../../../../../hooks/useCheckoutScreen"
import { useShippingOptions } from "../../hooks/useShippingOptions"

export const ShippingOptionsP = () => {
  useShippingOptions()
  const { control, errors } = useFormContext<ShippingData>()
  const {
    requirements: { shippingOptions },
    shipping,
  } = useCheckout().data.meta

  const { setCurrentStep } = useCheckoutScreen()

  const address =
    `${shipping?.destination?.city}, ${shipping?.destination?.state}, ${shipping?.destination?.postalCode}` ||
    ""

  return (
    <ContainerCard title={<Trans>Opciones de envío</Trans>}>
      <SingleColumnRow>
        <Subtitle>
          <Trans>¿Dónde lo enviamos?</Trans>
        </Subtitle>
        <DestinationContainer>
          {address}
          <DecoratedText onClick={() => setCurrentStep(1)}>
            <Trans>Modificar</Trans>
          </DecoratedText>
        </DestinationContainer>
        <Subtitle>
          <Trans>Elegí donde vas a retirar tu compra</Trans>
        </Subtitle>
        <Controller
          control={control}
          name="shippingOption"
          rules={{
            required: t`Elegí una opción para poder continuar.`,
          }}
          render={({ onChange, value }) => (
            <>
              {(shippingOptions as OptionsP).options.map(shippingOption => (
                <ShippingOptionCard
                  key={shippingOption.id}
                  label={
                    <>
                      <strong>{shippingOption.carrierName}</strong> -{" "}
                      {shippingOption.location.street}{" "}
                      {shippingOption.location.streetNumber}
                    </>
                  }
                  hasError={!!errors.shippingOption}
                  value={shippingOption.id}
                  checked={value === shippingOption.id}
                  onChange={onChange}
                  price={
                    shippingOption.price === 0 ? (
                      <Trans>Gratis</Trans>
                    ) : (
                      <PriceView
                        price={toLocalePrice(
                          shippingOption.price,
                          shippingOption.currency
                        )}
                      />
                    )
                  }
                  description={
                    <span>
                      <Trans>
                        Llega entre el{" "}
                        <strong>{shippingOption.arrivalDate}</strong>. <br />
                        {shippingOption.openHours}{" "}
                        {shippingOption.phone && `Tel: ${shippingOption.phone}`}
                      </Trans>
                    </span>
                  }
                />
              ))}
            </>
          )}
        />
      </SingleColumnRow>
    </ContainerCard>
  )
}
