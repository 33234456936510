import { colors, mediaQuery } from "../../../../../../../../theme"

import { Trans } from "@lingui/macro"
import styled from "@emotion/styled"
import { usePaymentOption } from "../../../../../../../../hooks"

export const PurchaseIndications = () => {
  const { paymentOption } = usePaymentOption()

  return (
    <Container>
      <Title data-testid="purchaseIndications-title">
        <Trans>Indicaciones</Trans>
      </Title>
      <Description data-testid="purchaseIndications-description">
        {paymentOption?.description}
      </Description>
    </Container>
  )
}

const Container = styled.div({
  display: "flex",
  flexFlow: "column nowrap",
  gap: ".8rem",
  marginBottom: "1.6rem",

  [mediaQuery.desktop]: {
    marginBottom: "2.4rem",
  },
})

const Title = styled.h1({
  fontSize: "1.6rem",
  color: colors.greyDark,
  margin: 0,
})

const Description = styled.p({
  whiteSpace: "pre-line",
  fontSize: "1.4rem",
  color: colors.greyDark,
  margin: 0,
  maxWidth: "100%",
  wordBreak: "break-word",
})
