import styled from "@emotion/styled/macro"
import { useCheckout } from "../../../../../../../../hooks"

export const ThanksYouTitle = () => {
  const { payment } = useCheckout().data.meta

  if (payment?.status === "failure") return <></>

  return (
    <Container>
      {payment?.status === "success" && "¡Gracias por tu compra!"}
      {payment?.status === "pending" && "¡Gracias por tu pedido!"}
    </Container>
  )
}

const Container = styled.h1({
  fontSize: "2.2rem",
  fontWeight: 600,
  margin: 0,
  paddingBottom: "1.6rem",
  paddingTop: "1.2rem",
})
