import { useFindVariantByValue } from "../../../../hooks/useFindVariantByValue"
import { useProductStore } from "../../../../provider/ProductStoreProvider"

type Props = {
  propertyValue: { propertyId: string; id: number }
}

export function useIsValueAvailable() {
  const { selectedValues } = useProductStore()
  const { findVariantByValue } = useFindVariantByValue()

  function isVariantAvailable({ propertyValue }: Props) {
    let isPossibleVariantAvailable = false
    const isSelected = selectedValues.some(
      value => value.id === propertyValue.id
    )
    const possibleVariant = findVariantByValue(propertyValue)

    if (!isSelected) {
      isPossibleVariantAvailable = possibleVariant
        ? possibleVariant?.stock > 0
        : false
    }

    return isPossibleVariantAvailable
  }

  return { isVariantAvailable }
}
