import { CheckoutContext, initialState } from "../providers/CheckoutProvider"
import { useCallback, useContext } from "react"

import { api } from "../api"
import { useHistory } from "react-router-dom"

const checkoutPath = window.ROUTES
  ? window.ROUTES.find(route => route.kind === "H")?.path
  : ""

export const useCheckout = (checkoutId?: string) => {
  const history = useHistory()
  const contextValue = useContext(CheckoutContext)

  if (contextValue === null) {
    throw new Error("useCheckout must be used within a CheckoutProvider")
  }

  const { data, setData, status, setStatus } = contextValue

  const getData = useCallback(async () => {
    setStatus("fetching")

    return api.stores
      .set()
      .checkout.set(checkoutId)
      .get()
      .then(checkoutData => {
        setData(checkoutData)
        setStatus("success")
      })
      .catch(err => {
        setStatus("error")
        console.error(err)
      })
  }, [setStatus, setData, checkoutId])

  const startCheckout = useCallback(() => {
    if (checkoutPath) {
      setStatus("fetching")

      api.stores
        .set()
        .carts.set()
        .startCheckout.post()
        .then(() => history.replace(checkoutPath))
        .catch(err => {
          console.error(err)
          setStatus("error")
        })
    }
  }, [history, setStatus])

  const deleteCheckout = useCallback(() => {
    setStatus("fetching")

    api.stores
      .set()
      .checkout.set()
      .delete()
      .then(() => history.replace("/"))
      .catch(err => {
        console.error(err)
        setStatus("error")
      })
  }, [history, setStatus])

  const resetCheckout = useCallback(() => {
    setData(initialState)
  }, [setData])

  return {
    status,
    setStatus,
    data,
    setData,
    getData,
    startCheckout,
    deleteCheckout,
    resetCheckout,
  }
}
