import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react"
import { colors, mediaQuery, opacity, shadows } from "../../theme"
import {
  useCart,
  useCheckout,
  useScreenBlockers,
  useScreenSize,
} from "../../hooks"

import { Button } from "../Button"
import { CartModalCounter } from "./components/CartModalCounter"
import { Chevron } from "../../icons"
import { PaymentMethodsLinkInCart } from "./components/PaymentMethodsLinkInCart"
import { PriceView } from "../PriceView"
import { ProductCard } from "./components/ProductCard"
import { PropsWithTheme } from "../../providers/StoreThemeProvider"
import { Trans } from "@lingui/macro"
import { api } from "../../api"
import styled from "@emotion/styled/macro"
import { toLocalePrice } from "../../utils/PriceUtil"

export function ShoppingCart() {
  const { isDesktop, innerHeight } = useScreenSize()
  const { register, unregister } = useScreenBlockers("shopping-cart")
  const { state, data, actions, isPurchaseCartDisabled } = useCart()
  const [noStockErrorArr, setNoStockErrorArr] = useState<number[]>(() =>
    createNoStockErrorArr(data)
  )
  const { startCheckout } = useCheckout()
  const [isPurchaseLoading, setIsPurchaseLoading] = useState<boolean>(false)

  const products = data.objects
  const metadata = data.meta

  useLayoutEffect(() => {
    if (state.isOpen) {
      register()
    } else {
      unregister()
    }
  }, [state.isOpen, register, unregister])

  useEffect(() => {
    setNoStockErrorArr(createNoStockErrorArr(data))
  }, [data])

  const isNotEmpty = products.length > 0

  const handlePurchase = async () => {
    setIsPurchaseLoading(true)
    const data = await actions.getData()

    if (!data) {
      setIsPurchaseLoading(false)
      return //TODO: Cover case where this request fails and the verification isn't complete
    }

    const noStockErrorArr = createNoStockErrorArr(data)
    setNoStockErrorArr(noStockErrorArr)

    if (!noStockErrorArr.length && !isPurchaseCartDisabled) {
      startCheckout()
      actions.closeCart()
      setIsPurchaseLoading(false)
    }
  }

  const handleStockError = useCallback(
    (variantId: number, hasStockError: boolean) => {
      const variantIdIndex = noStockErrorArr.indexOf(variantId)

      if (hasStockError && variantIdIndex === -1) {
        setNoStockErrorArr(prevState => [...prevState, variantId])
      } else if (!hasStockError && variantIdIndex !== -1) {
        const newStockErrorArr = noStockErrorArr.filter(id => id !== variantId)

        setNoStockErrorArr(newStockErrorArr)
      }
    },
    [noStockErrorArr]
  )

  return (
    <>
      {isDesktop && <Background open={state.isOpen} />}
      <SideDrawer
        innerHeight={innerHeight}
        data-testid="drawer"
        open={state.isOpen}
      >
        <Header>
          {(!isNotEmpty || (isNotEmpty && isDesktop)) && (
            <Title>
              <Trans>Mi carrito</Trans>
            </Title>
          )}
          {isNotEmpty && (
            <CloseDrawerButton
              data-testid="close-cart"
              onClick={actions.closeCart}
            >
              <Icon>
                <Chevron />
              </Icon>
              {isDesktop ? (
                <Trans>Seguir comprando</Trans>
              ) : (
                <Title>
                  <Trans>Mi carrito</Trans>
                </Title>
              )}
            </CloseDrawerButton>
          )}
        </Header>
        <Content>
          {isNotEmpty && !metadata.hasCheckout ? (
            <Container>
              {products.map((product, index) => (
                <Item key={product.itemId}>
                  <ProductCardContainer>
                    <ProductCard
                      index={index}
                      product={product}
                      handleStockError={noStockError =>
                        handleStockError(product.variantId, noStockError)
                      }
                    />
                  </ProductCardContainer>
                </Item>
              ))}
              <PaymentMethodsContainer>
                <PaymentMethodsLinkInCart />
              </PaymentMethodsContainer>
              <Divider />
              <SubTotal>
                <Trans>Total</Trans>:{" "}
                <PriceView
                  price={toLocalePrice(metadata.subtotal, metadata.currency)}
                />
              </SubTotal>
            </Container>
          ) : (
            <Fragment>
              <Description>
                <Trans>
                  Tu carrito está vacío. ¡Buscá ahora lo que querés!
                </Trans>
              </Description>
              <CloseDrawerButton onClick={actions.closeCart}>
                <Icon>
                  <Chevron />
                </Icon>
                <Trans>Ir a comprar</Trans>
              </CloseDrawerButton>
            </Fragment>
          )}
        </Content>
        {isNotEmpty && (
          <Button
            notAllowed={isPurchaseCartDisabled || noStockErrorArr.length > 0}
            disabled={
              isPurchaseCartDisabled ||
              noStockErrorArr.length > 0 ||
              isPurchaseLoading
            }
            onClick={handlePurchase}
            loading={isPurchaseLoading}
          >
            <Trans>Comprar carrito</Trans>
          </Button>
        )}
      </SideDrawer>
      <CartModalCounter />
    </>
  )
}

function createNoStockErrorArr(data: api.stores.carts.Result) {
  const initialNoStockErrorArr: number[] = []
  data.objects.forEach(elem => {
    if (elem.quantity > elem.stock) {
      initialNoStockErrorArr.push(elem.variantId)
    }
  })
  return initialNoStockErrorArr
}

type SideDrawerProps = {
  open: boolean
  innerHeight?: number
}

const Background = styled.div<SideDrawerProps>(props => ({
  zIndex: 10000,
  position: "fixed",
  top: 0,
  left: 0,
  height: "100vh",
  width: "100vw",
  backgroundColor: "black",
  opacity: props.open ? opacity[2] : opacity[0],
  pointerEvents: props.open ? "auto" : "none",
  transition: "opacity 200ms ease-in-out",
}))

const SideDrawer = styled.div<SideDrawerProps>(({ open, innerHeight }) => {
  const headerHeight = "3.2rem"
  const footerHeight = "4rem"
  const drawerPaddingMobile = "1.6rem"
  const drawerPaddingDesktop = "3.2rem"
  const contentMobile = `calc(${innerHeight}px - ${headerHeight} - ${footerHeight} - (${drawerPaddingMobile} * 2))`
  const contentDesktop = `calc(100vh - ${headerHeight} - ${footerHeight} - (${drawerPaddingDesktop} * 2))`

  return {
    zIndex: 10100,
    display: "grid",
    gridTemplateRows: `${headerHeight} ${contentMobile} ${footerHeight}`,
    transform: open ? "translateX(0px)" : "translate(100%)",
    position: "fixed",
    backgroundColor: colors.white,
    boxShadow: open ? shadows[1] : shadows[0],
    height: "100vh",
    top: 0,
    right: 0,
    padding: drawerPaddingMobile,
    width: "100%",
    [mediaQuery.desktop]: {
      width: "45%",
      padding: drawerPaddingDesktop,
      gridTemplateRows: `${headerHeight} ${contentDesktop} ${footerHeight}`,
    },
    transition: "transform 200ms ease-in-out",
  }
})

const Header = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: `2px solid ${colors.greyLight}`,
  paddingBottom: "0.8rem",
  [mediaQuery.desktop]: {
    paddingBottom: "1.6rem",
  },
})

const Title = styled.span<PropsWithTheme>(props => ({
  color: colors.greyMedium,
  fontFamily: props.theme.typographies.titles,
  fontSize: "2rem",
  fontWeight: "bold",
  marginTop: "0.4rem 0",
}))

const CloseDrawerButton = styled.button<PropsWithTheme>(props => ({
  display: "flex",
  alignItems: "baseline",
  justifyContent: "center",
  cursor: "pointer",
  fontSize: "1.6rem",
  fontFamily: props.theme.typographies.titles,
  background: "transparent",
  border: "none",
  color: colors.greyMedium,
  padding: 0,
  gap: "0.7rem",
  lineHeight: "1",
  "&:hover": {
    textDecoration: "underline",
    color: colors.black,
    "& svg": {
      color: colors.black,
    },
  },
}))

const Icon = styled.span<PropsWithTheme>(props => ({
  color: colors.greyMedium,
  transform: "rotate(90deg)",
  fontSize: "1rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const Content = styled.div({
  display: "flex",
  flexFlow: "column",
  height: "100%",
})

const Container = styled.div({
  height: "100%",
  marginBottom: "1rem",
  overflowY: "auto",
  width: "100%",

  [mediaQuery.desktop]: {
    overflowY: "scroll",
    width: "calc(100% + 3.2rem)",

    "::-webkit-scrollbar": {
      width: "3.2rem",
    },

    "::-webkit-scrollbar-thumb": {
      border: "1.2rem solid rgba(0, 0, 0, 0)",
      backgroundClip: "padding-box",
      WebkitBorderRadius: "1.6rem",
      backgroundColor: "rgba(0, 0, 0, 0.15)",
    },

    "::-webkit-scrollbar-button": {
      width: 0,
      height: 0,
      display: "none",
    },

    "::-webkit-scrollbar-corner": {
      backgroundColor: "transparent",
    },
  },
})

const Item = styled.div({
  "&:nth-of-type(odd)": {
    background: colors.greyLightest,
  },
  borderBottom: `2px solid ${colors.greyMainLight}`,

  "&:last-of-type": {
    borderBottom: `1px solid ${colors.greyMainLight}`,
  },
})

const ProductCardContainer = styled.div({
  padding: "0.8rem 0 0.8rem",
  [mediaQuery.desktop]: {
    padding: "1.6rem 0 1.6rem",
  },
})

const Description = styled.p<PropsWithTheme>(props => ({
  color: props.theme.colors.titles,
  fontFamily: props.theme.typographies.titles,
  fontSize: "1.6rem",
}))

const Divider = styled.h3<PropsWithTheme>(props => ({
  borderBottom: `1px solid ${colors.greyMainLight}`,
  margin: 0,
}))

const PaymentMethodsContainer = styled.div({
  display: "grid",
  justifyContent: "end",
})

const SubTotal = styled.h2<PropsWithTheme>(props => ({
  fontSize: "1.6rem",
  fontWeight: "bold",
  color: colors.greyMedium,
  fontFamily: props.theme.typographies.titles,
  textAlign: "right",
  margin: "1.6rem 0",

  "& sup": {
    fontSize: "1rem",
  },
}))
