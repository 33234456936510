import { useEffect, useState } from "react"

import { Requirements } from "../api/stores/checkout"
import { useCheckout } from "./useCheckout"

type PaymentOption = Requirements["paymentOptions"][0]

export function usePaymentOption() {
  const [paymentOption, setPaymentOption] = useState<
    PaymentOption | undefined
  >()
  const {
    payment,
    requirements: { paymentOptions },
  } = useCheckout().data.meta

  useEffect(() => {
    const paymentOption = paymentOptions.find(
      paymentOption => paymentOption.id === payment?.id
    )
    setPaymentOption(paymentOption)
  }, [payment, paymentOptions])

  return { paymentOption }
}
