import { CreditCard } from "../../../../icons"
import { PaymentMethodsCart } from "./PaymentMethodsCart"
import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { Trans } from "@lingui/macro"
import { colors } from "../../../../theme"
import styled from "@emotion/styled/macro"
import { useModal } from "../../../../hooks"

export function PaymentMethodsLinkInCart() {
  const {
    actions: { openModal },
  } = useModal()

  const handleClick = () => {
    openModal({
      title: (
        <ModalTitle>
          <Trans>Medios de pago</Trans>
        </ModalTitle>
      ),
      body: (
        <ModalBody>
          <PaymentMethodsCart />
        </ModalBody>
      ),
    })
  }
  return (
    <PaymentMethodsContainer onClick={handleClick}>
      <CreditCard />
      <Trans>Ver medios de pago</Trans>
    </PaymentMethodsContainer>
  )
}

const PaymentMethodsContainer = styled.div<PropsWithTheme>(props => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  fontSize: "1.4rem",
  fontFamily: props.theme.typographies.texts,
  background: "transparent",
  border: "none",
  color: colors.greyMedium,
  margin: "1.6rem 0",

  "& svg": {
    marginRight: "0.8rem",
    fontSize: "2rem",
  },

  "&:hover": {
    textDecoration: "underline",
    color: colors.black,
    "& svg": {
      color: colors.black,
    },
  },
}))

const ModalTitle = styled.h1<PropsWithTheme>(props => ({
  fontFamily: props.theme.typographies.titles,
  fontSize: "2.4rem",
  color: props.theme.colors.titles,
  fontWeight: 600,
  margin: 0,
}))

const ModalBody = styled.div<PropsWithTheme>({
  paddingTop: "2rem",
})
