import { colors, mediaQuery } from "../../../../theme"

import { Chevron } from "../../../../icons"
import { Fragment } from "react"
import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { readableColor } from "polished"
import styled from "@emotion/styled/macro"
import { usePagination } from "../../hooks/usePagination"

export function Paginator() {
  const {
    currentPage,
    totalPages,
    handleNextPage,
    handlePreviousPage,
    shownPages,
    handleChangePage,
  } = usePagination()

  return totalPages > 1 ? (
    <Container data-testid="paginator">
      {shownPages.length > 1 && (
        <PreviousButton
          data-testid="previous-button"
          disabled={currentPage === 1}
          onClick={handlePreviousPage}
        >
          <Chevron />
        </PreviousButton>
      )}
      {shownPages.map(page => (
        <PageButton
          data-testid={`page-${page}-button`}
          key={page}
          selected={page === currentPage}
          onClick={() => handleChangePage(page)}
        >
          {page}
        </PageButton>
      ))}
      {shownPages.length > 1 && (
        <NextButton
          data-testid="next-button"
          disabled={currentPage === totalPages}
          onClick={handleNextPage}
        >
          <Chevron />
        </NextButton>
      )}
    </Container>
  ) : (
    <Fragment />
  )
}

const Container = styled.div({
  display: "grid",
  gridAutoFlow: "column",
  gridAutoColumns: "max-content",
  gridGap: "2rem",

  [mediaQuery.desktop]: {
    gridGap: "0.8rem",
  },
})

type PreviousButtonProps = {
  disabled: boolean
}

const PreviousButton = styled.button<PropsWithTheme & PreviousButtonProps>(
  props => ({
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: props.disabled ? "default" : "pointer",

    "& svg": {
      color: props.disabled
        ? colors.greyMain
        : readableColor(props.theme.colors.background),
      transform: "rotate(90deg)",
      fontSize: "0.8rem",
    },
  })
)

type PageButtonProps = {
  selected: boolean
}

const PageButton = styled.button<PropsWithTheme & PageButtonProps>(props => {
  const selectedColor = props.theme.colors.primary
  return {
    width: "2.4rem",
    height: "2.4rem",
    border: "none",
    borderRadius: "50%",
    backgroundColor: props.selected ? selectedColor : "transparent",
    outline: "none",
    fontSize: "1.4rem",
    color: props.selected
      ? colors.white
      : readableColor(props.theme.colors.background),
    cursor: props.selected ? "default" : "pointer",
    padding: 0,

    "&:hover": {
      backgroundColor: selectedColor,
      opacity: props.selected ? "100%" : "60%",
    },
  }
})

type NextButtonProps = {
  disabled: boolean
}

const NextButton = styled.button<PropsWithTheme & NextButtonProps>(props => ({
  border: "none",
  outline: "none",
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: props.disabled ? "default" : "pointer",

  "& svg": {
    color: props.disabled
      ? colors.greyMain
      : readableColor(props.theme.colors.background),
    transform: "rotate(-90deg)",
    fontSize: "0.8rem",
  },
}))
