import { ProductStoreProvider } from "./provider/ProductStoreProvider"
import { ProductVIPHead } from "./components/ProductVIPHead"
import { ProductView } from "./components/ProductView"

type ProductVIPProps = {
  productId: string
  urlTitle: string
}

export function ProductVIP({ productId, urlTitle }: ProductVIPProps) {
  return (
    <ProductStoreProvider>
      <ProductVIPHead />
      <ProductView productId={productId} urlTitle={urlTitle} />
    </ProductStoreProvider>
  )
}
