import { language } from "./language"
import { maintenanceMode } from "./maintenance_mode"
import { paymentMethods } from "./payment_methods"
import { products } from "./products"
import { stores } from "./stores"

export const handlers = [
  ...stores,
  ...products,
  ...paymentMethods,
  ...maintenanceMode,
  ...language,
]
