import { FC, createContext, useEffect, useState } from "react"

import { BREAKPOINTS } from "../../theme"

export type ScreenSize = {
  innerHeight: number
  innerWidth: number
  isDesktop: boolean
  isMobile: boolean
}

export const ScreenSizeContext = createContext<ScreenSize | null>(null)

export const ScreenSizeProvider: FC = ({ children }) => {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight)
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)

  useEffect(() => {
    const listener = () => {
      setInnerHeight(window.innerHeight)
      setInnerWidth(window.innerWidth)
    }

    window.addEventListener("resize", listener)

    return () => window.removeEventListener("resize", listener)
  }, [])

  return (
    <ScreenSizeContext.Provider
      value={{
        innerHeight,
        innerWidth,
        isDesktop: innerWidth >= BREAKPOINTS.DESKTOP_MIN,
        isMobile:
          innerWidth >= BREAKPOINTS.MOBILE_MIN &&
          innerWidth <= BREAKPOINTS.MOBILE_MAX,
      }}
    >
      {children}
    </ScreenSizeContext.Provider>
  )
}
