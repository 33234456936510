import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

import { t } from "@lingui/macro"
import { toLocalePrice } from "../../../utils"
import { useProductsStore } from "../provider/ProductStoreProvider"

type Value = {
  id: number
  name: string
  count: number
}

type Filter = {
  id: number
  name: string
  values: Value[]
}

export function useFilters() {
  const { meta } = useProductsStore()
  const { pathname, search: locationSearch } = useLocation()
  const history = useHistory()
  const urlSearchParams = new URLSearchParams(locationSearch)
  const filtersFromQueryString = urlSearchParams.get("value_in")
  const priceGt = urlSearchParams.get("price_gte")
  const priceLt = urlSearchParams.get("price_lte")
  const [appliedFiltersValues, setAppliedFiltersValues] = useState<Value[]>([])
  const [filters, setFilters] = useState<Filter[]>([])
  const [priceFilter, setPriceFilter] = useState<string>("")

  useEffect(() => {
    const filtersFromQuery = getFiltersFromQuery(filtersFromQueryString)
    const appliedFilters = meta.attributes.filter(attribute => {
      const isApplied = attribute.values.some(value =>
        filtersFromQuery.includes(String(value.id))
      )
      return isApplied
    })

    let appliedFiltersValues: Value[] = []

    appliedFilters.forEach(filter => {
      filter.values.forEach(value => {
        if (filtersFromQuery.indexOf(String(value.id)) > -1)
          appliedFiltersValues.push(value)
      })
    })

    const filters = meta.attributes.filter(attribute => {
      return !appliedFilters.includes(attribute)
    })
    setAppliedFiltersValues(appliedFiltersValues)
    setFilters(filters)
  }, [meta.attributes, filtersFromQueryString])

  useEffect(() => {
    let minPrice
    let maxPrice
    let priceText = ""
    if (priceGt && priceLt) {
      minPrice = toLocalePrice(parseInt(priceGt) * 100)
      maxPrice = toLocalePrice(parseInt(priceLt) * 100)
      priceText = t`${minPrice} a ${maxPrice}`
    } else if (priceGt) {
      minPrice = toLocalePrice(parseInt(priceGt) * 100)
      priceText = t`Desde ${minPrice}`
    } else if (priceLt) {
      maxPrice = toLocalePrice(parseInt(priceLt) * 100)
      priceText = t`Hasta ${maxPrice}`
    }
    setPriceFilter(priceText)
  }, [priceGt, priceLt])

  function addFilter(filterId: number) {
    const filters = getFiltersFromQuery(filtersFromQueryString)
    filters.push(String(filterId))

    urlSearchParams.set("value_in", filters.join(","))
    history.push({
      pathname: pathname,
      search: `?${urlSearchParams}`,
    })
  }

  function removeFilter(filterId: number) {
    const filters = getFiltersFromQuery(filtersFromQueryString)
    const filteredArray = filters.filter(filter => filter !== String(filterId))

    if (filteredArray.length === 0) {
      urlSearchParams.delete("value_in")
      setAppliedFiltersValues([])
    } else {
      urlSearchParams.set("value_in", `${filteredArray.join(",")}`)
    }

    history.push({
      pathname: pathname,
      search: `?${urlSearchParams}`,
    })
  }

  function removeAllFilters() {
    urlSearchParams.delete("value_in")
    urlSearchParams.delete("price_gte")
    urlSearchParams.delete("price_lte")

    history.push({
      pathname: pathname,
      search: `?${urlSearchParams}`,
    })
  }

  function applyPriceFilter(minPrice?: number, maxPrice?: number) {
    if (minPrice) {
      urlSearchParams.set("price_gte", `${minPrice}`)
    }

    if (maxPrice) {
      urlSearchParams.set("price_lte", `${maxPrice}`)
    }

    history.push({
      pathname: pathname,
      search: `?${urlSearchParams}`,
    })
  }

  function removePriceFilter() {
    setPriceFilter("")
    urlSearchParams.delete("price_gte")
    urlSearchParams.delete("price_lte")

    history.push({
      pathname: pathname,
      search: `?${urlSearchParams}`,
    })
  }

  return {
    appliedFiltersValues,
    filters,
    addFilter,
    removeFilter,
    removeAllFilters,
    priceFilter,
    applyPriceFilter,
    removePriceFilter,
  }
}

function getFiltersFromQuery(filters: string | null) {
  return filters ? filters.split(",") : []
}
