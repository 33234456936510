import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { api } from "../../../../api"
import { colors } from "../../../../theme"
import styled from "@emotion/styled/macro"
import { toLocalePrice } from "../../../../utils/PriceUtil"

type CustomPaymentMethodsProps = {
  customMethods: api.paymentMethods.Get.CustomPaymentMethod[]
}

export function CustomPaymentMethods({
  customMethods,
}: CustomPaymentMethodsProps) {
  return (
    <>
      {customMethods.map(customMethod => (
        <CustomMethodContainer key={customMethod.id}>
          <Title>{customMethod.name}</Title>
          {customMethod.offersDiscount &&
          customMethod.discountType != null &&
          customMethod.discountValue != null ? (
            <DiscountTag>
              {customMethod.discountType === "P"
                ? `${customMethod.discountValue}%`
                : // customMethod.discountValue doesn'have cents so we multiply it before toLocalPrice
                  `${toLocalePrice(
                    customMethod.discountValue * 100,
                    customMethod.currency
                  )} `}
              OFF
            </DiscountTag>
          ) : null}
        </CustomMethodContainer>
      ))}
    </>
  )
}

const CustomMethodContainer = styled.div({
  display: "flex",
  flexFlow: "row noWrap",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "1.2rem",
  borderBottom: `1px solid ${colors.greyMainLight}`,
  paddingBottom: "2rem",

  "&:last-child": {
    borderBottom: "none",
  },
})

const Title = styled.h2<PropsWithTheme>(props => ({
  fontSize: "1.6rem",
  fontWeight: 600,
  fontStyle: "normal",
  color: props.theme.colors.texts,
  fontFamily: props.theme.typographies.texts,
  margin: 0,
}))

const DiscountTag = styled.div({
  width: "fit-content",
  padding: "0.4rem 0.8rem",
  fontSize: "1.4rem",
  color: colors.white,
  fontWeight: "bold",
  fontStyle: "normal",
  backgroundColor: colors.greenDark,
  borderRadius: ".2rem",
})
