import { Helmet } from "react-helmet"
import { useProductStore } from "../../provider/ProductStoreProvider"

export function ProductVIPHead() {
  const canonicalHref = window.location.href.split("?")[0]

  const { product } = useProductStore()

  const headTitle =
    product.title && `${product.title} - Comprar en ${window.STORE_NAME}`
  const descriptionInfo = product.description ? product.description : ""

  return (
    <Helmet>
      <title>{headTitle}</title>
      <link rel="canonical" href={canonicalHref} />
      <meta name="description" content={descriptionInfo} />
    </Helmet>
  )
}
