import { colors, mediaQuery } from "../../../../../../../theme"

import { PropsWithTheme } from "../../../../../../../providers/StoreThemeProvider"
import { Variant } from "../../../../../types"
import styled from "@emotion/styled/macro"
import { t } from "@lingui/macro"
import { useAvailableOptions } from "../../hooks/useAvailableOptions"
import { useFindVariantByValue } from "../../../../../hooks/useFindVariantByValue"
import { useIsValueAvailable } from "../../hooks/useIsValueAvailable"
import { useMemo } from "react"
import { useProductStore } from "../../../../../provider/ProductStoreProvider"

type PropertyValueProps = {
  propertyId: string
  valueId: number
  name: string
  isDisabledProduct: boolean
  resetVariantHasError: () => void
}

export function ValueButton({
  valueId,
  name,
  isDisabledProduct,
  propertyId,
  resetVariantHasError,
}: PropertyValueProps) {
  const { dispatch, selectedValues } = useProductStore()
  const { findVariantByValue } = useFindVariantByValue()
  const { isVariantAvailable } = useIsValueAvailable()
  const { getAvailableOptions } = useAvailableOptions()

  const isSelected = selectedValues.some(value => value.id === valueId)

  const propertyValue = useMemo(() => ({ propertyId, id: valueId }), [
    propertyId,
    valueId,
  ])

  const possibleVariant = findVariantByValue(propertyValue)

  const isVariantPossible = isVariantAvailable({ propertyValue })
  const availableOptions = getAvailableOptions({ propertyValue })

  const availableInfo = getName({ availableOptions })

  return (
    <Button
      id={`${valueId}`}
      selected={isSelected}
      dashed={!isVariantPossible || isDisabledProduct}
      title={isVariantPossible ? "" : availableInfo}
      onClick={clickHandler}
      disabled={isDisabledProduct || (availableOptions?.outStock as boolean)}
    >
      <ValueText>{name}</ValueText>
    </Button>
  )

  function clickHandler() {
    dispatch({
      type: "setSelectedValues",
      payload: propertyValue,
    })
    dispatch({
      type: "setSelectedVariant",
      payload: possibleVariant as Variant,
    })

    if (possibleVariant === null || possibleVariant.stock === 0) {
      dispatch({
        type: "removeOppositeSelectedValue",
        payload: propertyValue,
      })
      dispatch({
        type: "setSelectedVariant",
        payload: null,
      })
    }
    resetVariantHasError()
  }

  type GetNameProps = {
    availableOptions?: {
      hasOtherOptionsAvailable: boolean
      outStock: boolean
    }
  }

  function getName({ availableOptions }: GetNameProps) {
    let availableInfo = ""
    if (availableOptions?.outStock) {
      availableInfo = t`Sin stock`
    } else if (availableOptions?.hasOtherOptionsAvailable) {
      availableInfo = t`Disponible en otras opciones`
    } else {
      availableInfo = ""
    }

    return availableInfo
  }
}

type ValueButtonProps = {
  selected: boolean
  dashed: boolean
  disabled: boolean
}

const Button = styled.button<PropsWithTheme & ValueButtonProps>(
  ({ theme, selected = false, dashed = false, disabled }) => ({
    fontSize: "1.6rem",
    fontFamily: theme.typographies.texts,
    color: selected ? theme.colors.primary : theme.colors.texts,
    backgroundColor: "transparent",
    padding: "0.8rem 1.6rem",
    cursor: disabled ? "not-allowed" : "pointer",
    borderRadius: "0.4rem",
    minWidth: "4rem",
    height: "4rem",
    outline: "none",
    position: "relative",
    border: "none",

    ...(dashed
      ? {
          "&:hover&:after": {
            borderColor: theme.colors.primary,
          },
        }
      : {}),

    /** This implements a border to the button, that when increasing its size, won't affect
     * the layout of the rest of the buttons.
     * Source: https://stackoverflow.com/questions/32571296/how-to-prevent-adjoining-elements-from-moving-when-increasing-border-width
     */
    "&:after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: "0.4rem",
      borderWidth: selected ? "2px" : "1px",
      borderStyle: dashed && !selected ? "dashed" : "solid",
      borderColor: selected ? theme.colors.primary : colors.greyMain,
    },

    [mediaQuery.desktop]: {
      minWidth: "6rem",
    },
  })
)

const ValueText = styled.div({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "26rem",
})
