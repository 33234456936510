import * as z from "zod"

export const BranchesRawSchema = z.object({
  city: z.string(),
  email: z.string(),
  hours: z.string(),
  id: z.number(),
  name: z.string(),
  phone: z.string(),
  state: z.string(),
  street: z.string(),
  street_extra: z.string(),
  street_number: z.string(),
  zip_code: z.string(),
})

export type BranchesRaw = z.infer<typeof BranchesRawSchema>

export const CustomRawSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  price_type: z.string(),
  price: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  show_delivery_time: z.boolean(),
  delivery_time: z.string(),
})

export type CustomRaw = z.infer<typeof CustomRawSchema>

export const FreeShippingRawSchema = z.object({
  active: z.boolean(),
  starter_amount: z.number(),
})

export type FreeShippingRaw = z.infer<typeof FreeShippingRawSchema>

export const ResponseSchema = z.object({
  branches: z.array(BranchesRawSchema),
  custom: z.array(CustomRawSchema),
  free_shipping: FreeShippingRawSchema,
})

export type Response = z.infer<typeof ResponseSchema>

export const BranchesSchema = z.object({
  city: z.string(),
  email: z.string(),
  hours: z.string(),
  id: z.number(),
  name: z.string(),
  phone: z.string(),
  state: z.string(),
  street: z.string(),
  streetExtra: z.string(),
  streetNumber: z.string(),
  zipCode: z.string(),
})

export type Branches = z.infer<typeof BranchesSchema>

export const CustomSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  priceType: z.string(),
  price: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  showDeliveryTime: z.boolean(),
  deliveryTime: z.string(),
})

export type Custom = z.infer<typeof CustomSchema>

export const FreeShippingSchema = z.object({
  active: z.boolean(),
  starterAmount: z.number(),
})

export type FreeShipping = z.infer<typeof FreeShippingSchema>

export const ResultSchema = z.object({
  branches: z.array(BranchesSchema),
  custom: z.array(CustomSchema),
  freeShipping: FreeShippingSchema,
})

export type Result = z.infer<typeof ResultSchema>
