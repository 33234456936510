import { Container } from "./Container"

export function CreditCard() {
  return (
    <Container>
      <path
        d="M16.7994,5.5873H1.2006V3.495a.3032.3032,0,0,1,.3-.2987H16.5a.3032.3032,0,0,1,.3.2987Zm0,9.2664a.3033.3033,0,0,1-.3.2988H1.5005a.3033.3033,0,0,1-.3-.2988V9.1744H16.8v5.6793ZM17.5588,2.44A1.4478,1.4478,0,0,0,16.5,2H1.5005A1.4479,1.4479,0,0,0,.4412,2.44,1.4377,1.4377,0,0,0,0,3.495V14.8527a1.4377,1.4377,0,0,0,.4411,1.0555,1.4475,1.4475,0,0,0,1.0593.4394H16.5A1.5017,1.5017,0,0,0,18,14.8527V3.495A1.4377,1.4377,0,0,0,17.5588,2.44Z"
        fill="currentColor"
      />
      <rect
        x="1.9531"
        y="12.9909"
        width="2.3997"
        height="1.196"
        fill="currentColor"
      />
      <rect
        x="5.5532"
        y="12.9909"
        width="3.6001"
        height="1.196"
        fill="currentColor"
      />
    </Container>
  )
}
