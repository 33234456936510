import { api } from "../../../.."
import { delay } from "../../../utils"
import { rest } from "msw"
import { startCheckout } from "./start_checkout"

function getResponse() {
  const sessionData = window.sessionStorage.getItem("MOCK_SESSION_SHOPPINGCART")

  return sessionData ? JSON.parse(sessionData) : require("./fixture.json")
}

function saveResponse(response: any) {
  window.sessionStorage.setItem(
    "MOCK_SESSION_SHOPPINGCART",
    JSON.stringify(response)
  )
}

export const carts = [
  rest.get("*/v1/stores/:storeID/carts/", function (_req, res, ctx) {
    const response = getResponse()

    saveResponse(response)

    return res(
      delay(ctx),
      ctx.json({
        meta: response.meta,
        objects: response.objects,
      })
    )
  }),
  rest.post("*/v1/stores/:storeID/carts/", function (req, res, ctx) {
    const response = getResponse()

    const _item = req.body as api.stores.carts.Post.Request

    const index = response.objects.findIndex(
      (each: api.stores.carts.VariantRaw) => each.item_id === _item.item_id
    )

    if (index > -1) {
      response.objects[index] = {
        ...response.objects[index],
        item_id: _item.item_id,
        price: _item.price,
        currency: _item.currency,
        quantity: _item.quantity,
        title: _item.title,
        properties: _item.properties,
        thumbnail: _item.thumbnail,
      }
    } else {
      response.objects.push({
        item_id: _item.item_id,
        product_id: _item.item_id.split("-")[0],
        variant_id: parseInt(_item.item_id.split("-")[1]),
        properties: _item.properties,
        price: _item.price,
        currency: _item.currency,
        quantity: _item.quantity,
        stock: Math.floor(Math.random() * Math.floor(10)),
        title: _item.title,
        thumbnail: _item.thumbnail,
        info: {
          code: "ok",
          message: "",
        },
      })
    }

    response.meta.total_count = response.objects.length

    saveResponse(response)

    return res(
      delay(ctx),
      ctx.json({
        meta: response.meta,
        objects: response.objects,
      })
    )
  }),
  rest.delete("*/v1/stores/:storeID/carts/:itemID/", function (req, res, ctx) {
    const response = getResponse()

    const { itemID } = req.params

    response.objects = response.objects.filter(
      (each: api.stores.carts.VariantRaw) => each.item_id !== itemID
    )

    response.meta.total_count = response.objects.length

    saveResponse(response)

    return res(
      delay(ctx),
      ctx.json({
        meta: response.meta,
        objects: response.objects,
      })
    )
  }),
  ...startCheckout,
]
