import {
  FreeShipping,
  Result,
} from "../../../../../api/stores/shippings/get/types"

import { Currency } from "../../../../../utils/PriceUtil"
import { Shipments } from "../../../../../icons"
import { ShippingCostModalTrigger } from "../ShippingCostModalTrigger"
import { Trans } from "@lingui/macro"
import { colors } from "../../../../../theme"
import styled from "@emotion/styled/macro"

type FreeShippingStickyProps = {
  canQuote: boolean
  productID: string
  variantID: number
  quantity: number
  shippingData: Result | undefined
  productPrice: number
  currency: Currency
  freeShipping: boolean
}

const defaultFreeShippingValues: FreeShipping = {
  active: false,
  starterAmount: 0,
}

export function FreeShippingSticky({
  productID,
  variantID,
  quantity,
  shippingData,
  canQuote,
  productPrice,
  currency,
  freeShipping,
}: FreeShippingStickyProps) {
  return (
    <MainContainer>
      <IconContainer>
        <Shipments />
        <IconTitle>
          <Trans>Envío gratis</Trans>
        </IconTitle>
      </IconContainer>
      |
      <ShippingCostModalTrigger
        linkId="free-shipping-sticky-link"
        triggerTitle={"Conocer más"}
        productID={productID}
        variantID={variantID}
        quantity={quantity}
        customMethods={shippingData?.custom || []}
        canQuote={canQuote}
        freeShippingAmount={
          shippingData?.freeShipping ?? defaultFreeShippingValues
        }
        productPrice={productPrice}
        currency={currency}
        freeShipping={freeShipping}
      />
    </MainContainer>
  )
}

const MainContainer = styled.div({
  display: "flex",
  flexFlow: "row nowrap",
  alignItems: "center",
  gap: "1rem",
  fontSize: "1.6rem",
})

const IconContainer = styled(MainContainer)({
  color: colors.greenMain,
  fontWeight: "bold",
})

const IconTitle = styled.span({
  fontWeight: "normal",
})
