import { LinkContainer, LinkIconContainer } from "./styles"
import { useCallback, useEffect, useState } from "react"

import { Branches } from "../../../../../icons"
import { LinkButton } from "../../../../../components/LinkButton"
import { PropsWithTheme } from "../../../../../providers/StoreThemeProvider"
import { Trans } from "@lingui/macro"
import { api } from "../../../../../api"
import { mediaQuery } from "../../../../../theme"
import styled from "@emotion/styled/macro"
import { useModal } from "../../../../../hooks"

type BranchesProps = {
  branches: api.stores.shippings.Get.Branches[]
}

export function StorePickUpLink({ branches }: BranchesProps) {
  const {
    actions: { openModal },
  } = useModal()
  const [modalShouldOpen, setModalShouldOpen] = useState(false)

  const handleCloseModal = useCallback(() => {
    setModalShouldOpen(false)
  }, [setModalShouldOpen])

  const handleClick = () => {
    setModalShouldOpen(true)
  }

  useEffect(() => {
    if (modalShouldOpen) {
      openModal({
        title: (
          <ModalTitle>
            <Trans>Sucursales</Trans>
          </ModalTitle>
        ),
        body: (
          <ModalBodyBranches>
            {branches?.map(branch => {
              const fullAddress = `${branch.street}, ${branch.streetNumber}, ${branch.zipCode}, ${branch.state}, ${branch.city}`

              return (
                <BodyItem key={branch.id}>
                  <BodyText>{`${branch.name} - ${fullAddress}`}</BodyText>
                  <BodyText>{branch.hours}</BodyText>
                </BodyItem>
              )
            })}
          </ModalBodyBranches>
        ),
        onClose: handleCloseModal,
      })
    }
  }, [branches, handleCloseModal, modalShouldOpen, openModal])

  return (
    <LinkContainer>
      <LinkIconContainer>
        <Branches />
      </LinkIconContainer>
      <LinkButton onClick={handleClick}>
        <Trans>Retirar gratis en punto de venta</Trans>
      </LinkButton>
    </LinkContainer>
  )
}

function ModalBodyBranches(props: { children: import("react").ReactNode }) {
  const { children } = props
  return (
    <BodyContainer>
      <BodyTitle>
        <Trans>Gratis</Trans>
      </BodyTitle>
      {children}
    </BodyContainer>
  )
}

const ModalTitle = styled.h1<PropsWithTheme>(props => ({
  fontFamily: props.theme.typographies.titles,
  fontSize: "2.4rem",
  color: props.theme.colors.titles,
  fontWeight: "normal",
  margin: 0,
}))

const BodyContainer = styled.div({
  padding: "2.8rem 2.4rem",

  [mediaQuery.desktop]: {
    padding: "2.8rem 7.6rem",
  },
})

const BodyTitle = styled.h2<PropsWithTheme>(props => ({
  fontFamily: props.theme.typographies.titles,
  fontSize: "1.6rem",
  fontcolor: props.theme.colors.titles,
  marginBottom: "0.8rem",
  marginTop: 0,
}))

const BodyItem = styled.div({
  marginBottom: "0.8rem",
})

const BodyText = styled.p<PropsWithTheme>(props => ({
  fontFamily: props.theme.typographies.texts,
  fontSize: "1.2rem",
  fontColor: props.theme.colors.texts,
  margin: 0,
}))
