import * as carts from "./carts"
import * as checkout from "./checkout"
import * as config from "./config"
import * as pageConfig from "./page-config"
import * as pickUpPoints from "./pick-up-points"
import * as quote from "./quote"
import * as shippings from "./shippings"

export function set() {
  return {
    checkout,
    carts,
    quote,
    config,
    pageConfig,
    pickUpPoints,
    shippings,
  }
}

export { checkout }
export { carts }
export { quote }
export { config }
export { pageConfig }
export { pickUpPoints }
export { shippings }
