import { Error, Info, Success, Alert as Warning } from "../../icons"

import { colors } from "../../theme"
import styled from "@emotion/styled"

export type AlertProps = {
  hideIcon?: boolean
  icon?: import("react").ReactNode
  size?: "small" | "medium" | "large"
  text: import("react").ReactNode
  title?: import("react").ReactNode
  variant?: "warning" | "success" | "error" | "info"
}

export function AlertBox({
  hideIcon = false,
  icon = null,
  size = "medium",
  text,
  title = null,
  variant = "info",
}: AlertProps) {
  let thumbnail
  switch (variant) {
    case "warning":
      thumbnail = <Warning />
      break
    case "success":
      thumbnail = <Success />
      break
    case "error":
      thumbnail = <Error />
      break
    case "info":
      thumbnail = <Info />
  }

  return (
    <Container variant={variant} size={size}>
      {!hideIcon && size !== "small" && (icon != null ? icon : thumbnail)}
      <TextContainer>
        {title && <Title data-testid="alertBox-title">{title}</Title>}
        <Text data-testid="alertBox-text">{text}</Text>
      </TextContainer>
    </Container>
  )
}

type ContainerProps = {
  variant: AlertProps["variant"]
  size: AlertProps["size"]
}

const Container = styled.div<ContainerProps>(props => {
  let backgroundColor
  let borderColor
  let borderLeftWidth
  let gridGap
  let padding

  switch (props.variant) {
    case "warning":
      backgroundColor = colors.yellowLighter
      borderColor = colors.yellowMain
      break
    case "success":
      backgroundColor = colors.greenLighter
      borderColor = colors.greenMain
      break
    case "error":
      backgroundColor = colors.redLighter
      borderColor = colors.redMain
      break
    case "info":
      backgroundColor = colors.blueLighter
      borderColor = colors.blueMain
  }

  switch (props.size) {
    case "small":
      borderLeftWidth = 1
      padding = "0.4rem 0.8rem"
      break
    case "medium":
      borderLeftWidth = 1
      gridGap = "0.8rem"
      padding = "0.8rem"
      break
    case "large":
      borderLeftWidth = 4
      gridGap = "1.6rem"
      padding = "1.2rem"
  }

  return {
    alignItems: "center",
    backgroundColor,
    borderColor,
    borderWidth: 1,
    borderLeftWidth,
    borderStyle: "solid",
    display: "grid",
    gridGap,
    gridTemplateColumns: "auto 1fr",
    padding,
    width: "100%",

    "& svg": {
      alignSelf: "center",
      color: borderColor,
      fontSize: props.size === "medium" ? "2rem" : "2.4rem",
    },
  }
})

const TextContainer = styled.div({})

const Text = styled.p({
  color: colors.greyDark,
  fontSize: "1.4rem",
  lineHeight: "1.6rem",
  margin: 0,
  padding: 0,
})

const Title = styled(Text)({
  fontWeight: "bold",
  padding: "0 0 0.4rem",
})
