import { Fragment, useState } from "react"

import { Breadcrumb } from "../../../../components/Breadcrumb"
import { FilterSelector } from "./FilterSelector"
import { FiltersAndSorting } from "./FiltersAndSorting"
import { NoProductsCard } from "../NoProductsCard"
import { Paginator } from "../Paginator"
import { ProductBox } from "../ProductBox"
import { PropsWithTheme } from "../SortingSelector/SortingList/SortingList"
import { Spinner } from "../../../../icons"
import { Trans } from "@lingui/macro"
import { WhatsAppButton } from "../../../../components/WhatsAppButton"
import { colors } from "../../../../theme"
import readableColor from "polished/lib/color/readableColor"
import styled from "@emotion/styled"
import { toLocalePrice } from "../../../../utils/PriceUtil"
import { useMeta } from "../../hooks/useMeta"
import { useProducts } from "../../hooks/useProducts"
import { useProductsStore } from "../../provider/ProductStoreProvider"
import { useScrollToTop } from "../../hooks/useScrollToTop"
import { useStoreState } from "../../../../providers/StoreProvider"

type Props = {
  categoryPath: string
}

export function Mobile({ categoryPath }: Props) {
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false)
  const whatsAppButtonInfo = useStoreState()[0].config.whatsapp
  const default_limit_mobile = 12
  const { status } = useProducts({
    categoryPath: categoryPath,
    limit: default_limit_mobile,
  })
  const { meta, products } = useProductsStore()
  useScrollToTop(status)

  const { breadcrumbLinks, title } = useMeta()

  if ((status === "success" && meta.totalCount === 0) || status === "error") {
    return (
      <Grid>
        <NoProductsCard status={status} />
      </Grid>
    )
  }

  return (
    <Grid>
      {status === "fetching" ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <Fragment>
          <FiltersAndSorting openModal={setIsFiltersModalOpen} />
          <Container>
            {breadcrumbLinks.length > 0 ? (
              <>
                <BreadcrumbContainer>
                  <Breadcrumb links={breadcrumbLinks} />
                </BreadcrumbContainer>
                <Title>{title}</Title>
              </>
            ) : (
              <>
                <Title>{title}</Title>

                <TotalCount>
                  {meta.totalCount}{" "}
                  {meta.totalCount === 1 ? (
                    <Trans>resultado</Trans>
                  ) : (
                    <Trans>resultados</Trans>
                  )}
                </TotalCount>
              </>
            )}
            <ProductsList>
              {products.map(product => (
                <ProductBox
                  key={product.identifier}
                  productId={product.identifier}
                  thumbnail={product.coverImage}
                  title={product.title}
                  price={toLocalePrice(product.minPrice, product.currency)}
                  showLabelFrom={product.minPrice !== product.maxPrice}
                  stock={product.maxStock}
                  freeShipping={product.freeShipping}
                />
              ))}
            </ProductsList>
            <PaginatorContainer>
              <Paginator />
            </PaginatorContainer>
            {whatsAppButtonInfo?.showWhatsappButton && (
              <WhatsAppButton
                countryCode={whatsAppButtonInfo?.countryCode || ""}
                phoneNumber={whatsAppButtonInfo?.phoneNumber || ""}
                description={whatsAppButtonInfo.description || ""}
              />
            )}
          </Container>
        </Fragment>
      )}
      {isFiltersModalOpen && (
        <FilterSelector closeModal={() => setIsFiltersModalOpen(false)} />
      )}
    </Grid>
  )
}

const Grid = styled.div({
  minHeight: "100vh",
})

const SpinnerContainer = styled.div({
  color: colors.greyMain,
  right: "50%",
  bottom: "50%",
  transform: "translate(50%, 50%)",
  position: "absolute",
  fontSize: "6.4rem",
})

const Container = styled.div({
  padding: "0 1.6rem",
})

const BreadcrumbContainer = styled.div({
  padding: "0.8rem 0 0 0",
})

const TotalCount = styled.div<PropsWithTheme>(props => ({
  padding: "0 0 0.8rem 0",
  fontSize: "1.4rem",
  color: readableColor(props.theme.colors.background),
}))

const ProductsList = styled.section({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "1.2rem 0.8rem",
  justifyContent: "center",
  paddingTop: "0.8rem",
})

const PaginatorContainer = styled.div({
  padding: "2.6rem",
  display: "flex",
  justifyContent: "center",
})

const Title = styled.h1<PropsWithTheme>(props => ({
  fontSize: "1.6rem",
  fontWeight: 600,
  color: readableColor(props.theme.colors.background),
  margin: "0.8rem 0",
}))
