import { useEffect, useRef } from "react"

export function useSwipeScrollTo(selectedItem: unknown, items: unknown[]) {
  const swipeContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!swipeContainerRef.current) return

    if (selectedItem != null && typeof items === typeof Array(selectedItem)) {
      const selectedItemIndex = items.indexOf(selectedItem)

      if (selectedItemIndex !== -1) {
        const scrollWidth = swipeContainerRef.current.scrollWidth

        const elementWidth = scrollWidth / items.length

        const swipeOffset = selectedItemIndex * elementWidth

        swipeContainerRef.current.scrollTo(swipeOffset, 0)
      }
    }
  }, [selectedItem, items, swipeContainerRef])

  return {
    swipeContainerRef,
  }
}
