import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { Trans } from "@lingui/macro"
import { api } from "../../../../api"
import { colors } from "../../../../theme"
import styled from "@emotion/styled/macro"

type MercadoPagoPaymentMethodsProps = {
  mercadoPagoMethods: api.paymentMethods.Get.MPPaymentMethod[]
}

const mercadoPagoPaymentMethodTypes: {
  [key: string]: {
    title: import("react").ReactNode
    description: import("react").ReactNode
  }
} = {
  credit_card: {
    title: <Trans>Tarjetas de crédito</Trans>,
    description: (
      <Trans>Tenemos cuotas sin interés con bancos seleccionados</Trans>
    ),
  },
  debit_card: {
    title: <Trans>Tarjetas de débito</Trans>,
    description: <Trans>Acreditación instantánea.</Trans>,
  },
}

export function MercadoPagoPaymentMethods({
  mercadoPagoMethods,
}: MercadoPagoPaymentMethodsProps) {
  return (
    <>
      {Object.keys(mercadoPagoPaymentMethodTypes).map(type => (
        <PaymentMethodContainer>
          <Title>{mercadoPagoPaymentMethodTypes[type].title}</Title>
          <Description>
            {mercadoPagoPaymentMethodTypes[type].description}
          </Description>

          {mercadoPagoMethods.map(paymentMethod => {
            const { name, paymentTypeId, secureThumbnail } = paymentMethod

            const isCurrentPaymentType = paymentTypeId === type
            const isOtherPaymentType =
              paymentTypeId !== "credit_card" && paymentTypeId !== "debit_card"

            if (!isCurrentPaymentType && !isOtherPaymentType) return null

            return (
              <Image
                key={name + paymentTypeId}
                src={secureThumbnail}
                alt={name}
              />
            )
          })}
        </PaymentMethodContainer>
      ))}
    </>
  )
}

const Title = styled.h2<PropsWithTheme>(props => ({
  fontSize: "1.6rem",
  fontWeight: 600,
  fontStyle: "normal",
  color: props.theme.colors.texts,
  fontFamily: props.theme.typographies.texts,
  margin: 0,
}))

const Description = styled.p<PropsWithTheme>(props => ({
  fontSize: "1.4rem",
  fontWeight: "normal",
  marginBottom: "1.6rem",
  marginTop: "1.2rem",
  color: props.theme.colors.texts,
  fontFamily: props.theme.typographies.texts,
}))

const Image = styled.img({
  width: "5.2rem",
  height: "auto",
  objectFit: "cover",
  paddingRight: "1.2rem",
})

const PaymentMethodContainer = styled.div({
  borderBottom: `1px solid ${colors.greyMainLight}`,
  paddingBottom: "2rem",

  "&:last-child": {
    borderBottom: "none",
  },
})
