import { Fragment, useEffect, useState } from "react"
import { colors, mediaQuery, opacity } from "../../../../theme"
import {
  useCarrousel,
  useScreenSize,
  useSwipeScrollTo,
} from "../../../../hooks"

import { Chevron } from "../../../../icons"
import { ImageVideoGalleryModal } from "../ImageVideoGalleryModal"
import { InformationChip } from "./InformationChip"
import { api } from "../../../../api"
import styled from "@emotion/styled/macro"

type Props = {
  images: api.products.variations.Get.Image[]
  selectedImage?: api.products.variations.Get.Image
  stickyLabel?: string
}

const GALLERY_MAX_ITEMS = 5
const GALLERY_WIDTH = "76px"
const GALLERY_ITEMS_HEIGHT = "76px"
const PLACEHOLDER_EMPTY_ITEMS = [
  {
    id: "1",
    warholeID: 1,
    thumbnails: {
      thumbnail: `https://via.placeholder.com/${GALLERY_WIDTH}`,
      small: `https://via.placeholder.com/${GALLERY_WIDTH}`,
      medium: `https://via.placeholder.com/${GALLERY_WIDTH}`,
      large: `https://via.placeholder.com/${GALLERY_WIDTH}`,
      full: `https://via.placeholder.com/${GALLERY_WIDTH}`,
    },
  },
]

export function ImageVideoGallery({
  images: initialImages,
  selectedImage: initialSelectedImage,
  stickyLabel,
}: Props) {
  ;[initialImages, initialSelectedImage] =
    !initialSelectedImage || initialImages.length < 1
      ? [PLACEHOLDER_EMPTY_ITEMS, PLACEHOLDER_EMPTY_ITEMS[0]]
      : [initialImages, initialSelectedImage]

  const [
    swipeSelectedImage,
    setSwipeSelectedImage,
  ] = useState<api.products.variations.Get.Image>(initialSelectedImage)

  useEffect(() => {
    if (initialSelectedImage) setSwipeSelectedImage(initialSelectedImage)
  }, [initialSelectedImage])

  const { swipeContainerRef } = useSwipeScrollTo(
    swipeSelectedImage,
    initialImages
  )

  const screenSize = useScreenSize()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {
    items: images,
    selectedItem: selectedImage,
    handleNext,
    handlePrev,
    setSelectedItem: setSelectedImage,
  } = useCarrousel(initialImages, initialSelectedImage)

  const totalImages = images.length
  const isSingleImage = images.length === 1

  const thumbnails =
    totalImages > GALLERY_MAX_ITEMS
      ? images.slice(0, GALLERY_MAX_ITEMS + 1)
      : totalImages === 0
      ? PLACEHOLDER_EMPTY_ITEMS
      : images

  return (
    <Fragment>
      <Container data-testid="image-video-gallery">
        {screenSize.isDesktop && (
          <Gallery>
            {thumbnails.map(image => (
              <Thumbnail key={image.id}>
                <Image
                  src={image.thumbnails.small}
                  onMouseOver={() => setSelectedImage(image)}
                ></Image>
              </Thumbnail>
            ))}
            {totalImages > GALLERY_MAX_ITEMS && (
              <More
                onClick={() => {
                  setSelectedImage(images[GALLERY_MAX_ITEMS])
                  setIsModalOpen(true)
                }}
              >
                +{totalImages + 1 - thumbnails.length}
              </More>
            )}
          </Gallery>
        )}
        {screenSize.isDesktop ? (
          <SelectedImage onClick={() => setIsModalOpen(true)}>
            <DesktopLayer>
              <Image
                src={selectedImage.thumbnails.large}
                onClick={() => setIsModalOpen(true)}
              ></Image>
            </DesktopLayer>
            {!isSingleImage && (
              <DesktopLayer>
                <Button onClick={() => handlePrev(setSelectedImage)}>
                  <IconLeft data-testid="icon-left">
                    <Chevron />
                  </IconLeft>
                </Button>
                <Button onClick={() => handleNext(setSelectedImage)}>
                  <IconRight data-testid="icon-right">
                    <Chevron />
                  </IconRight>
                </Button>
              </DesktopLayer>
            )}
          </SelectedImage>
        ) : (
          <MobileImagesContainer
            screenWidth={screenSize.innerWidth}
            columnsCount={initialImages.length}
            id="swipe-container"
            ref={swipeContainerRef}
          >
            {initialImages.map(initialImage => (
              <MobileLayer key={initialImage.id}>
                <Image
                  src={initialImage.thumbnails.medium}
                  onClick={() => {
                    setIsModalOpen(true)
                    setSwipeSelectedImage(initialImage)
                  }}
                />
              </MobileLayer>
            ))}
          </MobileImagesContainer>
        )}
        {!screenSize.isDesktop && !isSingleImage && stickyLabel && (
          <ChipContainer>
            <InformationChip label={stickyLabel} />
          </ChipContainer>
        )}
      </Container>
      {isModalOpen && (
        <ImageVideoGalleryModal
          images={images}
          selectedImage={
            screenSize.isDesktop ? selectedImage : swipeSelectedImage
          }
          closeAction={imageIndex => {
            setIsModalOpen(false)
            const image = images[imageIndex]
            if (screenSize.isDesktop) {
              setSelectedImage(image)
            } else {
              setSwipeSelectedImage(image)
            }
          }}
        />
      )}
    </Fragment>
  )
}

const Container = styled.div({
  position: "relative",
  display: "grid",
  gridTemplateRows: `calc(${
    GALLERY_MAX_ITEMS + 1
  } * ${GALLERY_ITEMS_HEIGHT}/2)`,

  [mediaQuery.desktop]: {
    gridTemplateColumns: `${GALLERY_WIDTH} 1fr`,
    gridTemplateRows: `calc(${
      GALLERY_MAX_ITEMS + 1
    } * ${GALLERY_ITEMS_HEIGHT})`,
  },
})

const Gallery = styled.div({
  display: "none",

  [mediaQuery.desktop]: {
    display: "grid",
    gridGap: "0.4rem",
    marginRight: "0.4rem",
    overflow: "hidden",
    gridTemplateColumns: GALLERY_WIDTH,
    gridTemplateRows: `repeat(${
      GALLERY_MAX_ITEMS + 1
    }, ${GALLERY_ITEMS_HEIGHT})`,
  },
})

const Thumbnail = styled.div({
  cursor: "pointer",
  display: "flex",

  [`&:nth-of-type(${GALLERY_MAX_ITEMS + 1}n)`]: {
    gridArea: "-2 / 1 / -1 / 2",
    opacity: opacity[2],
  },
})

const Image = styled.img({
  width: "100%",
  height: "100%",
  objectFit: "contain",
})

const More = styled.button({
  cursor: "pointer",
  border: "none",
  background: "transparent",
  zIndex: 100,
  fontSize: "3.2rem",
  display: "grid",
  gridArea: "-2 / 1 / -1 / 2",
})

const SelectedImage = styled.div({
  [mediaQuery.desktop]: {
    gridGap: "3.2rem",
    gridTemplateRows: `calc(${
      GALLERY_MAX_ITEMS + 1
    } * ${GALLERY_ITEMS_HEIGHT})`,
  },

  display: "grid",
  cursor: "pointer",
  gridTemplateRows: `calc(${
    GALLERY_MAX_ITEMS + 1
  } * ${GALLERY_ITEMS_HEIGHT}/2)`,
})

const DesktopLayer = styled.div({
  display: "flex",
  justifyContent: "space-between",
  gridArea: "1 / 2 / -1 / -1",
})

type MobileImagesContainerProps = {
  screenWidth: number
  columnsCount: number
}

const MobileImagesContainer = styled.div<MobileImagesContainerProps>(props => ({
  display: "grid",
  gridAutoColumns: `${props.screenWidth}px`,
  gridAutoFlow: "column",
  overflowX: "auto",
  overflowY: "hidden",
  scrollSnapType: "x mandatory",

  // Hide scrollbars without losing scroll ability
  msOverflowStyle: "none", // IE
  "::-webkit-scrollbar": {
    // Chrome
    display: "none",
  },
}))

const MobileLayer = styled.div({
  height: `calc(${GALLERY_MAX_ITEMS + 1} * ${GALLERY_ITEMS_HEIGHT}/2)`,
  scrollSnapAlign: "center",
})

const Button = styled.button({
  display: "flex",
  alignItems: "center",
  alignSelf: "center",
  background: "transparent",
  color: colors.greyMain,
  fontSize: "4rem",
  cursor: "pointer",
  border: "none",
  fontWeight: "bold",
})

const IconLeft = styled.span({
  transform: "rotate(90deg)",
  color: colors.white,
  fontSize: "1.8rem",
  filter: "drop-shadow(0 2px 2px hsla(0, 0%, 0%, 0.2))",
})

const IconRight = styled(IconLeft)({
  transform: "rotate(-90deg)",
})

const ChipContainer = styled.div({
  position: "absolute",
  bottom: "0.8rem",
  left: "1.4rem",
})
