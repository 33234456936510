import { i18n } from "@lingui/core"

type PriceViewProps = {
  price: string
}

// We only add locales that have a different decimal separator than "."
const DECIMAL_SEPARATOR: Record<string, string> = {
  "es-MX": ".",
}

export function PriceView({ price }: PriceViewProps) {
  const decimalSeparator = DECIMAL_SEPARATOR[i18n.locale] || ","

  if (!price.includes(decimalSeparator)) {
    return <span>{price}</span>
  }

  const [entire, cents] = price.split(decimalSeparator)

  return (
    <span>
      {entire}
      <sup>{cents}</sup>
    </span>
  )
}
