import Tippy, { TippyProps } from "@tippyjs/react/headless"
import { motion, useSpring } from "framer-motion"

import { colors } from "../../theme"
import styled from "@emotion/styled"

export type TooltipProps = TippyProps & { hasPaddingInContent?: boolean }

export function Tooltip({
  children,
  content,
  maxWidth,
  placement = "bottom",
  hasPaddingInContent = true,
  ...props
}: TooltipProps) {
  const opacity = useSpring(0, { stiffness: 200 })

  function onMount() {
    opacity.set(1)
  }

  function onHide() {
    opacity.set(0)
  }

  return (
    <Tippy
      render={attrs => (
        <Container
          style={{
            opacity,
            maxWidth,
            ...(!hasPaddingInContent && { padding: 0 }),
          }}
          {...attrs}
        >
          {content}
        </Container>
      )}
      offset={[0, 8]}
      animation={true}
      onMount={onMount}
      onHide={onHide}
      hideOnClick={false}
      placement={placement}
      {...props}
    >
      {children}
    </Tippy>
  )
}

const Container = styled(motion.div)(() => ({
  backgroundColor: colors.white,
  borderColor: colors.greyMainLight,
  borderRadius: 2,
  borderStyle: "solid",
  borderWidth: 1,
  boxShadow: "0 2px 5px 0 hsla(0, 0%, 0%, 0.2)",
  color: colors.greyDark,
  fontFamily: "Open Sans, sans-serif",
  fontSize: "1.4rem",
  padding: "0.8rem 1.2rem",
}))
