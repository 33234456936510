import { useCallback } from "react"
import { useProductStore } from "../provider/ProductStoreProvider"

export function useQuantitySelector() {
  const { dispatch } = useProductStore()

  const handleChangeQuantity = useCallback(
    (e: import("react").ChangeEvent<HTMLInputElement>) => {
      const cleanValue = e.target.value?.replace(/[^0-9]/gi, "")

      const value = parseInt(cleanValue)
      dispatch({ type: "changeQuantity", payload: value })
    },
    [dispatch]
  )

  const handleOnBlur = useCallback(
    (e: import("react").ChangeEvent<HTMLInputElement>) => {
      const invalidNumber =
        e.target.value === "" || e.target.value.match(/^[0][0-9]{0,}/gi)

      if (invalidNumber) {
        dispatch({ type: "changeQuantity", payload: 1 })
      }
    },
    [dispatch]
  )

  return { handleChangeQuantity, handleOnBlur }
}
