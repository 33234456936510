import { colors } from "../../../../../theme"
import styled from "@emotion/styled/macro"

type Props = {
  label: string
}

export function InformationChip({ label }: Props) {
  return (
    <Container>
      <Label>{label}</Label>
    </Container>
  )
}

const Container = styled.div({
  borderRadius: 4,
  padding: "0.2rem 0.8rem",
  width: "fit-content",
  backgroundColor: colors.greyMedium,
  opacity: "0.5",
})

const Label = styled.p({
  fontFamily: "Open Sans, sans-serif",
  fontSize: "1.4rem",
  margin: 0,
  color: colors.white,
})
