import { ContinueButton, Form, FormBody } from "../../style"
import { FormProvider, useForm } from "react-hook-form"

import { AlertBox } from "../../../../../../components/AlertBox"
import { AppliedDiscountType } from "../../BodyCheckout"
import { CardAdditionalInfo } from "./components/CardAdditionalInfo"
import { CardBilling } from "./components/CardBilling"
import { CardPaymentOptions } from "./components/CardPaymentOptions"
import { CardShippingOptions } from "./components/CardShippingOptions"
import { FormData } from "../../../../types"
import { StepShippingForm } from "./types"
import { Trans } from "@lingui/macro"
import { useCheckout } from "../../../../../../hooks"
import { useCheckoutScreen } from "../../../../hooks/useCheckoutScreen"

type StepShippingType = {
  isCurrent: boolean
  changeAppliedDiscount: (appliedDiscount: AppliedDiscountType) => void
}

export function StepShipping({
  isCurrent,
  changeAppliedDiscount,
}: StepShippingType) {
  const { submitForm } = useCheckoutScreen()
  const { data } = useCheckout()
  const { payment, shipping } = data.meta
  const methods = useForm<StepShippingForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  })

  const getFormData = (data: StepShippingForm) => {
    if (data.isBillingSameAsReceiver === "1") {
      return {
        ...data,
        billingFullName: `${shipping?.receiver?.firstName || ""} ${
          shipping?.receiver?.lastName || ""
        }`,
        billingIdentityNumber: shipping?.receiver?.identityNumber || "",
        billingIdentityType: shipping?.receiver?.identityType || "",
        billingAddressStreet: shipping?.destination?.addressStreet || "",
        billingAddressNumber: shipping?.destination?.addressNumber || "",
        billingAddressAdditional: shipping?.destination?.addressOther || "",
        billingCity: shipping?.destination?.city || "",
        billingPostalCode: shipping?.destination?.postalCode || "",
        billingState: shipping?.destination?.state || "",
        shippingPrice: parseFloat(data.shippingPrice || "0"),
      } as FormData
    } else {
      return {
        ...data,
        shippingPrice: parseFloat(data.shippingPrice || "0"),
      } as FormData
    }
  }

  const onSubmit = (data: StepShippingForm) => {
    const formData = getFormData(data)

    submitForm(formData, result => {
      if (
        result.meta.payment?.type === "M" &&
        result.meta.payment?.status !== "failure" &&
        result.meta.payment?.initPoint
      ) {
        window.location.href = result.meta.payment?.initPoint
      }
    })
  }

  return (
    <FormProvider {...methods}>
      <Form
        data-testid="step-shipping"
        onSubmit={methods.handleSubmit(onSubmit)}
        autoComplete="on"
        active={isCurrent}
      >
        <FormBody>
          {payment?.status === "failure" && (
            <div data-testid="alertBox-payment-failure">
              <AlertBox
                size="large"
                title={<Trans>No pudimos procesar tu pago</Trans>}
                text={
                  <Trans>Volvé a intentarlo o elegí otro medio de pago.</Trans>
                }
                variant="error"
              />
            </div>
          )}
          <CardShippingOptions />
          <CardPaymentOptions changeAppliedDiscount={changeAppliedDiscount} />
          <CardBilling />
          <CardAdditionalInfo />
        </FormBody>
        <ContinueButton type="submit">
          <Trans>Pagar</Trans>
        </ContinueButton>
      </Form>
    </FormProvider>
  )
}
