import { colors, mediaQuery } from "../../../../../theme"

import { Currency } from "../../../../../utils/PriceUtil"
import { Discount } from "../../../../../icons"
import { Trans } from "@lingui/macro"
import styled from "@emotion/styled/macro"
import { toLocalePrice } from "../../../../../utils"

type OffersDiscountProps = {
  paymentMethod: {
    id: string
    name: string
    description: string
    offersDiscount: boolean
    discountType: "A" | "P" | null
    discountValue: number | null
    currency: Currency
  }
}

export function OffersDiscount({ paymentMethod }: OffersDiscountProps) {
  return (
    <DiscountContainer>
      <IconContainer>
        <Discount />
      </IconContainer>
      <DiscountInfo>
        <DiscountText>
          {paymentMethod.discountType === "P"
            ? ` ${paymentMethod.discountValue}% `
            : paymentMethod.discountValue != null &&
              ` ${toLocalePrice(
                paymentMethod.discountValue * 100,
                paymentMethod.currency
              )} `}
          <Trans>de descuento</Trans>
        </DiscountText>
        <PaymentText>
          <Trans>pagando con</Trans>
          {` ${paymentMethod.name}`}
        </PaymentText>
      </DiscountInfo>
    </DiscountContainer>
  )
}

const DiscountContainer = styled.div({
  alignItems: "baseline",
  gap: "1rem",
  display: "grid",
  padding: "1.6rem 1.2rem 0.8rem",
  position: "relative",
  gridTemplateColumns: "18px 1fr",

  "& svg": {
    fontSize: "1.8rem",
    minWidth: "1.8rem",
    position: "absolute",
    top: 18,
  },

  [mediaQuery.desktop]: {
    padding: "0.4rem 2rem 1.2rem",

    "& svg": {
      top: 6,
    },
  },
})

const DiscountText = styled.span({
  color: colors.greenMain,
  marginRight: 4,
})

const PaymentText = styled.span({
  color: colors.greyDark,
})

const IconContainer = styled.span({
  width: "1.8rem",
  marginTop: 4,
})

const DiscountInfo = styled.div({ fontSize: "1.6rem" })
