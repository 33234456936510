import { colors, mediaQuery } from "../../../../../../../../theme"

import { Currency } from "../../../../../../../../utils/PriceUtil"
import { PropsWithTheme } from "../../../../../../../../providers/StoreThemeProvider"
import styled from "@emotion/styled"
import { t } from "@lingui/macro"
import { toLocalePrice } from "../../../../../../../../utils"

type Content = JSX.Element | string

type InfoColumnProps = {
  description: Content
} & (
  | { type: "address" }
  | {
      type: "shipping"
      subtitle: Content
    }
  | {
      type: "payment"
      discountValue: number | null | undefined
      discountType: "P" | "A" | null | undefined
      offersDiscount: boolean | null | undefined
      currency: Currency
    }
  | { type: "contact" }
)

export const InformationColumn = (props: InfoColumnProps) => {
  return (
    <InformationColumnStyled data-testid={`informationColumn-${props.type}`}>
      <SubTitle data-testid={`informationColumn-${props.type}-subtitle`}>
        {props.type === "address" && t`Dirección`}
        {props.type === "shipping" && props.subtitle}
        {props.type === "payment" && t`Medio de pago`}
        {props.type === "contact" && t`Datos de contacto`}
      </SubTitle>
      <Description
        data-testid={`informationColumn-${props.type}-description`}
        isContactDescription={props.type === "contact"}
      >
        {props.description}
      </Description>
      {props.type === "payment" && props.offersDiscount && (
        <DiscountTag>
          {props.discountType === "P"
            ? ` ${props.discountValue}% OFF `
            : ` ${toLocalePrice(
                (props.discountValue as number) * 100,
                props.currency
              )} OFF`}
        </DiscountTag>
      )}
    </InformationColumnStyled>
  )
}

const InformationColumnStyled = styled.div<PropsWithTheme>(props => ({
  display: "grid",
  gridGap: "0.8rem",
  gridTemplateRows: "1.6rem",
  paddingBottom: "1.6rem",

  backgroundImage: `linear-gradient(to right, ${props.theme.colors.primary} 33%, rgba(255,255,255,0) 0%)`,
  backgroundPosition: "bottom",
  backgroundSize: "6px 1px",
  backgroundRepeat: "repeat-x",

  "&:last-of-type": {
    border: "none",
    paddingBottom: "0.8rem",
    backgroundImage: "none",
  },

  [mediaQuery.desktop]: {
    paddingBottom: 0,
    paddingRight: "1.6rem",
    borderBottom: "none",

    backgroundImage: `linear-gradient(${props.theme.colors.primary} 33%, rgba(255,255,255,0) 0%)`,
    backgroundPosition: "right",
    backgroundSize: "1px 6px",
    backgroundRepeat: " repeat-y",
    height: "fit-content",
  },
}))

const SubTitle = styled.h2({
  fontSize: "1.4rem",
  color: colors.greyDark,
  margin: 0,
})

type DescriptionProps = {
  isContactDescription: boolean
}

const Description = styled.p(({ isContactDescription }: DescriptionProps) => ({
  whiteSpace: "pre-line",
  fontSize: "1.4rem",
  color: colors.greyDark,
  margin: 0,
  maxWidth: isContactDescription ? "37rem" : "19rem",
  wordBreak: "break-word",
}))

const DiscountTag = styled.span({
  color: colors.white,
  backgroundColor: colors.greenDark,
  padding: "0.4rem 0.8rem",
  borderRadius: 2,
  fontWeight: 600,
  width: "fit-content",
  height: "2.5rem",
  fontSize: "1.4rem",
  lineHeight: "1.6rem",
})
