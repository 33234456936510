import * as z from "zod"

export const MetadataRawSchema = z.union([
  z.object({
    has_checkout: z.literal(false),
    cart_id: z.number(),
    total_count: z.number(),
    subtotal: z.number(),
    currency: z.enum(["ARS", "BRL", "MXN"]),
  }),
  z.object({ has_checkout: z.literal(true), time_left: z.number() }),
])

export type MetadataRaw = z.infer<typeof MetadataRawSchema>

export const PropertyRawSchema = z.object({
  name: z.string(),
  value: z.string(),
})

export type PropertyRaw = z.infer<typeof PropertyRawSchema>

export const InfoCodeSchema = z.union([
  z.literal("no_product"),
  z.literal("no_variant"),
  z.literal("no_stock"),
  z.literal("ok"),
])

export type InfoCode = z.infer<typeof InfoCodeSchema>

export const InfoRawSchema = z.object({
  code: InfoCodeSchema,
  message: z.string(),
})

export type InfoRaw = z.infer<typeof InfoRawSchema>

export const VariantRawSchema = z.object({
  item_id: z.string(),
  product_id: z.string(),
  variant_id: z.number(),
  properties: z.array(PropertyRawSchema),
  price: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  quantity: z.number(),
  stock: z.number(),
  title: z.string(),
  thumbnail: z.string(),
  info: InfoRawSchema,
})

export type VariantRaw = z.infer<typeof VariantRawSchema>

export const ResponseSchema = z.object({
  meta: MetadataRawSchema,
  objects: z.array(VariantRawSchema),
})

export type Response = z.infer<typeof ResponseSchema>

export const MetadataSchema = z.union([
  z.object({
    cartId: z.number(),
    totalCount: z.number(),
    subtotal: z.number(),
    hasCheckout: z.literal(false),
    currency: z.enum(["ARS", "BRL", "MXN"]),
  }),
  z.object({
    hasCheckout: z.literal(true),
    timeLeft: z.number(),
  }),
])

export type Metadata = z.infer<typeof MetadataSchema>

export const PropertySchema = z.object({
  name: z.string(),
  value: z.string(),
})

export type Property = z.infer<typeof PropertySchema>

export const InfoSchema = z.object({
  code: InfoCodeSchema,
  message: z.string(),
})

export type Info = z.infer<typeof InfoSchema>

export const VariantSchema = z.object({
  itemId: z.string(),
  productId: z.string(),
  variantId: z.number(),
  properties: z.array(PropertySchema),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  price: z.number(),
  quantity: z.number(),
  stock: z.number(),
  title: z.string(),
  thumbnail: z.string(),
  info: InfoSchema,
})

export type Variant = z.infer<typeof VariantSchema>

export const NewVariantRequestRawSchema = z.object({
  itemId: z.string(),
  price: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  quantity: z.number(),
  title: z.string(),
  properties: z.array(PropertySchema),
  thumbnail: z.string(),
})

export type NewVariantRequestRaw = z.infer<typeof NewVariantRequestRawSchema>

export const ResultSchema = z.object({
  meta: MetadataSchema,
  objects: z.array(VariantSchema),
})

export type Result = z.infer<typeof ResultSchema>
