import { Footer } from "./Footer"
import { Header } from "./Header"
import { ReactNode } from "react"
import { RouteComponentProps } from "react-router-dom"

export const WithHeaderAndFooter = ({
  location,
  history,
  match,
  children,
}: RouteComponentProps & { children: ReactNode }) => {
  return (
    <>
      <Header match={match} location={location} history={history} />
      {children}
      <Footer match={match} location={location} history={history} />
    </>
  )
}
