import { delay } from "../../utils"
import { parse } from "query-string"
import { rest } from "msw"
import { variations } from "./variations"

export const products = [
  rest.get("*/v1/products/:storeID/", function (req, res, ctx) {
    const query = parse(req.url.search) as {
      offset: string
    }

    const fixture = require("./get.fixture.json")
    fixture.meta.offset = parseInt(query.offset) || 0

    return res(delay(ctx), ctx.json(fixture))
  }),
  ...variations,
]
