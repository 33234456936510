import { Dispatch, FC, SetStateAction, createContext, useState } from "react"

import { Error } from "../../../../api/stores/checkout"
import { useCheckout } from "../../../../hooks"
import { useEffect } from "react"

export type Step = 1 | 2 | 3

export interface ContextValue {
  currentStep: [Step, Dispatch<SetStateAction<Step>>]
  error: [Error | null, Dispatch<SetStateAction<Error | null>>]
}

export const CheckoutScreenContext = createContext<ContextValue | null>(null)

export const CheckoutScreenProvider: FC = ({ children }) => {
  const { data } = useCheckout()
  const [currentStep, setCurrentStep] = useState<Step>(1)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const { checkoutStep } = data.meta

    switch (checkoutStep) {
      case "initial":
        setCurrentStep(1)
        break
      case "contact":
        setCurrentStep(2)
        break
      case "billing":
      case "data_complete":
      case "order_complete":
        setCurrentStep(3)
        break
    }
  }, [data])

  return (
    <CheckoutScreenContext.Provider
      value={{
        currentStep: [currentStep, setCurrentStep],
        error: [error, setError],
      }}
    >
      {children}
    </CheckoutScreenContext.Provider>
  )
}
