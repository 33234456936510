import { Controller, useFormContext } from "react-hook-form"
import { Trans, t } from "@lingui/macro"
import { colors, mediaQuery } from "../../../../../../../../theme"

import { ContainerCard } from "../../../../../../../../components/ContainerCard"
import { RadioFieldCard } from "../../../../../../../../components/RadioFieldCard"
import { ShippingData } from "../../types"
import { ShippingMethodP } from "./components/ShippingMethodP"
import { ShippingMethodS } from "./components/ShippingMethodS"
import { ShippingMethodZ } from "./components/ShippingMethodZ"
import { SingleColumnRow } from "../../../../style"
import styled from "@emotion/styled"
import { useCheckout } from "../../../../../../../../hooks"
import { useCheckoutScreen } from "../../../../../../hooks/useCheckoutScreen"
import { useEffect } from "react"

type Keys = keyof ShippingData

export const CardShippingMethod = () => {
  const { requirements, shipping, freeShipping } = useCheckout().data.meta
  const { error, setError } = useCheckoutScreen()
  const { control, errors, register, setValue } = useFormContext<ShippingData>()

  useEffect(() => {
    //set from api
    const isShippingEmpty = shipping == null || shipping.id === ""
    if (!isShippingEmpty && shipping != null) {
      for (const shippingMethod of requirements.shippingMethods) {
        if (
          shippingMethod.shippingType === shipping.shippingType ||
          (shipping.shippingType === "C" && shippingMethod.shippingType === "Z")
        ) {
          register("shippingMethodType")
          setValue("shippingMethodType", shippingMethod.shippingType)

          setValue("shippingMethodId", shippingMethod.id)
          setValue("receiverFirstName", shipping.receiver?.firstName)
          setValue("receiverLastName", shipping.receiver?.lastName)
          setValue("receiverIdentityType", shipping.receiver?.identityType)
          setValue("receiverIdentityNumber", shipping.receiver?.identityNumber)
          setValue("receiverPhone", shipping.receiver?.phone)
          setValue(
            "destinationAddressStreet",
            shipping.destination?.addressStreet
          )
          setValue(
            "destinationAddressNumber",
            shipping.destination?.addressNumber
          )
          setValue(
            "destinationAddressOther",
            shipping.destination?.addressOther
          )
          setValue("destinationCity", shipping.destination?.city)
          setValue("destinationPostalCode", shipping.destination?.postalCode)
          setValue("destinationState", shipping?.destination?.state)
          break
        }
      }
    }
  }, [requirements, shipping, register, setValue])

  return (
    <ContainerCard title={<Trans>¿Querés recibir o retirar tu compra?</Trans>}>
      <SingleColumnRow>
        <Controller
          control={control}
          name="shippingMethodId"
          rules={{
            required: t`Elegí una opción para poder continuar.`,
          }}
          render={({ onChange, value }) => {
            const typeSelectedShipping = getShippingMethod(value)?.shippingType

            return (
              <>
                {requirements?.shippingMethods.map(shippingMethod => (
                  <RadioFieldCard
                    key={shippingMethod.id}
                    label={shippingMethod.name}
                    hasError={errors.shippingMethodId ? true : false}
                    value={shippingMethod.id}
                    checked={shippingMethod.id === value}
                    onChange={e => {
                      onChange(e)
                      register("shippingMethodType")
                      setValue<Keys, string>(
                        "shippingMethodType",
                        shippingMethod.shippingType
                      )
                      clearError()
                    }}
                    tag={
                      freeShipping ? (
                        <TagFreeShipping>
                          <Trans>Gratis</Trans>
                        </TagFreeShipping>
                      ) : null
                    }
                  >
                    <SingleColumnRow>
                      {typeSelectedShipping === "Z" && <ShippingMethodZ />}
                      {typeSelectedShipping === "S" && <ShippingMethodS />}
                      {typeSelectedShipping === "P" && <ShippingMethodP />}
                    </SingleColumnRow>
                  </RadioFieldCard>
                ))}
                <ErrorMessage>{errors?.shippingMethodId?.message}</ErrorMessage>
              </>
            )
          }}
        />
      </SingleColumnRow>
    </ContainerCard>
  )

  function getShippingMethod(id: string) {
    return requirements?.shippingMethods.find(shipping => shipping.id === id)
  }

  function clearError() {
    error && setError(null)
  }
}

const ErrorMessage = styled.span({
  fontSize: "1.2rem",
  color: colors.redMain,
})

const TagFreeShipping = styled.span({
  fontSize: "1.2rem",
  color: colors.greenDark,
  [mediaQuery.desktop]: {
    fontSize: "1.6rem",
  },
})
