import { Container } from "./Container"

export function Shipments() {
  return (
    <Container>
      <path
        d="M13.9051,14.2088a.8185.8185,0,1,1,.0037,0ZM5.9072,11.5924a2.3282,2.3282,0,0,0-3.633,0H1.6363V3.744h9.8186v7.8484Zm-1.816,2.6164a.8743.8743,0,0,1,0-1.745.8743.8743,0,0,1,0,1.745ZM15.1358,6.7917l1.6,2.18H13.0912V6.7967Zm.4093-1.3037H13.0912V2H1.6363A1.6976,1.6976,0,0,0,0,3.744v9.5883H1.6363a2.6575,2.6575,0,0,0,1.2131,2.3086,2.3249,2.3249,0,0,0,2.4826,0,2.6575,2.6575,0,0,0,1.2131-2.3086h4.9088a2.5359,2.5359,0,0,0,2.4545,2.5653,2.5358,2.5358,0,0,0,2.4544-2.5653H18V8.9759Z"
        fill="currentColor"
      />
    </Container>
  )
}
