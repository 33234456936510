import { useEffect, useMemo, useRef, useState } from "react"

import { api } from "../api"

type State = "idle" | "fetching" | "success" | "error"

export function usePaymentMethods() {
  const isMounted = useRef<boolean | null>(null)
  const [state, setState] = useState<State>("idle")
  const [
    paymentMethods,
    setPaymentMethods,
  ] = useState<api.paymentMethods.Get.Result>({
    objects: {
      custom: [],
      mercadoPago: [],
    },
  })

  const isLoading = useMemo(() => state === "fetching", [state])

  useEffect(() => {
    isMounted.current = true

    setState("fetching")

    api.paymentMethods
      .get()
      .then(res => {
        if (isMounted.current) {
          setState("success")
          setPaymentMethods(res)
        }
      })
      .catch(err => console.log(err))

    return () => {
      isMounted.current = false
    }
  }, [])

  return useMemo(
    () => ({
      info: paymentMethods,
      isLoading,
    }),
    [paymentMethods, isLoading]
  )
}
