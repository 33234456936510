import { useEffect, useState } from "react"

import { useLocation } from "react-router-dom"
import { useProductsStore } from "../provider/ProductStoreProvider"

export function useMeta() {
  const { meta } = useProductsStore()
  const { search: locationSearch } = useLocation()
  const urlSearchParams = new URLSearchParams(locationSearch)
  const search = urlSearchParams.get("search") || undefined

  const [breadcrumbLinks, setBreadcrumbLinks] = useState<
    { id: string; name: string; url: string }[]
  >([])
  const [title, setTitle] = useState<String | undefined>(undefined)

  useEffect(() => {
    setBreadcrumbLinks(search ? [] : meta.breadcrumb.slice(0, -1))
  }, [setBreadcrumbLinks, meta, search])

  useEffect(() => {
    const category = meta.breadcrumb[meta.breadcrumb.length - 1]?.name
    setTitle(search ? search : category)
  }, [search, meta])

  return {
    breadcrumbLinks,
    subCategories: meta.subcategories,
    title,
    categories: meta.breadcrumb,
  }
}
