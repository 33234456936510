import * as z from "zod"

export const LogoTypeSchema = z.union([z.literal("T"), z.literal("I")])

export type LogoType = z.infer<typeof LogoTypeSchema>

export const VisibilityTypeSchema = z.union([z.literal("A"), z.literal("H")])

export type VisibilityType = z.infer<typeof VisibilityTypeSchema>

export const LogoRawSchema = z.object({
  type: LogoTypeSchema,
  img: z.string().nullable(),
  text: z.string(),
  typography: z.string(),
  color: z.string(),
})

export type LogoRaw = z.infer<typeof LogoRawSchema>

export const HeaderRawSchema = z.object({
  background_color: z.string(),
  banner_background_color: z.string(),
  banner_content: z.string(),
  banner_enabled: z.boolean(),
  banner_typography: z.string(),
  banner_visibility: VisibilityTypeSchema,
  skin: z.string(),
  sticky: z.boolean(),
})

export type HeaderRaw = z.infer<typeof HeaderRawSchema>

export const FooterRawSchema = z.object({
  background_color: z.string(),
  skin: z.string(),
  phone_number: z.string(),
  navigation: z.array(
    z.object({
      name: z.string(),
      url: z.string(),
      page_id: z.string(),
    })
  ),
  show_contact: z.boolean(),
  show_navigation: z.boolean(),
  social_facebook_active: z.boolean(),
  social_facebook_url: z.string(),
  social_instagram_active: z.boolean(),
  social_instagram_url: z.string(),
  social_pinterest_active: z.boolean(),
  social_pinterest_url: z.string(),
  social_spotify_active: z.boolean(),
  social_spotify_url: z.string(),
  social_twitter_active: z.boolean(),
  social_twitter_url: z.string(),
  social_vimeo_active: z.boolean(),
  social_vimeo_url: z.string(),
  social_youtube_active: z.boolean(),
  social_youtube_url: z.string(),
  html_afip: z.string(),
})

export type FooterRaw = z.infer<typeof FooterRawSchema>

export const MenuItemTypeSchema = z.union([
  z.literal("P"),
  z.literal("C"),
  z.literal("T"),
  z.literal("L"),
])

export type MenuItemType = z.infer<typeof MenuItemSchema>

export const ManuItemRawSchema = z.object({
  name: z.string(),
  url: z.string(),
  identifier: z.string(),
  type: MenuItemTypeSchema,
})

export type ManuItemRaw = z.infer<typeof ManuItemRawSchema>

export const MenuRawSchema = z.array(ManuItemRawSchema)

export type MenuRaw = z.infer<typeof MenuRawSchema>

export const WhatsappRawSchema = z.object({
  show_whatsapp_button: z.boolean().optional(),
  country_code: z.string().optional(),
  phone_number: z.string().optional(),
  description: z.string().optional(),
})

export type WhatsappRaw = z.infer<typeof WhatsappRawSchema>

export const ResponseSchema = z.object({
  logo_header: LogoRawSchema,
  logo_footer: LogoRawSchema,
  use_same_logo: z.boolean(),
  colors_primary: z.string(),
  colors_secondary: z.string(),
  colors_titles: z.string(),
  colors_texts: z.string(),
  colors_background: z.string(),
  typography_titles: z.string(),
  typography_texts: z.string(),
  typography_menu: z.string(),
  header: HeaderRawSchema,
  footer: FooterRawSchema,
  menu: MenuRawSchema,
  whatsapp: WhatsappRawSchema.optional(),
  email: z.string().optional(),
})

export type Response = z.infer<typeof ResponseSchema>

export const LogoSchema = z.object({
  type: LogoTypeSchema,
  img: z.string().optional(),
  text: z.string(),
  typography: z.string(),
  color: z.string(),
})

export type Logo = z.infer<typeof LogoSchema>

export const ColorsSchema = z.object({
  primary: z.string(),
  secondary: z.string(),
  titles: z.string(),
  texts: z.string(),
  background: z.string(),
})

export type Colors = z.infer<typeof ColorsSchema>

export const TypographiesSchema = z.object({
  titles: z.string(),
  texts: z.string(),
  menu: z.string(),
})

export type Typographies = z.infer<typeof TypographiesSchema>

export const BannerSchema = z.object({
  backgroundColor: z.string(),
  content: z.string(),
  enabled: z.boolean(),
  typography: z.string(),
  visibility: VisibilityTypeSchema,
})

export type Banner = z.infer<typeof BannerSchema>

export const HeaderSchema = z.object({
  backgroundColor: z.string(),
  banner: BannerSchema,
  design: z.string(),
  sticky: z.boolean(),
})

export type Header = z.infer<typeof HeaderSchema>

export const NavigationItemSchema = z.object({
  name: z.string(),
  url: z.string(),
  id: z.string(),
})

export type NavigationItem = z.infer<typeof NavigationItemSchema>

export const SocialsSchema = z.object({
  id: z.string(),
  iconName: z.string(),
  url: z.string(),
  title: z.string(),
})

export type Socials = z.infer<typeof SocialsSchema>

export const FooterSchema = z.object({
  backgroundColor: z.string(),
  design: z.string(),
  phoneNumber: z.string(),
  navigation: z.array(NavigationItemSchema),
  showContact: z.boolean(),
  showNavigation: z.boolean(),
  socials: z.array(SocialsSchema),
  htmlAfip: z.string(),
})

export type Footer = z.infer<typeof FooterSchema>

export const MenuItemSchema = z.object({
  name: z.string(),
  url: z.string(),
  id: z.string(),
  type: MenuItemTypeSchema,
})

export type MenuItem = z.infer<typeof MenuItemSchema>

export const MenuSchema = z.array(MenuItemSchema)

export type Menu = z.infer<typeof MenuSchema>

export const WhatsappSchema = z.object({
  showWhatsappButton: z.boolean().optional(),
  countryCode: z.string().optional(),
  phoneNumber: z.string().optional(),
  description: z.string().optional(),
})

export type Whatsapp = z.infer<typeof WhatsappSchema>

export const ResultSchema = z.object({
  logoHeader: LogoSchema,
  logoFooter: LogoSchema,
  useSameLogo: z.boolean().optional(),
  colors: ColorsSchema,
  typographies: TypographiesSchema,
  header: HeaderSchema,
  footer: FooterSchema,
  menu: MenuSchema,
  whatsapp: WhatsappSchema.optional(),
  email: z.string().optional(),
})

export type Result = z.infer<typeof ResultSchema>
