import {
  ShippingOptionsP,
  ShippingOptionsS,
} from "../../../../../../../../api/stores/checkout"
import { useCheckout, useShippingOption } from "../../../../../../../../hooks"

import { InformationColumn } from "../InformationColumn"
import { formatDestinationToString } from "../../../StepShipping/components/CardShippingOptions/helpers/formatDestinationString"

type ShippingOptionS = ShippingOptionsS["options"][0]
type ShippingOptionP = ShippingOptionsP["options"][0]

export const AddressColumn = () => {
  const { shipping } = useCheckout().data.meta
  const { shippingOption } = useShippingOption()
  return (
    <>
      {shipping?.shippingType === "S" && (
        <InformationColumn
          type="address"
          description={(() => {
            const option = shippingOption as ShippingOptionS
            return (
              <>
                {formatDestinationToString({
                  addressStreet: option?.street,
                  addressNumber: option?.streetNumber,
                  addressOther: option?.streetExtra,
                  state: option?.state,
                  city: option?.city,
                  postalCode: option?.zipCode,
                })}
              </>
            )
          })()}
        />
      )}
      {(shipping?.shippingType === "C" || shipping?.shippingType === "Z") && (
        <InformationColumn
          type="address"
          description={formatDestinationToString(shipping.destination)}
        />
      )}
      {shipping?.shippingType === "P" && shippingOption && (
        <InformationColumn
          type="address"
          description={(() => {
            const {
              location,
              openHours,
            } = (shippingOption as unknown) as ShippingOptionP
            const address = formatDestinationToString({
              addressStreet: location.street,
              addressNumber: location.streetNumber,
              addressOther: location.streetExtras,
              city: location.city,
              state: location.state,
              postalCode: "",
            })

            return (
              <>
                {address} <br />
                {openHours}
              </>
            )
          })()}
        />
      )}
    </>
  )
}
