import { Controller, useFormContext } from "react-hook-form"
import {
  DiscountTag,
  ErrorMsg,
  IconsMP,
  PaymentDescription,
  PaymentDescriptionBold,
  SingleColumnRow,
} from "../../../../style"
import { MercadoPago, RedirectMP } from "../../../../../../../../icons"
import { Trans, t } from "@lingui/macro"

import { AppliedDiscountType } from "../../../../BodyCheckout"
import { ContainerCard } from "../../../../../../../../components/ContainerCard"
import { Currency } from "../../../../../../../../utils/PriceUtil"
import { PaymentData } from "../../types"
import { RadioFieldCard } from "../../../../../../../../components/RadioFieldCard"
import { toLocalePrice } from "../../../../../../../../utils"
import { useCheckout } from "../../../../../../../../hooks"
import { useEffect } from "react"

type CardPaymentOptionsType = {
  changeAppliedDiscount: (appliedDiscount: AppliedDiscountType) => void
}

export const CardPaymentOptions = ({
  changeAppliedDiscount,
}: CardPaymentOptionsType) => {
  const {
    requirements: { paymentOptions },
    payment,
  } = useCheckout().data.meta
  const {
    control,
    errors,
    setValue,
    register,
    unregister,
  } = useFormContext<PaymentData>()

  useEffect(() => {
    // set paymentOptions from api
    const isPaymentEmpty = payment?.type == null

    if (!isPaymentEmpty) {
      const value = paymentOptions.find(
        option => option.paymentType === payment?.type
      )
      register("paymentMethodType")
      register("paymentMethodName")
      setValue("paymentMethodId", payment?.id || "")
      setValue("paymentMethodType", payment?.type || "")
      setValue("paymentMethodName", value?.name || "")
    }
  }, [payment, paymentOptions, setValue, register])

  return (
    <ContainerCard title={<Trans>¿Cómo vas a pagar?</Trans>}>
      <SingleColumnRow>
        <Controller
          control={control}
          name="paymentMethodId"
          rules={{
            required: t`Elegí una opción para poder continuar`,
          }}
          render={({ onChange, value }) => (
            <>
              {paymentOptions.map(
                ({
                  discountType,
                  discountValue,
                  paymentType,
                  offersDiscount,
                  currency,
                  ...option
                }) => (
                  <RadioFieldCard
                    key={option.id}
                    label={option.name}
                    hasError={!!errors.paymentMethodId}
                    value={option.id}
                    onChange={e => {
                      onChange(e)
                      register("paymentMethodType")
                      register("paymentMethodName")
                      setValue("paymentMethodType", paymentType)
                      setValue("paymentMethodName", option.name)
                      if (paymentType === "C" && offersDiscount) {
                        register("discountType")
                        register("discountValue")
                        setValue("discountType", discountType)
                        setValue("discountValue", discountValue)
                        changeAppliedDiscount({ discountType, discountValue })
                      } else {
                        unregister("discountType")
                        unregister("discountValue")
                        changeAppliedDiscount({
                          discountType: null,
                          discountValue: null,
                        })
                      }
                    }}
                    checked={value === option.id}
                    tag={
                      paymentType === "C" &&
                      offersDiscount && (
                        <DiscountTag>
                          {getDiscountText({
                            discountType,
                            discountValue,
                            currency,
                          })}
                        </DiscountTag>
                      )
                    }
                  >
                    {paymentType === "C" && (
                      <SingleColumnRow>
                        <PaymentDescription>
                          {option.description}
                        </PaymentDescription>
                      </SingleColumnRow>
                    )}
                    {paymentType === "M" && (
                      <SingleColumnRow>
                        <IconsMP>
                          <RedirectMP />
                          <MercadoPago />
                        </IconsMP>
                        <PaymentDescriptionBold>
                          <Trans>
                            Te redireccionaremos al sitio seguro de Mercado Pago
                            para que finalices tu compra.
                          </Trans>
                        </PaymentDescriptionBold>
                      </SingleColumnRow>
                    )}
                  </RadioFieldCard>
                )
              )}
            </>
          )}
        />
        {errors.paymentMethodId && (
          <ErrorMsg>{errors.paymentMethodId.message}</ErrorMsg>
        )}
      </SingleColumnRow>
    </ContainerCard>
  )

  type getDiscountTextType = {
    discountType: "A" | "P" | null
    discountValue: number | null
    currency: Currency
  }
  function getDiscountText({
    discountType,
    discountValue,
    currency,
  }: getDiscountTextType) {
    if (discountType === "P") {
      return `${discountValue}% OFF`
    }
    return `${toLocalePrice((discountValue as number) * 100, currency)} OFF`
  }
}
