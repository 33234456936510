export const checkoutRoute = window.ROUTES
  ? window.ROUTES.find(route => route.kind === "H")?.path || ""
  : ""

export function locationMatchCheckout() {
  const target = checkoutRoute.replaceAll("/", "")
  const match = window.location.pathname.split("/").find(path => {
    path = path.split("?")[0]
    return path === target
  })

  return match ? true : false
}
