import { Fragment, useEffect, useState } from "react"
import {
  RTCButton,
  RTCContactCard,
  RTCExtraInformation,
  RTCFAQs,
  RTCFeaturedCard,
  RTCImage2Gallery,
  RTCImage3Gallery,
  RTCImageCarrousel,
  RTCMap,
  RTCProductsCarousel,
  RTCSocialNetworks,
  RTCText,
  RTCTextImage,
  RTCTitle,
} from "store-components"
import { colors, mediaQuery } from "../../theme"

import { Breadcrumb } from "../../components/Breadcrumb"
import { ControllablePageHead } from "./ControllablePageHead"
import { RouteComponentProps } from "react-router-dom"
import { Spinner } from "../../icons"
import { WhatsAppButton } from "../../components/WhatsAppButton"
import styled from "@emotion/styled/macro"
import { usePageComponents } from "../../hooks/usePageComponents"
import { useStoreState } from "../../providers/StoreProvider"

const FULL_WIDTH_COMPONENTS = ["ImageCarrousel", "Map"]

type Props = {
  pageId: string
}

type RTComponents = {
  [componentType: string]: (props: Element) => JSX.Element
}

type Element = {
  [propKey: string]: string
}

export const ControllablePage: import("react").FC<
  RouteComponentProps & Props
> = ({ location, pageId }) => {
  const [breadcrumbLinks, setBreadcrumbLinks] = useState([
    {
      url: "/",
      id: "home",
      name:
        window.ROUTES.find(route => route.path === "/")?.name ??
        "### HOME PATH NOT FOUND ###",
    },
  ])

  const components = usePageComponents(pageId)
  const whatsAppButtonInfo = useStoreState()[0].config.whatsapp

  useEffect(() => {
    const locationArr = location.pathname.split("/").filter(Boolean)

    const newBreadcrumbLinks = locationArr.map(path => {
      const route = window.ROUTES.find(route => route.path === `/${path}/`)
      return {
        url: route?.path ?? "### PATH NOT FOUND ###",
        id: route?.id ?? "### PATH NOT FOUND ###",
        name: route?.name ?? "### PATH NOT FOUND ###",
      }
    })

    setBreadcrumbLinks(prevState => [...prevState, ...newBreadcrumbLinks])
  }, [location.pathname])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  const Components = ({
    Button: RTCButton,
    Text: RTCText,
    Title: RTCTitle,
    FAQs: RTCFAQs,
    ContactCard: RTCContactCard,
    ExtraInformation: RTCExtraInformation,
    FeaturedCard: RTCFeaturedCard,
    ImageCarrousel: RTCImageCarrousel,
    Image2Gallery: RTCImage2Gallery,
    Image3Gallery: RTCImage3Gallery,
    ProductsCarousel: RTCProductsCarousel,
    TextImage: RTCTextImage,
    SocialNetworks: RTCSocialNetworks,
    Map: RTCMap,
  } as unknown) as RTComponents

  return (
    <>
      <ControllablePageHead components={components.list} />
      <Grid>
        <Container>
          {breadcrumbLinks.length > 1 && (
            <BreadcrumbContainer>
              <BreadcrumbWrapper>
                <Breadcrumb links={breadcrumbLinks} />
              </BreadcrumbWrapper>
            </BreadcrumbContainer>
          )}
          {components.isLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <Fragment>
              {components.list.map(component => {
                if (component.kind in Components) {
                  const ComponentToRender = Components[component.kind]
                  const isFullWidth = FULL_WIDTH_COMPONENTS.includes(
                    component.kind
                  )

                  return (
                    <ComponentContainer
                      key={component.id}
                      isFullWidth={isFullWidth}
                    >
                      <ComponentWrapper isFullWidth={isFullWidth}>
                        <ComponentToRender {...component.props} />
                      </ComponentWrapper>
                    </ComponentContainer>
                  )
                } else {
                  return <div key={component.id}>{component.kind}</div>
                }
              })}
            </Fragment>
          )}
          {whatsAppButtonInfo?.showWhatsappButton && (
            <WhatsAppButton
              countryCode={whatsAppButtonInfo?.countryCode || ""}
              phoneNumber={whatsAppButtonInfo?.phoneNumber || ""}
              description={whatsAppButtonInfo.description || ""}
            />
          )}
        </Container>
      </Grid>
    </>
  )
}

const Grid = styled.div({
  flexGrow: 1,
})

const Container = styled.main({
  minHeight: "100vh",

  [mediaQuery.desktop]: {
    gridColumnEnd: 3,
    gridColumnStart: 2,
    minHeight: 0,
  },
})

const BreadcrumbContainer = styled.div({
  margin: "2.4rem 0",
  padding: "1.6rem",

  [mediaQuery.desktop]: {
    display: "grid",
    gridTemplateColumns: `auto 94rem auto`,
  },
})

const BreadcrumbWrapper = styled.div({
  [mediaQuery.desktop]: {
    gridColumn: "2 / 3",
  },
})

const SpinnerContainer = styled.div({
  bottom: "50%",
  color: colors.greyMain,
  fontSize: "6.4rem",
  position: "absolute",
  right: "50%",
  transform: "translate(50%, 50%)",
})

interface FullWidthInterface {
  isFullWidth: boolean
}

const ComponentContainer = styled.div<FullWidthInterface>(({ isFullWidth }) => {
  return !isFullWidth
    ? {
        padding: "1.6rem 0",
        margin: "0 1.2rem",
        [mediaQuery.desktop]: {
          display: "grid",
          gridTemplateColumns: `auto 94rem auto`,
        },
      }
    : {}
})

const ComponentWrapper = styled.div<FullWidthInterface>(({ isFullWidth }) => {
  return !isFullWidth
    ? {
        gridColumn: "2 / 3",
      }
    : {}
})
