import { Container } from "./Container"

export function Info({ ...props }) {
  return (
    <Container>
      <path
        d="M9,0a9,9,0,1,0,9,9A8.9989,8.9989,0,0,0,9,0ZM9,16.2857A7.2857,7.2857,0,1,1,16.2857,9,7.28,7.28,0,0,1,9,16.2857Z"
        fill="currentColor"
      />
      <circle cx="9" cy="4.8716" r="0.9643" fill="currentColor" />
      <path
        d="M9,6.8a.9647.9647,0,0,0-.9643.9643v5.3641a.9643.9643,0,1,0,1.9286,0V7.7643A.9647.9647,0,0,0,9,6.8Z"
        fill="currentColor"
      />
    </Container>
  )
}
