import { Link } from "react-router-dom"
import { PropsWithTheme } from "../../providers/StoreThemeProvider"
import { readableColor } from "polished"
import styled from "@emotion/styled/macro"
import { useMemo } from "react"
import { useVisibleLinks } from "../../hooks"

type LinkList = {
  id: string
  name: string
  url: string
}

type Props = {
  links: Array<LinkList>
}

export function Breadcrumb({ links }: Props) {
  const { linkList, lastBreadcrumbStep, listRef, renderDots } = useVisibleLinks(
    {
      links,
    }
  )
  const lastIndex = useMemo(() => linkList.length - 1, [linkList.length])

  return (
    <NavContainer>
      <Container data-testid="breadcrumb" ref={listRef}>
        {renderDots ? (
          <Item>
            <StartContainer>
              <StyledLink to={links[lastBreadcrumbStep].url} hasStep={true}>
                ...
              </StyledLink>
              <Separator>{"/"}</Separator>
            </StartContainer>
          </Item>
        ) : null}
        {linkList.map((link, index) => {
          const isLast = lastIndex === index
          return (
            <Item key={link.id}>
              <StyledLink to={link.url}>{link.name}</StyledLink>
              {!isLast && <Separator>{"/"}</Separator>}
            </Item>
          )
        })}
      </Container>
    </NavContainer>
  )
}

const NavContainer = styled.nav()

const Container = styled.ol({
  listStyle: "none",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  padding: 0,
  margin: 0,
  cursor: "default",
})

const StartContainer = styled.div({
  alignItems: "center",
  display: "flex",
})

const Item = styled.li({
  fontSize: "1.4rem",
})

type StyledLinkProps = {
  hasStep?: boolean
}

const StyledLink = styled(Link)<PropsWithTheme & StyledLinkProps>(props => ({
  color: readableColor(props.theme.colors.background),
  textDecoration: "none",

  "&:hover": {
    textDecoration: props.hasStep ? "underline" : "none",
  },
}))

const Separator = styled.span<PropsWithTheme>(props => ({
  display: "inline",
  fontSize: "1.6rem",
  color: readableColor(props.theme.colors.background),
  margin: "auto 0.8rem",
}))
