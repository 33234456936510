import * as Types from "../screens/ProductVIP/components/NamePriceActions/InformationLinks/ShippingCostLink/types"

import { useCallback, useMemo, useState } from "react"

import { api } from "../api"

type State = "idle" | "fetching" | "success" | "error"

type Props = {
  productID: string
  variantID?: number
  quantity: number
}

function isShippingCostDataWithError(
  shippingCostData:
    | api.stores.quote.Post.Result
    | api.stores.quote.Post.ErrorResult
): shippingCostData is api.stores.quote.Post.ErrorResult {
  return (shippingCostData as api.stores.quote.Post.ErrorResult).error != null
}

export function useShippingCost({ productID, variantID, quantity }: Props) {
  const [state, setState] = useState<State>("idle")
  const [data, setData] = useState<api.stores.quote.Post.Result>({
    results: [],
  })
  const [shippingRequest, setShippingRequest] = useState<Types.FormData>()
  const [showForm, setShowForm] = useState(true)
  const [error, setError] = useState<api.stores.quote.Post.ErrorResult>()

  const isLoading = useMemo(() => state === "fetching", [state])

  const submitForm = useCallback(
    (data: Types.FormData) => {
      setState("fetching")

      setShippingRequest(data)

      api.stores
        .set()
        .quote.post({
          ...data,
          productID,
          variantID: variantID as number,
          quantity,
        })
        .then(shippingData => {
          setState("success")

          if (isShippingCostDataWithError(shippingData)) {
            setError(shippingData)
          } else {
            setData(shippingData)
            setError(undefined)
            setShowForm(false)
          }
        })
        .catch(err => {
          console.log(err)
          setState("error")
        })
    },
    [setData, setState, productID, variantID, quantity]
  )

  return useMemo(
    () => ({
      submitForm,
      isLoading,
      data,
      error,
      shippingRequest,
      showForm,
      setShowForm,
    }),
    [submitForm, isLoading, data, error, shippingRequest, showForm, setShowForm]
  )
}
