import { Fragment } from "react"
import { PropsWithTheme } from "../../../../../../providers/StoreThemeProvider"
import { Step } from "../../../../providers/CheckoutScreenProvider"
import { Trans } from "@lingui/macro"
import { mediaQuery } from "../../../../../../theme"
import { readableColor } from "polished"
import styled from "@emotion/styled/macro"
import { useCheckoutScreen } from "../../../../hooks/useCheckoutScreen"
import { useScreenSize } from "../../../../../../hooks"

type StepContent = {
  index: Step
  name: JSX.Element
  enabled: boolean
}

const stepsContent: StepContent[] = [
  { index: 1, name: <Trans>Contacto</Trans>, enabled: true },
  { index: 2, name: <Trans>Envío</Trans>, enabled: true },
  { index: 3, name: <Trans>Confirmación</Trans>, enabled: true },
]

export function Stepper() {
  const { isDesktop } = useScreenSize()
  const { currentStep, setCurrentStep } = useCheckoutScreen()

  return (
    <Container>
      <List>
        {stepsContent.map(({ index, name, enabled }) => {
          const disabled = !enabled || index > currentStep

          return (
            <Fragment key={index}>
              <Divider disabled={disabled} />
              <StepButton
                onClick={() => setCurrentStep(index)}
                selected={index === currentStep}
                disabled={disabled}
                data-testid={`step-button-${index}`}
              >
                <Index>{isDesktop && index}</Index>
                <Title>{name}</Title>
              </StepButton>
            </Fragment>
          )
        })}
      </List>
    </Container>
  )
}

const Container = styled.div({
  display: "flex",
  justifyContent: "center",
  order: 2,
  margin: "1.6rem 0",

  [mediaQuery.desktop]: {
    gridRowStart: 1,
    margin: "0 1.6rem 2.4rem 1.6rem",
    justifyContent: "start",
  },
})

const List = styled.div({
  display: "grid",
  gridGap: "0.8rem",
  gridAutoFlow: "column",
  gridAutoColumns: "max-content",

  [mediaQuery.desktop]: {
    gridGap: "1.6rem",
  },
})

type StepButtonProps = {
  selected: boolean
  disabled: boolean
}

const StepButton = styled.button<PropsWithTheme & StepButtonProps>(props => ({
  fontSize: "1.4rem",
  border: "none",
  backgroundColor: "transparent",
  cursor: props.selected || props.disabled ? "default" : "pointer",
  padding: 0,
  display: "grid",
  gridAutoFlow: "column",
  gridGap: "0.8rem",
  opacity: props.disabled ? "40%" : "100%",

  ...(!props.disabled && {
    "&:hover": {
      opacity: props.selected ? 1 : 0.9,
    },
  }),
}))

const Index = styled.div<PropsWithTheme>(props => ({
  display: "flex",
  alignSelf: "center",
  justifyContent: "center",
  alignItems: "center",
  width: "0.8rem",
  height: "0.8rem",
  border: "none",
  borderRadius: "50%",
  backgroundColor: props.theme.colors.primary,
  color: readableColor(props.theme.colors.primary),
  fontWeight: "bold",

  [mediaQuery.desktop]: {
    fontSize: "1.4rem",
    width: "3.2rem",
    height: "3.2rem",
  },
}))

const Title = styled.div<PropsWithTheme>(props => ({
  fontSize: "1.2rem",
  color: readableColor(props.theme.colors.background),
  alignSelf: "center",

  [mediaQuery.desktop]: {
    fontSize: "1.4rem",
  },
}))

type DividerProps = {
  disabled: boolean
}

const Divider = styled.div<PropsWithTheme & DividerProps>(props => ({
  borderBottom: `1px solid ${readableColor(props.theme.colors.background)}`,
  width: "1.6rem",
  alignSelf: "center",
  opacity: props.disabled ? "40%" : "100%",

  [mediaQuery.desktop]: {
    width: "4.8rem",
  },

  [`&:first-of-type`]: {
    display: "none",
  },
}))
