import { Fragment, useState } from "react"
import { colors, mediaQuery } from "../../../../theme"

import { CheckoutChevron } from "../../../../icons"
import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { Trans } from "@lingui/macro"
import styled from "@emotion/styled/macro"

type Props = {
  attributes: {
    id: string
    name: string
    value: string
    valueType: string
  }[]
}

export function ProductAttributes({ attributes }: Props) {
  const [isOpen, setIsOpen] = useState(false)

  const visibleAttributes =
    attributes.length > 5 ? attributes.slice(0, 5) : attributes
  const hiddenAttributes = attributes.length > 5 ? attributes.slice(5) : []
  const hasHiddenAttributes = attributes.length > 5

  return (
    <Fragment>
      <Title>
        <Trans>Características</Trans>
      </Title>
      <Table>
        {visibleAttributes.map((attribute, index) => (
          <Tr key={attribute.id}>
            {index % 2 === 0 ? (
              <>
                <ThEven>{attribute.name}</ThEven>
                <TdEven>{attribute.value}</TdEven>{" "}
              </>
            ) : (
              <>
                <ThOdd>{attribute.name}</ThOdd>
                <TdOdd>{attribute.value}</TdOdd>{" "}
              </>
            )}
          </Tr>
        ))}
        {hasHiddenAttributes && !isOpen && (
          <CollapsableContainer onClick={() => setIsOpen(true)}>
            <CheckoutChevron />
            <Trans>Ver más características</Trans>
          </CollapsableContainer>
        )}
        {hasHiddenAttributes &&
          isOpen &&
          hiddenAttributes.map((attribute, index) => (
            <Tr key={attribute.id}>
              {index % 2 === 0 ? (
                <>
                  <ThEven>{attribute.name}</ThEven>
                  <TdEven>{attribute.value}</TdEven>{" "}
                </>
              ) : (
                <>
                  <ThOdd>{attribute.name}</ThOdd>
                  <TdOdd>{attribute.value}</TdOdd>{" "}
                </>
              )}
            </Tr>
          ))}
      </Table>
    </Fragment>
  )
}

const Title = styled.h2<PropsWithTheme>(props => ({
  fontSize: "1.8rem",
  fontWeight: "bold",
  color: props.theme.colors.texts,
  fontFamily: props.theme.typographies.texts,
  marginBottom: "2.4rem",
  marginTop: 0,

  [mediaQuery.desktop]: {
    marginBottom: "4rem",
  },
}))

const Table = styled.table({
  borderCollapse: "collapse",
  width: "100%",
  marginBottom: "4rem",
})

const Tr = styled.tr({
  display: "flex",
  textAlign: "left",
})

const Th = styled.th({
  padding: "2.4rem",
  fontFamily: "Open Sans, sans-serif",
  fontSize: "1.4rem",
  fontWeight: 600,
  width: "50%",
})

const Td = styled.td({
  padding: "2.4rem",
  fontFamily: "Open Sans, sans-serif",
  fontSize: "1.4rem",
  width: "50%",
})

const ThEven = styled(Th)({
  backgroundColor: "#EEEEEE",
})

const ThOdd = styled(Th)({
  backgroundColor: "#F5F5F5",
})

const TdEven = styled(Td)({
  backgroundColor: "#F5F5F5",
})

const TdOdd = styled(Td)({
  backgroundColor: "#FBFBFB",
})

const CollapsableContainer = styled.div({
  display: "flex",
  color: colors.greyMedium,
  fontFamily: "Open Sans, sans-serif",
  fontSize: "1.4rem",
  alignItems: "center",
  gap: "0.8rem",
  marginTop: "1.6rem",
  cursor: "pointer",

  "&:hover": {
    color: colors.greyDark,
    textDecoration: "underline",
  },

  "& svg": {
    fontSize: "1.6rem",
  },
})
