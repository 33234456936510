import { colors, opacity } from "../../../../theme"

import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { Spinner } from "../../../../icons"
import { readableColor } from "polished"
import styled from "@emotion/styled"

export function LoaderCheckout() {
  return (
    <LoadingContainer>
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    </LoadingContainer>
  )
}

const LoadingContainer = styled.div({
  zIndex: 10000, // dangerous line
  position: "fixed",
  top: 0,
  left: 0,
  height: "100vh",
  width: "100vw",
  backgroundColor: colors.greyMain,
  opacity: opacity[2],
  pointerEvents: "none",
})

const SpinnerContainer = styled.div<PropsWithTheme>(props => ({
  color: readableColor(props.theme.colors.background),
  right: "50%",
  bottom: "50%",
  transform: "translate(50%, 50%)",
  position: "absolute",
  fontSize: "6.4rem",
}))
