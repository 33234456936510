import { Trans } from "@lingui/macro"
import { colors } from "../../theme"
import styled from "@emotion/styled"

interface OutStockAlertProps {
  productsListStyle?: boolean
}

export function OutStockAlert({ productsListStyle }: OutStockAlertProps) {
  return (
    <Container productsListStyle={productsListStyle}>
      <Trans>Sin stock</Trans>
    </Container>
  )
}

type ContainerProps = {
  productsListStyle?: boolean
}

const Container = styled.div<ContainerProps>(({ productsListStyle }) => ({
  position: productsListStyle ? "absolute" : "relative",
  top: productsListStyle ? 8 : 0,
  left: productsListStyle ? 8 : 0,
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0.4rem 0.8rem",
  minWidth: "7.2rem",
  minHeight: "2.4rem",
  border: `0.1rem solid ${colors.white}`,
  borderRadius: "0.4rem",
  fontSize: "1.4rem",
  fontWeight: "normal",
  color: colors.white,
  backgroundColor: colors.greyDark,
}))
