import { Close } from "../../../../icons"
import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { colors } from "../../../../theme"
import styled from "@emotion/styled"

type Props = {
  label: string | import("react").ReactNode
  onClick: (
    e: import("react").MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
}

export function FilterChip({ label, onClick }: Props) {
  return (
    <Container data-testid="filter-chip">
      <Label>{label}</Label>
      <DeleteButton onClick={onClick}>
        <Close />
      </DeleteButton>
    </Container>
  )
}

const Container = styled.div<PropsWithTheme>(props => ({
  border: `1px solid ${colors.greyMedium}`,
  borderRadius: 4,
  padding: "0.6rem 1.6rem",
  width: "fit-content",
  display: "inline-flex",
  alignItems: "baseline",
  margin: 2,
  backgroundColor: colors.white,
  cursor: "default",

  "&:hover": {
    "& button": {
      color: colors.black,
    },
  },
}))

const Label = styled.span<PropsWithTheme>(props => ({
  color: colors.greyMedium,
  fontFamily: props.theme.typographies.texts,
  fontSize: "1.2rem",
}))

const DeleteButton = styled.button<PropsWithTheme>(props => ({
  outline: "none",
  color: colors.greyMedium,
  fontSize: "1rem",
  border: 0,
  backgroundColor: "transparent",
  cursor: "pointer",
  display: "flex",
  alignSelf: "center",
  padding: "0 0 0 0.8rem",
}))
