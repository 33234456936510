import { RequestBody } from "../../../../stores/quote/post/types"
import { delay } from "../../../utils"
import { rest } from "msw"

export const quote = [
  rest.post("*/v1/stores/:storeID/quote/", function (req, res, ctx) {
    const parsedRequest = req.body as RequestBody

    if (parsedRequest.postal_code === "1111") {
      return res(
        delay(ctx),
        ctx.status(400),
        ctx.json(require("./post.error.fixture.json"))
      )
    } else {
      return res(delay(ctx), ctx.json(require("./post.fixture.json")))
    }
  }),
]
