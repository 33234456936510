import { useCallback, useMemo, useState } from "react"

import { api } from "../api"

const BRANCHES = "branches"

type BranchesStorage = {
  timeStamp: number
  branches: api.stores.pickUpPoints.Get.PickUpPoint[]
}

export type State = "idle" | "fetching" | "success" | "error"

const storage = localStorage.getItem(BRANCHES)

const cacheDuration = 60000 * 2 // TODO: Check this with backend

export function useStoreBranches() {
  const [branchesStorage, setBranchesStorage] = useState<BranchesStorage>(
    storage != null
      ? JSON.parse(storage)
      : { timeStamp: new Date("2000/01/01").getTime(), branches: [] }
  )
  const [state, setState] = useState<State>("idle")

  const fetchAndCacheBranches = useCallback(async () => {
    const now = new Date().getTime()
    const newBranchesCache: BranchesStorage = {
      timeStamp: now,
      branches: [],
    }

    const response = await api.stores.set().pickUpPoints.get()

    newBranchesCache.branches = response?.pickUpPoints ?? []

    if (newBranchesCache.branches.length > 0) {
      localStorage.setItem(BRANCHES, JSON.stringify(newBranchesCache))
    }

    return newBranchesCache
  }, [])

  const getBranchesStorage = useCallback(() => {
    if (state === "idle") {
      const now = new Date().getTime()
      const cacheExpired =
        now - new Date(branchesStorage.timeStamp).getTime() > cacheDuration

      if (cacheExpired) {
        setState("fetching")
        fetchAndCacheBranches().then(
          branches => {
            setBranchesStorage(branches)
            setState("success")
            return branches
          },
          () => setState("error")
        )
      } else {
        setState("success")
        return branchesStorage
      }
    }
  }, [fetchAndCacheBranches, state, branchesStorage])

  return useMemo(
    () => ({
      getBranchesStorage,
      branchesStorage,
      state,
    }),
    [getBranchesStorage, branchesStorage, state]
  )
}
