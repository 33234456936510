import { Container } from "./Container"

export function Branches() {
  return (
    <Container>
      <path
        d="M15.4188,8.006a1.6883,1.6883,0,0,1-1.67-1.376h3.343a1.6984,1.6984,0,0,1-1.6741,1.377Zm.15,7.861H8.298V11.179A1.502,1.502,0,0,0,6.7855,9.691H5.5026a1.5019,1.5019,0,0,0-1.5115,1.488v4.693H2.4278v-7h.1544a2.59,2.59,0,0,0,2.14-1.122,2.602,2.602,0,0,0,4.2816,0,2.5959,2.5959,0,0,0,4.2735,0,2.59,2.59,0,0,0,2.14,1.122h.15v7Zm-8.1538,0h-2.54V11.179a.6239.6239,0,0,1,.6288-.619H6.79a.624.624,0,0,1,.6288.619v4.689ZM.9142,6.627h3.341a1.7033,1.7033,0,0,1-3.343,0Zm7.6185,0a1.7033,1.7033,0,0,1-3.343,0Zm4.2775,0a1.7012,1.7012,0,0,1-3.34,0ZM18,6.334a.3966.3966,0,0,0-.059-.214L15.2633,1.485a.4449.4449,0,0,0-.3829-.221H3.1154a.4369.4369,0,0,0-.3829.221L.0589,6.12A.4338.4338,0,0,0,0,6.334,2.541,2.541,0,0,0,1.545,8.659v7.643a.44.44,0,0,0,.4409.434H16.01a.44.44,0,0,0,.4408-.434V8.715a.1848.1848,0,0,0,0-.047A2.5478,2.5478,0,0,0,18,6.334Z"
        fill="currentColor"
      />
    </Container>
  )
}
