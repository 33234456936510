import { NewVariantRequestRaw, Request } from "./types"

import { Response } from "../types"
import paths from "../../../paths"
import { service } from "../../../Service"
import { transformResponse } from ".."

export function post() {
  return async function (item: NewVariantRequestRaw) {
    const body = transformRequest(item)

    try {
      const response = await service.post<Response>(paths.carts(), body)

      return transformResponse(response.body)
    } catch (error) {
      throw new Error(`API Carts did not respond correctly`)
    }
  }
}

function transformRequest(item: NewVariantRequestRaw): Request {
  return {
    item_id: item.itemId,
    price: item.price,
    currency: item.currency,
    quantity: item.quantity,
    title: item.title,
    properties: item.properties,
    thumbnail: item.thumbnail,
  }
}
