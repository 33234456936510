import { Helmet } from "react-helmet"
import { useLocation } from "react-router-dom"
import { useMeta } from "./hooks/useMeta"

export function ProductListHead() {
  const { categories } = useMeta()

  const canonicalHref = window.location.href.split("?")[0]

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const searchValue = searchParams.get("search")

  const categoriesNames = categories.length
    ? categories[categories.length - 1].name
    : ""

  let titleInfo
  let descriptionInfo

  if (searchValue !== null) {
    titleInfo = `${searchValue} - ${window.STORE_NAME}`
    descriptionInfo = `Comprá online productos como ${searchValue}. Hacé tu pedido y pagalo online.`
  } else {
    if (categories.length) {
      titleInfo = `Comprar ${categoriesNames} en ${window.STORE_NAME}`
      descriptionInfo = `Comprá online productos de ${categoriesNames}. Hacé tu pedido y pagalo online. `
    } else {
      titleInfo = `Comprá online productos en ${window.STORE_NAME}`
      descriptionInfo = `Comprá productos de ${window.STORE_NAME} por internet. Hacé tu pedido y pagalo online.`
    }
  }

  return (
    <Helmet>
      <title>{titleInfo}</title>
      <link rel="canonical" href={canonicalHref} />
      <meta name="description" content={descriptionInfo} />
    </Helmet>
  )
}
