import { BREAKPOINTS, colors, mediaQuery, opacity } from "../../../../theme"
import { Chevron, Close } from "../../../../icons"
import {
  useCarrousel,
  useScreenSize,
  useSwipeScrollTo,
} from "../../../../hooks"
import { useRef, useState } from "react"

import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { api } from "../../../../api"
import styled from "@emotion/styled/macro"

type ModalProps = {
  images: api.products.variations.Get.Image[]
  selectedImage: api.products.variations.Get.Image
  closeAction: (imageIndex: number) => void
}

export function ImageVideoGalleryModal({
  images: initialImages,
  selectedImage: initialSelectedImage,
  closeAction,
}: ModalProps) {
  const scrolling = useRef(0)

  const screenSize = useScreenSize()

  const [currentSwipeImageIndex, setCurrentSwipeImageIndex] = useState(
    initialImages.indexOf(initialSelectedImage) + 1
  )

  const { swipeContainerRef } = useSwipeScrollTo(
    initialSelectedImage,
    initialImages
  )

  const {
    items: images,
    selectedItem: selectedImage,
    handleNext,
    handlePrev,
    selectedItemIndex: selectedImageIndex,
  } = useCarrousel(initialImages, initialSelectedImage)

  const isSingleImage = images.length === 1

  return (
    <Modal>
      <Background />
      <Container>
        <Header>
          {!isSingleImage && (
            <IndexText>{`${
              screenSize.isDesktop
                ? selectedImageIndex + 1
                : currentSwipeImageIndex
            }/${images.length}`}</IndexText>
          )}
          <Button
            onClick={() =>
              closeAction(
                screenSize.isDesktop
                  ? selectedImageIndex
                  : currentSwipeImageIndex - 1
              )
            }
          >
            <CloseIcon>
              <Close />
            </CloseIcon>
          </Button>
        </Header>
        {screenSize.isDesktop && !isSingleImage ? (
          <Gallery>
            <Button onClick={() => handlePrev()}>
              <IconLeft>
                <Chevron />
              </IconLeft>
            </Button>
            <Image src={selectedImage.thumbnails.large} />
            <Button onClick={() => handleNext()}>
              <IconRight>
                <Chevron />
              </IconRight>
            </Button>
          </Gallery>
        ) : (
          <MobileGallery
            columnsCount={images.length}
            screenWidth={screenSize.innerWidth}
            id="swipe-modal-container"
            onScroll={handleScroll}
            ref={swipeContainerRef}
          >
            {images.map(image => {
              return (
                <MobileLayer key={image.id}>
                  <Image src={image.thumbnails.medium} />
                </MobileLayer>
              )
            })}
          </MobileGallery>
        )}
      </Container>
    </Modal>
  )

  function handleScroll(e: React.UIEvent<HTMLElement>) {
    clearTimeout(scrolling.current)

    const { scrollWidth, scrollLeft } = e.currentTarget

    scrolling.current = window.setTimeout(
      () => updateCurrentImageIndex({ scrollWidth, scrollLeft }),
      50 // Minimum duration without breaking the swipe feature
    )
  }

  function updateCurrentImageIndex({
    scrollWidth,
    scrollLeft,
  }: {
    scrollWidth: number
    scrollLeft: number
  }) {
    const elementWidth = scrollWidth / images.length
    const currentScrollIndex = Math.ceil(scrollLeft / elementWidth)
    const scrollPositionAtCurrentElement =
      1 + (scrollLeft - elementWidth * currentScrollIndex) / elementWidth

    const newScrollIndex =
      scrollPositionAtCurrentElement > 0.5
        ? currentScrollIndex + 1
        : currentScrollIndex

    if (newScrollIndex !== currentSwipeImageIndex) {
      setCurrentSwipeImageIndex(newScrollIndex)
    }
  }
}

const Modal = styled.div({
  zIndex: 10000,
  display: "grid",
  position: "fixed",
  left: 0,
  top: 0,
  right: 0,
  gridTemplateRows: "100vh",
  gridTemplateColumns: "100vw",

  [mediaQuery.desktop]: {
    gridTemplateColumns: `auto ${BREAKPOINTS.DESKTOP_MIN}px auto`,
  },
})

const Background = styled.div({
  gridArea: "1 / 1 / -1 / -1",
  backgroundColor: "black",
  opacity: opacity[4],
})

const Container = styled.div({
  display: "grid",
  gridArea: "1 / 1 / -1 / -1",
  gridTemplateRows: "1fr 12fr",
  padding: "6.4rem 1.6rem",
  gridGap: "4rem",
  zIndex: 100,

  [mediaQuery.desktop]: {
    padding: "3.6rem 6.4rem",
    gridColumnEnd: 3,
    gridColumnStart: 2,
  },
})

const Header = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
})

const IndexText = styled.div<PropsWithTheme>(props => ({
  color: colors.white,
  fontSize: "1.6rem",
  fontFamily: "Open Sans, sans-serif",

  [mediaQuery.desktop]: {
    fontSize: "2.4rem",
  },
}))

const Button = styled.button({
  display: "flex",
  alignSelf: "center",
  background: "transparent",
  color: colors.white,
  fontSize: "4rem",
  cursor: "pointer",
  border: "none",
  padding: 0,
})

const Gallery = styled.div({
  display: "grid",
  gridTemplateColumns: "auto 1fr auto",
  alignSelf: "center",
  height: "100%",
  minHeight: 0,
})

type MobileGalleryProps = {
  screenWidth: number
  columnsCount: number
}

const MobileGallery = styled.div<MobileGalleryProps>(props => ({
  display: "grid",
  gridAutoColumns: `calc(${props.screenWidth}px - 1.6rem)`,
  gridAutoFlow: "column",
  gridTemplateRows: "100%",
  overflowY: "hidden",
  scrollSnapType: "x mandatory",

  // Hide scrollbars without losing scroll ability
  msOverflowStyle: "none", // IE
  "::-webkit-scrollbar": {
    // Chrome
    display: "none",
  },
}))

const MobileLayer = styled.div({
  width: "calc(100vw - 3.2rem)",
  scrollSnapAlign: "center",
})

const Image = styled.img({
  height: "100%",
  maxWidth: "100%",
  objectFit: "contain",
  alignSelf: "center",
  justifySelf: "center",
  minWidth: 0,
  overflow: "hidden",
})

const CloseIcon = styled.span({
  color: colors.white,
  fontSize: "1.8rem",
})

const IconLeft = styled.span({
  transform: "rotate(90deg)",
  color: colors.white,
  fontSize: "1.8rem",
  filter: "drop-shadow(0 2px 2px hsla(0, 0%, 0%, 0.2))",
})

const IconRight = styled(IconLeft)({
  transform: "rotate(-90deg)",
})
