export const colors = {
  black: "black",
  white: "hsl(0, 0%, 100%)",
  greyLightest: "hsl(0, 0%, 98%)",
  greyLighter: "hsl(0, 0%, 96%)",
  greyLight: "hsl(0, 0%, 93%)",
  greyDark: "hsl(0, 1%, 16%)",
  greyMedium: "hsl(0, 0%, 35%)",
  greyMain: "hsl(0, 0%, 72%)",
  greyMainLight: "hsl(0, 0%, 86%)",
  rtGreenDark: "hsl(165, 46%, 52%)",
  rtGreenMain: "hsl(165, 57%, 65%)",
  rtGreenLight: "hsl(165, 58%, 75%)",
  rtGreenLighter: "hsl(165, 75%, 91%)",
  greenDark: "hsl(135, 45%, 36%)",
  greenMain: "hsl(111, 43%, 58%)",
  greenLight: "hsl(111, 55%, 81%)",
  greenLighter: "hsl(137, 50%, 93%)",
  blueDark: "hsl(207, 66%, 40%)",
  blueMain: "hsl(207, 66%, 55%)",
  blueLight: "hsl(207, 83%, 80%)",
  blueLighter: "hsl(188, 60%, 95%)",
  redDark: "hsl(358, 67%, 45%)",
  redMain: "hsl(358, 84%, 62%)",
  redLight: "hsl(357, 100%, 85%)",
  redLighter: "hsl(0, 76%, 95%)",
  yellowDark: "hsl(27, 100%, 48%)",
  yellowMain: "hsl(42, 100%, 67%)",
  yellowLight: "hsl(41, 100%, 84%)",
  yellowLighter: "hsl(53, 100%, 93%)",
  orangeDark: "hsl(14, 89%, 65%)",
  orangeMain: "hsl(14, 100%, 76%)",
  orangeLight: "hsl(14, 95%, 83%)",
  orangeLighter: "hsl(14, 100%, 91%)",
  lavenderDark: "hsl(224, 56%, 63%)",
  lavenderMain: "hsl(224, 65%, 73%)",
  lavenderLight: "hsl(224, 58%, 81%)",
  lavenderLighter: "hsl(225, 67%, 88%)",
  violetDark: "hsl(261, 53%, 55%)",
  violetMain: "hsl(260, 61%, 68%)",
  violetLight: "hsl(261, 72%, 79%)",
  violetLighter: "hsl(260, 78%, 87%)",
}

export const opacity = [0, 0.2, 0.4, 0.6, 0.8, 1]

const shadowKeyUmbraOpacity = 0.2
const shadowKeyPenumbraOpacity = 0.14
const shadowAmbientShadowOpacity = 0.12
function createShadow(...px: number[]) {
  return [
    `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px rgba(0,0,0,${shadowKeyUmbraOpacity})`,
    `${px[4]}px ${px[5]}px ${px[6]}px ${px[7]}px rgba(0,0,0,${shadowKeyPenumbraOpacity})`,
    `${px[8]}px ${px[9]}px ${px[10]}px ${px[11]}px rgba(0,0,0,${shadowAmbientShadowOpacity})`,
  ].join(",")
}

// This constant will be improved in the future
export const ecommerceColors = {
  grey: "hsl(0, 0%, 64%)",
  greyDark: "hsl(0, 0%, 45%)",
}

export const shadows = [
  "none",
  createShadow(0, 2, 1, -1, 0, 1, 1, 0, 0, 1, 3, 0),
  createShadow(0, 3, 1, -2, 0, 2, 2, 0, 0, 1, 5, 0),
  createShadow(0, 3, 3, -2, 0, 3, 4, 0, 0, 1, 8, 0),
  createShadow(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
  createShadow(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
  createShadow(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
  createShadow(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
  createShadow(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
  createShadow(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
  createShadow(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3),
  createShadow(0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3),
  createShadow(0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4),
  createShadow(0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4),
  createShadow(0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4),
  createShadow(0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5),
  createShadow(0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5),
  createShadow(0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5),
  createShadow(0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6),
  createShadow(0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6),
  createShadow(0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7),
  createShadow(0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7),
  createShadow(0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7),
  createShadow(0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8),
  createShadow(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8),
]

export const BREAKPOINTS = {
  MOBILE_MIN: 320,
  MOBILE_MAX: 1023,
  DESKTOP_MIN: 1024,
}

export const HEADER_HEIGHT = {
  MOBILE: 78,
  DESKTOP: 180,
}

export const mediaQuery = {
  desktop: `@media (min-width: ${BREAKPOINTS.DESKTOP_MIN}px)`,
}
