export function Tracking() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <defs>
        <clipPath id="prefix__a">
          <path
            d="M117.387 626.214l2.691 4.707-7.466 4.272-2.691-4.707zm13.456-7.757l2.691 4.707-7.466 4.27-2.691-4.706zm13.63-7.845l2.69 4.707-7.465 4.27-2.692-4.706zm-13.37 36.958a.802.802 0 00-1.042-.261l-15.018 8.629-8.768-15.428 6.337-3.661 8.768-5.056 4.775-2.702 8.768-5.056 4.862-2.79 8.768-5.055 6.337-3.66 8.768 15.428-8.334 4.794a.81.81 0 00-.26 1.046.655.655 0 00.607.348.52.52 0 00.348-.087l9.028-5.143a.87.87 0 00.347-.435.578.578 0 00-.086-.523l-9.463-16.649a.867.867 0 00-.434-.349.572.572 0 00-.52.087l-6.338 3.661-3.039-5.404a.802.802 0 00-1.042-.261l-8.768 5.055a.87.87 0 00-.347.436.578.578 0 00.087.523l3.038 5.404-3.559 2.092-3.038-5.404a.867.867 0 00-.434-.348.572.572 0 00-.521.087l-8.769 5.055a.81.81 0 00-.26 1.046l3.039 5.405-3.473 2.004-3.038-5.404a.867.867 0 00-.435-.349.572.572 0 00-.52.088l-8.769 5.055a.87.87 0 00-.347.436.578.578 0 00.087.523l3.038 5.404-6.337 3.661a.87.87 0 00-.347.436.578.578 0 00.087.523l9.462 16.649a.655.655 0 00.608.349.52.52 0 00.347-.088l15.627-8.978a.946.946 0 00.173-1.133z"
            fill="#292828"
          />
        </clipPath>
        <clipPath id="prefix__b">
          <path
            d="M172.162 662.393l-39.939-6.781 1.556-9.39 1.383-7.997 7.175 1.217 2.767.435 7.088 1.217 5.792.956 9.942 1.652 7.175 1.217zm-28.01-29.645l8.473 1.39v.262l-.865 5.129-6.397-1.043-2.075-.348zm15.734 2.608l8.472 1.39-.864 5.39-8.472-1.39zm16.166 8.259l-7.175-1.217 1.037-6.086a.716.716 0 00-.605-.87l-9.941-1.65a.713.713 0 00-.865.608l-1.037 6.085-4.41-.695.865-5.13.173-.956c0-.174 0-.434-.086-.521a.522.522 0 00-.519-.261l-9.941-1.652c-.173 0-.433 0-.519.087a.526.526 0 00-.26.522l-1.037 6.085-7.175-1.217c-.173 0-.432 0-.519.087a.526.526 0 00-.259.521l-1.47 8.78-1.728 10.085a.716.716 0 00.605.87l41.408 6.954h.086c.173 0 .26-.087.433-.173a.526.526 0 00.259-.522l3.112-18.865c.26-.435 0-.782-.432-.87z"
            fill="#292828"
          />
        </clipPath>
        <clipPath id="prefix__c">
          <path
            d="M165.447 658.352a.73.73 0 00.6.887l3.513.621h.085a.67.67 0 00.686-.62l2.056-12.422a.73.73 0 00-.6-.887.709.709 0 00-.856.621l-1.885 11.711-2.828-.532a.688.688 0 00-.771.621z"
            fill="#292828"
          />
        </clipPath>
        <clipPath id="prefix__d">
          <path
            d="M164.124 657.928l-1.179-.146a.82.82 0 00-.982.51c-.099.362.196.653.687.726l1.18.145h.098c.393 0 .786-.218.786-.509.098-.363-.197-.654-.59-.726z"
            fill="#292828"
          />
        </clipPath>
        <clipPath id="prefix__e">
          <path
            d="M40.022 548.607a9.671 9.671 0 01-5.596-1.786l-3.908 1.332 1.27-4.006a11.177 11.177 0 01-1.938-6.325q0-.524.05-1.046a10.445 10.445 0 0110.218-9.74c5.246.054 9.594 4.36 10.042 9.945.023.278.034.557.034.841-.03 5.978-4.58 10.803-10.175 10.786zm12.09-11.11c-.215-6.926-5.5-12.44-11.984-12.502s-11.862 5.346-12.195 12.266c-.006.187-.011.375-.011.563a13.396 13.396 0 001.733 6.626l-2.182 6.882 6.703-2.275a11.442 11.442 0 0011.953-.178 13.13 13.13 0 005.99-11.055c-.002-.11-.002-.219-.008-.328z"
            fill="#fff"
            clipRule="evenodd"
          />
        </clipPath>
        <clipPath id="prefix__f">
          <path
            d="M45.564 540.414c-.3-.157-1.754-.92-2.028-1.023s-.47-.157-.668.157-.77 1.023-.94 1.229-.345.236-.646.079a7.947 7.947 0 01-2.384-1.562 9.323 9.323 0 01-1.648-2.172.479.479 0 01.13-.642c.136-.145.295-.37.447-.55l.108-.152a3.627 3.627 0 00.188-.375.604.604 0 00-.023-.551c-.073-.157-.668-1.707-.917-2.336s-.493-.52-.669-.52-.368-.03-.563-.03a1.07 1.07 0 00-.793.393 3.642 3.642 0 00-1.037 2.626 4.27 4.27 0 00.153 1.071 7.326 7.326 0 001.06 2.179c.146.205 2.05 3.485 5.062 4.752 3.02 1.252 3.02.834 3.563.78a2.971 2.971 0 002-1.494 2.763 2.763 0 00.175-1.496c-.078-.128-.277-.206-.57-.363z"
            fill="#fff"
            clipRule="evenodd"
          />
        </clipPath>
        <clipPath id="prefix__g">
          <path
            d="M105.022 548.607a9.671 9.671 0 01-5.596-1.786l-3.908 1.332 1.27-4.006a11.177 11.177 0 01-1.938-6.325q0-.524.05-1.046a10.445 10.445 0 0110.218-9.74c5.246.054 9.594 4.36 10.042 9.945.023.278.034.557.034.841-.03 5.978-4.58 10.803-10.175 10.786zm12.09-11.11c-.215-6.926-5.5-12.44-11.984-12.502s-11.862 5.346-12.195 12.266c-.006.187-.011.375-.011.563a13.396 13.396 0 001.733 6.626l-2.182 6.882 6.703-2.275a11.442 11.442 0 0011.953-.178 13.13 13.13 0 005.99-11.055c-.002-.11-.002-.219-.008-.328z"
            fill="#fff"
            clipRule="evenodd"
          />
        </clipPath>
        <clipPath id="prefix__h">
          <path
            d="M110.564 540.414c-.3-.157-1.754-.92-2.028-1.023s-.47-.157-.668.157-.77 1.023-.94 1.229-.345.236-.646.079a7.947 7.947 0 01-2.384-1.562 9.323 9.323 0 01-1.648-2.172.479.479 0 01.13-.642c.136-.145.295-.37.447-.55l.108-.152a3.627 3.627 0 00.188-.375.604.604 0 00-.023-.551c-.073-.157-.668-1.707-.917-2.336s-.493-.52-.669-.52-.368-.03-.563-.03a1.07 1.07 0 00-.793.393 3.642 3.642 0 00-1.037 2.626 4.27 4.27 0 00.153 1.071 7.326 7.326 0 001.06 2.179c.146.205 2.05 3.485 5.062 4.752 3.02 1.252 3.02.834 3.563.78a2.971 2.971 0 002-1.494 2.763 2.763 0 00.175-1.496c-.078-.128-.277-.206-.57-.363z"
            fill="#fff"
            clipRule="evenodd"
          />
        </clipPath>
      </defs>
      <path
        d="M117.387 626.214l2.691 4.707-7.466 4.272-2.691-4.707zm13.456-7.757l2.691 4.707-7.466 4.27-2.691-4.706zm13.63-7.845l2.69 4.707-7.465 4.27-2.692-4.706zm-13.37 36.958a.802.802 0 00-1.042-.261l-15.018 8.629-8.768-15.428 6.337-3.661 8.768-5.056 4.775-2.702 8.768-5.056 4.862-2.79 8.768-5.055 6.337-3.66 8.768 15.428-8.334 4.794a.81.81 0 00-.26 1.046.655.655 0 00.607.348.52.52 0 00.348-.087l9.028-5.143a.87.87 0 00.347-.435.578.578 0 00-.086-.523l-9.463-16.649a.867.867 0 00-.434-.349.572.572 0 00-.52.087l-6.338 3.661-3.039-5.404a.802.802 0 00-1.042-.261l-8.768 5.055a.87.87 0 00-.347.436.578.578 0 00.087.523l3.038 5.404-3.559 2.092-3.038-5.404a.867.867 0 00-.434-.348.572.572 0 00-.521.087l-8.769 5.055a.81.81 0 00-.26 1.046l3.039 5.405-3.473 2.004-3.038-5.404a.867.867 0 00-.435-.349.572.572 0 00-.52.088l-8.769 5.055a.87.87 0 00-.347.436.578.578 0 00.087.523l3.038 5.404-6.337 3.661a.87.87 0 00-.347.436.578.578 0 00.087.523l9.462 16.649a.655.655 0 00.608.349.52.52 0 00.347-.088l15.627-8.978a.946.946 0 00.173-1.133z"
        fill="#292828"
      />
      <g clipPath="url(#prefix__a)">
        <path fill="#292828" d="M-469.138-53.506h714.064v1621.984h-714.064z" />
      </g>
      <path
        d="M172.162 662.393l-39.939-6.781 1.556-9.39 1.383-7.997 7.175 1.217 2.767.435 7.088 1.217 5.792.956 9.942 1.652 7.175 1.217zm-28.01-29.645l8.473 1.39v.262l-.865 5.129-6.397-1.043-2.075-.348zm15.734 2.608l8.472 1.39-.864 5.39-8.472-1.39zm16.166 8.259l-7.175-1.217 1.037-6.086a.716.716 0 00-.605-.87l-9.941-1.65a.713.713 0 00-.865.608l-1.037 6.085-4.41-.695.865-5.13.173-.956c0-.174 0-.434-.086-.521a.522.522 0 00-.519-.261l-9.941-1.652c-.173 0-.433 0-.519.087a.526.526 0 00-.26.522l-1.037 6.085-7.175-1.217c-.173 0-.432 0-.519.087a.526.526 0 00-.259.521l-1.47 8.78-1.728 10.085a.716.716 0 00.605.87l41.408 6.954h.086c.173 0 .26-.087.433-.173a.526.526 0 00.259-.522l3.112-18.865c.26-.435 0-.782-.432-.87z"
        fill="#292828"
      />
      <g clipPath="url(#prefix__b)">
        <path fill="#292828" d="M-469.138-53.506h714.064v1621.984h-714.064z" />
      </g>
      <path
        d="M165.447 658.352a.73.73 0 00.6.887l3.513.621h.085a.67.67 0 00.686-.62l2.056-12.422a.73.73 0 00-.6-.887.709.709 0 00-.856.621l-1.885 11.711-2.828-.532a.688.688 0 00-.771.621z"
        fill="#292828"
      />
      <g clipPath="url(#prefix__c)">
        <path fill="#292828" d="M-469.138-53.506h714.064v1621.984h-714.064z" />
      </g>
      <path
        d="M164.124 657.928l-1.179-.146a.82.82 0 00-.982.51c-.099.362.196.653.687.726l1.18.145h.098c.393 0 .786-.218.786-.509.098-.363-.197-.654-.59-.726z"
        fill="#292828"
      />
      <g clipPath="url(#prefix__d)">
        <path fill="#292828" d="M-469.138-53.506h714.064v1621.984h-714.064z" />
      </g>
      <path
        d="M40.022 548.607a9.671 9.671 0 01-5.596-1.786l-3.908 1.332 1.27-4.006a11.177 11.177 0 01-1.938-6.325q0-.524.05-1.046a10.445 10.445 0 0110.218-9.74c5.246.054 9.594 4.36 10.042 9.945.023.278.034.557.034.841-.03 5.978-4.58 10.803-10.175 10.786zm12.09-11.11c-.215-6.926-5.5-12.44-11.984-12.502s-11.862 5.346-12.195 12.266c-.006.187-.011.375-.011.563a13.396 13.396 0 001.733 6.626l-2.182 6.882 6.703-2.275a11.442 11.442 0 0011.953-.178 13.13 13.13 0 005.99-11.055c-.002-.11-.002-.219-.008-.328z"
        fill="#fff"
        fillRule="evenodd"
      />
      <g clipPath="url(#prefix__e)">
        <path fill="#fff" d="M-467.677-127.915H718.942v1489.594H-467.677z" />
      </g>
      <path
        d="M45.564 540.414c-.3-.157-1.754-.92-2.028-1.023s-.47-.157-.668.157-.77 1.023-.94 1.229-.345.236-.646.079a7.947 7.947 0 01-2.384-1.562 9.323 9.323 0 01-1.648-2.172.479.479 0 01.13-.642c.136-.145.295-.37.447-.55l.108-.152a3.627 3.627 0 00.188-.375.604.604 0 00-.023-.551c-.073-.157-.668-1.707-.917-2.336s-.493-.52-.669-.52-.368-.03-.563-.03a1.07 1.07 0 00-.793.393 3.642 3.642 0 00-1.037 2.626 4.27 4.27 0 00.153 1.071 7.326 7.326 0 001.06 2.179c.146.205 2.05 3.485 5.062 4.752 3.02 1.252 3.02.834 3.563.78a2.971 2.971 0 002-1.494 2.763 2.763 0 00.175-1.496c-.078-.128-.277-.206-.57-.363z"
        fill="#fff"
        fillRule="evenodd"
      />
      <g clipPath="url(#prefix__f)">
        <path fill="#fff" d="M-467.677-127.915H718.942v1489.594H-467.677z" />
      </g>
      <path
        d="M105.022 548.607a9.671 9.671 0 01-5.596-1.786l-3.908 1.332 1.27-4.006a11.177 11.177 0 01-1.938-6.325q0-.524.05-1.046a10.445 10.445 0 0110.218-9.74c5.246.054 9.594 4.36 10.042 9.945.023.278.034.557.034.841-.03 5.978-4.58 10.803-10.175 10.786zm12.09-11.11c-.215-6.926-5.5-12.44-11.984-12.502s-11.862 5.346-12.195 12.266c-.006.187-.011.375-.011.563a13.396 13.396 0 001.733 6.626l-2.182 6.882 6.703-2.275a11.442 11.442 0 0011.953-.178 13.13 13.13 0 005.99-11.055c-.002-.11-.002-.219-.008-.328z"
        fill="#fff"
        fillRule="evenodd"
      />
      <g clipPath="url(#prefix__g)">
        <path fill="#fff" d="M-402.677-127.915H783.942v1489.594H-402.677z" />
      </g>
      <path
        d="M110.564 540.414c-.3-.157-1.754-.92-2.028-1.023s-.47-.157-.668.157-.77 1.023-.94 1.229-.345.236-.646.079a7.947 7.947 0 01-2.384-1.562 9.323 9.323 0 01-1.648-2.172.479.479 0 01.13-.642c.136-.145.295-.37.447-.55l.108-.152a3.627 3.627 0 00.188-.375.604.604 0 00-.023-.551c-.073-.157-.668-1.707-.917-2.336s-.493-.52-.669-.52-.368-.03-.563-.03a1.07 1.07 0 00-.793.393 3.642 3.642 0 00-1.037 2.626 4.27 4.27 0 00.153 1.071 7.326 7.326 0 001.06 2.179c.146.205 2.05 3.485 5.062 4.752 3.02 1.252 3.02.834 3.563.78a2.971 2.971 0 002-1.494 2.763 2.763 0 00.175-1.496c-.078-.128-.277-.206-.57-.363z"
        fill="#fff"
        fillRule="evenodd"
      />
      <g clipPath="url(#prefix__h)">
        <path fill="#fff" d="M-402.677-127.915H783.942v1489.594H-402.677z" />
      </g>
      <path
        d="M14.475 2.923a1.556 1.556 0 101.54 1.557 1.55 1.55 0 00-1.54-1.557zm.017 2.525a.953.953 0 01-.959-.968.96.96 0 11.959.968z"
        fill="#727272"
      />
      <path
        d="M14.475.931a3.534 3.534 0 00-3.512 3.549c0 .994.89 3.083 2.574 6.042a1.106 1.106 0 00.938.546 1.065 1.065 0 00.938-.546c1.684-2.958 2.574-5.048 2.574-6.042A3.534 3.534 0 0014.475.93zm.337 9.255a.357.357 0 01-.337.192.396.396 0 01-.336-.19c-1.585-2.755-2.495-4.835-2.495-5.708a2.83 2.83 0 115.661 0c0 .856-.908 2.935-2.493 5.706zM2.994 10.92a1.38 1.38 0 101.365 1.379 1.374 1.374 0 00-1.365-1.38zm.014 2.155a.765.765 0 01-.769-.776.77.77 0 11.769.776z"
        fill="#727272"
      />
      <path
        d="M2.994 9.262a3.026 3.026 0 00-3.007 3.037c0 .85.761 2.634 2.2 5.162a.948.948 0 00.807.47.916.916 0 00.806-.469C5.24 14.934 6 13.15 6 12.3a3.026 3.026 0 00-3.006-3.037zm.282 7.907a.3.3 0 01-.282.16.332.332 0 01-.282-.16C1.359 14.82.584 13.045.584 12.3a2.425 2.425 0 012.41-2.434 2.425 2.425 0 012.41 2.434c0 .73-.776 2.505-2.128 4.87zM11.814 12.767h-1.629a1.917 1.917 0 010-3.833h.626a.31.31 0 000-.621h-.626a2.518 2.518 0 000 5.037h1.629a1.917 1.917 0 010 3.833H5.627a.31.31 0 000 .621h6.187a2.518 2.518 0 000-5.037z"
        fill="#727272"
      />
    </svg>
  )
}
