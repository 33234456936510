import { Container } from "./Container"

export function RedirectMP() {
  return (
    <Container>
      <path
        d="M8.81,13.2539H2.9964a1.6842,1.6842,0,0,1-1.6828-1.6828V3.31A1.6842,1.6842,0,0,1,2.9964,1.6272h9.485A1.6842,1.6842,0,0,1,14.1642,3.31V7.2876a.459.459,0,0,1-.9179,0V3.31a.766.766,0,0,0-.7649-.7649H2.9964a.766.766,0,0,0-.7649.7649v8.2611a.766.766,0,0,0,.7649.7649H8.81a.459.459,0,0,1,0,.9179Z"
        fill="currentColor"
      />
      <path
        d="M13.7052,4.6869H1.7725a.459.459,0,0,1,0-.9179H13.7052a.459.459,0,1,1,0,.9179Z"
        fill="currentColor"
      />
      <path
        d="M10.6456,16.3136a.4591.4591,0,0,1-.459-.4589V14.7838a4.1353,4.1353,0,0,1,4.1306-4.1306h.7649a.459.459,0,0,1,0,.9179h-.7649a3.2162,3.2162,0,0,0-3.2127,3.2127v1.0709A.4591.4591,0,0,1,10.6456,16.3136Z"
        fill="currentColor"
      />
      <path
        d="M13.7052,13.2539a.459.459,0,0,1-.31-.7973l1.4668-1.3444L13.3956,9.7678a.4591.4591,0,0,1,.62-.6768l1.8358,1.6828a.46.46,0,0,1-.0006.6768l-1.8358,1.6828A.4566.4566,0,0,1,13.7052,13.2539Z"
        fill="currentColor"
      />
    </Container>
  )
}
