import {
  BreadcrumbContainer,
  Container,
  Grid,
  PaginatorContainer,
  ProductsContainer,
  ProductsList,
  SidebarandProductsContainer,
  SortingPane,
  SpinnerContainer,
} from "./styles"

import { Breadcrumb } from "../../../../components/Breadcrumb"
import { NoProductsCard } from "../NoProductsCard"
import { Paginator } from "../Paginator"
import { ProductBox } from "../ProductBox"
import { Sidebar } from "./Sidebar"
import { SortingSelector } from "../SortingSelector"
import { Spinner } from "../../../../icons"
import { WhatsAppButton } from "../../../../components/WhatsAppButton"
import { toLocalePrice } from "../../../../utils/PriceUtil"
import { useMeta } from "../../hooks/useMeta"
import { useProducts } from "../../hooks/useProducts"
import { useProductsStore } from "../../provider/ProductStoreProvider"
import { useScrollToTop } from "../../hooks/useScrollToTop"
import { useStoreState } from "../../../../providers/StoreProvider"

type Props = {
  categoryPath: string
}

export function Desktop({ categoryPath }: Props) {
  const whatsAppButtonInfo = useStoreState()[0].config.whatsapp
  const default_limit_desktop = 12
  const { status } = useProducts({
    categoryPath,
    limit: default_limit_desktop,
  })
  const { meta, products } = useProductsStore()
  const { breadcrumbLinks } = useMeta()
  useScrollToTop(status)

  if ((status === "success" && meta.totalCount === 0) || status === "error") {
    return (
      <Grid>
        <Container>
          <NoProductsCard status={status} />
        </Container>
      </Grid>
    )
  }

  return (
    <Grid>
      {status === "fetching" ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <Container>
          {breadcrumbLinks.length > 0 && (
            <BreadcrumbContainer>
              <Breadcrumb links={breadcrumbLinks} />
            </BreadcrumbContainer>
          )}
          <SidebarandProductsContainer>
            <Sidebar />
            <ProductsList>
              <SortingPane>
                <SortingSelector />
              </SortingPane>
              <ProductsContainer>
                {products.map(product => (
                  <ProductBox
                    key={product.identifier}
                    productId={product.identifier}
                    thumbnail={product.coverImage}
                    title={product.title}
                    price={toLocalePrice(product.minPrice, product.currency)}
                    showLabelFrom={product.minPrice !== product.maxPrice}
                    stock={product.maxStock}
                    freeShipping={product.freeShipping}
                  />
                ))}
              </ProductsContainer>
              <PaginatorContainer>
                <Paginator />
              </PaginatorContainer>
            </ProductsList>
          </SidebarandProductsContainer>
          {whatsAppButtonInfo?.showWhatsappButton && (
            <WhatsAppButton
              countryCode={whatsAppButtonInfo?.countryCode || ""}
              phoneNumber={whatsAppButtonInfo?.phoneNumber || ""}
              description={whatsAppButtonInfo.description || ""}
            />
          )}
        </Container>
      )}
    </Grid>
  )
}
