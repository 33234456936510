import { RequestBodyRaw } from "../../../../stores/checkout/put/types"
import { delay } from "../../../utils"
import { rest } from "msw"

export const checkout = [
  rest.get(
    /\/v1\/stores\/[\w-]+\/checkout\/([\w-]+\/)?/,
    function (_req, res, ctx) {
      const sessionStep = getSessionStep()

      const _delay = delay(ctx)
      let response

      switch (sessionStep) {
        case "contactZ":
          response = ctx.json(require("./contact/fixture.contactZ.json"))
          break
        case "contactS":
          response = ctx.json(require("./contact/fixture.contactS.json"))
          break
        case "contactP":
          response = ctx.json(require("./contact/fixture.contactP.json"))
          break
        case "contactZPaymentFailure":
          response = ctx.json(
            require("./contact/fixture.contactZ.paymentFail.json")
          )
          break
        case "dataCompleteM":
          response = ctx.json(
            require("./dataComplete/fixture.dataCompleteM.json")
          )
          break
        case "dataCompleteC":
          response = ctx.json(
            require("./dataComplete/fixture.dataCompleteC.json")
          )
          break
        case "orderCompleteM":
          response = ctx.json(
            require("./orderComplete/fixture.orderCompleteM.json")
          )
          break
        case "orderCompleteC":
          response = ctx.json(
            require("./orderComplete/fixture.shippingP.paymentC.json")
          )
          break

        default:
          response = ctx.json(require("./fixture.initial.json"))
      }

      return res(_delay, response)
    }
  ),
  rest.put<RequestBodyRaw>(
    "*/v1/stores/:storeId/checkout/",
    function (req, res, ctx) {
      const _delay = delay(ctx)

      if (getSimmulate400()) {
        return res(
          _delay,
          ctx.status(400),
          ctx.json(require("./contact/fixture.postalCode.error.json"))
        )
      }

      let response = ctx.json(require("./fixture.initial.json"))

      switch (req.body.checkout_step) {
        case "contact":
          setSessionStep("contactZ")
          response = ctx.json(require("./contact/fixture.contactZ.json"))
          break
        case "billing":
          if (req.body.payment.type === "M") {
            setSessionStep("dataCompleteM")
            response = ctx.json(
              require("./dataComplete/fixture.dataCompleteM.json")
            )
          } else {
            setSessionStep("dataCompleteC")
            response = ctx.json(
              require("./dataComplete/fixture.dataCompleteC.json")
            )
          }
          break
      }

      return res(_delay, response)
    }
  ),
  rest.delete("*/v1/stores/:storeId/checkout/", function (_req, res, ctx) {
    setSessionStep()
    return res(delay(ctx), ctx.json(require("./delete.fixture.json")))
  }),
]

const MOCK_STEP_CHECKOUT = "MOCK_STEP_CHECKOUT"
const SIM_RESPONSE_400 = "SIM_RESPONSE_400"

export function setSimulate400(set: boolean) {
  if (set) {
    window.sessionStorage.setItem(SIM_RESPONSE_400, "on")
  } else {
    window.sessionStorage.setItem(SIM_RESPONSE_400, "off")
  }
}

export function getSimmulate400() {
  const simulate = window.sessionStorage.getItem(SIM_RESPONSE_400)

  if (simulate === "on") {
    return true
  }
  return false
}

type SessionStep =
  | "contactZ"
  | "contactS"
  | "contactP"
  | "contactZPaymentFailure"
  | "dataCompleteM"
  | "dataCompleteC"
  | "orderCompleteM"
  | "orderCompleteC"

export function getSessionStep() {
  return window.sessionStorage.getItem(MOCK_STEP_CHECKOUT) as SessionStep | null
}

export function setSessionStep(step?: SessionStep) {
  if (step == null) {
    window.sessionStorage.removeItem(MOCK_STEP_CHECKOUT)
  } else {
    window.sessionStorage.setItem(MOCK_STEP_CHECKOUT, step)
  }
}
