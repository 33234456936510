import { colors, mediaQuery } from "../../theme"
import { useModal, useScreenBlockers } from "../../hooks"

import { Close } from "../../icons"
import { PropsWithTheme } from "../../providers/StoreThemeProvider"
import styled from "@emotion/styled/macro"
import { useLayoutEffect } from "react"

export function Modal() {
  const { state, actions } = useModal()
  const { register, unregister } = useScreenBlockers("modal")

  useLayoutEffect(() => {
    if (state.open) {
      register()
    } else {
      unregister()
    }
  }, [state.open, register, unregister])

  const handleClose = () => {
    if (typeof state.onClose === "function") state.onClose()
    actions.closeModal()
  }

  return state.open ? (
    <Container>
      <ModalBox disableMobile={state.disableMobile} size={state.size}>
        <Header>
          <div>{state.title}</div>
          {!state.disableCloseButton && (
            <CloseButton onClick={handleClose} data-testid="close-button">
              <Icon>
                <Close />
              </Icon>
            </CloseButton>
          )}
        </Header>
        <Separator />
        <div>{state.body}</div>
      </ModalBox>
    </Container>
  ) : null
}

const Container = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.65)",
  zIndex: 90000,
})

type ModalBoxProps = {
  disableMobile?: boolean
  size?: "large" | "medium"
}

const ModalBox = styled.div<ModalBoxProps>(props => ({
  position: "fixed",
  backgroundColor: colors.white,
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  padding: "2rem 1.6rem",
  maxHeight: "95%",
  overflowY: "auto",

  ...(props.disableMobile
    ? {
        width: "90%",
        height: "auto",
        borderRadius: "0.4rem",
      }
    : {
        width: "100%",
        height: "100%",
        borderRadius: 0,
      }),

  [mediaQuery.desktop]: {
    ...(props.size === "large" || props.size == null
      ? { width: "60rem" }
      : { width: "44.4rem" }),
    height: "auto",
    borderRadius: "0.4rem",
    padding: "2.4rem",
  },
}))

const Header = styled.div({
  width: "100%",
  position: "relative",
})

const Separator = styled.hr<PropsWithTheme>(props => ({
  display: "block",
  height: 1,
  border: 0,
  borderTop: `1px solid ${props.theme.colors.primary}`,
  marginTop: "0.8rem",
  marginBottom: 0,
  padding: 0,
}))

const CloseButton = styled.button({
  position: "absolute",
  right: 0,
  cursor: "pointer",
  backgroundColor: "transparent",
  padding: 0,
  margin: 0,
  border: "none",
  outline: "none",
  width: "2.8rem",
  height: "2.8rem",
  top: 0,

  "&:hover": {
    borderRadius: "50%",
    backgroundColor: colors.greyMainLight,
  },
})

const Icon = styled.div<PropsWithTheme>(props => ({
  textAlign: "center",
  fontSize: "1.6rem",
  color: colors.greyMedium,
  lineHeight: 0,

  [mediaQuery.desktop]: {
    fontsize: "1.8rem",
  },
}))
