const BASE = [process.env.REACT_APP_API, "v1"].filter(Boolean) as string[]
const STORE_ID = window.STORE_ID

const products = (productId: string) => [...BASE, "products", productId]

const variations = (productID: string) => [...products(productID), "variations"]

const paymentMethods = () => [...BASE, STORE_ID, "payment_methods"]

const stores = () => [...BASE, "stores", STORE_ID]

const config = () => [...stores(), "config"]

const pickUpPoints = () => [...stores(), "pick-up-points"]

const pageConfig = (pageId: string) => [...stores(), "page-config", pageId]

const checkout = (checkoutId?: string) => [...stores(), "checkout", checkoutId]

const carts = (itemId?: string) => [...stores(), "carts", itemId]

const startCheckout = () => [...carts(), "start_checkout"]

const quote = () => [...stores(), "quote"]

const shippings = () => [...stores(), "shippings"]

const maintenanceMode = () => [...stores(), "maintenance_mode"]

const language = () => [...BASE, "version"]

const paths = {
  products: (productId: string) => toString(products(productId)),
  variations: (productId: string) => toString(variations(productId)),
  paymentMethods: () => toString(paymentMethods()),
  stores: () => toString(stores()),
  config: () => toString(config()),
  pickUpPoints: () => toString(pickUpPoints()),
  pageConfig: (pageId: string) => toString(pageConfig(pageId)),
  checkout: (checkoutId?: string) => toString(checkout(checkoutId)),
  carts: (itemId?: string) => toString(carts(itemId)),
  startCheckout: () => toString(startCheckout()),
  quote: () => toString(quote()),
  shippings: () => toString(shippings()),
  maintenanceMode: () => toString(maintenanceMode()),
  language: () => toString(language()),
}

export default paths

function toString(pathArray: Array<string | undefined>) {
  const cleanPath = pathArray.filter(Boolean)

  if (process.env.NODE_ENV !== "development") {
    pathArray.unshift("")
  }

  cleanPath.push("")

  return cleanPath.join("/")
}
