import { Controller, useFormContext } from "react-hook-form"
import { DualColumnRow, ExternalLink } from "../../../../../../style"
import { Trans, t } from "@lingui/macro"

import { DropdownField } from "../../../../../../../../../../components/DropdownField"
import { ReceiverForm } from "../ReceiverForm"
import { ShippingMethodPData } from "../../../../types"
import { TextField } from "../../../../../../../../../../components/TextField"
import { getLocaleStates } from "../../../../../../../../../../utils/getLocaleStates"
import { i18n } from "@lingui/core"

export const ShippingMethodP = () => {
  const { control, errors } = useFormContext<ShippingMethodPData>()
  const language = i18n.locale

  return (
    <>
      <ReceiverForm
        title={t`¿Quién retira?`}
        fields={{
          receiverPhone: false,
        }}
      />
      <Controller
        control={control}
        name="destinationCity"
        defaultValue=""
        rules={{
          required: t`Completá el campo ciudad.`,
        }}
        render={props => (
          <TextField
            name="destinationCity"
            label={<Trans>Ciudad</Trans>}
            errorMsg={errors.destinationCity?.message}
            maxLength={100}
            hideLength={true}
            {...props}
          />
        )}
      />

      <DualColumnRow>
        <Controller
          control={control}
          name="destinationPostalCode"
          defaultValue=""
          rules={{
            required: t`Completá el campo código postal.`,
          }}
          render={({ onChange, value }) => (
            <TextField
              name="destinationPostalCode"
              label={<Trans>Código Postal</Trans>}
              errorMsg={errors.destinationPostalCode?.message}
              maxLength={language === "es-AR" ? 4 : 5}
              hideLength={true}
              onChange={onChange}
              value={value?.replace(/[^0-9]/, "")}
            />
          )}
        />
        <ExternalLink
          rel="noopener noreferrer"
          href={t`https://www.correoargentino.com.ar/formularios/cpa`}
          target="_blank"
        >
          <Trans>No conozco mi código postal</Trans>
        </ExternalLink>
      </DualColumnRow>

      <Controller
        control={control}
        name="destinationState"
        defaultValue=""
        rules={{
          required: t`Elegí la provincia.`,
        }}
        render={props => (
          <DropdownField
            name="destinationState"
            label={<Trans>Provincia</Trans>}
            options={getLocaleStates()}
            errorMsg={errors.destinationState?.message}
            {...props}
          />
        )}
      />
    </>
  )
}
