import { Container } from "./Container"

export function Close() {
  return (
    <Container>
      <polygon
        points="18 1.8 16.2 0 9 7.2 1.8 0 0 1.8 7.2 9 0 16.2 1.8 18 9 10.8 16.2 18 18 16.2 10.8 9 18 1.8"
        fill="currentColor"
      />
      <path
        d="M-5.2141-367.9684a.7311.7311,0,0,0-1.0338,0l-11.85,11.85-4.6609-4.661a.7309.7309,0,0,0-1.0337.0131.731.731,0,0,0,0,1.0205l5.1779,5.1779a.7311.7311,0,0,0,1.0338,0l12.3667-12.3667A.7311.7311,0,0,0-5.2141-367.9684Z"
        fill="currentColor"
      />
    </Container>
  )
}
