import { arStates, mexStates } from "../constants/states"

import { i18n } from "@lingui/core"

export const getLocaleStates = () => {
  let localeState = i18n.locale === "es-AR" ? arStates : mexStates

  const statesOptions = localeState.map((state, i) => ({
    id: state + i,
    name: state,
    value: state,
  }))

  return statesOptions
}
