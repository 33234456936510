import {
  CarrierName,
  DecoratedText,
  Description,
  DestinationContainer,
  ErrorMsg,
  SingleColumnRow,
  Subtitle,
} from "../../../../../../style"
import { Controller, useFormContext } from "react-hook-form"
import {
  ShippingOptionsC as OptionsC,
  ShippingOptionsZ as OptionsZ,
} from "../../../../../../../../../../api/stores/checkout"
import { Trans, t } from "@lingui/macro"
import { useCheckout, useScreenSize } from "../../../../../../../../../../hooks"

import { ContainerCard } from "../../../../../../../../../../components/ContainerCard"
import { PriceView } from "../../../../../../../../../../components/PriceView"
import { ShippingData } from "../../../../types"
import { ShippingOptionCard } from "../ShippingOptionCard"
import { formatDestinationToString } from "../../helpers/formatDestinationString"
import { toLocalePrice } from "../../../../../../../../../../utils"
import { useCheckoutScreen } from "../../../../../../../../hooks/useCheckoutScreen"
import { useShippingOptions } from "../../hooks/useShippingOptions"

export const ShippingOptionsZ = () => {
  useShippingOptions()
  const { control, errors } = useFormContext<ShippingData>()
  const { setCurrentStep } = useCheckoutScreen()
  const {
    requirements: { shippingOptions },
    shipping,
  } = useCheckout().data.meta

  const { isMobile } = useScreenSize()

  return (
    <ContainerCard title={<Trans>Opciones de envío</Trans>}>
      <SingleColumnRow>
        <Subtitle>
          <Trans>Dónde lo enviamos</Trans>
        </Subtitle>
        <DestinationContainer>
          {formatDestinationToString(shipping?.destination)}
          <DecoratedText onClick={() => setCurrentStep(1)}>
            <Trans>Modificar</Trans>
          </DecoratedText>
        </DestinationContainer>
        <Subtitle>
          <Trans>Opciones de envío</Trans>
        </Subtitle>
        <Controller
          control={control}
          name="shippingOption"
          rules={{
            required: t`Elegí una opción para poder continuar.`,
          }}
          render={({ onChange, value }) => (
            <>
              {(shippingOptions as OptionsZ | OptionsC).options.map(
                shippingOption => {
                  return (
                    <ShippingOptionCard
                      key={shippingOption.id}
                      label={
                        <>
                          {shippingOption.shippingType === "Z" && (
                            <span>
                              <Trans>Llega entre el</Trans>&nbsp;
                              <strong>{shippingOption.arrivalDate}</strong>
                            </span>
                          )}
                          {shippingOption.shippingType === "C" && (
                            <span>
                              {shippingOption.arrivalDate ? (
                                <Trans>{shippingOption.arrivalDate}</Trans>
                              ) : (
                                <Trans>Entrega a convenir</Trans>
                              )}
                            </span>
                          )}
                        </>
                      }
                      hasError={!!errors.shippingOption}
                      value={shippingOption.id}
                      checked={value === shippingOption.id}
                      onChange={onChange}
                      price={
                        shippingOption.shippingType === "C" &&
                        shippingOption.priceType === "T" ? (
                          <Trans>
                            {isMobile ? "A convenir" : "Precio a convenir"}
                          </Trans>
                        ) : shippingOption.price === 0 ? (
                          <Trans>Gratis</Trans>
                        ) : (
                          <PriceView
                            price={toLocalePrice(
                              shippingOption.price,
                              shippingOption.currency
                            )}
                          />
                        )
                      }
                      description={
                        <>
                          {shippingOption.shippingType === "C" && (
                            <span>
                              {shippingOption.carrierName && (
                                <CarrierName>
                                  {shippingOption.carrierName}
                                </CarrierName>
                              )}
                              {shippingOption.description && (
                                <Description>
                                  {shippingOption.description}
                                </Description>
                              )}
                            </span>
                          )}
                        </>
                      }
                    />
                  )
                }
              )}
            </>
          )}
        />
        {errors.shippingOption && (
          <ErrorMsg>{errors.shippingOption.message}</ErrorMsg>
        )}
      </SingleColumnRow>
    </ContainerCard>
  )
}
