import { colors, mediaQuery } from "../../../../theme"

import { Chevron } from "../../../../icons"
import { Link } from "react-router-dom"
import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { Trans } from "@lingui/macro"
import styled from "@emotion/styled/macro"

type Props = {
  redirectUrl: string
  category: string
}

export function ProductNotAvailable({ redirectUrl, category }: Props) {
  const actionDescription =
    category !== "" ? (
      <Trans>Seguir buscando en {category}</Trans>
    ) : (
      <Trans>Seguir buscando</Trans>
    )

  const allProductsPath = window.ROUTES.find(route => {
    return route.id === "product" && route.kind === "C"
  })?.path

  const productDeletedPath = allProductsPath != null ? allProductsPath : "/"

  return (
    <Card>
      <div>
        <Title>
          <Trans>¡Ups!</Trans>
        </Title>
        <Subtitle>
          <Trans>El producto no se enuentra disponible.</Trans>
        </Subtitle>
      </div>
      <ActionLink to={redirectUrl !== "" ? redirectUrl : productDeletedPath}>
        <ChevronContainer>
          <Chevron />
        </ChevronContainer>
        <ActionText>{actionDescription}</ActionText>
      </ActionLink>
    </Card>
  )
}

const Card = styled.div({
  backgroundColor: colors.white,
  margin: "2.8rem 1.6rem 6.8rem",
  padding: "2.4rem 1.6rem",
  textAlign: "center",
  borderRadius: "0.4rem",
  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.28)",
  display: "grid",
  gridGap: "1.2rem",

  [mediaQuery.desktop]: {
    marginTop: "5.8rem",
    height: "23rem",
    padding: "5.8rem 0",
    gridGap: "3.6rem",
  },
})

const Title = styled.h1<PropsWithTheme>(props => ({
  margin: 0,
  fontFamily: props.theme.typographies.texts,
  fontSize: "1.6rem",
  color: props.theme.colors.texts,

  [mediaQuery.desktop]: {
    fontSize: "1.8rem",
  },
}))

const Subtitle = styled.p<PropsWithTheme>(props => ({
  margin: 0,
  fontFamily: props.theme.typographies.texts,
  fontSize: "1.6rem",
  color: props.theme.colors.texts,

  [mediaQuery.desktop]: {
    fontSize: "1.8rem",
  },
}))

const ActionLink = styled(Link)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  background: "transparent",
  border: "none",
  textDecoration: "none",
})

const ChevronContainer = styled.div({
  transform: "rotate(90deg)",
  marginRight: "0.8rem",
  fontSize: "1.4rem",
  color: colors.greyDark,
})

const ActionText = styled.p({
  margin: 0,
  fontFamily: "Open Sans, sans-serif",
  fontSize: "1.6rem",
  color: colors.greyDark,
})
