import { Controller, useFormContext } from "react-hook-form"
import {
  DualColumnRow,
  ExternalLink,
  SingleColumnRow,
  Subtitle,
} from "../../../../../../style"
import { Trans, t } from "@lingui/macro"

import { BillingData } from "../../../../types"
import { DropdownField } from "../../../../../../../../../../components/DropdownField"
import { IdentityType } from "../../../../../../../../../../api/stores/checkout"
import { TextField } from "../../../../../../../../../../components/TextField"
import { getLocaleStates } from "../../../../../../../../../../utils/getLocaleStates"
import { i18n } from "@lingui/core"
import { useCheckout } from "../../../../../../../../../../hooks"
import { useEffect } from "react"

type Option = { id: string; name: string; value: IdentityType }
const arrayOfAllOptions: ArrayOfAllUnion<Option> = array => array

export const BillingForm = () => {
  const { billing } = useCheckout().data.meta
  const { control, errors, setValue } = useFormContext<BillingData>()
  const language = i18n.locale

  useEffect(() => {
    //set billing from api
    const isBillingEmpty = billing == null || billing?.fullName === ""

    if (!isBillingEmpty) {
      setValue("billingFullName", billing?.fullName || "")
      setValue("billingIdentityNumber", billing?.identityNumber || "")
      setValue("billingIdentityType", billing?.identityNumber || "")
      setValue("billingAddressStreet", billing?.addressStreet || "")
      setValue("billingAddressNumber", billing?.addressNumber || "")
      setValue("billingAddressAdditional", billing?.addressOther || "")
      setValue("billingCity", billing?.city || "")
      setValue("billingPostalCode", billing?.postalCode || "")
      setValue("billingState", billing?.state || "")
    }
  }, [billing, setValue])

  return (
    <SingleColumnRow>
      <Subtitle>
        <Trans>Datos de la persona que paga</Trans>
      </Subtitle>

      <Controller
        control={control}
        name="billingFullName"
        defaultValue=""
        rules={{
          required: t`Completá el campo nombre y apellido/razón social.`,
        }}
        render={props => (
          <TextField
            name="billingFullName"
            label={<Trans>Nombre y apellido/Razón social</Trans>}
            errorMsg={errors.billingFullName?.message}
            maxLength={100}
            hideLength={true}
            {...props}
          />
        )}
      />
      <DualColumnRow>
        <Controller
          control={control}
          name="billingIdentityType"
          defaultValue="DU"
          rules={{
            required: t`Elegí el tipo de documento.`,
          }}
          render={({ onChange, value }) => (
            <DropdownField
              name="billingIdentityType"
              label={<Trans>Tipo de documento</Trans>}
              errorMsg={errors.billingIdentityType?.message}
              options={arrayOfAllOptions([
                {
                  id: "1",
                  name: t`DNI`,
                  value: "DU",
                },
                {
                  id: "2",
                  name: t`CUIT`,
                  value: "CUIT",
                },
                {
                  id: "3",
                  name: t`Otro`,
                  value: "OTHER",
                },
              ])}
              value={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="billingIdentityNumber"
          defaultValue=""
          rules={{
            required: t`Completá el campo con tu documento.`,
          }}
          render={({ onChange, value }) => (
            <TextField
              name="billingIdentityNumber"
              label={<Trans>Número de documento</Trans>}
              errorMsg={errors.billingIdentityNumber?.message}
              maxLength={11}
              hideLength={true}
              onChange={onChange}
              value={value?.replace(/[^0-9]/g, "")}
            />
          )}
        />
      </DualColumnRow>

      <Controller
        control={control}
        name="billingAddressStreet"
        defaultValue=""
        rules={{
          required: t`Completá el campo calle.`,
        }}
        render={props => (
          <TextField
            name="billingAddressStreet"
            label={<Trans>Calle (sin número)</Trans>}
            errorMsg={errors.billingAddressStreet?.message}
            maxLength={100}
            hideLength={true}
            {...props}
          />
        )}
      />

      <DualColumnRow>
        <Controller
          control={control}
          name="billingAddressNumber"
          defaultValue=""
          rules={{
            required: t`Completá el campo número.`,
          }}
          render={({ onChange, value }) => (
            <TextField
              name="billingAddressNumber"
              label={<Trans>Número</Trans>}
              errorMsg={errors.billingAddressNumber?.message}
              maxLength={10}
              hideLength={true}
              onChange={onChange}
              value={value?.replace(/[^0-9]/g, "")}
            />
          )}
        />

        <Controller
          control={control}
          name="billingAddressAdditional"
          defaultValue=""
          render={props => (
            <TextField
              name="billingAddressAdditional"
              label={<Trans>Departamento (opcional)</Trans>}
              errorMsg={errors.billingAddressAdditional?.message}
              maxLength={100}
              hideLength={true}
              {...props}
            />
          )}
        />
      </DualColumnRow>

      <Controller
        control={control}
        name="billingCity"
        defaultValue=""
        rules={{
          required: t`Completá el campo ciudad.`,
        }}
        render={props => (
          <TextField
            name="billingCity"
            label={<Trans>Ciudad</Trans>}
            errorMsg={errors.billingCity?.message}
            maxLength={100}
            hideLength={true}
            {...props}
          />
        )}
      />

      <DualColumnRow>
        <Controller
          control={control}
          name="billingPostalCode"
          defaultValue=""
          rules={{
            required: t`Completá el campo código postal.`,
          }}
          render={({ onChange, value }) => (
            <TextField
              name="billingPostalCode"
              label={<Trans>Código Postal</Trans>}
              errorMsg={errors.billingPostalCode?.message}
              maxLength={language === "es-AR" ? 4 : 5}
              hideLength={true}
              onChange={onChange}
              value={value?.replace(/[^0-9]/g, "")}
            />
          )}
        />

        <ExternalLink
          rel="noopener noreferrer"
          href={t`https://www.correoargentino.com.ar/formularios/cpa`}
          target="_blank"
        >
          <Trans>No conozco mi código postal</Trans>
        </ExternalLink>
      </DualColumnRow>

      <Controller
        control={control}
        name="billingState"
        defaultValue=""
        rules={{
          required: t`Elegí la provincia.`,
        }}
        render={props => (
          <DropdownField
            name="billingState"
            label={<Trans>Provincia</Trans>}
            errorMsg={errors.billingState?.message}
            options={getLocaleStates()}
            {...props}
          />
        )}
      />
    </SingleColumnRow>
  )
}
