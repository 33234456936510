import { PropsWithTheme } from "../../providers/StoreThemeProvider"
import styled from "@emotion/styled/macro"

export const LinkButton = styled.button<PropsWithTheme>(props => ({
  fontSize: "1.6rem",
  color: props.theme.colors.texts,
  fontFamily: props.theme.typographies.texts,
  cursor: "pointer",
  backgroundColor: "transparent",
  padding: 0,
  margin: 0,
  border: "none",
  outline: "none",

  "&:hover": {
    textDecoration: "underline",
  },
}))
