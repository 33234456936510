import { useEffect, useMemo, useState } from "react"

import { api } from "../../../api"
import { useLocation } from "react-router-dom"
import { useProductsStore } from "../provider/ProductStoreProvider"

export type Status = "idle" | "fetching" | "success" | "error"

type Props = {
  limit: number
  categoryPath: string
}

export function useProducts({ limit, categoryPath }: Props) {
  let location = useLocation()
  const [status, setStatus] = useState<Status>("idle")
  const { ordering, dispatch } = useProductsStore()

  const queries = useMemo(() => {
    const urlSearchParams = new URLSearchParams(location.search)
    return {
      search: urlSearchParams.get("search") || undefined,
      page: urlSearchParams.get("page") || undefined,
      priceGt: urlSearchParams.get("price_gte") || undefined,
      priceLt: urlSearchParams.get("price_lte") || undefined,
      stockGt: urlSearchParams.get("stock_gt") || undefined,
      filtersId: urlSearchParams.get("value_in") || undefined,
    }
  }, [location.search])

  useEffect(() => {
    const fetchProducts = async () => {
      return await api.products.set().get({
        priceGt: queries.priceGt,
        search: queries.search,
        priceLt: queries.priceLt,
        stockGt: queries.stockGt,
        filtersId: queries.filtersId,
        ordering: ordering,
        offset: (Number(queries.page) - 1) * limit,
        limit,
        categoryPath,
      })
    }
    setStatus("fetching")

    if (queries.page !== undefined) {
      fetchProducts()
        .then(products => {
          setStatus("success")
          dispatch({
            type: "addResults",
            payload: products,
          })
        })
        .catch(err => {
          setStatus("error")
          console.log(err)
        })
    }
  }, [
    queries.priceGt,
    queries.priceLt,
    queries.search,
    queries.filtersId,
    queries.stockGt,
    queries.page,
    dispatch,
    limit,
    ordering,
    categoryPath,
  ])

  return { status }
}
