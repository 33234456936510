import { colors, mediaQuery } from "../../../../../../theme"

import { Currency } from "../../../../../../utils/PriceUtil"
import { Fragment } from "react"
import { Trans } from "@lingui/macro"
import styled from "@emotion/styled/macro"
import { toLocalePrice } from "../../../../../../utils"

type Props = {
  deliveryTime: string
  name: string
  price: number
  priceType: string
  showDeliveryTime: boolean
  currency: Currency
}

export function CustomShippingItem({
  deliveryTime,
  name,
  price,
  priceType,
  showDeliveryTime,
  currency,
}: Props) {
  const hasFreeShipping = price === 0

  return (
    <ShippingOptionCard>
      <Header>
        {name}
        <Price freeShipping={hasFreeShipping}>
          {hasFreeShipping ? (
            "Gratis"
          ) : priceType === "F" ? (
            toLocalePrice(price, currency)
          ) : (
            <Trans>Costo a convenir</Trans>
          )}
        </Price>
      </Header>
      <Description>
        {showDeliveryTime && (
          <Fragment>
            <ShippingDate>{deliveryTime}</ShippingDate>
          </Fragment>
        )}
      </Description>
    </ShippingOptionCard>
  )
}

const ShippingOptionCard = styled.div({
  fontSize: "1.4rem",
  display: "grid",
  gridGap: "0.4rem",
  padding: "1.6rem 0.8rem",
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: colors.greyMainLight,

  "&:nth-of-type(even)": {
    backgroundColor: colors.greyLightest,
  },

  "&:nth-of-type(odd)": {
    backgroundColor: colors.white,
  },

  "&:last-of-type": {
    border: "none",
  },

  [mediaQuery.desktop]: {
    padding: "1.6rem",
  },
})

const Header = styled.div({
  display: "flex",
  justifyContent: "space-between",
})

type PriceProps = {
  freeShipping: boolean
}

const Price = styled.p<PriceProps>(({ freeShipping }) => ({
  color: freeShipping ? colors.greenDark : colors.greyDark,
  margin: 0,
  fontWeight: freeShipping ? "normal" : "bold",
}))

const Description = styled.span({
  display: "flex",
})

const ShippingDate = styled.p({
  margin: 0,
  fontWeight: "bold",
})
