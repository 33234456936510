import { colors, mediaQuery } from "../../../../../theme"

import { AddToCartMobileSticky } from "./AddToCartMobileSticky"
import { Button } from "../../../../../components/Button"
import { PropsWithTheme } from "../../../../../providers/StoreThemeProvider/StoreThemeProvider"
import { Tooltip } from "../../../../../components/Tooltip"
import { Trans } from "@lingui/macro"
import { readableColor } from "polished"
import styled from "@emotion/styled/macro"
import { useRef } from "react"
import { useScreenSize } from "../../../../../hooks"

type AddToCartButtonProps = {
  onClick: (
    e: import("react").MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  isDisabled?: boolean
  tooltipDescription?: string
  spinnerSize?: string
  price: string | import("react").ReactNode
  error?: import("react").ReactNode
  tooltipDisabled?: boolean
}

export function AddToCartButton({
  price,
  error,
  ...props
}: AddToCartButtonProps) {
  const buttonRef = useRef<HTMLDivElement | null>(null)
  const { isMobile } = useScreenSize()

  const button = (
    <Tooltip
      content={props.tooltipDescription}
      placement="bottom"
      disabled={props.tooltipDisabled}
    >
      <TooltipWrapper>
        <Button
          {...props}
          height={isMobile ? "3.5rem" : "5.2rem"}
          disabled={props.isDisabled}
        >
          <Trans>Agregar al carrito</Trans>
        </Button>
      </TooltipWrapper>
    </Tooltip>
  )

  return (
    <>
      {isMobile && (
        <AddToCartMobileSticky
          price={price}
          error={error}
          buttonIsDisabled={props.isDisabled}
          listeningRef={buttonRef}
        >
          <StickyButtonContainer>{button}</StickyButtonContainer>
        </AddToCartMobileSticky>
      )}
      <ButtonContainer
        isDisabled={props.isDisabled}
        data-testid="button-container"
        ref={buttonRef}
      >
        {button}
      </ButtonContainer>
    </>
  )
}

type ButtonContainerProps = {
  isDisabled?: boolean
}

const ButtonContainer = styled.div<PropsWithTheme & ButtonContainerProps>(
  props => {
    const primaryColor = props.theme.colors.primary

    return {
      gridArea: "addToCartButton",
      width: "100%",
      padding: "0rem 1.6rem 2.5rem 1.6rem",
      "& button": {
        color: props.isDisabled
          ? colors.greyMedium
          : readableColor(primaryColor),
      },

      [mediaQuery.desktop]: {
        display: "block",
        alignSelf: "center",
        height: "5.6rem",
        width: "100%",
        padding: "0 1.2rem",

        ...(!props.isDisabled && {
          "&:hover": {
            opacity: 0.9,
          },
        }),

        "& button": {
          backgroundColor: props.isDisabled
            ? colors.greyMainLight
            : primaryColor,
        },
      },
    }
  }
)

const StickyButtonContainer = styled.div({
  display: "flex",
  justifyContent: "flex-end",
  "& button": { width: "15.4rem" },
})

const TooltipWrapper = styled.span()
