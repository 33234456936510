import { useEffect } from "react"

type Status = "idle" | "fetching" | "success" | "error"

export function useScrollToTop(status: Status) {
  useEffect(() => {
    if (status === "fetching") {
      window.scrollTo(0, 0)
    }
  }, [status])
}
