import * as Types from "../../../../screens/ProductVIP/components/NamePriceActions/InformationLinks/ShippingCostLink/types"
import * as z from "zod"

export type RequestBodyRaw = Types.FormData

export type RequestBody = {
  product_id: string
  variant_id: string
  city: string
  postal_code: string
  state: string
  quantity: number
}

export const CarrierRaw = z.object({
  id: z.number(),
  name: z.string(),
  rating: z.number(),
  logo: z.string(),
})

export const DeliveryTimeRaw = z.object({
  is_range: z.boolean(),
  date: z.string(),
})

export const ResponseSchema = z.object({
  results: z.object({
    standard_delivery: z.array(
      z.object({
        price: z.number(),
        currency: z.enum(["ARS", "BRL", "MXN"]),
        carrier: CarrierRaw,
        delivery_time: DeliveryTimeRaw,
      })
    ),
  }),
})

export type Response = z.infer<typeof ResponseSchema>

export const CarrierSchema = z.object({
  id: z.number(),
  name: z.string(),
  rating: z.number(),
  logo: z.string(),
})

export type Carrier = z.infer<typeof CarrierSchema>

export const DeliveryTimeSchema = z.object({
  isRange: z.boolean(),
  date: z.string(),
})

export type DeliveryTime = z.infer<typeof DeliveryTimeSchema>

export const ResultSchema = z.object({
  results: z.array(
    z.object({
      price: z.number(),
      currency: z.enum(["ARS", "BRL", "MXN"]),
      carrier: CarrierSchema,
      deliveryTime: DeliveryTimeSchema,
    })
  ),
})

export type Result = z.infer<typeof ResultSchema>

export const ErrorRawSchema = z.object({
  error: z.object({
    field: z.union([
      z.literal("general"),
      z.literal("zip_code"),
      z.literal("product"),
      z.literal("variation"),
    ]),
    message: z.string(),
    code_error: z.union([
      z.literal("postal_code"),
      z.literal("authentication_key"),
      z.literal("no_measurements"),
      z.literal("overpriced_value"),
      z.literal("quote_error"),
      z.literal("general"),
    ]),
  }),
})

export type ErrorRaw = z.infer<typeof ErrorRawSchema>

export const ErrorResultSchema = z.object({
  error: z.object({
    field: z.union([
      z.literal("general"),
      z.literal("zip_code"),
      z.literal("product"),
      z.literal("variation"),
    ]),
    message: z.string(),
    codeError: z.union([
      z.literal("postal_code"),
      z.literal("authentication_key"),
      z.literal("no_measurements"),
      z.literal("overpriced_value"),
      z.literal("quote_error"),
      z.literal("general"),
    ]),
  }),
})

export type ErrorResult = z.infer<typeof ErrorResultSchema>
