import styled from "@emotion/styled"

export const LinkContainer = styled.div({
  display: "flex",
  flexFlow: "row nowrap",
  alignItems: "center",
  gap: "1.2rem",
})

export const LinkIconContainer = styled(LinkContainer)({
  fontSize: "1.8rem",
})
