import * as Types from "./types"

import { Controller, useFormContext } from "react-hook-form"
import { Trans, t } from "@lingui/macro"
import { colors, mediaQuery } from "../../../../../../theme"

import { AlertBox } from "../../../../../../components/AlertBox"
import { Button } from "../../../../../../components/Button"
import { DropdownField } from "../../../../../../components/DropdownField"
import { Fragment } from "react"
import { TextField } from "../../../../../../components/TextField"
import { api } from "../../../../../../api"
import { getLocaleStates } from "../../../../../../utils/getLocaleStates"
import { i18n } from "@lingui/core"
import styled from "@emotion/styled"

type Props = {
  onSubmit: (data: Types.FormData) => void
  error?: api.stores.quote.Post.ErrorResult
  isLoading: boolean
}

export function CalculateShippingCostForm({
  onSubmit,
  error,
  isLoading,
}: Props) {
  const {
    register,
    watch,
    errors,
    handleSubmit,
  } = useFormContext<Types.FormData>()

  const isPostalError = error != null && error.error.codeError === "postal_code"

  const isQuoteError = error != null && error.error.codeError === "quote_error"

  const isErrorZipCodeField = error != null && error.error.field === "zip_code"

  const isGenericError = error != null && !isPostalError && !isQuoteError

  const language = i18n.locale

  return (
    <Fragment>
      {(isPostalError || isQuoteError || isGenericError) && (
        <AlertBoxContainer>
          <AlertBox
            text={
              <AlertBoxTextContainer>
                {(isGenericError || isPostalError) && (
                  <Trans>
                    No pudimos calcular el costo de envío. {"\n"} Por favor
                    intentalo de nuevo.
                  </Trans>
                )}
                {isQuoteError && (
                  <Trans>
                    No hay opciones de envíos disponibles para la ubicación que
                    ingresaste.
                  </Trans>
                )}
              </AlertBoxTextContainer>
            }
            variant="error"
            size="large"
          />
        </AlertBoxContainer>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <TextField
            name="shippingCity"
            inputRef={register({
              required: t`Campo requerido`,
            })}
            label={<Trans>Ciudad</Trans>}
            value={watch("shippingCity")}
            errorMsg={errors.shippingCity?.message}
            disabled={isLoading}
          />
          <Controller
            name="shippingState"
            rules={{
              required: t`Campo requerido`,
            }}
            render={props => (
              <DropdownField
                name="shippingState"
                label={<Trans>Provincia</Trans>}
                value={watch("shippingState")}
                options={getLocaleStates()}
                errorMsg={errors.shippingState?.message}
                disabled={isLoading}
                onChange={props.onChange}
              />
            )}
          />
          <Controller
            name="shippingPostalCode"
            rules={{
              required: t`Campo requerido`,
              pattern: {
                value: language === "es-AR" ? /^[0-9]{4}$/ : /^[0-9]{5}$/,
                message: t`El código postal ingresado es incorrecto o no existe`,
              },
            }}
            render={() => (
              <TextField
                name="shippingPostalCode"
                inputRef={register({
                  required: t`Campo requerido`,
                })}
                label={<Trans>Código Postal</Trans>}
                value={watch("shippingPostalCode")}
                errorMsg={
                  errors.shippingPostalCode?.message || isErrorZipCodeField
                    ? t`El código postal ingresado es incorrecto o no existe`
                    : undefined
                }
                disabled={isLoading}
                maxLength={language === "es-AR" ? 4 : 5}
              />
            )}
          />

          <LinkContainer>
            <ExternalLink
              rel="noopener noreferrer"
              href={t`https://www.correoargentino.com.ar/formularios/cpa`}
              target="_blank"
              title={t`No conozco mi código postal`}
            >
              <Trans>No conozco mi código postal</Trans>
            </ExternalLink>
          </LinkContainer>
        </div>
        <ButtonContainer>
          <Button
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            spinnerSize="2rem"
          >
            <Trans>Calcular envío</Trans>
          </Button>
        </ButtonContainer>
      </form>
    </Fragment>
  )
}

const AlertBoxContainer = styled.div({
  marginBottom: "1.6rem",
})

const AlertBoxTextContainer = styled.div({
  whiteSpace: "pre-line",
  lineHeight: "normal",
})

const LinkContainer = styled.div({
  paddingTop: "0.8rem",
})

const ExternalLink = styled.a({
  cursor: "pointer",
  fontSize: "1.4rem",
  color: colors.greyDark,
  textDecoration: "underline",

  "&:hover": {
    fontWeight: "bold",
  },
})

const ButtonContainer = styled.div({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "2.4rem",
  "& button": {
    height: "3.2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  [mediaQuery.desktop]: {
    "& button": {
      width: "13.7rem",
    },
  },
})
