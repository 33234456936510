import { colors, mediaQuery, shadows } from "../../theme"

import { PropsWithTheme } from "../../providers/StoreThemeProvider"
import styled from "@emotion/styled/macro"

export type Props = {
  title: import("react").ReactNode
  children: import("react").ReactNode
}

export function ContainerCard({ title, children }: Props) {
  return (
    <Container>
      <Title>{title}</Title>
      <Divider />
      {children}
    </Container>
  )
}

const Container = styled.div<PropsWithTheme>(props => ({
  borderRadius: 4,
  backgroundColor: colors.white,
  boxShadow: shadows[3],
  padding: "1.6rem",

  [mediaQuery.desktop]: {
    padding: "2.4rem 2.4rem 3.2rem 2.4rem",
  },
}))

const Title = styled.h1<PropsWithTheme>(props => ({
  fontSize: "1.6rem",
  color: colors.greyDark,
  margin: 0,
  padding: 0,
  fontWeight: "normal",
  justifySelf: "center",
  cursor: "default",
}))

const Divider = styled.div<PropsWithTheme>(props => ({
  borderBottom: `1px solid ${props.theme.colors.primary}`,
  margin: "1.6rem 0 1.6rem",

  [mediaQuery.desktop]: {
    margin: "1.6rem 0 2.4rem",
  },
}))
