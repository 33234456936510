import { useCallback, useEffect, useRef, useState } from "react"
import { useCheckout, useModal } from "../../../hooks"

import { getModalContent } from "../utils/getModalContent"
import { useHistory } from "react-router-dom"
import { useRouteMatchCheckout } from "../../../hooks/useRouteMatchCheckout"

type TimeoutRef = ReturnType<typeof setTimeout>

export const useCheckoutModal = () => {
  const [counter, setCounter] = useState(false)
  const timeoutRefs = useRef<TimeoutRef[]>([])
  const match = useRouteMatchCheckout()
  const history = useHistory()
  const {
    deleteCheckout,
    data: {
      meta: { timeLeft },
    },
    resetCheckout,
  } = useCheckout()
  const { openModal, closeModal } = useModal().actions

  const showExpired = useCallback(() => {
    openModal({
      ...getModalContent("expired", {
        closeModal: () => {
          closeModal()
          history.replace("/")
        },
      }),
    })
  }, [closeModal, openModal, history])

  const showAbort = useCallback(() => {
    openModal({
      ...getModalContent("abort", {
        closeModal,
        cancelPurchase: () => {
          closeModal()
          deleteCheckout()
          resetCheckout()
          history.replace("/")
        },
      }),
    })
  }, [openModal, closeModal, deleteCheckout, history, resetCheckout])

  const showReminder = useCallback(() => {
    openModal({
      ...getModalContent("reminder", {
        cancelPurchase: showAbort,
        closeModal,
        timeLeft,
      }),
    })
  }, [openModal, closeModal, showAbort, timeLeft])

  const startCounter = useCallback(() => {
    const reminderIn = 600000
    const timeoutExpire = setTimeout(showExpired, timeLeft)
    let refs = [timeoutExpire]

    if (timeLeft && timeLeft <= reminderIn) {
      showReminder()
    } else if (timeLeft) {
      const timeoutReminder = setTimeout(showReminder, timeLeft - reminderIn)
      refs = [...refs, timeoutReminder]
    }

    timeoutRefs.current = [...refs]
    return () => {
      timeoutRefs.current.forEach(timeout => clearTimeout(timeout))
    }
  }, [showExpired, showReminder, timeLeft])

  useEffect(() => {
    setCounter(match?.isExact || false)
  }, [match])

  useEffect(() => {
    if (counter) {
      const clearCounter = startCounter()
      return clearCounter
    } else {
      closeModal()
      timeoutRefs.current.forEach(timeout => clearTimeout(timeout))
    }
  }, [startCounter, closeModal, counter])

  return { showAbort }
}
