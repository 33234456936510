import { Trans, t } from "@lingui/macro"

import { Fragment } from "react"
import { PropsWithTheme } from "../../../../../../providers/StoreThemeProvider"
import styled from "@emotion/styled"

type ZippinDescriptionProps = {
  hasCustomMethods: boolean
  isFormShowing: boolean
}
export function ZippinDescription({
  hasCustomMethods,
  isFormShowing,
}: ZippinDescriptionProps) {
  return (
    <Fragment>
      {hasCustomMethods && (
        <Title>
          <Trans>A través de un correo o transporte</Trans>
        </Title>
      )}
      {isFormShowing && (
        <Description>
          {hasCustomMethods
            ? t`Podés calcular los costos y tiempos de entrega para tu envío.`
            : t`Completá con tus datos para conocer el costo y tiempo de entrega. `}
        </Description>
      )}
    </Fragment>
  )
}

const Title = styled.p({
  fontWeight: "bold",
  fontSize: "1.4rem",
})

const Description = styled.p<PropsWithTheme>(props => ({
  fontFamily: props.theme.typographies.texts,
  fontSize: "1.4rem",
  color: props.theme.colors.texts,
  margin: "1.6rem 0",
}))
