import {
  ShippingOptionsP,
  ShippingOptionsZ,
} from "../../../../../../../../../api/stores/checkout"
import { useFormContext, useWatch } from "react-hook-form"

import { ShippingData } from "../../../types"
import { useCheckout } from "../../../../../../../../../hooks"
import { useEffect } from "react"

type Keys = keyof ShippingData
type Values = ShippingData[Keys]
type ShippingOptionItem =
  | ShippingOptionsZ["options"][0]
  | ShippingOptionsP["options"][0]

export function useShippingOptions() {
  const { setData, data } = useCheckout()
  const { shippingOptions } = data.meta.requirements
  const { control, setValue, register } = useFormContext<ShippingData>()
  const optionId = useWatch({ control, name: "shippingOption" })

  useEffect(() => {
    if (optionId && shippingOptions.__kind) {
      let option: ShippingOptionItem | undefined
      for (const item of shippingOptions.options) {
        if (item.id === optionId) {
          option = item as ShippingOptionItem
          break
        }
      }

      setData(data => {
        if (option) {
          let serviceType = {}
          let carrierId = {}

          register("shippingPrice")
          register("shippingType")
          register("carrierName")
          register("currency")
          setValue<Keys, Values>("shippingPrice", `${option.price}`)
          setValue<Keys, Values>("shippingType", option.shippingType)
          setValue<Keys, Values>("carrierName", option.carrierName)
          setValue<Keys, Values>("currency", option.currency)

          if (option.shippingType === "P" || option.shippingType === "Z") {
            register("shippingLogisticType")
            register("shippingServiceType")
            setValue<Keys, Values>("shippingLogisticType", option.logisticType)
            setValue<Keys, Values>("shippingServiceType", option.serviceType)

            serviceType = { serviceType: option.serviceType }
          }

          if (option.shippingType === "P" || option.shippingType === "Z") {
            register("carrierId")
            setValue<Keys, Values>("carrierId", option.carrierId)

            carrierId = {
              carrierId: option.carrierId,
            }
          }

          return {
            ...data,
            meta: {
              ...data.meta,
              shipping: {
                destination: null,
                receiver: null,
                ...(data.meta.shipping || {}),
                id: option?.id,
                price: option?.price || 0,
                currency: option?.currency,
                trackingExternal: "",
                shippingStatus: "",
                shippingType: option.shippingType,
                carrierName: option.carrierName,
                ...(option.shippingType === "Z" ? serviceType : {}),
                ...(option.shippingType === "Z" || option.shippingType === "P"
                  ? carrierId
                  : {}),
              },
            },
          }
        }

        return { ...data }
      })
    }
  }, [optionId, shippingOptions, setData, setValue, register])
}
