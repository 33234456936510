import { Fragment, useState } from "react"

import { Chevron } from "../../icons"
import { PropsWithTheme } from "../../providers/StoreThemeProvider"
import { colors } from "../../theme"
import { readableColor } from "polished"
import styled from "@emotion/styled/macro"
import { t } from "@lingui/macro"
import { useScreenSize } from "../../hooks"

type Props = {
  clickHandler: (min?: number, max?: number) => void
}

type IsMobileProps = {
  isMobile: boolean
}

export function RangeSelector({ clickHandler }: Props) {
  const [quantity, setQuantity] = useState<{
    min: number | undefined
    max: number | undefined
  }>({ min: undefined, max: undefined })

  const { isMobile } = useScreenSize()

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    selector: "min" | "max"
  ) => {
    setQuantity({
      ...quantity,
      [selector]: e.target.value ? parseInt(e.target.value) : undefined,
    })
  }

  return (
    <Fragment>
      <Container>
        <QuantitySelector
          onChange={e => handleChange(e, "min")}
          placeholder={t`Mínimo`}
        />
        <Spacer isMobile={isMobile}>&minus;</Spacer>
        <QuantitySelector
          onChange={e => handleChange(e, "max")}
          placeholder={t`Máximo`}
        />
        <ChevronIcon
          disabled={
            (quantity.min == null && quantity.max == null) ||
            (quantity.min != null &&
              quantity.max != null &&
              quantity.min > quantity.max)
          }
          onClick={() => clickHandler(quantity.min, quantity.max)}
          isMobile={isMobile}
        >
          <Chevron />
        </ChevronIcon>
      </Container>
    </Fragment>
  )
}

const Container = styled.form({
  display: "grid",
  gridAutoColumns: "max-content",
  gridAutoFlow: "column",
  gridGap: "0.8rem",
  alignItems: "center",
})

const Spacer = styled.span<PropsWithTheme & IsMobileProps>(props => ({
  color: props.isMobile
    ? colors.greyMedium
    : readableColor(props.theme.colors.background),
}))

type QuantityProps = {
  onChange: (event: import("react").ChangeEvent<HTMLInputElement>) => void
  placeholder: string
}

function QuantitySelector({ onChange, placeholder }: QuantityProps) {
  const { isMobile } = useScreenSize()

  return (
    <Fragment>
      <label>
        <InputContainer
          data-testid="range-selector"
          name="quantity"
          type="number"
          min={0}
          onChange={onChange}
          placeholder={placeholder}
          isMobile={isMobile}
        />
      </label>
    </Fragment>
  )
}

const InputContainer = styled.input<PropsWithTheme & IsMobileProps>(props => ({
  "::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },

  color: props.isMobile
    ? colors.greyMedium
    : readableColor(props.theme.colors.background),
  background: "transparent",
  width: "5.4rem",
  fontSize: "1.4rem",
  border: 0,
  padding: 0,
  outline: "none",
  borderBottom: `1px solid ${
    props.isMobile
      ? colors.greyMedium
      : readableColor(props.theme.colors.background)
  }`,

  "&:focus": { borderBottom: `1px solid ${props.theme.colors.primary}` },

  "::placeholder": {
    color: props.isMobile
      ? colors.greyMedium
      : readableColor(props.theme.colors.background),
  },
}))

type ChevronIconProps = {
  disabled: boolean
  isMobile: boolean
}

const ChevronIcon = styled.button<PropsWithTheme & ChevronIconProps>(props => ({
  border: "none",
  outline: "none",
  color: props.isMobile
    ? colors.greyMedium
    : readableColor(props.theme.colors.background),
  fontSize: "0.8rem",
  display: "inline-block",
  alignSelf: "center",
  background: "transparent",
  cursor: props.disabled ? "default" : "pointer",
  padding: 0,

  "& svg": {
    color: props.disabled
      ? colors.greyMain
      : props.isMobile
      ? colors.greyMedium
      : readableColor(props.theme.colors.background),
    transform: "rotate(-90deg)",
    fontSize: "1rem",
    verticalAlign: "text-bottom",
  },
}))
