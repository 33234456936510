import * as startCheckout from "./start_checkout"

import { Response, Result } from "./types"

import { del } from "./delete"
import { get } from "./get"
import { post } from "./post"

export function set() {
  return {
    startCheckout: {
      post: startCheckout.post(),
    },
    get: get(),
    post: post(),
    delete: del(),
  }
}

export { startCheckout }
export * from "./types"
export * as Post from "./post/types"

export function transformResponse(response: Response): Result {
  const meta = response.meta.has_checkout
    ? {
        hasCheckout: response.meta.has_checkout,
        timeLeft: response.meta.time_left,
      }
    : {
        hasCheckout: response.meta.has_checkout,
        cartId: response.meta.cart_id,
        totalCount: response.meta.total_count,
        subtotal: response.meta.subtotal,
        currency: response.meta.currency,
      }

  const objects = response.objects.map(variant => {
    return {
      itemId: variant.item_id,
      productId: variant.product_id,
      variantId: variant.variant_id,
      properties: variant.properties,
      price: variant.price,
      currency: variant.currency,
      quantity: variant.quantity,
      stock: variant.stock,
      title: variant.title,
      thumbnail: variant.thumbnail,
      info: variant.info,
    }
  })

  return {
    meta,
    objects,
  }
}
