import { PropertiesList } from "./components/PropertyList"
import { useProductStore } from "../../../provider/ProductStoreProvider"

// import { PropsWithTheme } from "../../providers/StoreThemeProvider"
// import { colors } from "../../theme"

export type ProductVariantsProps = {
  isDisabledProduct?: boolean
  variantHasError: boolean
  resetVariantHasError: () => void
}

export function ProductVariants({
  isDisabledProduct = false,
  variantHasError,
  resetVariantHasError,
}: ProductVariantsProps) {
  const { product } = useProductStore()
  const { properties, variants } = product

  if (properties.length === 0 || variants.length === 0) return null

  return (
    <div data-testid="product-variants">
      <PropertiesList
        isDisabledProduct={isDisabledProduct}
        properties={properties}
        variantHasError={variantHasError}
        resetVariantHasError={resetVariantHasError}
      />
    </div>
  )
}
