import { Response, Result } from "./types"

import paths from "../../paths"
import { service } from "../../Service"

export async function get() {
  try {
    const response = await service.get<Response>(paths.paymentMethods())

    return transformResponse(response.body)
  } catch (error) {
    throw new Error(`API Payment Methods did not respond correctly`)
  }
}

function transformResponse(response: Response): Result {
  const mercadoPago = response.objects.mercadopago.map(type => ({
    name: type.name,
    paymentTypeId: type.payment_type_id,
    secureThumbnail: type.secure_thumbnail,
  }))

  const custom = response.objects.custom.map(method => ({
    name: method.name,
    id: method.id,
    description: method.description,
    offersDiscount: method.offers_discount,
    discountType: method.discount_type,
    discountValue: method.discount_value,
    currency: method.currency,
  }))

  return {
    objects: {
      custom,
      mercadoPago,
    },
  }
}
