import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"

import { api } from "../api"

export type Status = "idle" | "fetching" | "success" | "error"

const defaultData: api.stores.carts.Result = Object.freeze({
  meta: {
    hasCheckout: false,
    cartId: 0,
    totalCount: 0,
    subtotal: 0,
    total: 0,
    currency: "ARS",
  },
  objects: [],
})

const defaultState = {
  isOpen: false,
  status: "idle" as Status,
}

type State = {
  isOpen: boolean
  status: Status
}

export const OpenStateContext = createContext<
  [State, React.Dispatch<React.SetStateAction<State>>]
>([defaultState, () => {}])

export const DataStateContext = createContext<
  [
    api.stores.carts.Result,
    React.Dispatch<React.SetStateAction<api.stores.carts.Result>>
  ]
>([defaultData, () => {}])

export function CartProvider({ children }: { children: React.ReactNode }) {
  const state = useState(defaultState)
  const data = useState(defaultData)

  return (
    <OpenStateContext.Provider value={state}>
      <DataStateContext.Provider value={data}>
        {children}
      </DataStateContext.Provider>
    </OpenStateContext.Provider>
  )
}

export function useCart() {
  const [state, setState] = useContext(OpenStateContext)
  const [data, setData] = useContext(DataStateContext)

  const isPurchaseCartDisabled = useMemo(() => {
    return data.objects.filter(o => o.info.code !== "ok").length > 0
  }, [data.objects])

  const openCart = useCallback(
    (callApi: boolean = true) => {
      if (callApi) {
        api.stores
          .set()
          .carts.set()
          .get()
          .then(
            response => {
              setData(response)
              setState(state => ({
                ...state,
                isOpen: true,
              }))
            },
            error =>
              console.error(
                "There was an error trying to get the items of the cart:",
                error
              )
          )
      } else {
        setState(state => ({
          ...state,
          isOpen: true,
        }))
      }
    },
    [setState, setData]
  )

  const closeCart = useCallback(() => {
    setState({
      status: "idle",
      isOpen: false,
    })
  }, [setState])

  const getData = useCallback(async () => {
    try {
      const data = await api.stores.set().carts.set().get()
      setData(data)
      return data
    } catch (error) {
      console.error(
        "There was an error trying to get the items of the cart:",
        error
      )
    }
  }, [setData])

  const deleteItem = useCallback(
    (itemId: string) =>
      api.stores
        .set()
        .carts.set()
        .delete(itemId)
        .then(
          data => {
            setData(data)
            if (data.objects.length === 0) {
              closeCart()
            }
          },
          error =>
            console.error(
              `There was an error trying to delete the item ${itemId} of the cart:`,
              error
            )
        ),
    [setData, closeCart]
  )

  const addOrModifyItem = useCallback(
    (
      item: api.stores.carts.Post.NewVariantRequestRaw,
      callback?: () => void
    ) => {
      setState(state => ({
        ...state,
        status: "fetching",
      }))
      api.stores
        .set()
        .carts.set()
        .post(item)
        .then(
          response => {
            setData(response)
            setState(state => ({
              ...state,
              status: "success",
            }))
            if (callback != null) callback()
          },
          error => {
            console.error(
              `There was an error trying to add or modify the item ${item.itemId} of the cart:`,
              error
            )
            setState(state => ({
              ...state,
              status: "error",
            }))
          }
        )
    },
    [setState, setData]
  )

  const actions = useMemo(
    () => ({
      openCart,
      closeCart,
      getData,
      deleteItem,
      addOrModifyItem,
    }),
    [openCart, closeCart, getData, deleteItem, addOrModifyItem]
  )

  return {
    data,
    actions,
    state,
    isPurchaseCartDisabled,
  }
}

export type CartTypes = ReturnType<typeof useCart>
