import { colors, ecommerceColors, mediaQuery } from "../../../../theme"

import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { readableColor } from "polished"
import styled from "@emotion/styled"

export const Form = styled.form<{ active: boolean }>(({ active }) => ({
  display: active ? "block" : "none",
  order: 4,

  [mediaQuery.desktop]: {
    gridRowStart: 3,
    gridColumnStart: 1,
  },
}))

export const FormBody = styled.div({
  display: "grid",
  gridGap: "1.6rem",
})

export const DualColumnRow = styled.div({
  display: "grid",
  rowGap: "0.8rem",

  [mediaQuery.desktop]: {
    gridTemplateColumns: "1fr 1fr",
    columnGap: "1.8rem",
  },
})

export const SingleColumnRow = styled.div({
  display: "grid",
  rowGap: "0.8rem",
})

export const Subtitle = styled.p({
  fontSize: "1.4rem",
  fontWeight: "bold",
  color: colors.greyDark,
})

export const ExternalLink = styled.a<PropsWithTheme>({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  fontSize: "1.4rem",
  color: colors.greyDark,
  textDecoration: "none",

  "&:hover": {
    textDecoration: "underline",
    color: colors.black,
    "& svg": {
      color: colors.black,
    },
  },
})

export const ErrorMsg = styled.p({
  fontSize: "1.2rem",
  color: colors.redMain,
  margin: 0,
})

export const ContinueButton = styled.button<PropsWithTheme>(props => ({
  border: "none",
  borderRadius: 4,
  backgroundColor: props.theme.colors.primary,
  color: readableColor(props.theme.colors.primary),
  fontSize: "1.4rem",
  fontFamily: props.theme.typographies.titles,
  padding: "0.8rem 1.6rem ",
  margin: "1.6rem 0",
  cursor: "pointer",
  width: "100%",

  [mediaQuery.desktop]: {
    fontSize: "1.6rem",
    float: "right",
    width: "auto",

    "&:hover": {
      opacity: 0.9,
    },
  },
}))

export const DestinationContainer = styled.div<PropsWithTheme>(props => ({
  borderRadius: 4,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: props.theme.colors.primary,
  padding: "0.8rem",
  fontSize: "1.4rem",
  display: "flex",
  justifyContent: "space-between",

  [mediaQuery.desktop]: {
    padding: "0.8rem 1.6rem",
  },
}))

export const DecoratedText = styled.p({
  color: colors.greyDark,
  cursor: "pointer",
  margin: 0,

  "&:hover": {
    textDecoration: "underline",
    color: colors.black,
  },
})

export const PaymentDescription = styled.p({
  fontSize: "1.4rem",
  whiteSpace: "pre-wrap",
})

export const PaymentDescriptionBold = styled.p({
  fontSize: "1.4rem",
  fontWeight: "bold",
})

export const IconsMP = styled.div({
  justifySelf: "center",
  display: "grid",
  gridAutoFlow: "column",
  gridGap: "1.2rem",
  alignItems: "center",

  "& svg": {
    fontSize: "4rem",
  },
})

export const TextArea = styled.textarea<PropsWithTheme>(props => ({
  resize: "vertical",
  borderRadius: 2,
  borderColor: colors.greyMain,
  outline: "none",
  backgroundColor: "transparent",
  padding: "1.2rem",
  height: "4.4rem",

  "&:focus": {
    backgroundColor: "transparent",
    border: `1px solid ${ecommerceColors.greyDark}`,
  },
}))

export const Description = styled.div(() => ({
  padding: "0.8rem 0 0 2.3rem",
  whiteSpace: "pre-wrap",
}))

export const CarrierName = styled.div(() => ({
  padding: "1rem 0 0 2.3rem",
}))

export const DiscountTag = styled.span({
  color: colors.white,
  backgroundColor: colors.greenDark,
  padding: "0.4rem 0.8rem",
  borderRadius: "2px",
  fontWeight: 600,
})
