import { ChangeEvent, useEffect, useState } from "react"
import { colors, mediaQuery, opacity } from "../../../../theme"

import { AlertBox } from "../../../AlertBox"
import { Link } from "react-router-dom"
import { PriceView } from "../../../PriceView"
import { PropsWithTheme } from "../../../../providers/StoreThemeProvider"
import { QuantitySelector } from "../../../QuantitySelector"
import { Trashcan } from "../../../../icons"
import { api } from "../../../../api"
import { getProductVIPUrl } from "../../../../utils"
import styled from "@emotion/styled/macro"
import { t } from "@lingui/macro"
import { toLocalePrice } from "../../../../utils/PriceUtil"
import { useCart } from "../../../../hooks"
import { useStockError } from "../../../../hooks/useStockError"

type Props = {
  index: number
  product: api.stores.carts.Variant
  handleStockError: (err: boolean) => void
}

export function ProductCard({ index, product, handleStockError }: Props) {
  const variantName = product.properties.map(each => each.value).join(" - ")
  const [quantity, setQuantity] = useState(product.quantity)
  const [hasStockAvailable, setHasStockAvailable] = useState(false)
  const { actions } = useCart()

  const { stock } = product

  const { errorAlert } = useStockError({
    stock,
    hasStockAvailable,
    quantity,
    handleStockError,
  })

  useEffect(() => {
    setHasStockAvailable(_ => !(quantity > stock))
  }, [quantity, stock])

  useEffect(() => {
    setQuantity(product.quantity)
  }, [product.quantity])

  const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
    const quantity = parseInt(e.target.value)
    setQuantity(quantity)
  }

  const handleFocusEvent = (e: ChangeEvent<HTMLInputElement>) => {
    const invalidNumber =
      e.target.value === "" || e.target.value.match(/^[0][0-9]{0,}/gi)

    if (invalidNumber) {
      setQuantity(1)
    }
    hasStockAvailable &&
      actions.addOrModifyItem({
        ...product,
        quantity: invalidNumber ? 1 : parseInt(e.target.value),
      })
  }

  const deleteHandler = () => actions.deleteItem(product.itemId)

  return (
    <Card data-testid={`product-card-${index + 1}`}>
      {errorAlert && <AlertBox size="small" {...errorAlert} />}
      <Product>
        <Container isDisabled={product.info.code === "no_product"}>
          <Thumbnail
            src={product.thumbnail}
            alt={t`Imagen miniatura de: ${product.title}`}
          />
          <Detail>
            <Title>
              <ProductLink
                onClick={actions.closeCart}
                to={`/${getProductVIPUrl(product.title)}-rtid${
                  product.productId
                }/`}
              >
                {product.title}
              </ProductLink>
            </Title>
            <VariantLink>{variantName}</VariantLink>
            <Price>
              <PriceView
                price={toLocalePrice(product.price, product.currency)}
              />
            </Price>
            <div>
              <QuantitySelector
                quantitySelected={quantity}
                productHasStock={stock > 0}
                error={errorAlert != null}
                onChange={handleQuantityChange}
                onBlur={handleFocusEvent}
              />
            </div>
          </Detail>
        </Container>
        <Delete onClick={deleteHandler} title={t`Eliminar`}>
          <Trashcan />
        </Delete>
      </Product>
    </Card>
  )
}

type ContainerProps = {
  isDisabled: boolean
}

const Card = styled.div({
  display: "grid",
  gridGap: "1rem",
  padding: "0 0.4rem 0 0.4rem",
  cursor: "default",

  [mediaQuery.desktop]: {
    padding: "0 0.8rem 0 0.8rem",
  },
})

const Product = styled.div({
  display: "grid",
  gridTemplateColumns: "18fr 1fr",
})

const Container = styled.div<PropsWithTheme & ContainerProps>(props => ({
  display: "grid",
  gridTemplateColumns: "1fr 5fr",
  pointerEvents: props.isDisabled ? "none" : "auto",
  opacity: props.isDisabled ? opacity[2] : opacity[5],
  gridGap: "0.4rem",
  [mediaQuery.desktop]: {
    gridGap: "0.8rem",
  },
}))

const Thumbnail = styled.img({
  width: "100%",
  height: "auto",
  objectFit: "contain",
})

const Detail = styled.div()

const Title = styled.h3<PropsWithTheme>(props => ({
  marginTop: "0",
  marginBottom: "0.4rem",
  fontSize: "1.4rem",
  color: colors.greyMedium,
  fontFamily: props.theme.typographies.titles,
}))

const ProductLink = styled(Link)({
  color: colors.greyMedium,
  textDecoration: "none",

  "&:hover": {
    textDecoration: "underline",
    color: colors.black,
  },
})

const VariantLink = styled.span<PropsWithTheme>(props => ({
  fontSize: "1.4rem",
  color: colors.greyMedium,
  fontFamily: props.theme.typographies.texts,
  textDecoration: "none",
}))

const Price = styled.p<PropsWithTheme>(props => ({
  fontSize: "1.4rem",
  fontWeight: "bold",
  marginTop: "0.4rem",
  marginBottom: "0.4rem",
  color: colors.greyMedium,
  fontFamily: props.theme.typographies.texts,

  "& sup": {
    fontSize: "1rem",
  },
}))

const Delete = styled.button<PropsWithTheme>(props => ({
  display: "flex",
  alignSelf: "flex-start",
  background: "transparent",
  cursor: "pointer",
  border: "none",
  borderRadius: "50%",
  width: "4rem",
  height: "4rem",
  marginTop: "-0.8rem",
  marginRight: "-0.4rem",
  alignItems: "center",
  justifyContent: "center",
  color: colors.greyMedium,

  "& svg": {
    width: "1.6rem",
    height: "1.6rem",
  },

  "&:hover": {
    backgroundColor: colors.greyLight,
    color: props.theme.colors.primary,
  },
}))
