import { MutableRefObject, useLayoutEffect, useState } from "react"

export function useStickyHeaderPosition(
  ref: MutableRefObject<HTMLElement | null>,
  isSticky: boolean
) {
  const [showSticky, setShowSticky] = useState<boolean>(false)
  const [headerSize, setHeaderSize] = useState<number>(0)

  useLayoutEffect(() => {
    if (isSticky) {
      const headerDom = document.body.getElementsByTagName("header")

      if (headerDom.length > 1) return

      const headerElement = headerDom[0] as HTMLElement
      setHeaderSize(headerElement.getBoundingClientRect().height)
    }
  }, [isSticky])

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (ref.current != null) {
        setShowSticky(ref.current.getBoundingClientRect().top <= headerSize)
      }
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [headerSize, ref])

  return { showSticky, headerSize }
}
