import { ContactData, ReceiverData } from "../../../types"
import { useCallback, useEffect, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"

type Keys = keyof Omit<ReceiverData, "title">

type Fields = {
  [key in Keys]: string
}

const names: (keyof ContactData)[] = [
  "contactFirstName",
  "contactLastName",
  "contactIdentityType",
  "contactIdentityNumber",
  "contactPhone",
]

export const useCopyReceiver = () => {
  const [disableFields, setDisableFields] = useState(false)
  const { control, setValue, getValues } = useFormContext()
  const isReceiverSameAsContact: string | undefined = useWatch({
    control,
    name: "isReceiverSameAsContact",
  })
  const contact = useWatch<ContactData>({
    control,
    name: names,
  })

  const setFormValues = useCallback(
    (fields: Fields) => {
      for (const key in fields) {
        setValue(key, fields[key as Keys])
      }
    },
    [setValue]
  )

  useEffect(() => {
    const isCopyContact = isReceiverSameAsContact === "true"
    const contact = getValues(names)

    if (isCopyContact) {
      setFormValues({
        receiverFirstName: contact.contactFirstName || "",
        receiverLastName: contact.contactLastName || "",
        receiverIdentityType: contact.contactIdentityType || "",
        receiverIdentityNumber: contact.contactIdentityNumber || "",
        receiverPhone: contact.contactPhone || "",
        isReceiverSameAsContact: "true",
      })

      setDisableFields(true)
    } else {
      setFormValues({
        receiverFirstName: "",
        receiverLastName: "",
        receiverIdentityType: "DU",
        receiverIdentityNumber: "",
        receiverPhone: "",
        isReceiverSameAsContact: "",
      })
      setDisableFields(false)
    }
  }, [isReceiverSameAsContact, contact, setFormValues, getValues])

  return disableFields
}
