import { Controller, useFormContext } from "react-hook-form"
import { DualColumnRow, Subtitle } from "../../../../../../style"
import {
  TextField,
  parseField,
  parsePhoneNumberField,
} from "../../../../../../../../../../components/TextField"
import { Trans, t } from "@lingui/macro"

import { CheckboxField } from "../../../../../../../../../../components/CheckboxField"
import { DropdownField } from "../../../../../../../../../../components/DropdownField"
import { IdentityType } from "../../../../../../../../../../api/stores/checkout"
import { ReceiverData } from "../../../../types"
import { useCopyReceiver } from "../../hooks/useCopyReceiver"

type Keys = keyof Omit<ReceiverData, "title">

type Fields = {
  [key in Keys]?: boolean
}

export type ReceiverFormProps = {
  title: JSX.Element | string
  fields?: Fields
}

type Option = { id: string; name: string; value: IdentityType }

const arrayOfAllOptions: ArrayOfAllUnion<Option> = array => array

export const isFieldValid = (field?: boolean) =>
  field === true || field === undefined

export const ReceiverForm = ({
  title,
  fields = {
    isReceiverSameAsContact: true,
    receiverFirstName: true,
    receiverLastName: true,
    receiverIdentityType: true,
    receiverIdentityNumber: true,
    receiverPhone: true,
  },
}: ReceiverFormProps) => {
  const { control, errors, getValues } = useFormContext<ReceiverData>()
  const disableFields = useCopyReceiver()

  return (
    <>
      <Subtitle>{title}</Subtitle>

      {isFieldValid(fields.isReceiverSameAsContact) && (
        <Controller
          control={control}
          name="isReceiverSameAsContact"
          render={({ onChange, value }) => (
            <CheckboxField
              label={<Trans>Usar los mismos datos de compra</Trans>}
              value={getValues("isReceiverSameAsContact") ? "" : "true"}
              checked={value === "true"}
              onChange={onChange}
            />
          )}
        />
      )}

      <DualColumnRow>
        {isFieldValid(fields.receiverFirstName) && (
          <Controller
            control={control}
            name="receiverFirstName"
            defaultValue=""
            rules={{
              required: t`Completá el campo con tu nombre.`,
            }}
            render={props => (
              <TextField
                name="receiverFirstName"
                label={<Trans>Nombre</Trans>}
                errorMsg={errors.receiverFirstName?.message}
                disabled={disableFields}
                maxLength={100}
                hideLength={true}
                {...props}
              />
            )}
          />
        )}

        {isFieldValid(fields.receiverLastName) && (
          <Controller
            control={control}
            name="receiverLastName"
            defaultValue=""
            rules={{
              required: t`Completá el campo con tu apellido.`,
            }}
            render={props => (
              <TextField
                name="receiverLastName"
                label={<Trans>Apellido</Trans>}
                errorMsg={errors.receiverLastName?.message}
                disabled={disableFields}
                maxLength={100}
                hideLength={true}
                {...props}
              />
            )}
          />
        )}

        {isFieldValid(fields.receiverIdentityType) && (
          <Controller
            control={control}
            name="receiverIdentityType"
            defaultValue="DU"
            rules={{
              required: t`Elegí el tipo de documento.`,
            }}
            render={props => (
              <DropdownField
                name="receiverIdentityType"
                label={<Trans>Tipo de documento</Trans>}
                errorMsg={errors.receiverIdentityType?.message}
                disabled={disableFields}
                options={arrayOfAllOptions([
                  {
                    id: "1",
                    name: t`DNI`,
                    value: "DU",
                  },
                  {
                    id: "2",
                    name: t`CUIT`,
                    value: "CUIT",
                  },
                  {
                    id: "2",
                    name: t`Otro`,
                    value: "OTHER",
                  },
                ])}
                {...props}
              />
            )}
          />
        )}

        {isFieldValid(fields.receiverIdentityNumber) && (
          <Controller
            control={control}
            name="receiverIdentityNumber"
            defaultValue=""
            rules={{
              required: t`Completá el campo con tu documento.`,
            }}
            render={({ onChange, value }) => (
              <TextField
                name="receiverIdentityNumber"
                label={<Trans>Número de documento</Trans>}
                errorMsg={errors.receiverIdentityNumber?.message}
                disabled={disableFields}
                type="text"
                maxLength={11}
                hideLength={true}
                onChange={e => onChange(parseField(e.target.value))}
                value={value}
              />
            )}
          />
        )}
      </DualColumnRow>

      {isFieldValid(fields.receiverPhone) && (
        <Controller
          control={control}
          name="receiverPhone"
          defaultValue=""
          rules={{
            required: t`Completá el campo con tu teléfono.`,
          }}
          render={({ onChange, value }) => (
            <TextField
              name="receiverPhone"
              label={<Trans>Teléfono</Trans>}
              errorMsg={errors.receiverPhone?.message}
              disabled={disableFields}
              type="tel"
              maxLength={100}
              hideLength={true}
              onChange={e => onChange(parsePhoneNumberField(e.target.value))}
              value={value}
            />
          )}
        />
      )}
    </>
  )
}
