import React, { createContext, useContext, useState } from "react"

import { api } from "../../api"
import { colors } from "../../theme"

type State = {
  config: api.stores.config.Get.Result
}

export const initialState: State = Object.freeze({
  config: {
    logoHeader: {
      type: "T",
      img: undefined,
      text: "Logo",
      typography: "serif",
      color: colors.greyMain,
    },
    logoFooter: {
      color: colors.white,
      text: "Logo",
      img: undefined,
      type: "T",
      typography: "serif",
    },
    useSameLogo: undefined,
    colors: {
      primary: colors.greyMain,
      secondary: colors.greyMedium,
      titles: colors.black,
      texts: colors.black,
      background: colors.white,
    },
    typographies: {
      titles: "Open Sans, sans-serif",
      texts: "Open Sans, sans-serif",
      menu: "Open Sans, sans-serif",
    },
    header: {
      backgroundColor: colors.white,
      banner: {
        backgroundColor: colors.greyMain,
        content: "<p>Banner promo content</p>",
        enabled: true,
        typography: "Open Sans, sans-serif",
        visibility: "A",
      },
      design: "1",
      sticky: false,
    },
    footer: {
      design: "1",
      backgroundColor: colors.greenMain,
      navigation: [],
      phoneNumber: "",
      showContact: true,
      showNavigation: true,
      socials: [],
      htmlAfip: "",
    },
    menu: [],
  },
})

const StoreStateContext = createContext<
  [State, React.Dispatch<React.SetStateAction<State>>]
>([initialState, () => {}])

const StoreProvider = ({
  children,
}: {
  children: import("react").ReactNode
}) => {
  const state = useState(initialState)

  return (
    <StoreStateContext.Provider value={state}>
      {children}
    </StoreStateContext.Provider>
  )
}

function useStoreState() {
  const context = useContext(StoreStateContext)
  if (context === undefined) {
    throw new Error("useStoreState must be used within a StoreProvider")
  }

  return context
}

export { StoreProvider, useStoreState }
