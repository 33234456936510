import { AppHead } from "./AppHead"
import { BrowserRouter } from "react-router-dom"
import { Error } from "./components/Error"
import { Modal } from "./components/Modal"
import { PropsWithTheme } from "./providers/StoreThemeProvider"
import { Router } from "./components/Router"
import { ShoppingCart } from "./components/ShoppingCart"
import { Spinner } from "./icons"
import { colors } from "./theme"
import styled from "@emotion/styled/macro"
import { useConfig } from "./hooks/useConfig"
import { useScreenSize } from "./hooks"

export function App() {
  const { isDesktop } = useScreenSize()
  const { loading, error } = useConfig()

  if (error !== "") {
    return <Error>{error}</Error>
  }

  return (
    <>
      <AppHead />
      <AppContainer className="App">
        {loading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          <Container className={isDesktop ? "desktop" : ""}>
            <BrowserRouter basename={window.SUBDOMAIN}>
              <Router />
              <ShoppingCart />
            </BrowserRouter>
          </Container>
        )}
        <Modal />
      </AppContainer>
    </>
  )
}

const AppContainer = styled.div<PropsWithTheme>(({ theme }) => ({
  backgroundColor: theme.colors.background,
  minHeight: "100vh",
}))

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
})

const SpinnerContainer = styled.div({
  color: colors.greyMain,
  right: "50%",
  bottom: "50%",
  transform: "translate(50%, 50%)",
  position: "absolute",
  fontSize: "6.4rem",
})
