import { Container } from "./Container"

export function Trashcan() {
  return (
    <Container>
      <path
        d="M11.5017,6.5142a.4213.4213,0,0,0-.4212.4213V14.9a.4213.4213,0,0,0,.8425,0V6.943a.4214.4214,0,0,0-.4213-.4288Z"
        fill="currentColor"
      />
      <path
        d="M6.53,6.5142a.4213.4213,0,0,0-.4213.4213V14.9a.4213.4213,0,0,0,.8426,0V6.943A.4214.4214,0,0,0,6.53,6.5142Z"
        fill="currentColor"
      />
      <path
        d="M3.3207,3.0326H14.7132a.7589.7589,0,1,1,0,1.5168H3.3207a.7589.7589,0,1,1,0-1.5168Zm3.0015-1.37A.8113.8113,0,0,1,7.1454.8416h3.741a.8114.8114,0,0,1,.8265.8222V2.19H6.3222Zm6.68,15.4883H5.0294a1.3346,1.3346,0,0,1-1.28-1.4138V5.3919H14.2834V15.7372a1.3345,1.3345,0,0,1-1.281,1.4138ZM2.9059,5.36V15.7414a2.3254,2.3254,0,0,0,.6185,1.6079A2.0754,2.0754,0,0,0,5.0251,18h7.973a2.0745,2.0745,0,0,0,1.5008-.6507,2.3254,2.3254,0,0,0,.6185-1.6079V5.36a1.6079,1.6079,0,0,0-.4127-3.1654H12.5554V1.6627A1.6552,1.6552,0,0,0,10.8864,0H7.1454a1.6552,1.6552,0,0,0-1.669,1.6626V2.189H3.3207A1.6079,1.6079,0,0,0,2.9059,5.36Z"
        fill="currentColor"
      />
      <path
        d="M9.0159,6.5142a.4214.4214,0,0,0-.4213.4213V14.9a.4213.4213,0,0,0,.8426,0V6.943a.4214.4214,0,0,0-.4213-.4288Z"
        fill="currentColor"
      />
    </Container>
  )
}
