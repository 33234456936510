import { Trans, t } from "@lingui/macro"

import { AlertBox } from "../../../../../../../../components/AlertBox"
import { AlertProps } from "../../../../../../../../components/AlertBox/AlertBox"
import styled from "@emotion/styled"
import { useCallback } from "react"
import { useCheckout } from "../../../../../../../../hooks"

export const PurchaseAlert = () => {
  const { payment } = useCheckout().data.meta

  const getAlertProps = useCallback((): AlertProps => {
    let title: AlertProps["title"] = ""
    let text: AlertProps["text"] = ""
    let variant: AlertProps["variant"]

    switch (payment?.status) {
      case "success":
        variant = "success"
        break
      case "pending":
        variant = "warning"
        break
      case "failure":
        variant = "error"
        break
      default:
        variant = "info"
    }

    if (payment?.type === "C" && payment?.status === "pending") {
      title = t`¡Ya falta poco!`
      text = t`Hacé el pago para finalizar tu compra y avisanos. Cuando lo recibamos, actualizaremos el estado de tu compra.`
    } else if (payment?.type === "C" && payment?.status === "success") {
      title = t`Recibimos tu pago`
      text = t`Te mandamos por email la confirmación con los datos.`
    } else if (payment?.type === "M" && payment?.status === "pending") {
      title = t`Estamos procesando tu pago`
      text = t`Cuando esté acreditado, vas a recibir la confirmación en tu email (puede demorar hasta 72 h).`
    } else if (payment?.type === "M" && payment?.status === "success") {
      title = t`Recibimos tu pago`
      text = t`Te mandamos por email la confirmación con los datos.`
    } else if (payment?.type === "M" && payment?.status === "failure") {
      title = t`Mercado Pago rechazó tu pago`
      text = t`Aún no terminaste tu compra. Por favor volvé a intentarlo o probá con otro medio de pago.`
    }

    return { title, text, variant }
  }, [payment])

  return (
    <>
      <ContainerAlert data-testid="purchaseAlert-alert">
        <AlertBox size="large" {...getAlertProps()} />
      </ContainerAlert>
      {payment?.type === "C" && payment?.status === "pending" && (
        <ContainerAlert data-testid="purchaseAlert-alert-small" spacing="S">
          <AlertBox
            size="small"
            text={
              <Trans>
                Algunos medios de pago pueden demorar en acreditarse.
              </Trans>
            }
          />
        </ContainerAlert>
      )}
    </>
  )
}

const ContainerAlert = styled.div<{ spacing?: "S" | "M" | "L" }>(
  ({ spacing = "M" }) => ({
    display: "flex",
    marginBottom:
      spacing === "S" ? "1.6rem" : spacing === "M" ? "2.4rem" : "3.2rem",
  })
)
