class Service {
  public get: <T>(url: string, config?: RequestInit) => Promise<Response<T>>

  public post: <T>(url: string, data: unknown) => Promise<Response<T>>

  public put: <T>(url: string, data: unknown) => Promise<Response<T>>

  public delete: <T>(url: string, config?: RequestInit) => Promise<Response<T>>

  constructor() {
    this.get = this.withMethod("GET")

    this.post = <T>(url: string, data: unknown) =>
      this.withMethod("POST")<T>(url, { body: JSON.stringify(data) })

    this.put = <T>(url: string, data: unknown) =>
      this.withMethod("PUT")<T>(url, { body: JSON.stringify(data) })

    this.delete = this.withMethod("DELETE")
  }

  private getCookie = (targetName: string) => {
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";")

      for (let i = 0; i < cookies.length; i++) {
        const [cookieName, cookieValue] = cookies[i].trim().split("=")

        if (cookieName === targetName) {
          return decodeURIComponent(cookieValue)
        }
      }
    }

    return ""
  }

  private withMethod = (method: "GET" | "POST" | "PUT" | "DELETE") => {
    return async <T>(url: string, config?: RequestInit) => {
      const response = await fetch(url, {
        method,
        credentials: "include",
        headers: {
          "X-CSRFToken": this.getCookie("csrftoken"),
          "Content-Type": "application/json",
        },
        ...(config != null ? config : {}),
      })

      const body = (await response.json()) as T
      const status = {
        code: response.status,
        message: response.statusText,
      }

      return { body, status }
    }
  }
}

export interface Response<T> {
  body: T
  status: {
    code: number
    message: string
  }
}

export const service = new Service()
