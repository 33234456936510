import {
  ShippingOptionsC,
  ShippingOptionsP,
  ShippingOptionsS,
  ShippingOptionsZ,
} from "../../../../../../../../api/stores/checkout"
import { Trans, t } from "@lingui/macro"
import { useCheckout, useShippingOption } from "../../../../../../../../hooks"

import { InformationColumn } from "../InformationColumn"

type ShippingOptionS = ShippingOptionsS["options"][0]
type ShippingOptionC = ShippingOptionsC["options"][0]
type ShippingOptionZ = ShippingOptionsZ["options"][0]
type ShippingOptionP = ShippingOptionsP["options"][0]

export const ShippingColumn = () => {
  const { shipping } = useCheckout().data.meta
  const { shippingOption } = useShippingOption()

  return (
    <>
      {shipping?.shippingType === "C" && (
        <InformationColumn
          type="shipping"
          subtitle={t`Envío a domicilio`}
          description={(shippingOption as ShippingOptionC)?.arrivalDate}
        />
      )}
      {shipping?.shippingType === "Z" && shippingOption && (
        <InformationColumn
          type="shipping"
          subtitle={t`Envío a domicilio`}
          description={(() => {
            const {
              arrivalDate,
              carrierName,
            } = shippingOption as ShippingOptionZ
            return (
              <>
                {arrivalDate} <Trans>vía</Trans> {carrierName}
              </>
            )
          })()}
        />
      )}
      {shipping?.shippingType === "S" && (
        <InformationColumn
          type="shipping"
          subtitle={t`Retiro por punto de entrega`}
          description={
            <>
              {(shippingOption as ShippingOptionS)?.name} <br />
              {(shippingOption as ShippingOptionS)?.hours} <br />
              {(shippingOption as ShippingOptionS)?.phone &&
                t`Tel. ` + (shippingOption as ShippingOptionS)?.phone}
            </>
          }
        />
      )}
      {shipping?.shippingType === "P" && shippingOption && (
        <InformationColumn
          type="shipping"
          subtitle={t`Retiro por sucursal de correo cercano`}
          description={(() => {
            const {
              arrivalDate,
              carrierName,
            } = (shippingOption as unknown) as ShippingOptionP
            return (
              <>
                {arrivalDate} <Trans>por</Trans> {carrierName}
              </>
            )
          })()}
        />
      )}
    </>
  )
}
