import { Shipping } from "../../../../../../../../../api/stores/checkout"

export function formatDestinationToString(
  destination?: Shipping["destination"]
) {
  if (!destination) return ""

  const orderDataDestination = [
    destination.addressStreet,
    destination.addressNumber,
    destination.addressOther,
    destination.city,
    destination.state,
    destination.postalCode ? `CP: ${destination.postalCode}` : null,
  ]

  return orderDataDestination.filter(value => value).join(", ")
}
