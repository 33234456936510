import { Trans } from "@lingui/macro"
import { colors } from "../../theme"
import styled from "@emotion/styled"

export function TagFreeShipping() {
  return (
    <Container>
      <Trans>Envío gratis</Trans>
    </Container>
  )
}

const Container = styled.div(() => ({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0.4rem 0.8rem",
  width: "8.1rem",
  height: "2rem",
  borderRadius: "0.4rem",
  fontSize: "1.2rem",
  fontWeight: "normal",
  color: colors.greenDark,
  backgroundColor: colors.greenLighter,
}))
