import { Response, Result, Socials } from "./types"

import paths from "../../../paths"
import { service } from "../../../Service"

export async function get() {
  try {
    const response = await service.get<Response>(paths.config())

    return transformResponse(response.body)
  } catch (error) {
    throw new Error(`API Config did not respond correctly`)
  }
}

export function transformResponse(response: Response): Result {
  const {
    logo_header,
    logo_footer,
    use_same_logo,
    colors_primary,
    colors_secondary,
    colors_titles,
    colors_texts,
    colors_background,
    typography_titles,
    typography_texts,
    typography_menu,
    header,
    footer,
    menu,
    whatsapp,
    email,
  } = response

  const footerNavigation = footer.navigation.map(item => {
    return {
      name: item.name,
      url: item.url,
      id: item.page_id,
    }
  })

  const menuNavigation = menu.map(item => {
    return {
      name: item.name,
      url: item.url,
      id: item.identifier,
      type: item.type,
    }
  })

  const socialMediaDefaults = [
    { title: "Instagram", iconName: "instagram" },
    { title: "Facebook", iconName: "facebook" },
    { title: "Spotify", iconName: "spotify" },
    { title: "Twitter", iconName: "twitter" },
    { title: "Youtube", iconName: "youtube" },
    { title: "Vimeo", iconName: "vimeo" },
    { title: "Pinterest", iconName: "pinterest" },
  ]

  const footerSocials: Socials[] = []

  socialMediaDefaults.forEach(social => {
    const active = `social_${social.iconName}_active` as keyof typeof footer
    const url = `social_${social.iconName}_url` as keyof typeof footer
    if (footer[active]) {
      footerSocials.push({
        url: footer[url] as string,
        id: social.title,
        ...social,
      })
    }
  })

  const logoHeaderResult = {
    ...logo_header,
    img: logo_header.img == null ? undefined : logo_header.img,
  }

  const logoFooterResult = {
    ...logo_footer,
    img: logo_footer.img == null ? undefined : logo_footer.img,
  }

  const colorsResult = {
    primary: colors_primary,
    secondary: colors_secondary,
    titles: colors_titles,
    texts: colors_texts,
    background: colors_background,
  }

  const typographiesResult = {
    titles: typography_titles,
    texts: typography_texts,
    menu: typography_menu,
  }

  const headerResult = {
    backgroundColor: header.background_color,
    banner: {
      backgroundColor: header.banner_background_color,
      content: header.banner_content,
      enabled: header.banner_enabled,
      typography: header.banner_typography,
      visibility: header.banner_visibility,
    },
    design: header.skin,
    sticky: header.sticky,
  }

  const footerResult = {
    backgroundColor: footer.background_color,
    design: footer.skin,
    phoneNumber: footer.phone_number,
    navigation: footerNavigation,
    showContact: footer.show_contact,
    showNavigation: footer.show_navigation,
    socials: footerSocials,
    htmlAfip: footer.html_afip,
  }

  const whatsappResult = {
    showWhatsappButton: whatsapp?.show_whatsapp_button,
    countryCode: whatsapp?.country_code,
    phoneNumber: whatsapp?.phone_number,
    description: whatsapp?.description,
  }

  return {
    logoHeader: logoHeaderResult,
    logoFooter: logoFooterResult,
    useSameLogo: use_same_logo,
    colors: colorsResult,
    typographies: typographiesResult,
    header: headerResult,
    footer: footerResult,
    menu: menuNavigation,
    whatsapp: whatsappResult,
    email,
  }
}

export * as Get from "./types"
