import { CartProvider, ModalProvider, ScreenBlockersProvider } from "../hooks"

import { CheckoutProvider } from "./CheckoutProvider"
import { EmotionConfigurationProvider } from "./EmotionConfigurationProvider"
import { LanguageProvider } from "./LanguageProvider"
import { ScreenSizeProvider } from "./ScreenSizeProvider"
import { StoreComponentsElementsProvider } from "./StoreComponentsElementsProvider"
import { StoreProvider } from "./StoreProvider"
import { StoreThemeProvider } from "./StoreThemeProvider"

export const Provider: import("react").FunctionComponent = ({ children }) => {
  return (
    <EmotionConfigurationProvider>
      <ScreenBlockersProvider>
        <ScreenSizeProvider>
          <LanguageProvider>
            <StoreProvider>
              <StoreThemeProvider>
                <CartProvider>
                  <CheckoutProvider>
                    <ModalProvider>
                      <StoreComponentsElementsProvider>
                        {children}
                      </StoreComponentsElementsProvider>
                    </ModalProvider>
                  </CheckoutProvider>
                </CartProvider>
              </StoreThemeProvider>
            </StoreProvider>
          </LanguageProvider>
        </ScreenSizeProvider>
      </ScreenBlockersProvider>
    </EmotionConfigurationProvider>
  )
}
