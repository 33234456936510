import {
  ErrorResponse,
  ErrorResult,
  MetaDataRawC,
  MetaDataRawOther,
  MetaDataRawP,
  MetaDataRawS,
  MetaDataRawZ,
  Response,
  Result,
  ShippingMethodRaw,
  ShippingOptionC,
  ShippingOptionZ,
} from "./types"

import { del } from "./delete"
import { get } from "./get"
import { put } from "./put"
import { t } from "@lingui/macro"

export function set(checkoutId?: string) {
  return {
    delete: del(),
    get: get(checkoutId),
    put: put(),
  }
}

export * from "./types"

type MetaData = MetaDataRawZ | MetaDataRawS | MetaDataRawP | MetaDataRawOther

function isMetaDataRawZ(meta: MetaData): meta is MetaDataRawZ {
  return (meta as MetaDataRawZ).shipping?.shipping_type === "Z"
}

function isMetaDataRawC(meta: MetaData): meta is MetaDataRawC {
  return (meta as MetaDataRawC).shipping?.shipping_type === "C"
}

function isMetaDataRawS(meta: MetaData): meta is MetaDataRawS {
  return (meta as MetaDataRawS).shipping?.shipping_type === "S"
}

function isMetaDataRawP(meta: MetaData): meta is MetaDataRawP {
  return (meta as MetaDataRawP).shipping?.shipping_type === "P"
}

function transformShippingMethod({
  id,
  name,
  shipping_type,
}: ShippingMethodRaw) {
  switch (shipping_type) {
    case "S":
      return {
        id,
        name: t`Retiro gratis en punto de entrega`,
        shippingType: shipping_type,
      }
    case "Z":
      return {
        id,
        name: t`Envío a domicilio`,
        shippingType: shipping_type,
      }
    case "P":
      return {
        id,
        name: t`Retiro por sucursal de correo cercana`,
        shippingType: shipping_type,
      }
    default:
      const exhaustiveCheck: never = shipping_type
      throw new Error(`Unhandled shipping status case: ${exhaustiveCheck}`)
  }
}

const shippingOptions = {
  Z: (shippingOption: ShippingOptionZ) => ({
    carrierName: shippingOption.carrier_name,
    arrivalDate: shippingOption.arrival_date,
    price: shippingOption.price,
    currency: shippingOption.currency,
    id: shippingOption.id,
    carrierId: shippingOption.carrier_id,
    serviceType: shippingOption.service_type,
    logisticType: shippingOption.logistic_type,
    shippingType: shippingOption.shipping_type,
  }),
  C: (shippingOption: ShippingOptionC) => ({
    carrierName: shippingOption.carrier_name,
    price: shippingOption.price,
    currency: shippingOption.currency,
    id: shippingOption.id,
    shippingType: shippingOption.shipping_type,
    description: shippingOption.description,
    priceType: shippingOption.price_type,
    arrivalDate: shippingOption.delivery_time,
  }),
}

export function transformResponse(response: Response): Result {
  return {
    meta: {
      totalCount: response.meta.total_count,
      subtotalPrice: response.meta.subtotal_price,
      totalPrice: response.meta.total_price,
      currency: response.meta.currency,
      uniqueId: response.meta.unique_id,
      storeId: response.meta.store_id,
      identifier: response.meta.identifier,
      contact: response.meta.contact
        ? {
            firstName: response.meta.contact.first_name,
            lastName: response.meta.contact.last_name,
            identityType: response.meta.contact.identity_type,
            identityNumber: response.meta.contact.identity_number,
            email: response.meta.contact.email,
            phone: response.meta.contact.phone,
          }
        : null,
      shipping: response.meta.shipping
        ? {
            shippingType: response.meta.shipping.shipping_type,
            id: response.meta.shipping.id,
            shippingStatus: response.meta.shipping.shipping_status,
            price: response.meta.shipping.price,
            currency: response.meta.shipping.currency,
            trackingExternal: response.meta.shipping.tracking_external || "",
            receiver: response.meta.shipping.receiver
              ? {
                  firstName: response.meta.shipping.receiver.first_name,
                  lastName: response.meta.shipping.receiver.last_name,
                  identityType: response.meta.shipping.receiver.identity_type,
                  identityNumber:
                    response.meta.shipping.receiver.identity_number,
                  phone: response.meta.shipping.receiver.phone,
                }
              : null,
            destination: response.meta.shipping.destination
              ? {
                  addressStreet:
                    response.meta.shipping.destination.address_street,
                  addressNumber:
                    response.meta.shipping.destination.address_number,
                  addressOther:
                    response.meta.shipping.destination.address_other,
                  city: response.meta.shipping.destination.city,
                  postalCode: response.meta.shipping.destination.postal_code,
                  state: response.meta.shipping.destination.state,
                }
              : null,
          }
        : null,
      billing: response.meta.billing
        ? {
            fullName: response.meta.billing.full_name,
            identityNumber: response.meta.billing.identity_number,
            identityType: response.meta.billing.identity_type,
            addressStreet: response.meta.billing.address_street,
            addressNumber: response.meta.billing.address_number,
            addressOther: response.meta.billing.address_other,
            city: response.meta.billing.city,
            postalCode: response.meta.billing.postal_code,
            state: response.meta.billing.state,
          }
        : null,
      payment: response.meta.payment
        ? {
            type: response.meta.payment.type,
            initPoint: response.meta.payment.init_point,
            id: response.meta.payment.id,
            status: response.meta.payment.status,
          }
        : null,
      userComment: response.meta.user_comment,
      extraData: response.meta.extra_data,
      preferenceId: response.meta.preference_id,
      timeLeft: response.meta.time_left,
      checkoutStep: response.meta.checkout_step,
      sellerEmail: response.meta.seller_email,
      requirements: {
        shippingMethods: response.meta.requirements.shipping_methods.map(
          transformShippingMethod
        ),
        shippingOptions: (function parseShippingOptions() {
          if (isMetaDataRawZ(response.meta)) {
            // if shippingMethod === Z may contain shippingOption Z | C
            return {
              __kind: "Z" as "Z",
              options: response.meta.requirements.shipping_options.map(
                shippingOption => {
                  if (shippingOption?.shipping_type === "C") {
                    // shippingOption === C
                    return shippingOptions["C"](shippingOption)
                  }
                  return shippingOptions["Z"](shippingOption)
                }
              ),
            }
          } else if (isMetaDataRawS(response.meta)) {
            return {
              __kind: "S" as "S",
              options: response.meta.requirements.shipping_options.map(
                option => ({
                  id: option.id,
                  name: option.name,
                  zipCode: option.zip_code,
                  street: option.street,
                  streetNumber: option.street_number,
                  streetExtra: option.street_extra,
                  city: option.city,
                  state: option.state,
                  hours: option.hours,
                  shippingType: option.shipping_type,
                  phone: option.phone,
                  email: option.email,
                })
              ),
            }
          } else if (isMetaDataRawC(response.meta)) {
            // if shippingMethod === C may contain shippingOption Z | C
            return {
              __kind: "C" as "C",
              options: response.meta.requirements.shipping_options.map(
                shippingOption => {
                  if (shippingOption?.shipping_type === "C") {
                    // shippingOption === C
                    return shippingOptions["C"](shippingOption)
                  }
                  return shippingOptions["Z"](shippingOption)
                }
              ),
            }
          } else if (isMetaDataRawP(response.meta)) {
            return {
              __kind: "P" as "P",
              options: response.meta.requirements.shipping_options.map(
                shippingOption => ({
                  carrierName: shippingOption.carrier_name,
                  distance: shippingOption.distance,
                  arrivalDate: shippingOption.arrival_date,
                  location: {
                    street: shippingOption.location.street,
                    streetNumber: shippingOption.location.street_number,
                    streetExtras: shippingOption.location.street_extras,
                    city: shippingOption.location.city,
                    state: shippingOption.location.state,
                  },
                  openHours: shippingOption.open_hours,
                  phone: shippingOption.phone,
                  description: shippingOption.description,
                  price: shippingOption.price,
                  currency: shippingOption.currency,
                  carrierId: shippingOption.carrier_id,
                  id: shippingOption.id,
                  serviceType: shippingOption.service_type,
                  logisticType: shippingOption.logistic_type,
                  shippingType: shippingOption.shipping_type,
                })
              ),
            }
          }

          return {
            __kind: null,
          }
        })(),
        paymentOptions: response.meta.requirements.payment_options.map(
          paymentOption => ({
            name: paymentOption.name,
            paymentType: paymentOption.payment_type,
            id: paymentOption.id,
            description: paymentOption.description,
            discountValue: paymentOption.discount_value,
            discountType: paymentOption.discount_type,
            offersDiscount: paymentOption.offers_discount,
            currency: paymentOption.currency,
          })
        ),
      },
      freeShipping: Boolean(response.meta.free_shipping),
    },
    objects: response.objects.map(checkoutLine => ({
      productId: checkoutLine.product_id,
      productIdentifier: checkoutLine.product_identifier,
      variationId: checkoutLine.variation_id,
      price: checkoutLine.price,
      currency: checkoutLine.currency,
      quantity: checkoutLine.quantity,
      title: checkoutLine.title,
      thumbnail: checkoutLine.thumbnail,
      values: checkoutLine.values,
    })),
  }
}

export function transformErrorResponse(response: ErrorResponse): ErrorResult {
  return {
    error: {
      field: response.error.field,
      code: response.error.code_error,
      message: response.error.message,
    },
  }
}
