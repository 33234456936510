import { Checkmark } from "../../icons"
import { PropsWithTheme } from "../../providers/StoreThemeProvider"
import { colors } from "../../theme"
import styled from "@emotion/styled/macro"

type Props = {
  name?: import("react").InputHTMLAttributes<HTMLInputElement>["name"]
  inputRef?: import("react").ClassAttributes<HTMLInputElement>["ref"]
  disabled?: import("react").InputHTMLAttributes<HTMLInputElement>["disabled"]
  checked?: import("react").InputHTMLAttributes<HTMLInputElement>["checked"]
  onChange?: import("react").InputHTMLAttributes<HTMLInputElement>["onChange"]
  label: import("react").ReactNode
  value: string
}

export function CheckboxField({
  name,
  inputRef,
  label,
  value,
  disabled,
  checked,
  onChange,
}: Props) {
  return (
    <Container>
      <Checkbox
        id={value}
        name={name}
        ref={inputRef}
        type="checkbox"
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <CheckmarkHolder disabled={disabled} />
      <CheckmarkContainer disabled={disabled}>
        <Checkmark />
      </CheckmarkContainer>
      <Label htmlFor={value} disabled={disabled}>
        {label}
      </Label>
    </Container>
  )
}

const Container = styled.label<PropsWithTheme>(props => ({
  display: "grid",
  position: "relative",
  gridTemplateColumns: `1.4rem auto`,
  fontSize: "1.4rem",
}))

type DisableProp = {
  disabled?: boolean
}

const CheckmarkHolder = styled.div<DisableProp>(props => ({
  gridColumnStart: 1,
  gridColumnEnd: 2,
  position: "absolute",
  borderRadius: 2,
  border: `2px solid ${props.disabled ? colors.greyMain : colors.greyDark}`,
  width: "1em",
  height: "1em",
  boxSizing: "border-box",
  pointerEvents: "none",
  backgroundColor: colors.white,
  alignSelf: "center",
  justifySelf: "center",
  cursor: props.disabled ? "default" : "pointer",
}))

const CheckmarkContainer = styled.div<PropsWithTheme & DisableProp>(props => ({
  gridColumnStart: 1,
  gridColumnEnd: 2,
  position: "absolute",
  display: "none",
  backgroundColor: props.disabled
    ? colors.greyMain
    : props.theme.colors.primary,
  borderRadius: 2,
  alignSelf: "center",
  justifySelf: "center",
  cursor: props.disabled ? "default" : "pointer",
  color: colors.white,

  "& svg": {
    fontSize: "1rem",
    verticalAlign: "top",
  },
}))

const Label = styled.label<DisableProp>(props => ({
  fontSize: "1em",
  color: props.disabled ? colors.greyMain : colors.greyDark,
  gridColumnStart: 2,
  gridColumnEnd: 3,
  paddingLeft: "0.8rem",
  cursor: props.disabled ? "default" : "pointer",
}))

const Checkbox = styled.input<PropsWithTheme & DisableProp>`
  opacity: 0;
  position: absolute;
  grid-column-start: 1;
  grid-column-end: 2;
  align-self: center;
  justify-self: center;
  margin: 0;
  cursor: ${props => (props.disabled ? "default" : "pointer")};

  &:focus {
    & ~ ${CheckmarkHolder} {
      border: 2px solid ${props => props.theme.colors.primary};
    }
  }

  &:checked {
    & ~ ${CheckmarkContainer} {
      height: 1rem;
      transition: width 0.1s ease-out, height 0.1s ease-out;
      display: block;
    }

    & ~ ${CheckmarkHolder} {
      border: 1px solid
        ${props =>
          props.disabled ? colors.greyMain : props.theme.colors.primary};
      background-color: ${props =>
        props.disabled ? colors.greyMain : props.theme.colors.primary};
    }
  }
`
