import { ScreenSizeContext } from "../providers/ScreenSizeProvider"
import { useContext } from "react"

type ScreenSize = {
  innerHeight: number
  innerWidth: number
  isDesktop: boolean
  isMobile: boolean
}

export function useScreenSize(): ScreenSize {
  const screenSize = useContext(ScreenSizeContext)

  if (!screenSize)
    throw new Error("useScreenSize must be used within a ScreenSizeProvider")

  return screenSize
}
