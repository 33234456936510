import { Action, SelectedValues, State } from "./types"
import { createContext, useContext, useReducer } from "react"

import { Variant } from "../types"
import { api } from "../../../api"
import { productReducer } from "./reducers/productReducer"

type ContextType = {
  product: api.products.variations.Get.Result
  selectedVariant: Variant | null
  selectedValues: SelectedValues[]
  quantity: number
  dispatch: (action: Action) => void
}

const ProductVIPContext = createContext<ContextType | null>(null)

type Props = {
  children: import("react").ReactNode
}

export const ProductStoreProvider = ({ children }: Props) => {
  const initialState: State = {
    product: {
      images: [],
      breadcrumb: [],
      coverImageId: "",
      description: "",
      identifier: "",
      status: "A",
      canQuote: true,
      properties: [],
      title: "",
      variants: [],
      attributes: [],
    },
    selectedVariant: null,
    selectedValues: [],
    quantity: 1,
  }

  const [
    { product, selectedVariant, selectedValues, quantity },
    dispatch,
  ] = useReducer(productReducer, initialState)

  const value = { product, selectedVariant, selectedValues, quantity, dispatch }

  return (
    <ProductVIPContext.Provider value={value}>
      {children}
    </ProductVIPContext.Provider>
  )
}

export const useProductStore = () => {
  const productStore = useContext(ProductVIPContext)
  if (!productStore) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error("You have forgot to use StoreProvider, shame on you.")
  }
  return productStore
}
