import { PropsWithTheme } from "../providers/StoreThemeProvider"
import { keyframes } from "@emotion/react"
import { readableColor } from "polished"
import styled from "@emotion/styled"

type Props = {
  size?: string
  color?: string
}

export function Spinner({ color, size = "3.4rem" }: Props) {
  return (
    <Container key="spinner" size={size} color={color}>
      <SVG viewBox="22 22 44 44" fill="currentColor" size={size}>
        <Circle
          cx="44"
          cy="44"
          r="20.2"
          fill="none"
          strokeWidth="3.6"
          color={color}
        />
      </SVG>
    </Container>
  )
}

type SVGProps = {
  size: string
}

const Container = styled.div<SVGProps>(({ size }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: size,
  height: size,
}))

const rotate = keyframes`
  100% {
    transform: rotate(360dg)
  }
`

const SVG = styled.svg<SVGProps>(({ size }) => ({
  animation: `${rotate} 2s linear infinite`,
  zIndex: 2,
  width: size,
  height: size,
}))

type CircleProps = {
  color?: string
}

const Circle = styled.circle<CircleProps & PropsWithTheme>(
  ({ color, ...props }) => `
  stroke: ${color || readableColor(props.theme.colors.background)};
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
  stroke-dasharray: "80px, 200px";
  stroke-dashoffset: 0;
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200px;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -15px;
    }
    100% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -125px;
    }
  }
`
)
