import { Response } from "../../../checkout/types"
import paths from "../../../../paths"
import { service } from "../../../../Service"
import { transformResponse } from "../../../checkout"

// import fetchMock from "fetch-mock"
// import { fetchMockConfig } from "../../../../api"

export function post() {
  return async function () {
    // if (process.env.REACT_APP_MOCK) {
    //   fetchMock.post(
    //     /\/v1\/stores\/[\d\w]+\/carts\/start_checkout\//,
    //     require("./fixture.json"),
    //     fetchMockConfig()
    //   )
    // }
    const body = null

    try {
      const response = await service.post<Response>(paths.startCheckout(), body)

      return transformResponse(response.body)
    } catch (error) {
      console.log(error)

      throw new Error(`API POST Checkout did not respond correctly`)
    }
  }
}
