import { colors, ecommerceColors } from "../../theme"

import { PropsWithTheme } from "../../providers/StoreThemeProvider"
import styled from "@emotion/styled/macro"

type Props = {
  name?: import("react").InputHTMLAttributes<HTMLInputElement>["name"]
  inputRef?: import("react").ClassAttributes<HTMLInputElement>["ref"]
  disabled?: import("react").InputHTMLAttributes<HTMLInputElement>["disabled"]
  value?: string
  onChange?: import("react").InputHTMLAttributes<HTMLInputElement>["onChange"]
  errorMsg?: string
  maxLength?: import("react").InputHTMLAttributes<HTMLInputElement>["maxLength"]
  hideLength?: boolean
  type?: import("react").InputHTMLAttributes<HTMLInputElement>["type"]
  autoComplete?: import("react").InputHTMLAttributes<HTMLInputElement>["autoComplete"]
  label: import("react").ReactNode
  onBlur?: import("react").InputHTMLAttributes<HTMLInputElement>["onBlur"]
}

/**
 * @onBlur `e.target.value` is trimmed before handling it to the callback and call onChange after call onBlur
 */
export function TextField({
  name,
  inputRef,
  label,
  errorMsg,
  value,
  onChange,
  maxLength,
  hideLength,
  disabled,
  onBlur,
  type = "text",
  autoComplete,
}: Props) {
  return (
    <Container>
      <InputContainer>
        <InputField
          id={name}
          name={name}
          ref={inputRef}
          type={type}
          autoComplete={autoComplete}
          disabled={disabled}
          hasError={!!errorMsg}
          hasValue={!!value}
          maxLength={maxLength}
          onBlur={_onBlur}
          {...(value != null && onChange != null
            ? { value, onChange: _onChange }
            : {})}
        />
        <Label
          disabled={disabled}
          hasValue={!!value}
          hasError={!!errorMsg}
          htmlFor={name}
        >
          {label}
        </Label>
      </InputContainer>
      <Helpers>
        {!!errorMsg && <HelperErrorText>{errorMsg}</HelperErrorText>}
        {!!maxLength && !hideLength && (
          <HelperChar>
            {!!value ? value.length : 0}/{maxLength}
          </HelperChar>
        )}
      </Helpers>
    </Container>
  )

  function _onBlur(e: import("react").FocusEvent<HTMLInputElement>) {
    e.target.value = e.target.value.trim()

    if (onBlur != null) {
      onBlur(e)
      // TODO remove react-hook-form don't save onBlur
      if (onChange != null) onChange(e)
    }
  }

  function _onChange(e: import("react").ChangeEvent<HTMLInputElement>) {
    if (e.target.value === " ") e.target.value = ""

    if (onChange != null) {
      onChange(e)
    }
  }
}

const Container = styled.div<PropsWithTheme>(props => ({
  display: "grid",
  gridGap: "0.4rem",
  gridTemplateRows: `1.6rem auto 1.6rem`,
  backgroundColor: "transparent",
}))

const InputContainer = styled.div({
  position: "relative",
  gridRowStart: 2,
  gridRowEnd: 3,
})

type LabelProps = {
  hasValue?: boolean
  hasError?: boolean
  disabled?: boolean
}

const Label = styled.label<PropsWithTheme & LabelProps>(
  ({ hasValue, hasError, disabled }) => ({
    fontSize: hasValue ? "1.2rem" : "1.4rem",
    position: "absolute",
    backgroundColor: "transparent",
    top: 0,
    left: "0.4rem",
    padding: 0,
    width: hasValue ? "auto" : "calc(100% - 2.4rem)",
    color: disabled
      ? colors.greyMain
      : hasError && hasValue
      ? colors.redMain
      : hasValue
      ? ecommerceColors.greyDark
      : colors.greyMedium,
    pointerEvents: "none",
    transform: hasValue ? "translate(-0.2rem, -2rem)" : "none",
    transition: "all 200ms cubic-bezier(0.0, 0, 0.2, 1)",
  })
)

type InputFieldProps = {
  hasError: boolean
  hasValue: boolean
}

const InputField = styled.input<PropsWithTheme & InputFieldProps>(props => ({
  color: colors.greyDark,
  fontSize: "1.4rem",
  border: 0,
  borderBottom: `1px solid ${
    props.hasError
      ? colors.redMain
      : props.hasValue
      ? props.theme.colors.primary
      : colors.greyMain
  }`,
  outline: "none",
  width: "100%",
  gridRowStart: 2,
  gridRowEnd: 3,
  backgroundColor: "transparent",

  "&:focus": {
    backgroundColor: "transparent",
    borderBottom: `1px solid ${
      props.hasError ? colors.redMain : ecommerceColors.greyDark
    }`,
  },

  "&:focus ~ label": {
    backgroundColor: "transparent",
    color: props.hasError ? colors.redMain : ecommerceColors.greyDark,
    fontSize: "1.2rem",
    transform: "translateY(-20px)",
  },
  "&:disabled": {
    backgroundColor: "transparent",
    borderBottomColor: ecommerceColors.grey,
    color: ecommerceColors.grey,
    cursor: "not-allowed",
    "~ label": {
      color: ecommerceColors.grey,
    },
  },
}))

const Helpers = styled.div({
  gridRowStart: 3,
  display: "grid",
  gridAutoFlow: "column",
  fontSize: "1.2rem",
})

const HelperErrorText = styled.span({
  color: colors.redMain,
})

const HelperChar = styled.span({
  textAlign: "end",
  color: colors.greyMain,
})
