import { rest } from "msw"
import { delay } from "../../../utils"

export const pageConfig = [
  rest.get("*/v1/stores/:storeID/page-config/:pageID/", function(
    _req,
    res,
    ctx
  ) {
    return res(delay(ctx), ctx.json(require("./get.fixture.json")))
  }),
]
