import { Response, Result } from "./types"

import paths from "../../../paths"
import { service } from "../../../Service"

export async function get() {
  try {
    const response = await service.get<Response>(paths.pickUpPoints())

    return transformResponse(response.body)
  } catch (error) {
    throw new Error(`API Pick Up Points did not respond correctly`)
  }
}

function transformResponse(response: Response): Result {
  const pickUpPoints = response.objects.map(branch => {
    return {
      fullAddress: `${branch.street_address}, ${branch.postal_code}, ${branch.district}, ${branch.city}, ${branch.country}`,
      id: branch.id,
      branchId: branch.branch_id,
      branchName: branch.branch_name,
      latitude: branch.latitude,
      longitude: branch.longitude,
      availableTimeFrame: branch.available_timeframe,
    }
  })

  return {
    totalCount: response.meta.total_count,
    pickUpPoints,
  }
}

export * as Get from "./types"
