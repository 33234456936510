import { AlertBox } from "../../../../../../../components/AlertBox"
import { Fragment } from "react"
import { Property } from "../../types"
import { PropertyValuesDropdown } from "../PropertyValuesDropdown"
import { PropsWithTheme } from "../../../../../../../providers/StoreThemeProvider"
import { ValuesList } from "../ValuesList/ValuesList"
import styled from "@emotion/styled"
import { useProductStore } from "../../../../../provider/ProductStoreProvider"

type PropertiesProps = {
  properties: Property[]
  isDisabledProduct: boolean
  variantHasError: boolean
  resetVariantHasError: () => void
}
export function PropertiesList({
  isDisabledProduct,
  properties,
  variantHasError,
  resetVariantHasError,
}: PropertiesProps) {
  const { selectedValues } = useProductStore()

  return (
    <>
      {properties.map(property => {
        const { name, values, id: propertyId } = property

        const isNotSelected = !selectedValues.find(
          propertyValue => propertyValue.propertyId === propertyId
        )

        const hasErrorAndIsValueList =
          variantHasError && isNotSelected && values.length < 6

        return (
          <Fragment key={propertyId}>
            <PropertyName>{name}</PropertyName>
            {hasErrorAndIsValueList && (
              <AlertBox text="Seleccioná una opción posible" variant="error" />
            )}
            <ButtonsContainer>
              {values.length > 6 ? (
                <PropertyValuesDropdown
                  propertyId={propertyId}
                  name={name}
                  values={values}
                  variantHasError={variantHasError}
                  resetVariantHasError={resetVariantHasError}
                />
              ) : (
                <ValuesList
                  propertyId={propertyId}
                  values={values}
                  isDisabledProduct={isDisabledProduct}
                  resetVariantHasError={resetVariantHasError}
                />
              )}
            </ButtonsContainer>
          </Fragment>
        )
      })}
    </>
  )
}

const PropertyName = styled.p<PropsWithTheme>(props => ({
  fontFamily: props.theme.typographies.texts,
  fontSize: "1.6rem",
  color: props.theme.colors.texts,
  marginBottom: "1.2rem",
  marginTop: "2.4rem",
}))

const ButtonsContainer = styled.div({
  display: "flex",
  flexWrap: "wrap",
  gap: "1.6rem",
})
