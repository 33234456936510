import { Link, RouteComponentProps } from "react-router-dom"

import { Fragment } from "react"
import { RTCFooter } from "store-components"
import { RTCFooterProps } from "store-components/dist/Footer"
import { i18n } from "@lingui/core"
import { parseHomeUrl } from "../helpers/parseHomeUrl"
import { useStoreState } from "../../../providers/StoreProvider"

export function Footer({ match, location }: RouteComponentProps) {
  const [state] = useStoreState()

  const phoneNumber = state.config.footer.phoneNumber

  const hasPlusSymbol = phoneNumber[0] === "+"

  const parsedPhoneNumber = state.config.footer.phoneNumber.replace(
    /[^0-9]/gi,
    ""
  )

  const phoneNumberURI = `${hasPlusSymbol ? "+" : ""}${parsedPhoneNumber}`

  const logo = state.config.useSameLogo
    ? { ...state.config.logoHeader }
    : { ...state.config.logoFooter }

  const footerProps: RTCFooterProps = {
    skin: state.config.footer.design,
    backgroundColor: state.config.footer.backgroundColor,
    homeUrl: parseHomeUrl(),
    htmlAfip: state.config.footer.htmlAfip,
    logoColor: logo.color,
    logoContent: logo.text,
    logoImage: logo.img,
    logoType: logo.type,
    logoTypography: logo.typography,
    menu: state.config.menu.map(({ id, name, url, type }) => ({
      id: id,
      title: name,
      href: type !== "L" || /^https?:\/\//.test(url) ? url : "http://" + url,
      type: type,
    })),
    phoneNumber: state.config.footer.phoneNumber,
    phoneNumberURI: `tel:${phoneNumberURI}`,
    renderLink: ({ title, href }: { title: string; href: string }) => (
      <Link title={title} to={href}>
        {title}
      </Link>
    ),
    showContact: state.config.footer.showContact,
    showNavigation: state.config.footer.showNavigation,
    socialNetworks: state.config.footer.socials,
    typographyTexts: state.config.typographies.texts,
    shopName: window.STORE_NAME,
    email: state.config.email || "",
    storeId: window.STORE_ID || "",
    language: i18n.locale,
  }
  return (
    <Fragment>
      <RTCFooter {...footerProps} />
    </Fragment>
  )
}
