import { useEffect, useLayoutEffect, useState } from "react"

export function useCarrousel<T>(items: T[], selectedItem: T) {
  const [selectedItemIndex, setSelectedItemIndex] = useState(
    items.indexOf(selectedItem)
  )

  const [_selectedItem, setSelectedItem] = useState(selectedItem)

  useEffect(
    function () {
      setSelectedItem(selectedItem)
      setSelectedItemIndex(items.indexOf(selectedItem))
    },
    [items, selectedItem]
  )

  useLayoutEffect(
    function () {
      setSelectedItem(items[selectedItemIndex])
    },
    [items, selectedItemIndex]
  )

  const totalItems = items.length
  const lastItemIndex = totalItems - 1

  function handleNext(callback?: (item: T) => void) {
    if (selectedItemIndex === lastItemIndex) {
      if (callback != null) callback(items[0])
      setSelectedItemIndex(0)
    } else {
      if (callback != null) callback(items[selectedItemIndex + 1])
      setSelectedItemIndex(currentIndex => currentIndex + 1)
    }
  }

  function handlePrev(callback?: (item: T) => void) {
    if (selectedItemIndex === 0) {
      if (callback != null) callback(items[lastItemIndex])
      setSelectedItemIndex(lastItemIndex)
    } else {
      if (callback != null) callback(items[selectedItemIndex - 1])
      setSelectedItemIndex(currentIndex => currentIndex - 1)
    }
  }

  return {
    items,
    selectedItem: _selectedItem,
    handleNext,
    handlePrev,
    selectedItemIndex,
    setSelectedItem,
  }
}
