import { useEffect, useMemo, useState } from "react"

import { useProductStore } from "../provider/ProductStoreProvider"

export function useProductInfo() {
  const { product, dispatch } = useProductStore()
  const [productHasStock, setProductHasStock] = useState<boolean>()
  const [minPrice, setMinPrice] = useState(0)
  const [
    productHasDifferentPrices,
    setProductHasDifferentPrices,
  ] = useState<boolean>()

  useEffect(() => {
    let hasDifferentPrices = false
    let hasStock = false
    let minPrice = 0

    if (product.variants.length > 0) {
      const prices = product.variants.map(variant => {
        hasDifferentPrices =
          product.variants[0].price !== variant.price || hasDifferentPrices
        hasStock = variant.stock > 0 || hasStock
        return variant.price
      })
      minPrice = Math.min(...prices)
    }

    setProductHasStock(hasStock)
    setMinPrice(minPrice)
    setProductHasDifferentPrices(hasDifferentPrices)
  }, [product.variants, dispatch])

  return useMemo(
    () => ({
      productHasStock,
      minPrice,
      productHasDifferentPrices,
    }),
    [productHasStock, minPrice, productHasDifferentPrices]
  )
}
