import { ErrorRaw, ErrorResult, Response, Result } from "./types"
import { RequestBody, RequestBodyRaw } from "./types"

import paths from "../../../paths"
import { service } from "../../../Service"

export async function post(shippingCostData: RequestBodyRaw) {
  const body = transformRequest(shippingCostData)

  try {
    const response = await service.post<Response | ErrorRaw>(
      paths.quote(),
      body
    )

    if (response.status.code === 400) {
      return transformErrorResponse(response.body as ErrorRaw)
    } else {
      return transformResponse(response.body as Response)
    }
  } catch (error) {
    console.log(error)

    throw new Error("API Shipping Cost did not respond correctly")
  }
}

export const transformRequest = (
  shippingCostData: RequestBodyRaw
): RequestBody => {
  return {
    product_id: shippingCostData.productID,
    variant_id: shippingCostData.variantID.toString(),
    city: shippingCostData.shippingCity,
    postal_code: shippingCostData.shippingPostalCode,
    state: shippingCostData.shippingState,
    quantity: shippingCostData.quantity,
  }
}

export function transformResponse(response: Response): Result {
  const shippingOptions = response.results.standard_delivery.map(
    ({ delivery_time, ...option }) => ({
      ...option,
      deliveryTime: {
        date: delivery_time.date,
        isRange: delivery_time.is_range,
      },
    })
  )

  return {
    results: shippingOptions,
  }
}

export function transformErrorResponse(response: ErrorRaw): ErrorResult {
  return {
    error: {
      message: response.error.message,
      field: response.error.field,
      codeError: response.error.code_error,
    },
  }
}
