import { Container } from "./Container"

export function Filters() {
  return (
    <Container>
      <path
        d="M9.3913,2.387V1.1739a.3913.3913,0,1,0-.7826,0V2.3871a1.9569,1.9569,0,0,0,0,3.8346V16.8261a.3913.3913,0,0,0,.7826,0V6.2216a1.9569,1.9569,0,0,0,0-3.8346ZM9,5.4783a1.174,1.174,0,1,1,1.1739-1.174A1.1751,1.1751,0,0,1,9,5.4783Z"
        fill="currentColor"
      />
      <path
        d="M2.3478,11.7783V1.1739a.3913.3913,0,0,0-.7826,0V11.7784a1.9569,1.9569,0,0,0,0,3.8346v1.2131a.3913.3913,0,0,0,.7826,0V15.6129a1.9569,1.9569,0,0,0,0-3.8346ZM1.9565,14.87A1.174,1.174,0,1,1,3.13,13.6957,1.175,1.175,0,0,1,1.9565,14.87Z"
        fill="currentColor"
      />
      <path
        d="M15.6522,12.0912v4.7349a.3913.3913,0,1,0,.7826,0V12.0912a1.9569,1.9569,0,0,0,0-3.8346V1.1739a.3913.3913,0,0,0-.7826,0V8.2566a1.9569,1.9569,0,0,0,0,3.8346ZM16.0435,9A1.1739,1.1739,0,1,1,14.87,10.1739,1.175,1.175,0,0,1,16.0435,9Z"
        fill="currentColor"
      />
    </Container>
  )
}
