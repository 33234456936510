import { Response, Result } from "./types"

import paths from "../../../paths"
import { service } from "../../../Service"

export function get(pageId: string) {
  return async function () {
    try {
      const response = await service.get<Response>(paths.pageConfig(pageId))

      return transformResponse(response.body)
    } catch (error) {
      throw new Error(`API Page Config did not respond correctly`)
    }
  }
}

function transformResponse(response: Response): Result {
  const pageComponents = response.elements.map(component => {
    return {
      id: component.identifier,
      kind: component.kind,
      props: component.props,
    }
  })

  return pageComponents
}

export * as Get from "./types"
