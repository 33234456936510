import { Currency } from "../../../../../../utils/PriceUtil"
import { CustomShippingItem } from "./CustomShippingItem"
import { Fragment } from "react"
import { Trans } from "@lingui/macro"
import { api } from "../../../../../../api"
import { colors } from "../../../../../../theme"
import styled from "@emotion/styled/macro"

type Props = {
  customMethods: api.stores.shippings.Get.Custom[]
  hasCanQuote: boolean
  productPrice: number
  productSelectedQuantity: number
  freeShippingAmount: api.stores.shippings.Get.FreeShipping
  currency: Currency
  freeShipping: boolean
}

export function CustomShippingsOptions({
  customMethods,
  hasCanQuote,
  productPrice,
  freeShipping,
  freeShippingAmount,
  productSelectedQuantity,
  currency,
}: Props) {
  const hasFreeShipping =
    freeShipping &&
    freeShippingAmount.active &&
    freeShippingAmount.starterAmount <= productPrice * productSelectedQuantity

  return (
    <Fragment>
      <Subtitle>
        <Trans>Te ofrecemos las siguientes opciones:</Trans>
      </Subtitle>
      <ShippingOptionsContainer
        hasCanQuote={hasCanQuote}
        data-testid="custom-shipping-options"
      >
        {customMethods?.map(custom => (
          <CustomShippingItem
            deliveryTime={custom.deliveryTime}
            key={custom.id}
            name={custom.name}
            price={hasFreeShipping ? 0 : custom.price}
            priceType={custom.priceType}
            showDeliveryTime={custom.showDeliveryTime}
            currency={currency}
          />
        ))}
      </ShippingOptionsContainer>
    </Fragment>
  )
}

const Subtitle = styled.h2({
  fontSize: "1.4rem",
  fontWeight: "normal",
  color: colors.greyDark,
  margin: "1.6rem 0.8rem",
})

type ShippingOptionsContainerProps = {
  hasCanQuote: boolean
}

const ShippingOptionsContainer = styled.div<ShippingOptionsContainerProps>(
  ({ hasCanQuote }) => ({
    borderRadius: hasCanQuote ? "4px 4px 0 0 " : 4,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colors.greyMainLight,
    overflowY: "auto",
    maxHeight: "22.8rem",
  })
)
