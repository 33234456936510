import { delay } from "../../../../utils"
import { rest } from "msw"

export const startCheckout = [
  rest.post(
    "*/v1/stores/:storeId/carts/start_checkout/",
    function (_req, res, ctx) {
      return res(delay(ctx), ctx.json(require("./fixture.json")))
    }
  ),
]
