import "moment/locale/es"
import "moment/locale/pt-br"
import "moment/locale/es-mx"

import React, { useEffect, useState } from "react"
import { es, pt } from "make-plural/plurals"

import { I18nProvider } from "@lingui/react"
import { api } from "../../api"
import { i18n } from "@lingui/core"
import moment from "moment"

const defaultLocale = "es-AR"

i18n.loadLocaleData({
  "es-AR": { plurals: es },
  "pt-BR": { plurals: pt },
  "es-MX": { plurals: es },
})

const dateLocales = {
  "es-AR": "es",
  "pt-BR": "pt-br",
  "es-MX": "es-mx",
}

async function dynamicActivate(locale: "es-AR" | "es-MX" | "pt-BR") {
  const { messages } = await import(`../../locales/${locale}/messages`)
  i18n.load(locale, messages)

  i18n.activate(locale)

  // dates languages configuration
  moment.locale(dateLocales[locale])
}

export const LanguageProvider: React.FunctionComponent = ({ children }) => {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const init = async () => {
      try {
        const { language } = await api.language.get()

        await dynamicActivate(language)
      } catch {
        console.error("Could not get store language")
        await dynamicActivate(defaultLocale)
      }

      setReady(true)
    }

    init()
  }, [])

  if (!ready) return null

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>
}
