import { Redirect, Route } from "react-router-dom"
import { colors, mediaQuery } from "../../../../theme"

import { AlertBox } from "../../../../components/AlertBox"
import { PurchaseDetailCard } from "./components/PurchaseDetailCard"
import { StepContact } from "./components/StepContact"
import { StepShipping } from "./components/StepShipping"
import { Stepper } from "./components/Stepper"
import { ThankYouPage } from "./components/ThankYouPage"
import { checkoutRoute } from "../../../../utils/locationMatchCheckout"
import styled from "@emotion/styled"
import { t } from "@lingui/macro"
import { useCheckout } from "../../../../hooks"
import { useCheckoutScreen } from "../../hooks/useCheckoutScreen"
import { useState } from "react"

const checkoutPath = checkoutRoute.substring(0, checkoutRoute?.lastIndexOf("/"))
const thankYouPath = `${checkoutPath}/:id`

export type AppliedDiscountType = {
  discountType: "P" | "A" | null
  discountValue: number | null
}

export const BodyCheckout = () => {
  const { currentStep, error } = useCheckoutScreen()
  const { uniqueId, requirements } = useCheckout().data.meta

  const [appliedDiscount, setAppliedDiscount] = useState<AppliedDiscountType>({
    discountType: null,
    discountValue: null,
  })

  const changeAppliedDiscount = (appliedDiscount: AppliedDiscountType) => {
    setAppliedDiscount(appliedDiscount)
  }

  const hasCustomPaymentsWithDiscounts = requirements?.paymentOptions?.some(
    option => option.paymentType === "C" && option.offersDiscount
  )
  return (
    <>
      {currentStep === 3 && (
        <Route exact path={thankYouPath}>
          <Container whiteBackground={true}>
            <ThankYouPage />
          </Container>
        </Route>
      )}
      <Route exact path={checkoutPath}>
        <Container>
          <Content>
            <Stepper />

            {(error?.code === "postal_code" ||
              error?.code === "quote_error") && (
              <AlertContainer>
                <AlertBox
                  size="large"
                  title={t`No pudimos cotizar tu envío`}
                  text={t`Revisá los datos de entrega y volvé a intentarlo.`}
                  variant="error"
                />
              </AlertContainer>
            )}

            {error?.code === "general" && (
              <AlertContainer>
                <AlertBox size="large" text={error?.message} variant="error" />
              </AlertContainer>
            )}
            <PurchaseDetailCard
              {...(hasCustomPaymentsWithDiscounts
                ? { appliedDiscount: appliedDiscount }
                : {})}
            />
            <StepContact isCurrent={currentStep === 1} />
            <StepShipping
              isCurrent={currentStep === 2}
              changeAppliedDiscount={changeAppliedDiscount}
            />
            {currentStep === 3 && (
              <Redirect to={thankYouPath.replace(":id", uniqueId)} />
            )}
          </Content>
        </Container>
      </Route>
    </>
  )
}

const Container = styled.div<{ whiteBackground?: boolean }>(
  ({ whiteBackground }) => ({
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    padding: "1.6rem 1.6rem 2.4rem 1.6rem",
    backgroundColor: whiteBackground ? colors.white : "inherit",

    [mediaQuery.desktop]: {
      padding: "2.4rem 0 7.2rem 0",
    },
  })
)

const Content = styled.div({
  width: "100%",
  display: "flex",
  flexFlow: "column nowrap",

  [mediaQuery.desktop]: {
    maxWidth: "94rem",
    display: "grid",
    gridTemplateColumns: "2fr 35.6rem",
    gridTemplateRows: "max-content",
    gridColumnGap: "2.4rem",
  },
})

const AlertContainer = styled.div({
  order: 3,
  padding: "0 0 2.4rem 0",

  [mediaQuery.desktop]: {
    gridRowStart: 2,
    gridColumnStart: 1,
    gridColumnEnd: 3,
    padding: "0 0 3.2rem 0",
  },
})
