import { Controller, useFormContext } from "react-hook-form"
import { DualColumnRow, ExternalLink, Subtitle } from "../../../../../../style"
import { Trans, t } from "@lingui/macro"

import { DropdownField } from "../../../../../../../../../../components/DropdownField"
import { ReceiverForm } from "../ReceiverForm"
import { ShippingMethodZData } from "../../../../types"
import { TextField } from "../../../../../../../../../../components/TextField"
import { getLocaleStates } from "../../../../../../../../../../utils/getLocaleStates"
import { i18n } from "@lingui/core"
import { useCheckoutScreen } from "../../../../../../../../hooks/useCheckoutScreen"
import { useEffect } from "react"

type Field = keyof ShippingMethodZData

export const ShippingMethodZ = () => {
  const { error } = useCheckoutScreen()
  const { errors, control, setError } = useFormContext<ShippingMethodZData>()
  const language = i18n.locale

  useEffect(() => {
    const fields: Field[] = [
      "destinationAddressStreet",
      "destinationAddressNumber",
      "destinationCity",
      "destinationPostalCode",
      "destinationState",
    ]
    const errorMsg = {
      type: "manual",
      message: t`Revisá este dato y volvé a completarlo`,
    }

    if (error?.code === "quote_error")
      fields.forEach(field => setError(field, errorMsg))
    else if (error?.code === "postal_code")
      setError("destinationPostalCode", errorMsg)
  }, [error, setError])

  return (
    <>
      <ReceiverForm title={t`¿Quién recibe?`} />
      <Subtitle>
        <Trans>¿Dónde la enviamos?</Trans>
      </Subtitle>

      <Controller
        control={control}
        name="destinationAddressStreet"
        defaultValue=""
        rules={{
          required: t`Completá el campo calle.`,
        }}
        render={props => (
          <TextField
            name="destinationAddressStreet"
            label={<Trans>Calle (sin número)</Trans>}
            errorMsg={errors.destinationAddressStreet?.message}
            maxLength={100}
            hideLength={true}
            {...props}
          />
        )}
      />

      <DualColumnRow>
        <Controller
          control={control}
          name="destinationAddressNumber"
          defaultValue=""
          rules={{
            required: t`Completá el campo número.`,
          }}
          render={({ onChange, value }) => (
            <TextField
              name="destinationAddressNumber"
              label={<Trans>Número</Trans>}
              errorMsg={errors.destinationAddressNumber?.message}
              maxLength={100}
              hideLength={true}
              onChange={onChange}
              value={value?.replace(/[^0-9]/, "")}
            />
          )}
        />
        <Controller
          control={control}
          name="destinationAddressOther"
          defaultValue=""
          render={props => (
            <TextField
              name="destinationAddressOther"
              label={<Trans>Departamento (opcional)</Trans>}
              maxLength={100}
              hideLength={true}
              {...props}
            />
          )}
        />
      </DualColumnRow>

      <Controller
        control={control}
        name="destinationCity"
        defaultValue=""
        rules={{
          required: t`Completá el campo ciudad.`,
        }}
        render={props => (
          <TextField
            name="destinationCity"
            label={<Trans>Ciudad</Trans>}
            errorMsg={errors.destinationCity?.message}
            maxLength={100}
            hideLength={true}
            {...props}
          />
        )}
      />

      <DualColumnRow>
        <Controller
          control={control}
          name="destinationPostalCode"
          defaultValue=""
          rules={{
            required: t`Completá el campo código postal.`,
          }}
          render={({ onChange, value }) => (
            <TextField
              name="destinationPostalCode"
              label={<Trans>Código postal</Trans>}
              errorMsg={errors.destinationPostalCode?.message}
              maxLength={language === "es-AR" ? 4 : 5}
              hideLength={true}
              onChange={onChange}
              value={value?.replace(/[^0-9]/, "")}
            />
          )}
        />
        <ExternalLink
          rel="noopener noreferrer"
          href={t`https://www.correoargentino.com.ar/formularios/cpa`}
          target="_blank"
        >
          <Trans>No conozco mi código postal</Trans>
        </ExternalLink>
      </DualColumnRow>

      <Controller
        control={control}
        name="destinationState"
        defaultValue=""
        rules={{
          required: t`Elegí la provincia.`,
        }}
        render={props => (
          <DropdownField
            name="destinationState"
            label={<Trans>Provincia</Trans>}
            options={getLocaleStates()}
            errorMsg={errors.destinationState?.message}
            {...props}
          />
        )}
      />
    </>
  )
}
