import { ReceiverForm } from "../ReceiverForm"
import { t } from "@lingui/macro"

export const ShippingMethodS = () => {
  return (
    <ReceiverForm
      title={t`¿Quién retira?`}
      fields={{
        receiverPhone: false,
      }}
    />
  )
}
