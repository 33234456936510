import { Controller, useFormContext } from "react-hook-form"

import { BillingData } from "../../../../types"
import { BillingForm } from "../BillingForm"
import { RadioFieldCard } from "../../../../../../../../../../components/RadioFieldCard"
import { SingleColumnRow } from "../../../../../../style"
import { formatBillingToString } from "../../helpers/formatBillingToString"
import { t } from "@lingui/macro"
import { useCheckout } from "../../../../../../../../../../hooks"
import { useEffect } from "react"

type ZippinBillingForm = Pick<BillingData, "isBillingSameAsReceiver">

export const ZippinBilling = () => {
  const { shipping, billing } = useCheckout().data.meta
  const { control, errors, setValue } = useFormContext<ZippinBillingForm>()

  useEffect(() => {
    // set billing from api
    const areBillingFieldsEmpty =
      billing == null ||
      (billing?.fullName === "" &&
        billing?.identityNumber === "" &&
        billing?.addressStreet === "" &&
        billing?.addressNumber === "" &&
        billing?.addressOther === "" &&
        billing?.city === "" &&
        billing?.postalCode === "" &&
        billing?.state === "")

    if (!areBillingFieldsEmpty) {
      const isSameAsReceiver =
        billing?.fullName ===
          `${shipping?.receiver?.firstName} ${shipping?.receiver?.lastName}` &&
        billing?.identityType === shipping?.receiver?.identityType &&
        billing?.identityNumber === shipping?.receiver?.identityNumber &&
        billing?.addressStreet === shipping?.destination?.addressStreet &&
        billing?.addressNumber === shipping?.destination?.addressNumber &&
        billing?.addressOther === shipping?.destination?.addressOther &&
        billing?.city === shipping?.destination?.city &&
        billing?.postalCode === shipping?.destination?.postalCode &&
        billing?.state === shipping?.destination?.state

      setValue(
        "isBillingSameAsReceiver",
        isSameAsReceiver || areBillingFieldsEmpty ? "1" : "0"
      )
    }
  }, [setValue, billing, shipping])

  return (
    <SingleColumnRow>
      <Controller
        control={control}
        name="isBillingSameAsReceiver"
        rules={{
          required: t`Elegí una opción para poder continuar`,
        }}
        defaultValue="1"
        render={({ onChange, value }) => (
          <>
            <RadioFieldCard
              label={t`Mis datos de facturación y entrega son los mismos`}
              hasError={!!errors.isBillingSameAsReceiver}
              value="1"
              onChange={onChange}
              checked={value === "1"}
            >
              {t`Datos de facturación:` +
                formatBillingToString(shipping?.receiver)}
            </RadioFieldCard>

            <RadioFieldCard
              label={t`Mis datos de facturación no son los de entrega`}
              hasError={!!errors.isBillingSameAsReceiver}
              value="0"
              onChange={onChange}
              checked={value === "0"}
            >
              <BillingForm />
            </RadioFieldCard>
          </>
        )}
      />
    </SingleColumnRow>
  )
}
