import { Container } from "./Container"

export function Success({ ...props }) {
  return (
    <Container>
      <path
        d="M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0ZM9,16.3636A7.3636,7.3636,0,1,1,16.3636,9,7.359,7.359,0,0,1,9,16.3636Z"
        fill="currentColor"
      />
      <path
        d="M12.7636,5.5227,7.5477,10.7386,5.2364,8.4272A.8245.8245,0,1,0,4.07,9.5931L6.975,12.4772a.7909.7909,0,0,0,1.1455,0l5.7886-5.7886a.8149.8149,0,0,0,0-1.1659A.8279.8279,0,0,0,12.7636,5.5227Z"
        fill="currentColor"
      />
    </Container>
  )
}
