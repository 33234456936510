import { Dispatch, FC, SetStateAction, createContext, useState } from "react"

import { Result } from "../../api/stores/checkout"

export type Status = "idle" | "fetching" | "success" | "error"

export interface ContextValue {
  status: Status
  setStatus: Dispatch<SetStateAction<Status>>
  data: Result
  setData: Dispatch<SetStateAction<Result>>
}

export const CheckoutContext = createContext<ContextValue | null>(null)

export const initialState: Result = {
  objects: [],
  meta: {
    totalCount: 0,
    subtotalPrice: 0,
    totalPrice: 0,
    currency: "ARS",
    uniqueId: "",
    storeId: "",
    identifier: "",
    contact: null,
    shipping: null,
    billing: null,
    payment: null,
    userComment: null,
    extraData: null,
    preferenceId: null,
    sellerEmail: null,
    checkoutStep: "initial",
    timeLeft: 1200000,
    freeShipping: false,
    requirements: {
      shippingMethods: [],
      shippingOptions: { __kind: null },
      paymentOptions: [],
    },
  },
}

export const CheckoutProvider: FC = ({ children }) => {
  const [status, setStatus] = useState<Status>("idle")
  const [data, setData] = useState<Result>(initialState)

  const value = { status, setStatus, data, setData }

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  )
}
