import { Controller, useFormContext } from "react-hook-form"
import { DualColumnRow, SingleColumnRow } from "../../../../style"
import {
  TextField,
  parseField,
  parsePhoneNumberField,
} from "../../../../../../../../components/TextField"
import { Trans, t } from "@lingui/macro"

import { ContactData } from "../../types"
import { ContainerCard } from "../../../../../../../../components/ContainerCard"
import { DropdownField } from "../../../../../../../../components/DropdownField"
import { IdentityType } from "../../../../../../../../api/stores/checkout"
import { isValidEmail } from "../../../../../../utils/isValidEmail"
import { useCheckout } from "../../../../../../../../hooks"
import { useEffect } from "react"

type Option = { id: string; name: string; value: IdentityType }

const arrayOfAllOptions: ArrayOfAllUnion<Option> = array => array

export const CardContact = () => {
  const { contact } = useCheckout().data.meta
  const { control, errors, reset } = useFormContext<ContactData>()

  useEffect(() => {
    if (contact) {
      reset({
        contactFirstName: contact?.firstName || "",
        contactLastName: contact?.lastName || "",
        contactIdentityType: contact?.identityType || "DU",
        contactIdentityNumber: contact?.identityNumber || "",
        contactEmail: contact?.email || "",
        contactPhone: contact?.phone || "",
      })
    }
  }, [contact, reset])

  return (
    <ContainerCard title={<Trans>Completá tus datos</Trans>}>
      <SingleColumnRow>
        <DualColumnRow>
          <Controller
            control={control}
            name="contactFirstName"
            defaultValue=""
            rules={{
              required: t`Completá el campo con tu nombre.`,
            }}
            render={props => (
              <TextField
                name="contactFirstName"
                label={<Trans>Nombre</Trans>}
                errorMsg={errors.contactFirstName?.message}
                maxLength={100}
                hideLength={true}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name="contactLastName"
            defaultValue=""
            rules={{
              required: t`Completá el campo con tu apellido.`,
            }}
            render={props => (
              <TextField
                name="contactLastName"
                label={<Trans>Apellido</Trans>}
                errorMsg={errors.contactLastName?.message}
                maxLength={100}
                hideLength={true}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name="contactIdentityType"
            defaultValue="DU"
            rules={{
              required: t`Elegí el tipo de documento.`,
            }}
            render={({ onChange, value }) => (
              <DropdownField
                name="contactIdentityType"
                label={<Trans>Tipo de documento</Trans>}
                errorMsg={errors.contactIdentityType?.message}
                options={arrayOfAllOptions([
                  {
                    id: "1",
                    name: t`DNI`,
                    value: "DU",
                  },
                  {
                    id: "2",
                    name: t`CUIT`,
                    value: "CUIT",
                  },
                  {
                    id: "3",
                    name: t`Otro`,
                    value: "OTHER",
                  },
                ])}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="contactIdentityNumber"
            defaultValue=""
            rules={{
              required: t`Completá el campo con tu documento.`,
            }}
            render={({ onChange, value }) => (
              <TextField
                name="contactIdentityNumber"
                label={<Trans>Número de documento</Trans>}
                errorMsg={errors.contactIdentityNumber?.message}
                type="text"
                maxLength={11}
                hideLength={true}
                onChange={e => onChange(parseField(e.target.value))}
                value={value}
              />
            )}
          />
        </DualColumnRow>
        <Controller
          control={control}
          name="contactEmail"
          defaultValue=""
          rules={{
            required: t`Completá el campo con tu email.`,
            validate: {
              checkCorrectFormat: value => {
                if (!value) return true
                else
                  return (
                    isValidEmail(value) || t`Usá el formato hola@ejemplo.com`
                  )
              },
            },
          }}
          render={props => (
            <TextField
              name="contactEmail"
              label={<Trans>Email</Trans>}
              errorMsg={errors.contactEmail?.message}
              type="text"
              maxLength={100}
              hideLength={true}
              {...props}
            />
          )}
        />
        <Controller
          control={control}
          name="contactPhone"
          defaultValue=""
          rules={{
            required: t`Completá el campo con tu teléfono.`,
          }}
          render={({ onChange, value }) => (
            <TextField
              name="contactPhone"
              label={<Trans>Teléfono</Trans>}
              errorMsg={errors.contactPhone?.message}
              type="tel"
              maxLength={100}
              hideLength={true}
              onChange={e => onChange(parsePhoneNumberField(e.target.value))}
              value={value}
            />
          )}
        />
      </SingleColumnRow>
    </ContainerCard>
  )
}
