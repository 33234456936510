import * as Types from "./types"

import { colors, mediaQuery } from "../../../../../../theme"

import { Fragment } from "react"
import { PropsWithTheme } from "../../../../../../providers/StoreThemeProvider"
import { ShippingOptionItem } from "./ShippingOptionItem"
import { Trans } from "@lingui/macro"
import { api } from "../../../../../../api"
import styled from "@emotion/styled/macro"

type Props = {
  shippingInfo?: Types.FormData
  shippingOptions: api.stores.quote.Post.Result
  onModifyClick: () => void
}

export function SippingCostOptionsList({
  shippingInfo,
  shippingOptions,
  onModifyClick,
}: Props) {
  return (
    <Fragment>
      <Subtitle>
        <Trans>Domicilio</Trans>
      </Subtitle>
      <ShippingAddressContainer>
        {shippingInfo != null &&
          `${shippingInfo.shippingCity}, ${shippingInfo.shippingState}, CP: ${shippingInfo.shippingPostalCode}`}
        <ExternalLink onClick={onModifyClick}>
          <Trans>Modificar</Trans>
        </ExternalLink>
      </ShippingAddressContainer>
      <Subtitle>
        <Trans>Opciones de envío</Trans>
      </Subtitle>
      <ShippingOptionsContainer>
        {shippingOptions.results
          .sort((a, b) => a.price - b.price)
          .slice(0, 3)
          .map(
            ({
              carrier: { id },
              price,
              currency,
              deliveryTime: { date, isRange },
            }) => (
              <ShippingOptionItem
                key={id}
                price={price}
                arrivalDate={date}
                isRangeArrivalDate={isRange}
                currency={currency}
              />
            )
          )}
      </ShippingOptionsContainer>
    </Fragment>
  )
}

const Subtitle = styled.h2({
  fontSize: "1.4rem",
  fontWeight: "bold",
  color: colors.greyDark,
  margin: "1.6rem 0 0.8rem",
})

const ShippingAddressContainer = styled.div<PropsWithTheme>(props => ({
  borderRadius: 4,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: props.theme.colors.primary,
  padding: "0.8rem 1.6rem",
  fontSize: "1.4rem",
  display: "grid",

  [mediaQuery.desktop]: {
    display: "flex",
    justifyContent: "space-between",
  },
}))

const ExternalLink = styled.a({
  cursor: "pointer",
  fontSize: "1.4rem",
  color: colors.greyDark,
  textDecoration: "underline",

  "&:hover": {
    fontWeight: "bold",
  },
})

const ShippingOptionsContainer = styled.div({
  borderRadius: 4,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: colors.greyMainLight,
  maxHeight: "30.4rem",
  overflowY: "auto",
})
