import { ErrorResponse, Response } from "../types"
import { RequestBody, RequestBodyRaw } from "./types"
import { transformErrorResponse, transformResponse } from ".."

import paths from "../../../paths"
import { service } from "../../../Service"

export function put() {
  return async function (checkoutData: RequestBody) {
    const body = transformRequest(checkoutData)

    try {
      const response = await service.put<Response | ErrorResponse>(
        paths.checkout(),
        body
      )

      if (response.status.code >= 400 && response.status.code < 600) {
        return transformErrorResponse(response.body as ErrorResponse)
      } else {
        return transformResponse(response.body as Response)
      }
    } catch (error) {
      console.log(error)

      throw new Error(`API Checkout did not respond correctly`)
    }
  }
}

export const transformRequest = (data: RequestBody): RequestBodyRaw => {
  switch (data.checkoutStep) {
    case "contact":
      return {
        checkout_step: data.checkoutStep,
        contact: {
          first_name: data.contactFirstName,
          last_name: data.contactLastName,
          identity_type: data.contactIdentityType,
          identity_number: data.contactIdentityNumber,
          email: data.contactEmail,
          phone: data.contactPhone,
        },
        shipping: {
          shipping_type: data.shippingMethodType,
          id: data.shippingMethodId,
          receiver: {
            first_name: data?.receiverFirstName || "",
            last_name: data?.receiverLastName || "",
            identity_type: data?.receiverIdentityType || "",
            identity_number: data?.receiverIdentityNumber || "",
            phone: data?.receiverPhone || "",
          },
          destination: {
            address_street: data?.destinationAddressStreet || "",
            address_number: data?.destinationAddressNumber || "",
            address_other: data?.destinationAddressOther || "",
            city: data?.destinationCity || "",
            postal_code: data?.destinationPostalCode || "",
            state: data?.destinationState || "",
          },
        },
      }

    case "billing":
      return {
        checkout_step: data.checkoutStep,
        shipping: {
          id: data.shippingOption,
          logistic_type: data.shippingLogisticType,
          service_type: data.shippingServiceType,
          price: data.shippingPrice,
          currency: data.currency,
          shipping_type: data.shippingType,
          carrier_name: data.carrierName,
          ...(data.shippingType === "Z" || data.shippingType === "P"
            ? { carrier_id: data.carrierId }
            : {}),
        },
        payment: {
          id: data.paymentMethodId,
          type: data.paymentMethodType,
          name: data.paymentMethodName,
          discount_type: data.discountType,
          discount_value: data.discountValue,
          currency: data.currency,
        },
        billing: {
          full_name: data.billingFullName,
          identity_number: data.billingIdentityNumber,
          identity_type: data.billingIdentityType,
          address_street: data.billingAddressStreet,
          address_number: data.billingAddressNumber,
          address_other: data.billingAddressAdditional,
          city: data.billingCity,
          postal_code: data.billingPostalCode,
          state: data.billingState,
        },
        user_comment: data.userComment,
      }
  }
}
