import { CacheProvider } from "@emotion/react"
import { FC } from "react"
import createCache from "@emotion/cache"

const speedy = navigator.userAgent.toLowerCase().indexOf("prerender") === -1

const emotionCache = createCache({
  key: "emotion-cache",
  speedy: speedy,
})

export const EmotionConfigurationProvider: FC = ({ children }) => {
  return <CacheProvider value={emotionCache}>{children}</CacheProvider>
}
