import { Container } from "./Container"

export function Order() {
  return (
    <Container>
      <path
        d="M9.11,16.41l3.72-3.75v1.18L8.7,18,4.58,13.84V12.66l3.71,3.75V7.63h.82ZM3.71,1.59,0,5.34V4.16L4.12,0,8.25,4.16V5.34L4.54,1.59v8.78H3.71Z"
        fill="currentColor"
        transform="translate(2,0)"
      />
    </Container>
  )
}
