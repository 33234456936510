import { MutableRefObject } from "react"
import { PropsWithTheme } from "../../../../../../providers/StoreThemeProvider"
import { colors } from "../../../../../../theme"
import { readableColor } from "polished"
import styled from "@emotion/styled/macro"
import { useStickyHeaderPosition } from "../../../../../../hooks"
import { useStoreState } from "../../../../../../providers/StoreProvider"

type Props = {
  price: string | import("react").ReactNode
  error?: import("react").ReactNode
  children: import("react").ReactNode
  listeningRef: MutableRefObject<HTMLElement | null>
  buttonIsDisabled?: boolean
  buttonIsNotAllowed?: boolean
}

export function AddToCartMobileSticky({
  price,
  error,
  listeningRef,
  children,
  buttonIsDisabled,
  buttonIsNotAllowed,
}: Props) {
  const isStickyHeader = useStoreState()[0].config.header.sticky
  const { showSticky, headerSize } = useStickyHeaderPosition(
    listeningRef,
    isStickyHeader
  )

  return (
    <StickyContainer showSticky={showSticky} topPosition={headerSize}>
      <ActionsContainer>
        <PriceContainer>
          <Price data-testid="product-price">{price}</Price>
        </PriceContainer>
        <ButtonContainer
          isDisabled={buttonIsDisabled}
          isNotAllowed={buttonIsNotAllowed}
        >
          {children}
        </ButtonContainer>
      </ActionsContainer>
      {error && <ErrorContainer>{error}</ErrorContainer>}
    </StickyContainer>
  )
}

type MobileStickyContainerProps = {
  showSticky: boolean
  topPosition: number
}

const StickyContainer = styled.div(
  ({ topPosition, showSticky }: MobileStickyContainerProps) => ({
    ...(!showSticky && { display: "none" }),
    position: "fixed",
    top: topPosition,
    left: 0,
    width: "100%",
    backgroundColor: "#ffffff",
    height: "5.9rem",
    padding: "1.6rem",
    boxShadow: "0 2px 4px hsla(0, 0%, 0%, 0.15)",
    zIndex: 1,
  })
)

const ActionsContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",
  height: "100%",
})

const PriceContainer = styled.div({
  alignSelf: "center",
  textAlign: "center",
})

const Price = styled.span<PropsWithTheme>(props => ({
  fontSize: "1.8rem",
  lineHeight: "2rem",
  fontWeight: "bold",
  color: props.theme.colors.texts,
  fontFamily: props.theme.typographies.texts,
  display: "block",
  width: "fit-content",
  textAlign: "left",
  margin: "auto",
}))

type ButtonContainerProps = {
  isDisabled?: boolean
  isNotAllowed?: boolean
}

const ButtonContainer = styled.div<PropsWithTheme & ButtonContainerProps>(
  props => {
    const primaryColor = props.theme.colors.primary

    return {
      alignSelf: "center",
      height: "3.6rem",
      minWidth: "40%",
      "& button": {
        backgroundColor:
          props.isNotAllowed || props.isDisabled
            ? colors.greyMainLight
            : primaryColor,
        color:
          props.isNotAllowed || props.isDisabled
            ? colors.greyMedium
            : readableColor(primaryColor),
      },
    }
  }
)

const ErrorContainer = styled.div({
  height: "1.6rem",
  marginTop: "0.4rem",
  textAlign: "center",
})
