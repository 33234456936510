import { FilterChip } from "../../FilterChip"
import { Filters } from "../../../../../icons"
import { PropsWithTheme } from "../../SortingSelector/SortingList/SortingList"
import { SortingSelector } from "../../SortingSelector"
import { Trans } from "@lingui/macro"
import { colors } from "../../../../../theme"
import styled from "@emotion/styled"
import { useFilters } from "../../../hooks/useFilters"

type Props = {
  openModal: (isOpen: boolean) => void
}

export function FiltersAndSorting({ openModal }: Props) {
  const {
    appliedFiltersValues,
    removeFilter,
    priceFilter,
    removePriceFilter,
  } = useFilters()
  return (
    <Container>
      <SelectorsContainer>
        <FiltersButton onClick={() => openModal(true)}>
          <Filters />
          <Label>
            <Trans>Filtros</Trans>
          </Label>
        </FiltersButton>
        <DividerVertical />
        <SortingSelector />
      </SelectorsContainer>
      {(appliedFiltersValues.length > 0 || priceFilter) && (
        <FiltersActivated>
          {appliedFiltersValues.map(filter => (
            <FilterChip
              key={filter.id}
              label={filter.name}
              onClick={() => removeFilter(filter.id)}
            />
          ))}
          {priceFilter && (
            <FilterChip label={priceFilter} onClick={removePriceFilter} />
          )}
        </FiltersActivated>
      )}
    </Container>
  )
}

const Container = styled.aside({
  backgroundColor: colors.white,
  padding: "0 1.6rem",
  marginBottom: "0.8rem",
})

const FiltersButton = styled.button<PropsWithTheme>(props => ({
  border: "none",
  backgroundColor: "transparent",
  display: "inline-flex",
  outline: "none",
  color: colors.greyMedium,
  margin: 0,
  padding: 0,
  fontSize: "1.4rem",
  alignItems: "center",

  "& svg": {
    color: colors.greyMedium,
  },
}))

const Label = styled.span({
  paddingLeft: "0.8rem",
})

const SelectorsContainer = styled.div({
  padding: "1.6rem 0",
  display: "grid",
  gridGap: "0.8rem",
  gridTemplateColumns: "auto auto 1fr",
})

const DividerVertical = styled.div({
  borderLeft: `1px solid ${colors.greyMedium}`,
})

const FiltersActivated = styled.div({
  padding: "0 0 1.6rem",
})
