import * as Types from "./types"

import { FormProvider, useForm } from "react-hook-form"
import { useCallback, useEffect } from "react"

import { CalculateShippingCostForm } from "./CalculateShippingCostForm"
import { SippingCostOptionsList } from "./ShippingCostOptionsList"
import { ZippinDescription } from "./ZippinDescription"
import { colors } from "../../../../../../theme"
import styled from "@emotion/styled/macro"
import { useShippingCost } from "../../../../../../hooks"

type ZippinModalBodyProps = {
  productID: string
  variantID: number
  quantity: number
  hasCustomMethods: boolean
}

export function ZippinModalBody({
  productID,
  variantID,
  quantity,
  hasCustomMethods,
}: ZippinModalBodyProps) {
  const formMethods = useForm<Types.FormData>({
    mode: "onSubmit",
  })

  const reset = formMethods.reset

  const {
    data,
    error,
    submitForm,
    isLoading,
    shippingRequest,
    showForm,
    setShowForm,
  } = useShippingCost({
    productID,
    variantID,
    quantity,
  })

  const setShippingFormValues = useCallback(() => {
    if (shippingRequest != null) {
      reset({
        shippingCity: shippingRequest.shippingCity,
        shippingState: shippingRequest.shippingState,
        shippingPostalCode: shippingRequest.shippingPostalCode,
      })
    }
  }, [reset, shippingRequest])

  useEffect(() => {
    if (error != null) {
      setShippingFormValues()
    }
  }, [error, setShippingFormValues])

  const handleModifyClick = () => {
    setShippingFormValues()
    setShowForm(true)
  }

  return (
    <BackgroundContainer
      hasCustom={hasCustomMethods}
      data-testid="zippin-modal-options"
    >
      <FormProvider {...formMethods}>
        <ZippinDescription
          hasCustomMethods={hasCustomMethods}
          isFormShowing={showForm}
        />
        {showForm ? (
          <CalculateShippingCostForm
            onSubmit={submitForm}
            error={error}
            isLoading={isLoading}
          />
        ) : (
          <SippingCostOptionsList
            shippingInfo={shippingRequest}
            shippingOptions={data}
            onModifyClick={handleModifyClick}
          />
        )}
      </FormProvider>
    </BackgroundContainer>
  )
}

type BackgroundContainerProps = {
  hasCustom: boolean
}

const BackgroundContainer = styled.div<BackgroundContainerProps>(props => ({
  backgroundColor: props.hasCustom ? colors.greyLightest : "transparent",
  borderColor: props.hasCustom ? colors.greyMainLight : "transparent",
  borderStyle: "solid",
  borderWidth: props.hasCustom ? "0 1px 1px 1px" : "unset",
  padding: props.hasCustom ? "2rem 1.6rem" : "unset",
  borderRadius: props.hasCustom ? "0 0 4px 4px" : "unset",
}))
