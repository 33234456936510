import { CartTypes, useCart } from "../../../hooks/useCart"
import { useCallback, useMemo } from "react"

import { api } from "../../../api"
import { useProductStore } from "../provider/ProductStoreProvider"

export function useAddToCart(
  productId: string,
  selectedImage: api.products.variations.Get.Image
) {
  const { quantity, product, selectedVariant } = useProductStore()
  const { title, properties } = product
  const { actions: cartActions, state: cartState, data: cartData } = useCart()

  const cartIsLoading = useMemo(() => cartState.status === "fetching", [
    cartState,
  ])

  const cartError = useMemo(() => cartState.status === "error", [cartState])

  const handleAddToCart = useCallback(() => {
    const item: api.stores.carts.Post.NewVariantRequestRaw = {
      itemId: `${productId}-${selectedVariant?.id}`,
      properties: [],
      price: 0,
      currency: "ARS",
      quantity: 0,
      title,
      thumbnail: selectedImage.thumbnails.thumbnail,
    }

    if (selectedVariant) {
      item.quantity = quantity
      item.price = selectedVariant.price
      item.currency = selectedVariant.currency

      for (let property of properties) {
        for (let value of property.values) {
          if (selectedVariant.values.includes(value.id)) {
            item.properties.push({
              name: property.name,
              value: value.name,
            })

            break
          }
        }
      }

      const variantOnCart = findVariantOnCart({
        cartData,
        productId,
        variantId: selectedVariant.id.toString(),
      })

      if (variantOnCart != null) {
        item.quantity += variantOnCart.quantity
      }

      cartActions.addOrModifyItem(item, () => cartActions.openCart(true))
    }
  }, [
    selectedVariant,
    properties,
    cartActions,
    productId,
    title,
    selectedImage,
    quantity,
    cartData,
  ])

  return useMemo(
    () => ({
      handleAddToCart,
      cartIsLoading,
      cartError,
    }),
    [handleAddToCart, cartIsLoading, cartError]
  )
}

function findVariantOnCart({
  cartData,
  productId,
  variantId,
}: {
  cartData: CartTypes["data"]
  productId: string
  variantId: string
}) {
  return cartData.objects.find(
    variant =>
      // Make sure that all the values are string
      variant.productId.toString() === productId.toString() &&
      variant.variantId.toString() === variantId.toString()
  )
}
