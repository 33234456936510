import { carts } from "./carts"
import { checkout } from "./checkout"
import { config } from "./config"
import { pageConfig } from "./page-config"
import { pickUpPoints } from "./pick-up-points"
import { quote } from "./quote"
import { shippings } from "./shippings"

export const stores = [
  ...carts,
  ...checkout,
  ...config,
  ...pageConfig,
  ...pickUpPoints,
  ...quote,
  ...shippings,
]
