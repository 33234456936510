import { Container } from "./Container"

export function Checkmark() {
  return (
    <Container>
      <path
        d="M15.5762,2.9272,6.4871,12.0163,2.4594,7.9886a1.42,1.42,0,0,0-2.0317,0,1.42,1.42,0,0,0,0,2.0317L5.4891,15.046a1.3781,1.3781,0,0,0,1.9961,0L17.5723,4.9589a1.42,1.42,0,0,0,0-2.0317A1.4427,1.4427,0,0,0,15.5762,2.9272Z"
        fill="currentColor"
      />
    </Container>
  )
}
