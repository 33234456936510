import { Checkout as CheckoutScreen } from "./Checkout"
import { CheckoutScreenProvider } from "./providers/CheckoutScreenProvider"

export * from "./Checkout"

export const Checkout = () => {
  return (
    <CheckoutScreenProvider>
      <CheckoutScreen />
    </CheckoutScreenProvider>
  )
}
