import {
  LinkContainer,
  LinkIconContainer,
} from "../../screens/ProductVIP/components/NamePriceActions/InformationLinks/styles"

import { CreditCard } from "../../icons"
import { HEADER_HEIGHT } from "../../theme"
import { LinkButton } from "../LinkButton"
import { Trans } from "@lingui/macro"
import { useScreenSize } from "../../hooks"
import { useStoreState } from "../../providers/StoreProvider"

export function PaymentMethodsLink() {
  const { isDesktop } = useScreenSize()
  const [state] = useStoreState()

  const handleClick = () => {
    const { sticky } = state.config.header

    const buttonTarget = document.getElementById("payment-methods")

    if (buttonTarget) {
      const headerOffset = !sticky
        ? 0
        : isDesktop
        ? HEADER_HEIGHT.DESKTOP
        : HEADER_HEIGHT.MOBILE

      const elementPosition = buttonTarget.getBoundingClientRect().top

      const offsetPosition = elementPosition - headerOffset

      window.scrollBy({
        top: offsetPosition,
        behavior: "smooth",
      })
    }
  }

  return (
    <LinkContainer>
      <LinkIconContainer>
        <CreditCard />
      </LinkIconContainer>
      <LinkButton onClick={handleClick}>
        <Trans>Ver medios de pago</Trans>
      </LinkButton>
    </LinkContainer>
  )
}
