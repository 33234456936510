export type CalcDiscountType = {
  subtotalPrice: number
  appliedDiscount: {
    discountType: "P" | "A" | null
    discountValue: number | null
  }
}

export function calcDiscount({
  subtotalPrice,
  appliedDiscount,
}: CalcDiscountType) {
  if (
    appliedDiscount.discountType === "P" &&
    appliedDiscount.discountValue !== null
  ) {
    return (subtotalPrice * appliedDiscount.discountValue) / 100
  } else {
    return (appliedDiscount.discountValue as number) * 100
  }
}
