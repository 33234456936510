import { colors, mediaQuery, shadows } from "../../../../../theme"

import { SortingOption } from "../../../hooks/useSort"
import { StoreTheme } from "../../../../../providers/StoreThemeProvider"
import styled from "@emotion/styled/macro"

type Props = {
  options: SortingOption[]
  selected: SortingOption
  clickHandler: ({ value }: SortingOption) => void
}
export function SortingList({ options, selected, clickHandler }: Props) {
  return (
    <List>
      {options.map(option => (
        <Item
          key={option.id}
          selected={option.id === selected.id}
          onClick={() => clickHandler(option)}
        >
          {option.label}
        </Item>
      ))}
    </List>
  )
}

export type PropsWithTheme = Partial<{ theme: StoreTheme }>

const List = styled.div<PropsWithTheme>(props => ({
  backgroundColor: colors.white,
  margin: "3.2rem -1.6rem 0",
  display: "grid",
  gridTemplateRows: "auto",
  width: "auto",
  zIndex: 2,
  [mediaQuery.desktop]: {
    borderRadius: "0.4rem",
    boxShadow: shadows[2],
    position: "absolute",
    margin: "0.8rem 0 0",
    padding: "0.8rem 0 0",
  },

  "&:hover": {
    color: props.theme.colors.primary,
    borderColor: props.theme.colors.primary,
  },
}))

type ItemProps = {
  selected: boolean
}

const Item = styled.div<PropsWithTheme & ItemProps>(props => ({
  fontFamily: props.theme.typographies.texts,
  fontSize: "1.4rem",
  color: colors.greyMedium,
  padding: "1.6rem",
  whiteSpace: "nowrap",
  textAlign: "left",
  borderBottom: `1px solid ${colors.greyMainLight}`,
  background: props.selected ? colors.greyLighter : "inherit",

  [mediaQuery.desktop]: {
    borderBottom: "none",
    padding: "1.2rem 2rem",
    cursor: "pointer",
  },

  "&:hover": {
    backgroundColor: colors.greyLight,
  },
}))
