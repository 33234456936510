import { Trans, t } from "@lingui/macro"
import { colors, mediaQuery } from "../../../../../../theme"
import {
  useCart,
  useCheckout,
  usePaymentOption,
  useScreenSize,
  useShippingOption,
} from "../../../../../../hooks"
import { useEffect, useMemo } from "react"

import { AddressColumn } from "./components/AddressColumn"
import { InformationColumn } from "./components/InformationColumn"
import { PaymentColumn } from "./components/PaymentColumn"
import { PriceView } from "../../../../../../components/PriceView"
import { PropsWithTheme } from "../../../../../../providers/StoreThemeProvider"
import { PurchaseAlert } from "./components/PurchaseAlert/PurchaseAlert"
import { PurchaseIndications } from "./components/PurshaseIndications"
import { ShippingColumn } from "./components/ShippingColumn"
import { ThanksYouTitle } from "./components/ThanksYouTitle"
import { Tracking } from "../../../../../../icons/"
import { calcDiscount } from "../../../../utils/calcDiscount"
import styled from "@emotion/styled"
import { toLocalePrice } from "../../../../../../utils"
import { useHistory } from "react-router-dom"

export function ThankYouPage() {
  const { data, resetCheckout } = useCheckout()
  const {
    meta: {
      contact,
      shipping,
      payment,
      identifier,
      subtotalPrice,
      sellerEmail,
      currency,
    },
    objects: products,
  } = data

  const { isDesktop } = useScreenSize()

  const { actions } = useCart()
  const { shippingPrice, shippingOption } = useShippingOption()
  const { paymentOption } = usePaymentOption()
  const history = useHistory()

  const isToBeAgreed = useMemo(
    () =>
      shippingOption?.shippingType === "C" && shippingOption.priceType === "T",
    [shippingOption]
  )

  useEffect(() => {
    actions.getData()
  }, [actions])

  const isPendingAndCustom =
    payment?.type === "C" && payment?.status === "pending"

  const clickHandler = () => {
    resetCheckout()
    history.push("/")
  }

  const appliedDiscount = {
    discountType: paymentOption?.discountType || null,
    discountValue: paymentOption?.discountValue || null,
  }

  const discountAmount =
    appliedDiscount != null
      ? calcDiscount({ subtotalPrice, appliedDiscount })
      : 0
  const total =
    appliedDiscount != null
      ? subtotalPrice + shippingPrice - Math.min(discountAmount, subtotalPrice)
      : subtotalPrice + shippingPrice

  return (
    <Container data-testid="step-thankYouPage">
      <PurchaseSummaryContainer>
        <ThanksYouTitle />
        <PurchaseAlert />

        {isPendingAndCustom && <PurchaseIndications />}

        <PurchaseInformations>
          <PurchaseInformationsTitle>
            {(payment?.type === "M" || payment?.status === "pending") && (
              <Title data-testid="purchaseInformations-title">
                <Trans>Número de pedido</Trans> #{identifier}
              </Title>
            )}
            {payment?.type === "C" && payment?.status === "success" && (
              <Title data-testid="purchaseInformations-title">
                <Trans>¡Tu compra fue un éxito!</Trans>
              </Title>
            )}
            {(shipping?.shippingType === "Z" ||
              shipping?.shippingType === "P") && (
              <TrackShipping
                href={shipping.trackingExternal}
                rel="noreferrer"
                target="_blank"
              >
                <Tracking />
                <TrackShippingLink>
                  <Trans>Seguir mi envío</Trans>
                </TrackShippingLink>
              </TrackShipping>
            )}
          </PurchaseInformationsTitle>
          <InformationColumnsContainer>
            <ShippingColumn />
            <AddressColumn />
            <PaymentColumn />
            {/* Column contact */}
            <InformationColumn
              type="contact"
              description={
                <>
                  {contact?.email || ""} <br />
                  {contact?.phone || ""}
                </>
              }
            />
          </InformationColumnsContainer>
        </PurchaseInformations>
        <PurchaseSummary>
          <Title>
            <Trans>Resumen de la compra</Trans>
          </Title>
          <Table>
            {isDesktop && (
              <TableHeader>
                <SubTitle>
                  <Trans>Producto</Trans>
                </SubTitle>
                <SubTitle>
                  <Trans>Precio</Trans>
                </SubTitle>
                <SubTitle>
                  <Trans>Cantidad</Trans>
                </SubTitle>
              </TableHeader>
            )}
            <TableBody>
              {products.map(product => (
                <CheckoutLineContainer key={product.productId}>
                  <CheckoutLine>
                    <Thumbnail
                      src={product.thumbnail}
                      alt={t`Imagen miniatura de: ${product.variationId}`}
                    />
                    <Detail>
                      <ProductTitleAndVariant>
                        <Text>{product.title}</Text>
                        <Text>{product.values.join(", ")}</Text>
                      </ProductTitleAndVariant>
                      <Text>
                        <PriceView
                          price={toLocalePrice(product.price, product.currency)}
                        />
                      </Text>
                      <Text>
                        {`${product.quantity} `}
                        {product.quantity > 1 ? (
                          <Trans>unidades</Trans>
                        ) : (
                          <Trans>unidad</Trans>
                        )}
                      </Text>
                    </Detail>
                  </CheckoutLine>
                  <Price>
                    <PriceView
                      price={toLocalePrice(
                        product.quantity * product.price,
                        product.currency
                      )}
                    />
                  </Price>
                </CheckoutLineContainer>
              ))}
            </TableBody>
          </Table>
          <Price data-testid="price-subtotal">
            <Trans>Subtotal</Trans>:{" "}
            <PriceView price={toLocalePrice(subtotalPrice, currency)} />
          </Price>
          {appliedDiscount != null && discountAmount > 0 && (
            <DiscountText data-testid="discount">
              <Trans>Descuento por medio de pago</Trans>: -
              <PriceView price={toLocalePrice(discountAmount, currency)} />
            </DiscountText>
          )}
          <Price data-testid="price-shipping-cost">
            <Trans>Costo de envío</Trans>:{" "}
            {shippingPrice > 0 ? (
              <PriceView price={toLocalePrice(shippingPrice, currency)} />
            ) : isToBeAgreed ? (
              t`A convenir`
            ) : (
              t`Gratis`
            )}
          </Price>
          <Divider />
          <TotalPrice data-testid="price-total">
            <Trans>Total</Trans>:{" "}
            <PriceView price={toLocalePrice(total, currency)} />
          </TotalPrice>
        </PurchaseSummary>
      </PurchaseSummaryContainer>
      <Footer>
        <FooterText>
          <Title>
            <Trans>¿Tenés dudas?</Trans>
          </Title>
          <Text>
            <Trans>Podés ponerte en contacto con el vendedor</Trans>:{" "}
            <EmailLink href={`mailto:${sellerEmail}`}>{sellerEmail}</EmailLink>
          </Text>
        </FooterText>
        <Button onClick={clickHandler}>
          <Trans>Seguir comprando</Trans>
        </Button>
      </Footer>
    </Container>
  )
}

const Container = styled.div({
  width: "100%",
  maxWidth: "94rem",
  cursor: "default",
})

const PurchaseSummaryContainer = styled.div<PropsWithTheme>(props => ({
  display: "grid",
  paddingBottom: "2.4rem",

  backgroundImage: `linear-gradient(to right, ${props.theme.colors.primary} 33%, rgba(255,255,255,0) 0%)`,
  backgroundPosition: "bottom",
  backgroundSize: "6px 1px",
  backgroundRepeat: "repeat-x",

  [mediaQuery.desktop]: {
    paddingBottom: "3.2rem",
  },
}))

const PurchaseInformations = styled.div({
  display: "flex",
  flexFlow: "column nowrap",
  gap: "1.6rem",
  marginBottom: "3.2rem",

  [mediaQuery.desktop]: {
    gap: "2.4rem",
  },
})

const PurchaseInformationsTitle = styled.div({
  display: "flex",
  flexFlow: "column nowrap",
  gap: ".8rem",
})

const Title = styled.h1({
  fontSize: "1.6rem",
  color: colors.greyDark,
  margin: 0,
})

const TrackShipping = styled.a({
  textDecoration: "none",
  display: "flex",
  flexFlow: "row nowrap",
  alignItems: "center",
  gap: "1.2rem",
  svg: {
    height: "2.4rem",
    width: "2.4rem",
  },
})

const TrackShippingLink = styled.span({
  fontSize: "1.4rem",
  color: colors.greyDark,
  textDecoration: "underline",
  margin: 0,
  padding: 0,
})

const InformationColumnsContainer = styled.div({
  display: "grid",
  gridGap: "1.6rem",

  [mediaQuery.desktop]: {
    gridTemplateColumns: "repeat(4, max-content)",
  },
})

const SubTitle = styled.h2({
  fontSize: "1.4rem",
  color: colors.greyDark,
  margin: 0,
})

const EmailLink = styled.a({
  fontSize: "1.4rem",
  color: colors.greyDark,
  textDecoration: "none",
})

const PurchaseSummary = styled.div({
  display: "grid",
  gridGap: "1.6rem",
})

const Table = styled.div({})

const TableBody = styled.div({
  borderTop: `2px solid ${colors.greyMainLight}`,
  borderBottom: `1px solid ${colors.greyMainLight}`,
})

const TableHeader = styled.div({
  display: "grid",
  gridTemplateColumns: "42.4rem 20.8rem auto",
  paddingBottom: "0.8rem",
})

const CheckoutLineContainer = styled.div({
  display: "grid",
  padding: "1.6rem 0 1.6rem",
  gridTemplateColumns: "auto auto",
  borderBottom: `1px solid ${colors.greyMainLight}`,

  "&:nth-of-type(odd)": {
    background: colors.greyLightest,
  },

  "&:last-of-type": {
    borderBottom: `1px solid ${colors.greyMainLight}`,
  },
})

const CheckoutLine = styled.div({
  display: "grid",
  gridTemplateColumns: "4.8rem auto",
  gridGap: "0.8rem",
  paddingLeft: "0.8rem",

  [mediaQuery.desktop]: {
    gridGap: "1.6rem",
  },
})

const Thumbnail = styled.img({
  width: "5rem",
  height: "5rem",
  objectFit: "cover",
})

const Detail = styled.div({
  display: "grid",
  gridGap: "0.8rem",

  [mediaQuery.desktop]: {
    gridTemplateColumns: "34.4rem 20rem auto",
  },
})

const ProductTitleAndVariant = styled.div({
  display: "flex",
  flexFlow: "column nowrap",
})

const Text = styled.span({
  fontSize: "1.4rem",
  color: colors.greyDark,

  "&:first-letter": {
    textTransform: "uppercase",
  },
})

const Price = styled.div({
  fontSize: "1.4rem",
  color: colors.greyDark,
  textAlign: "end",
  paddingRight: "0.8rem",

  [mediaQuery.desktop]: {
    paddingRight: "2.4rem",
  },
})

const Divider = styled.div({
  borderBottom: `1px solid ${colors.greyMainLight}`,
})

const TotalPrice = styled.h2({
  fontSize: "1.4rem",
  color: colors.greyDark,
  margin: 0,
  textAlign: "end",
  paddingRight: "0.8rem",

  [mediaQuery.desktop]: {
    paddingRight: "2.4rem",
  },
})

const Footer = styled.div({
  display: "grid",
  marginTop: "1.6rem",
  gridGap: "1.6rem",

  [mediaQuery.desktop]: {
    gridTemplateColumns: "1fr auto",
    marginTop: "2.4rem",
  },
})

const FooterText = styled.div({
  display: "grid",
  gridGap: "0.8rem",
})

const Button = styled.button<PropsWithTheme>(props => ({
  height: "3.2rem",
  border: "none",
  borderRadius: 4,
  backgroundColor: props.theme.colors.primary,
  fontSize: "1.6rem",
  color: colors.white,
  cursor: "pointer",
  padding: "0.4rem 1.6rem",
  textDecoration: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "&:hover": {
    opacity: 0.9,
  },
}))

const DiscountText = styled.div({
  color: colors.greenDark,
  margin: 0,
  fontSize: "1.4rem",
  textAlign: "right",
  paddingRight: "0.8rem",

  [mediaQuery.desktop]: {
    paddingRight: "2.4rem",
  },
})
