import { Chevron, Close } from "../../../../../icons"
import { Fragment, useState } from "react"
import { colors, shadows } from "../../../../../theme"

import { Link } from "react-router-dom"
import { PropsWithTheme } from "../../../../../providers/StoreThemeProvider"
import { RangeSelector } from "../../../../../components/RangeSelector"
import { Trans } from "@lingui/macro"
import styled from "@emotion/styled"
import { useFilters } from "../../../hooks/useFilters"
import { useMeta } from "../../../hooks/useMeta"

type Props = {
  closeModal: () => void
}

export function FilterSelector({ closeModal }: Props) {
  const [expanded, setExpanded] = useState("")
  const { subCategories } = useMeta()
  const {
    filters,
    addFilter,
    appliedFiltersValues,
    priceFilter,
    removeAllFilters,
    applyPriceFilter,
  } = useFilters()

  const isRemoveAllFiltersVisible =
    appliedFiltersValues.length > 0 || !!priceFilter
  const CATEGORY_GROUP = "CATEGORY_GROUP"
  const PRICE_GROUP = "PRICE_GROUP"

  const RangeSelectorHandler = (min?: number, max?: number) => {
    applyPriceFilter(min, max)
    closeModal()
  }

  return (
    <Modal>
      <Header>
        <Trans>Filtrar por</Trans>
        <CloseButton onClick={closeModal}>
          <Close />
        </CloseButton>
      </Header>
      <Separator />
      <SelectorGroups isRemoveAllFiltersVisible={isRemoveAllFiltersVisible}>
        {subCategories.length > 0 && (
          <Fragment>
            <SelectorGroup
              onClick={() =>
                setExpanded(expanded === CATEGORY_GROUP ? "" : CATEGORY_GROUP)
              }
            >
              <Label>
                <Trans>Categorías</Trans>
              </Label>
              <ChevronIcon isExpanded={expanded === CATEGORY_GROUP}>
                <Chevron />
              </ChevronIcon>
            </SelectorGroup>
            {expanded === CATEGORY_GROUP && (
              <List>
                {subCategories.map(subcategory => (
                  <StyledLink key={subcategory.id} to={subcategory.url}>
                    {`${subcategory.name} (${subcategory.count})`}
                  </StyledLink>
                ))}
              </List>
            )}
          </Fragment>
        )}

        {filters.map(filter => {
          const key = `${filter.id}`
          const isExpanded = expanded === key

          return (
            <Fragment key={key}>
              <SelectorGroup onClick={() => setExpanded(isExpanded ? "" : key)}>
                <Label>{filter.name}</Label>
                <ChevronIcon isExpanded={isExpanded}>
                  <Chevron />
                </ChevronIcon>
              </SelectorGroup>
              {isExpanded && (
                <List>
                  {filter.values.map(value => (
                    <Label
                      key={value.id}
                      onClick={() => {
                        addFilter(value.id)
                      }}
                    >
                      {`${value.name} (${value.count})`}
                    </Label>
                  ))}
                </List>
              )}
            </Fragment>
          )
        })}
        {!priceFilter && (
          <Fragment>
            <SelectorGroup
              onClick={() =>
                setExpanded(expanded === PRICE_GROUP ? "" : PRICE_GROUP)
              }
            >
              <Label>
                <Trans>Precio</Trans>
              </Label>
              <ChevronIcon isExpanded={expanded === PRICE_GROUP}>
                <Chevron />
              </ChevronIcon>
            </SelectorGroup>
            {expanded === PRICE_GROUP && (
              <List>
                <Label>
                  <RangeSelector clickHandler={RangeSelectorHandler} />
                </Label>
              </List>
            )}
          </Fragment>
        )}
      </SelectorGroups>
      {isRemoveAllFiltersVisible && (
        <RemoveAllFilters>
          <Button onClick={removeAllFilters}>
            <Trans>Limpiar Filtros</Trans>
          </Button>
        </RemoveAllFilters>
      )}
    </Modal>
  )
}

const Modal = styled.div({
  position: "fixed",
  height: "100vh",
  width: "100vw",
  top: 0,
  left: 0,
  padding: "1.6rem",
  backgroundColor: colors.white,
  zIndex: 90000,
})

const Header = styled.h1<PropsWithTheme>(props => ({
  fontFamily: props.theme.typographies.texts,
  fontSize: "2.4rem",
  color: colors.greyMedium,
  display: "flex",
  justifyContent: "space-between",
  margin: 0,
}))

const CloseButton = styled.button<PropsWithTheme>(props => ({
  right: 0,
  padding: 0,
  margin: 0,
  border: "none",
  outline: "none",
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& svg": {
    color: colors.greyMedium,
    fontSize: "1.6rem",
  },
}))

const Separator = styled.hr<PropsWithTheme>(props => ({
  display: "block",
  height: 1,
  border: 0,
  borderTop: `1px solid ${props.theme.colors.primary}`,
  marginTop: "0.8rem",
  marginBottom: 0,
  padding: 0,
}))

type FilterGroupsProps = {
  isRemoveAllFiltersVisible: boolean
}

const SelectorGroups = styled.div<FilterGroupsProps>(props => ({
  maxHeight: "calc(100vh - 10rem)",
  paddingBottom: props.isRemoveAllFiltersVisible ? "6.4rem" : 0,
  paddingTop: "1.2rem",
  overflowY: "auto",
  margin: "0 -1.6rem",
}))

const SelectorGroup = styled.div<PropsWithTheme>({
  display: "flex",
  justifyContent: "space-between",
  borderBottom: `1px solid ${colors.greyMainLight}`,
  padding: "0 1.6rem",
})

const StyledLink = styled(Link)<PropsWithTheme>(props => ({
  display: "block",
  color: colors.greyMedium,
  fontFamily: props.theme.typographies.texts,
  fontSize: "1.4rem",
  margin: "1.6rem 0",
  textDecoration: "none",
}))

const Label = styled.p<PropsWithTheme>(props => ({
  color: colors.greyMedium,
  fontFamily: props.theme.typographies.texts,
  fontSize: "1.4rem",
  margin: "1.6rem 0",
}))

type IconProps = {
  isExpanded: boolean
}

const ChevronIcon = styled.span<IconProps>(props => ({
  color: colors.greyMedium,
  fontSize: "1rem",
  display: "flex",
  alignSelf: "center",
  marginRight: "0.4rem",
  transform: props.isExpanded ? "rotate(180deg)" : "rotate(0deg)",
}))

const List = styled.div({
  padding: "0.8rem 1.6rem",
  backgroundColor: colors.greyLightest,
  borderBottom: `1px solid ${colors.greyMainLight}`,
})

const RemoveAllFilters = styled.div<PropsWithTheme>(props => ({
  boxShadow: shadows[3],
  borderRadius: 4,
  height: "6.4rem",
  padding: "1.6rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  position: "fixed",
  bottom: "0rem",
  width: "100vw",
  background: colors.white,
  margin: "0 -1.6rem",
}))

const Button = styled.button<PropsWithTheme>(props => ({
  height: "3.4rem",
  border: "none",
  borderRadius: 4,
  backgroundColor: props.theme.colors.primary,
  fontFamily: props.theme.typographies.texts,
  fontSize: "1.4rem",
  color: colors.white,
  padding: "0.8rem 1.6rem",
  alignSelf: "center",
}))
