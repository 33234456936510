import * as z from "zod"

export const ElementRawSchema = z.object({
  identifier: z.string(),
  kind: z.string(),
  props: z.record(z.string()),
})

export type ElementRaw = z.infer<typeof ElementRawSchema>

export const ResponseSchema = z.object({
  elements: z.array(ElementRawSchema),
})

export type Response = z.infer<typeof ResponseSchema>

export const ElementSchema = z.object({
  id: z.string(),
  kind: z.string(),
  props: z.record(z.string()),
})

export type Element = z.infer<typeof ElementSchema>

export const ResultSchema = z.array(ElementSchema)

export type Result = z.infer<typeof ResultSchema>
