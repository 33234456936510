import { Desktop } from "./components/Desktop"
import { Mobile } from "./components/Mobile"
import { ProductListHead } from "./ProductListHead"
import { ProductStoreProvider } from "./provider/ProductStoreProvider"
import styled from "@emotion/styled"
import { useScreenSize } from "../../hooks"

type Props = {
  categoryPath: string
}

export function ProductsList({ categoryPath }: Props) {
  const { isDesktop } = useScreenSize()

  return (
    <ProductStoreProvider>
      <ProductListHead />
      <ProductListMain>
        {isDesktop ? (
          <Desktop categoryPath={categoryPath} />
        ) : (
          <Mobile categoryPath={categoryPath} />
        )}
      </ProductListMain>
    </ProductStoreProvider>
  )
}

const ProductListMain = styled.main()
