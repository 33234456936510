import { i18n } from "@lingui/core"

type Options = Partial<Intl.NumberFormatOptions>

export type Currency = "ARS" | "BRL" | "MXN"

type ToLocalePrice = (
  price: number,
  currency?: Currency,
  options?: Options
) => string

// We only set symbols that are different from $
const SYMBOL_MAP: Partial<Record<Currency, string>> = {
  BRL: "R$",
}

export const toLocalePrice: ToLocalePrice = (
  price,
  currency = "ARS",
  options = {}
) => {
  const adjustedPrice = price / 100
  const symbol = SYMBOL_MAP[currency] || "$"

  return `${symbol}${adjustedPrice.toLocaleString(i18n.locale, {
    minimumFractionDigits: Number.isInteger(adjustedPrice) ? 0 : 2,
    maximumFractionDigits: Number.isInteger(adjustedPrice) ? 0 : 2,
    ...options,
  })}`
}
