import { Global, css } from "@emotion/react"
import { ThemeProvider, useTheme } from "@emotion/react"

import { api } from "../../api"
import { useStoreState } from "../StoreProvider"

const globalStyles = css`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
    font-family: Open Sans, sans-serif;
  }

  body {
    margin: 0;
    font-family: Open Sans, sans-serif;
  }

  body,
  input,
  textarea,
  select,
  option,
  button {
    font-family: Open Sans, sans-serif;
    outline: none;
  }
`

export type StoreTheme = {
  colors: api.stores.config.Get.Colors
  typographies: api.stores.config.Get.Typographies
}

export type PropsWithTheme = Partial<{ theme: StoreTheme }>

const StoreThemeProvider = ({
  children,
}: {
  children: import("react").ReactNode
}) => {
  const [state] = useStoreState()

  const storeTheme = {
    colors: state.config.colors,
    typographies: state.config.typographies,
  }

  return (
    <ThemeProvider theme={storeTheme}>
      <Global styles={globalStyles} />
      {children}
    </ThemeProvider>
  )
}

function useStoreTheme() {
  const context = useTheme()
  if (context === undefined) {
    throw new Error("useStoreTheme must be used within a StoreThemeProvider")
  }

  return context
}

export { useStoreTheme, StoreThemeProvider }
