import * as z from "zod"

export const PickUpPointRawSchema = z.object({
  postal_code: z.string(),
  street_address: z.string(),
  country: z.string(),
  id: z.number(),
  branch_id: z.number(),
  branch_name: z.string(),
  latitude: z.string(),
  longitude: z.string(),
  district: z.string(),
  city: z.string(),
  available_timeframe: z.string(),
})

export type PickUpPointRaw = z.infer<typeof PickUpPointRawSchema>

export const ResponseSchema = z.object({
  meta: z.object({
    total_count: z.number(),
  }),
  objects: z.array(PickUpPointRawSchema),
})

export type Response = z.infer<typeof ResponseSchema>

export const PickUpPointSchema = z.object({
  fullAddress: z.string(),
  id: z.number(),
  branchId: z.number(),
  branchName: z.string(),
  latitude: z.string(),
  longitude: z.string(),
  availableTimeFrame: z.string(),
})

export type PickUpPoint = z.infer<typeof PickUpPointSchema>

export const ResultSchema = z.object({
  totalCount: z.number(),
  pickUpPoints: z.array(PickUpPointSchema),
})

export type Result = z.infer<typeof ResultSchema>
