import { Controller, useFormContext } from "react-hook-form"
import { SingleColumnRow, TextArea } from "../../../../style"

import { AdditionalData } from "../../types"
import { ContainerCard } from "../../../../../../../../components/ContainerCard"
import { Trans } from "@lingui/macro"
import { useCheckout } from "../../../../../../../../hooks"
import { useEffect } from "react"

export const CardAdditionalInfo = () => {
  const { userComment } = useCheckout().data.meta
  const { control, setValue } = useFormContext<AdditionalData>()

  useEffect(() => {
    setValue("userComment", userComment || "")
  }, [userComment, setValue])

  return (
    <ContainerCard
      title={<Trans>¿Querés agregar información adicional?</Trans>}
    >
      <SingleColumnRow>
        <Controller
          control={control}
          name="userComment"
          defaultValue=""
          render={props => (
            <TextArea name="userComment" maxLength={1500} {...props} />
          )}
        />
      </SingleColumnRow>
    </ContainerCard>
  )
}
