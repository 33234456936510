import { colors, ecommerceColors } from "../../theme"
import { useEffect, useRef, useState } from "react"

import { Chevron } from "../../icons"
import { PropsWithTheme } from "../../providers/StoreThemeProvider"
import styled from "@emotion/styled/macro"
import { useOnClickOutside } from "../../hooks"

type Item = {
  id: string
  name: string
  value: string
  disabled?: boolean
  isHidden?: boolean
}

type Props = {
  name?: string
  disabled?: boolean
  value?: string
  onChange?: (v: string) => void
  errorMsg?: string
  label: import("react").ReactNode
  options: Item[]
  hideError?: boolean
}

export function DropdownField({
  name,
  disabled = false,
  hideError = false,
  value = "",
  onChange,
  errorMsg,
  label,
  options,
}: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const selectElement = useRef<HTMLDivElement | null>(null)
  const [currentOption, setCurrentOption] = useState<string>(() => value)
  const [currentName, setCurrentName] = useState<string>(value)

  useOnClickOutside(selectElement, e => {
    if (e.target !== selectElement.current) {
      setIsOpen(false)
    }
  })

  useEffect(() => {
    if (value !== currentOption) setCurrentOption(value)
  }, [currentOption, value])

  useEffect(() => {
    const option = options.find(option => option.value === currentOption)
    if (option != null) setCurrentName(option.name)
  }, [options, currentOption])

  return (
    <Container hideError={hideError} hasLabel={!!label}>
      <SelectContainer
        onClick={() => {
          if (!disabled) {
            setIsOpen(!isOpen)
          }
        }}
        id={name}
        ref={selectElement}
      >
        <Select disabled={disabled} hasError={!!errorMsg} hasValue={!!value}>
          {currentName}
        </Select>
        <ChevronContainer disabled={disabled}>
          <Chevron />
        </ChevronContainer>
        <Label disabled={disabled} hasValue={value}>
          {label}
        </Label>
        <Option key={"option-disabled-hidden"} hidden></Option>
        {isOpen && (
          <OptionsContainer>
            {options.map(option => (
              <Option
                isCurrentOption={currentOption === option.value}
                key={option.id}
                value={option.value}
                data-testid={option.id}
                onClick={() => {
                  setCurrentOption(option.value)
                  onChange != null && onChange(option.value)
                  setIsOpen(false)
                }}
                disabled={option.disabled}
                hidden={option.isHidden}
              >
                {option.name}
              </Option>
            ))}
          </OptionsContainer>
        )}
      </SelectContainer>
      {!!errorMsg && <ErrorText>{errorMsg}</ErrorText>}
    </Container>
  )
}

type ContainerProps = {
  hideError: boolean
  hasLabel: boolean
}

const Container = styled.div<PropsWithTheme & ContainerProps>(props => ({
  display: "grid",
  gridGap: props.hideError ? 0 : "0.6rem",
  gridTemplateRows: `${props.hasLabel ? `1.6rem` : 0} 2rem ${
    props.hideError ? 0 : `1.6rem`
  }`,
  backgroundColor: "transparent",
  fontSize: "1.4rem",
}))

const SelectContainer = styled.div({
  position: "relative",
  gridRowStart: 2,
  gridRowEnd: 3,
})

type SelectProps = {
  hasError: boolean
  hasValue: boolean
  disabled: boolean
}

const Select = styled.div<PropsWithTheme & SelectProps>(props => ({
  color: colors.greyDark,
  fontSize: "1.4rem",
  border: 0,
  width: "100%",
  borderBottom: `1px solid ${
    props.hasError
      ? colors.redMain
      : props.hasValue
      ? props.theme.colors.primary
      : colors.greyMain
  }`,
  cursor: "pointer",
  backgroundColor: "transparent",
  padding: "1px 2px",
  height: "100%",

  "&:focus": {
    borderBottom: `1px solid ${ecommerceColors.greyDark}`,
    backgroundColor: "transparent",
  },

  ...(props.disabled && {
    backgroundColor: "transparent",
    borderBottomColor: ecommerceColors.grey,
    color: ecommerceColors.grey,
    cursor: "not-allowed",

    "~ label": {
      color: ecommerceColors.grey,
    },
  }),
}))

const OptionsContainer = styled.div({
  position: "absolute",
  top: 28,
  left: 0,
  width: "100%",
  zIndex: 2,
  border: `1px solid ${colors.greyLight}`,
  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
  maxHeight: "21rem",
  overflowY: "auto",
})

type OptionProps = {
  isCurrentOption?: boolean
}

const Option = styled.button<OptionProps>(({ isCurrentOption }) => ({
  padding: "1.2rem",
  backgroundColor: colors.white,
  border: "none",
  cursor: "pointer",
  fontWeight: isCurrentOption ? 600 : 400,
  lineHeight: "1.6rem",
  minWidth: "100%",
  outline: "none",
  textAlign: "left",
  transition: "background-color 200ms ease-in-out",
  width: "max-content",

  "&:hover": {
    backgroundColor: colors.greyLighter,
  },
}))

type ChevronContainerProps = {
  disabled?: boolean
}

const ChevronContainer = styled.span<ChevronContainerProps>(props => ({
  position: "absolute",
  top: 2,
  right: 10,
  cursor: props.disabled ? "not-allowed" : "pointer",

  "& svg": {
    width: "1rem",
    color: props.disabled ? ecommerceColors.grey : colors.greyDark,
  },
}))

type LabelProps = {
  hasValue?: string
  disabled?: boolean
}

const Label = styled.div<PropsWithTheme & LabelProps>(
  ({ hasValue, disabled }) => ({
    fontSize: hasValue ? "1.2rem" : "1.4rem",
    position: "absolute",
    backgroundColor: "transparent",
    bottom: hasValue ? 25 : 2,
    left: "0.4rem",
    padding: 0,
    width: hasValue ? "auto" : "calc(100% - 2.4rem)",
    color: disabled
      ? colors.greyMain
      : hasValue
      ? ecommerceColors.greyDark
      : colors.greyMedium,
    pointerEvents: "none",
    transition: "all 200ms cubic-bezier(0.0, 0, 0.2, 1)",
    marginBottom: "0.6rem",
  })
)

const ErrorText = styled.span({
  color: colors.redMain,
  gridRowStart: 3,
  display: "grid",
  gridAutoFlow: "column",
  fontSize: "1.2rem",
})
