import { useEffect, useMemo, useState } from "react"

import { t } from "@lingui/macro"
import { useProductsStore } from "../provider/ProductStoreProvider"

export type SortingOption = {
  id: string
  value: "id" | "-id" | "-price" | "price"
  label: import("react").ReactNode
}

export function useSort() {
  const sortingOptions: SortingOption[] = useMemo(
    () => [
      { id: "1", label: t`Más nuevos`, value: "-id" },
      { id: "2", label: t`Menor precio`, value: "price" },
      { id: "3", label: t`Mayor precio`, value: "-price" },
    ],
    []
  )
  const { dispatch, ordering } = useProductsStore()
  const initialState = getDefaultSelected(ordering)
  const [selected, setSelected] = useState<SortingOption>(initialState)

  useEffect(() => {
    dispatch({
      type: "changeOrdering",
      payload: { ordering: selected.value, offset: 0 },
    })
  }, [selected, dispatch])

  function getDefaultSelected(ordering: string) {
    const selected = sortingOptions.find(option => option.value === ordering)
    return selected ? selected : sortingOptions[0]
  }

  return { sortingOptions, selected, setSelected }
}
