import * as variations from "./variations"

import { get } from "./get"

export function set(productID?: string) {
  return {
    variations: {
      get: variations.get(productID),
    },
    get: get(),
  }
}

export { variations }
export * as Get from "./get/types"
