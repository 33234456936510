import * as z from "zod"

export const LinkRawSchema = z.object({
  id: z.number(),
  name: z.string(),
  url: z.string(),
})

export type LinkRaw = z.infer<typeof LinkRawSchema>

export const FilterValueRawSchema = z.object({
  id: z.number(),
  name: z.string(),
  count: z.number(),
})

export type FilterValueRaw = z.infer<typeof FilterValueRawSchema>

export const FilterRawSchema = z.object({
  id: z.number(),
  name: z.string(),
  values: z.array(FilterValueRawSchema),
})

export type FilterRaw = z.infer<typeof FilterRawSchema>

export const SubcategoryRawSchema = z.object({
  id: z.number(),
  name: z.string(),
  url: z.string(),
  count: z.number(),
})

export type SubcategoryRaw = z.infer<typeof SubcategoryRawSchema>

export const MetaDataRawSchema = z.object({
  total_count: z.number(),
  breadcrumb: z.array(LinkRawSchema),
  attributes: z.array(FilterRawSchema),
  subcategories: z.array(SubcategoryRawSchema),
  offset: z.number(),
  limit: z.number(),
  elements: z.number(),
})

export type MetaDataRaw = z.infer<typeof MetaDataRawSchema>

export const ThumbnailsSchema = z.object({
  thumbnail: z.string(),
  small: z.string(),
  medium: z.string(),
  large: z.string(),
  full: z.string(),
})

export type Thumbnails = z.infer<typeof ThumbnailsSchema>

export const WarholeImageRawSchema = z.object({
  cover: z.object({
    thumbnails: ThumbnailsSchema,
  }),
})

export const ProductRawSchema = z.object({
  identifier: z.string(),
  date_created: z.number(),
  title: z.string(),
  max_price: z.number(),
  min_price: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  max_stock: z.number(),
  cover_image: WarholeImageRawSchema,
  free_shipping: z.boolean().optional(),
})

export type ProductRaw = z.infer<typeof ProductRawSchema>

export const ResponseSchema = z.object({
  meta: MetaDataRawSchema,
  objects: z.array(ProductRawSchema),
})

export type Response = z.infer<typeof ResponseSchema>

export const LinkSchema = z.object({
  id: z.string(),
  name: z.string(),
  url: z.string(),
})

export type Link = z.infer<typeof LinkSchema>

export const FilterValueSchema = z.object({
  id: z.number(),
  name: z.string(),
  count: z.number(),
})

export type FilterValue = z.infer<typeof FilterValueSchema>

export const FilterSchema = z.object({
  id: z.number(),
  name: z.string(),
  values: z.array(FilterValueSchema),
})

export type Filter = z.infer<typeof FilterSchema>

export const SubcategorySchema = z.object({
  id: z.number(),
  name: z.string(),
  url: z.string(),
  count: z.number(),
})

export type Subcategory = z.infer<typeof SubcategorySchema>

export const MetaDataSchema = z.object({
  totalCount: z.number(),
  breadcrumb: z.array(LinkSchema),
  attributes: z.array(FilterSchema),
  subcategories: z.array(SubcategorySchema),
  offset: z.number(),
  limit: z.number(),
  elements: z.number(),
})

export type MetaData = z.infer<typeof MetaDataSchema>

export const ProductSchema = z.object({
  identifier: z.string(),
  dateCreated: z.number(),
  title: z.string(),
  maxPrice: z.number(),
  minPrice: z.number(),
  currency: z.enum(["ARS", "BRL", "MXN"]),
  coverImage: z.string(),
  maxStock: z.number(),
  freeShipping: z.boolean().optional(),
})

export type Product = z.infer<typeof ProductSchema>

export const ResultSchema = z.object({
  meta: MetaDataSchema,
  objects: z.array(ProductSchema),
})

export type Result = z.infer<typeof ResultSchema>
