import { Response } from "../types"
import paths from "../../../paths"
import { service } from "../../../Service"
import { transformResponse } from ".."

export function del() {
  return async function (itemId: string) {
    try {
      const response = await service.delete<Response>(paths.carts(itemId))

      return transformResponse(response.body)
    } catch (error) {
      throw new Error(`API Carts did not respond correctly`)
    }
  }
}
