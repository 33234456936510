import { Property } from "../../../api/products/variations/get/types"
import { Variant } from "../types"
import { useProductStore } from "../provider/ProductStoreProvider"

type RelatedVariant = Omit<Variant, "values">

type ValuesOptions = { [index: number]: number[] }

/**
 *
 * @returns an object of values with its related values with  stock
 */
export function useValuesAvailableOptions() {
  const { product } = useProductStore()
  let valuesOptions: ValuesOptions = {}

  const hasOneProperty = product.properties.length === 1
  if (hasOneProperty) return valuesOptions

  product.properties.forEach(addValue)

  product.variants.forEach(variant => {
    const { values, ...restOfVariant } = variant

    addValuesRelations(values[0], values[1], restOfVariant)
  })

  function addValue(property: Property) {
    property.values.forEach(({ id }) => {
      valuesOptions = { ...valuesOptions, [id]: [] }
    })
  }

  function addValuesRelations(
    property: number,
    relatedProperty: number,
    relation: RelatedVariant
  ) {
    if (relation.stock > 0) {
      valuesOptions[property] = [...valuesOptions[property], relatedProperty]
      valuesOptions[relatedProperty] = [
        ...valuesOptions[relatedProperty],
        property,
      ]
    }
  }

  return valuesOptions
}
