import { Response } from "../types"
import paths from "../../../paths"
import { service } from "../../../Service"

export function del() {
  return async function () {
    try {
      const response = await service.delete<Response>(paths.checkout())

      return response
    } catch (error) {
      console.log(error)

      throw new Error(`API DELETE Checkout did not respond correctly`)
    }
  }
}
