import { Container } from "./Container"

export function Error({ ...props }) {
  return (
    <Container>
      <path
        d="M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0ZM9,16.3636A7.3636,7.3636,0,1,1,16.3636,9,7.359,7.359,0,0,1,9,16.3636Z"
        fill="currentColor"
      />
      <path
        d="M13.5,4.5a.8149.8149,0,0,0-1.1659,0L9,7.834,5.6659,4.52A.8244.8244,0,0,0,4.5,5.6863L7.8341,9,4.52,12.3341A.8174.8174,0,1,0,5.6659,13.5L9,10.1659,12.3341,13.5A.8172.8172,0,0,0,13.48,12.3341L10.1659,9,13.5,5.6659A.8149.8149,0,0,0,13.5,4.5Z"
        fill="currentColor"
      />
    </Container>
  )
}
