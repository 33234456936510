import { Response, Result } from "./types"

import paths from "../../paths"
import { service } from "../../Service"

export async function get() {
  try {
    const response = await service.get<Response>(paths.maintenanceMode())
    if (response.status.code >= 400 && response.status.code < 600) {
      throw new Error(response.status.message)
    }

    return transformResponse(response.body)
  } catch (error) {
    console.error(error)
    throw new Error("API Maintenance Mode did not respond correctly")
  }
}

function transformResponse(response: Response): Result {
  return {
    logo: response.logo,
    shopName: response.shopName,
    maintenanceMessage: response.maintenance_message,
  }
}
