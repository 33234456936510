import { FC, createContext } from "react"

import { ElementsProvider } from "store-components"
import { useScreenSize } from "../../hooks"

export const StoreComponentsElementsContext = createContext(null)

export const StoreComponentsElementsProvider: FC = ({ children }) => {
  const { isMobile } = useScreenSize()

  return (
    <ElementsProvider context="ecommerce" isMobile={isMobile}>
      {children}
    </ElementsProvider>
  )
}
