export function getProductVIPUrl(title: string) {
  const urlPrefix = title
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .replace(/[^\w\s]/g, "") // Remove non-word characters
    .replace(/_/g, "")
    .trim()
    .toLowerCase()
    .split(/\s+/)
    .join("-")

  return urlPrefix
}
