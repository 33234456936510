import { Controller, useFormContext } from "react-hook-form"
import { ErrorMsg, SingleColumnRow } from "../../../../../../style"
import { Trans, t } from "@lingui/macro"

import { ContainerCard } from "../../../../../../../../../../components/ContainerCard"
import { ShippingOptionsS as OptionsS } from "../../../../../../../../../../api/stores/checkout"
import { ShippingData } from "../../../../types"
import { ShippingOptionCard } from "../ShippingOptionCard"
import { useCheckout } from "../../../../../../../../../../hooks"
import { useShippingOptions } from "../../hooks/useShippingOptions"

export const ShippingOptionsS = () => {
  useShippingOptions()
  const { control, errors } = useFormContext<ShippingData>()
  const {
    requirements: { shippingOptions },
  } = useCheckout().data.meta

  return (
    <ContainerCard title={<Trans>Puntos de retiro</Trans>}>
      <SingleColumnRow>
        <Controller
          control={control}
          name="shippingOption"
          rules={{
            required: t`Elegí una opción para poder continuar.`,
          }}
          render={({ onChange, value }) => (
            <>
              {(shippingOptions as OptionsS).options.map(
                ({
                  street,
                  streetNumber,
                  streetExtra,
                  zipCode,
                  city,
                  state,
                  hours,
                  ...shippingOption
                }) => (
                  <ShippingOptionCard
                    key={shippingOption.id}
                    label={shippingOption.name}
                    hasError={!!errors.shippingOption}
                    value={shippingOption.id}
                    checked={value === shippingOption.id}
                    onChange={onChange}
                    price={<Trans>Gratis</Trans>}
                    description={
                      <span>
                        <Trans>
                          {street}, {streetNumber}
                          {streetExtra ? `, ${streetExtra}` : ""} - {city},{" "}
                          {state}, CP: {zipCode} <br />
                          {hours}
                        </Trans>
                      </span>
                    }
                  />
                )
              )}
            </>
          )}
        />
        {errors.shippingOption && (
          <ErrorMsg>{errors.shippingOption.message}</ErrorMsg>
        )}
      </SingleColumnRow>
    </ContainerCard>
  )
}
