import { Response, Result } from "./types"

import paths from "../../paths"
import queryString from "query-string"
import { service } from "../../Service"

interface Query {
  offset: number | undefined
  limit: number | undefined
  ordering: string | undefined
  search: string | undefined
  filtersId: string | undefined
  stockGt: string | undefined
  priceGt: string | undefined
  priceLt: string | undefined
  categoryPath: string | undefined
}

export function get() {
  return async function get({
    categoryPath,
    filtersId,
    limit,
    offset,
    ordering,
    priceGt,
    priceLt,
    search,
    stockGt,
  }: Query) {
    const query = queryString.stringify({
      search,
      offset,
      limit,
      value_in: filtersId,
      stock_gt: stockGt,
      ordering,
      price_gte: priceGt,
      price_lte: priceLt,
      "category-path_eq": categoryPath === "" ? undefined : categoryPath,
    })

    let response = await service.get<Response>(
      paths.products(window.STORE_ID) + `?${query}`
    )

    return transformResponse(response.body)
  }

  function transformResponse(response: Response): Result {
    const meta = {
      totalCount: response.meta.total_count,
      breadcrumb: response.meta.breadcrumb.map(breadcrumb => {
        return {
          id: `${breadcrumb.id}`,
          url: breadcrumb.url,
          name: breadcrumb.name,
        }
      }),
      attributes: response.meta.attributes,
      subcategories: response.meta.subcategories,
      limit: response.meta.limit,
      offset: response.meta.offset,
      elements: response.meta.elements,
    }

    const objects = response.objects.map(product => {
      return {
        identifier: product.identifier,
        dateCreated: product.date_created,
        title: product.title,
        maxPrice: product.max_price,
        minPrice: product.min_price,
        currency: product.currency,
        maxStock: product.max_stock,
        coverImage: product.cover_image.cover.thumbnails.medium,
        freeShipping: product.free_shipping,
      }
    })

    return {
      meta,
      objects,
    }
  }
}
