import {
  ShippingOptionsC,
  ShippingOptionsS,
  ShippingOptionsZ,
} from "../api/stores/checkout"
import { useEffect, useState } from "react"

import { useCheckout } from "./useCheckout"

type ShippingOptionS = ShippingOptionsS["options"][0]
type ShippingOptionC = ShippingOptionsC["options"][0]
type ShippingOptionZ = ShippingOptionsZ["options"][0]
type ShippingOption = ShippingOptionS | ShippingOptionC | ShippingOptionZ

export function useShippingOption() {
  const [shippingPrice, setShippingPrice] = useState(0)
  const [shippingOption, setShippingOption] = useState<
    ShippingOption | undefined
  >()
  const {
    shipping,
    requirements: { shippingOptions },
  } = useCheckout().data.meta

  useEffect(() => {
    const shippingOption = (shippingOptions as ShippingOptionsS).options.find(
      shippingOption => shippingOption.id === shipping?.id
    )

    setShippingOption(shippingOption)

    if (shippingOption) {
      const price = (shippingOption as { price?: number })?.price || 0
      setShippingPrice(price)
    }
  }, [shippingOptions, shipping])

  return { shippingPrice, shippingOption }
}
