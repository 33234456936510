import { colors, mediaQuery } from "../../theme"

import { PropsWithTheme } from "../../providers/StoreThemeProvider"
import { Spinner } from "../../icons"
import { readableColor } from "polished"
import styled from "@emotion/styled/macro"
import { useStoreState } from "../../providers/StoreProvider"

type Props = {
  children: import("react").ReactNode
  onClick?: (
    e: import("react").MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  disabled?: boolean
  notAllowed?: boolean
  tooltipText?: string
  loading?: boolean
  spinnerSize?: string
  height?: string
  type?: import("react").ButtonHTMLAttributes<HTMLButtonElement>["type"]
}

export function Button({
  children,
  onClick,
  disabled,
  notAllowed,
  tooltipText,
  loading,
  spinnerSize = "2.4rem",
  height = "4rem",
  type = "button",
}: Props) {
  const [state] = useStoreState()

  return (
    <Container
      data-testid="button"
      onClick={onClick}
      notAllowed={notAllowed}
      disabled={disabled}
      title={tooltipText}
      height={height}
      type={type}
    >
      {loading ? (
        <Spinner
          size={spinnerSize}
          color={readableColor(state.config.colors.primary)}
        />
      ) : (
        children
      )}
    </Container>
  )
}

type ContainerProps = {
  notAllowed?: boolean
  disabled?: boolean
  height: string
}

const Container = styled.button<PropsWithTheme & ContainerProps>(props => {
  const primaryColor = props.theme.colors.primary
  return {
    width: "100%",
    height: props.height,
    border: "none",
    borderRadius: 4,
    backgroundColor:
      props.notAllowed || props.disabled ? colors.greyMainLight : primaryColor,
    color: readableColor(
      props.notAllowed || props.disabled ? colors.greyMainLight : primaryColor
    ),
    cursor: props.notAllowed ? "not-allowed" : "pointer",
    fontSize: "1.4rem",
    fontFamily: props.theme.typographies.titles,
    padding: "0.8rem 1.6rem",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [mediaQuery.desktop]: {
      fontSize: "1.6rem",
      padding: "0.8rem 0 0.8rem",
      backgroundColor: primaryColor,
      color: readableColor(primaryColor),
      opacity: props.notAllowed || props.disabled ? 0.5 : 1,

      ...(!props.disabled &&
        !props.notAllowed && {
          "&:hover": {
            opacity: 0.9,
          },
        }),
    },
  }
})
