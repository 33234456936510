import { PropsWithTheme } from "../../providers/StoreThemeProvider"
import { api } from "../../api"
import { mediaQuery } from "../../theme"
import styled from "@emotion/styled/macro"

type Props = {
  subtitle: import("react").ReactNode
  description: import("react").ReactNode
  type: string
  methods: api.paymentMethods.Get.MPPaymentMethod[]
}

export function PaymentMethod({ subtitle, description, type, methods }: Props) {
  return (
    <Container>
      <SubTitle>{subtitle}</SubTitle>
      <Description>{description}</Description>

      {methods.map(paymentMethod => {
        const { name, paymentTypeId, secureThumbnail } = paymentMethod
        const isCurrentPaymentType = paymentTypeId === type
        const isOtherPaymentType =
          type === "other" &&
          paymentTypeId !== "credit_card" &&
          paymentTypeId !== "debit_card"

        if (isCurrentPaymentType || isOtherPaymentType) {
          return (
            <Image
              key={name + paymentTypeId}
              src={secureThumbnail}
              alt={name}
            />
          )
        } else {
          return null
        }
      })}
    </Container>
  )
}

const Container = styled.div({})

const SubTitle = styled.h3<PropsWithTheme>(props => ({
  fontSize: "1.6rem",
  fontWeight: "normal",
  marginBottom: "0.4rem",
  marginTop: 0,
  color: props.theme.colors.texts,
  fontFamily: props.theme.typographies.texts,
}))

const Description = styled.p<PropsWithTheme>(props => ({
  fontSize: "1.4rem",
  margin: "0 0 1.2rem",
  color: props.theme.colors.texts,
  fontFamily: props.theme.typographies.texts,

  [mediaQuery.desktop]: {
    fontsize: "1.2rem",
  },
}))

const Image = styled.img({
  width: "5.2rem",
  height: "auto",
  objectFit: "cover",
  paddingRight: "1.2rem",
})
