import { BillingForm } from "./components/BillingForm"
import { ContainerCard } from "../../../../../../../../components/ContainerCard"
import { Trans } from "@lingui/macro"
import { ZippinBilling } from "./components/ZippinBilling"
import { useCheckout } from "../../../../../../../../hooks"

export const CardBilling = () => {
  const { shipping } = useCheckout().data.meta

  return (
    <ContainerCard title={<Trans>Datos de facturación</Trans>}>
      {shipping?.shippingType === "Z" ? <ZippinBilling /> : <BillingForm />}
    </ContainerCard>
  )
}
